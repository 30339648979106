import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SettingsModuleFeatureName } from './settings.reducer';
import { SettingsModuleState } from '@dunefront/common/modules/settings/settings-module.state';
import { getCurrentAppModuleType } from '../ui/ui.selectors';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { WellVisualizationType } from '@dunefront/common/modules/settings/dto/settingsDto';

export const getSettingsModuleState = createFeatureSelector<SettingsModuleState>(SettingsModuleFeatureName);
export const getWellVisualizationType = createSelector(
  getSettingsModuleState,
  getCurrentAppModuleType,
  (state, appModule) =>
    state.wellVisualizationType ??
    (appModule === ModuleType.Simulate_Disp ? WellVisualizationType.Mixed_Concentration : WellVisualizationType.Packing),
);
