import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { updateSearchPhrase } from '../../../+store/file-manager/file-manager.actions';
import { getSearchPhrase } from '../../../+store/file-manager/file-manager.selectors';

@Component({
  selector: 'app-file-filter',
  templateUrl: 'file-filter.component.html',
  styleUrls: ['./file-filter.component.scss'],
})
export class FileFilterComponent {
  public searchPhrase$ = this.store.select(getSearchPhrase);

  constructor(private store: Store) {}

  public clearSearchPhrase(): void {
    this.store.dispatch(updateSearchPhrase({ searchPhrase: '' }));
  }

  public onChange(event: any): void {
    this.store.dispatch(updateSearchPhrase({ searchPhrase: event.value }));
  }
}
