import { WellModuleState } from '@dunefront/common/modules/well/well-module.state';
import { DocumentGeneratorHelper } from '../doc/document-generator.helper';
import { getSelectItemValue, ISelectItem } from '@dunefront/common/common/select.helpers';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { LocationType, TemperatureProfileType, TreatmentType } from '@dunefront/common/modules/well/dto/well.dto';
import { IWellGeneralData } from '../doc/sections/well-report-generator.helper';
import { ReportInfoDto } from '@dunefront/common/dto/report-info.dto';
import { Pipe } from '@dunefront/common/modules/pipes/pipe';
import { PipeType } from '@dunefront/common/dto/pipe.dto';
import { casingDataGridConfig } from '../../../../../pages/simulate-evaluate-page/well/casing-data/casing-data-grid/casing-data-grid.config';
import { ZoneModel } from '@dunefront/common/modules/well/model/zone/zone.model';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { getReservoirGridConfig } from '../../../../../+store/well/well.grid.selectors';
import { lowerCompletionGridConfig } from '../../../../../pages/simulate-evaluate-page/completion/lower-completion/lower-completion-grid/lower-completion-grid.config';
import { IUnitSystemDto, NoneUnit, UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { runningStringGridConfig } from '../../../../../pages/simulate-evaluate-page/completion/running-string/running-string-grid/running-string-grid.config';
import { CompletionModuleState, ValidatedCompletionModuleState } from '@dunefront/common/modules/completion/completion-module.state';
import { TubeShape } from '@dunefront/common/dto/shunt-tube.dto';
import { ReportShuntTube } from '../doc/sections/completion-report-generator.helper';
import { getRowsForCalculations, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { SectionInformationRow } from '@dunefront/common/modules/completion/model/volumes/section-information-row';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { getGaugeSectionInfoGridConfig } from '../../../../../pages/simulate-evaluate-page/completion/volumes/gauge-section-info-grid/gauge-section-info-grid.config';
import { FluidModuleState } from '@dunefront/common/modules/fluid/fluid-module.state';
import { ValidatedSettings } from '@dunefront/common/modules/settings/dto/settingsDto';
import { Fluid } from '@dunefront/common/modules/fluid/model/fluid';
import { FluidType } from '@dunefront/common/modules/fluid/dto/fluid.dto';
import { DictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { RheologyCalculations } from '@dunefront/common/modules/fluid/dto/rheology/rheology.calculations';
import { fluidsProperties } from './grid-configs/fluids';
import { ImportColumnDto } from '@dunefront/common/modules/data-storage/dto/import-column.dto';
import { getAllStorageColumnsArray } from '../../../../../+store/data-storage/data-storage.selectors';
import { DataType } from '@dunefront/common/dto/data-storage';
import { GaugeMeasurementType } from '@dunefront/common/dto/running-string-pipe.dto';
import { UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import { ColumnType, IGridColumnConfig } from '../../../../components/grid/grid.interfaces';
import { IReportImportColumn } from '../doc/sections/imported-data-generator.helper';
import { Pumping } from '@dunefront/common/modules/pumping/model/pumping/pumping';
import {
  ChokeSetting,
  HydrilState,
  PumpingDirection,
  PumpingExtensions,
  ToolPosition,
  VariableChokeSetting,
} from '@dunefront/common/modules/pumping/dto/pumping.dto';
import {
  annularBOPProperties,
  chokeProperties,
  detailedWellFluidsProperties,
  openBOPProperties,
  pumpingInitialLineProperties,
  pumpingInitialProperties,
  simpleWellFluidsProperties,
} from './grid-configs/pumping';
import { ValidatedPumpingModuleState } from '@dunefront/common/modules/pumping/pumping-module.state';
import { IFluidSelectorData } from '../../../../../+store/fluid/fluid.selectors';
import { IGravelSelectorData } from '../../../../../+store/gravel/gravel.selectors';
import { ReportPumpingSchedule, ReportPumpingSimpleFluid, ReportWellFluid } from '../doc/sections/pumping-report-generator.helper';
import { ReferenceVariableCalculatorResultsDto } from '@dunefront/common/modules/reference-variables/dto/reference-variable-calculator-results.dto';
import { pumpingRefParamsProperties } from './grid-configs/pumping-schedule';
import { PumpingSchedule } from '@dunefront/common/modules/pumping/model/pumping-schedule/pumping-schedule';
import { scheduleGridConfig } from '../../../../../pages/simulate-evaluate-page/pumping/schedule/schedule-grid/schedule-grid.config';
import { SummaryModuleState } from '../../../../../+store/summary/summary.reducer';
import { WarningMessage } from '@dunefront/common/modules/summary/warning-message.factory';
import { SummaryDto } from '@dunefront/common/dto/summary.dto';
import {
  summaryFinalFracDataProperties,
  summaryGravelBelowPackerProperties,
  summaryMaxFrictionPressureProperties,
  summaryMaxTotalECDsProperties,
  summaryMaxTotalPressureProperties,
  summaryPackEfficienciesProperties,
} from './grid-configs/summary';
import { ReportScenarioImages } from '../doc/sections/chart-report-generator.helper';
import { getPsdDict, getPsdReadingsDict } from '../../../../../+store/psd-analysis/psd-analysis.selectors';
import { Base64Image } from '../../../../../common-modules/chart/image-provider.helpers';
import { getPsdPropertiesGridConfig, psdDefinition, psdSandProduction } from './grid-configs/psd';
import { PSDInputType } from '@dunefront/common/modules/psd-analysis/dto/psd.dto';
import { PsdReading } from '@dunefront/common/modules/psd-analysis/model/psd-readings/psd-reading';
import { getPsdDataGridConfig } from '../../../../../pages/psd-analysis-page/psd-input/psd-data/psd-data-grid/psd-data-grid.config';
import { PsdSandProductionInputs } from '@dunefront/common/modules/psd-analysis/dto/psd-sand-production.inputs';
import { PSDResult } from '@dunefront/common/modules/psd-analysis/model/psd/psd';
import { getSummaryGridConfig } from '../../../../../pages/psd-analysis-page/psd-results/summary/summary-grid/summary-grid.config';
import { PSDValidation } from '@dunefront/common/modules/psd-analysis/model/psd/psd.validation';
import { IValidatedScenarioRangeProperties } from '@dunefront/common/modules/range/model/range';
import { trendAnalysisInputs } from './grid-configs/trend-analysis-inputs';
import { getStandaloneScreensGridConfig } from '../../../../../pages/psd-analysis-page/psd-results/screen-and-gravel-selection/standalone-screens-grid/standalone-screens-grid.config';
import { gravelPackGridConfig } from '../../../../../pages/psd-analysis-page/psd-results/screen-and-gravel-selection/gravel-pack-grid/gravel-pack-grid.config';
import { PSDAnalysis } from '@dunefront/common/modules/psd-analysis/model/psd-analysis/psd-analysis';
import { HeadingLevel, Paragraph, TextRun } from 'docx';
import { defaultIndexCellWidth, mainFont } from '../../report-consts';
import { PresetColumnsWidths } from '../doc/document-table-generator.helper';
import { getCurrentAppModuleType } from '../../../../../+store/ui/ui.selectors';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { hideWarningMessagesTime } from '../../../e2e.helpers';

export class CommonReportDataHelpers {
  public static createGeneralData(wellModuleState: WellModuleState): KeyValueDataWithEnumMaps<IWellGeneralData> {
    const keyValueData: IDataRowWithCode<IWellGeneralData> = {
      TreatmentType: wellModuleState.TreatmentType,
      LocationType: wellModuleState.LocationType,
      SeaLevelDepth: wellModuleState.TemperatureProfile.SeaLevelDepth,
      SeaBedDepth: wellModuleState.TemperatureProfile.SeaBedDepth,
      BottomholeMD: Math.max(...wellModuleState.SurveyData.rows.map((row) => row.rowData.MD)),
      BottomholeTVD: Math.max(...wellModuleState.SurveyData.rows.map((row) => row.rowData.TVD)),
      MaxDeviation: Math.max(...wellModuleState.SurveyData.rows.map((row) => row.rowData.Deviation)),
      TemperatureProfile: wellModuleState.TemperatureProfile.TemperatureProfileType,
      IsLossRateCalculated: wellModuleState.IsLossRateCalculated,
    };
    const lossDefinitions = [
      { value: 1, text: 'Calculate Losses' },
      { value: 0, text: 'Specify Losses' },
    ];
    const enumItemMap = new Map<keyof IWellGeneralData, ISelectItem<any>[]>();
    enumItemMap.set('TreatmentType', EnumHelpers.EnumToISelectItemArray(TreatmentType));
    enumItemMap.set('LocationType', EnumHelpers.EnumToISelectItemArray(LocationType));
    enumItemMap.set('TemperatureProfile', EnumHelpers.EnumToISelectItemArray(TemperatureProfileType));
    enumItemMap.set('IsLossRateCalculated', lossDefinitions);

    return { keyValueData, enumItemMap };
  }

  public static createCompletionGeneralData(
    completionState: ValidatedCompletionModuleState,
  ): KeyValueDataWithEnumMaps<Partial<ValidatedCompletionModuleState>> {
    const keyValueData: IDataRowWithCode<Partial<ValidatedCompletionModuleState>> = {
      HasCentralizers: completionState.HasCentralizers,
      WashpipeEccentricity: completionState.WashpipeEccentricity,
      CentralizerOD: completionState.CentralizerOD,
    };

    return { keyValueData, enumItemMap: new Map() };
  }

  public static createReportInformationBulletPoints(reportInfo: ReportInfoDto, date: string): IBulletPoint[] {
    return [
      { label: 'Well', value: reportInfo.Well, level: 0, tabulations: 5 },
      { label: 'Field', value: reportInfo.Field, level: 0, tabulations: 5 },
      { label: 'Country', value: reportInfo.Country, level: 0, tabulations: 5 },
      { label: 'Date', value: date, level: 0, tabulations: 5 },
      { label: 'Prepared By', value: '', level: 0 },
      { label: 'Name', value: reportInfo.PreparedByName, level: 1, tabulations: 4 },
      { label: 'Company', value: reportInfo.PreparedByCompany, level: 1, tabulations: 3 },
      { label: 'Phone', value: reportInfo.PreparedByPhone, level: 1, tabulations: 4 },
      { label: 'Email', value: reportInfo.PreparedByEmail, level: 1, tabulations: 4 },
      { label: 'Prepared For', value: '', level: 0 },
      { label: 'Name', value: reportInfo.PreparedForName, level: 1, tabulations: 4 },
      { label: 'Company', value: reportInfo.PreparedForCompany, level: 1, tabulations: 3 },
      { label: 'Phone', value: reportInfo.PreparedForPhone, level: 1, tabulations: 4 },
      { label: 'Email', value: reportInfo.PreparedForEmail, level: 1, tabulations: 4 },
    ];
  }

  public static createCasingTableData(casingData: IDataRowWithCode<Pipe>[]): TableDataWithEnumMaps<Pipe> {
    const enumItemMap = new Map<keyof Pipe, ISelectItem<number>[]>();
    enumItemMap.set('PipeType', EnumHelpers.EnumToISelectItemArray(PipeType));

    const tableData: ITableData<Pipe> = {
      dataRows: casingData,
      dataCells: this.gridColConfigToTableColConfig(casingDataGridConfig),
    };

    return { tableData, enumItemMap };
  }

  public static async createReservoirZoneTableData(
    zoneData: IDataRowWithCode<ZoneModel>[],
    store: Store,
  ): Promise<TableDataWithEnumMaps<ZoneModel>> {
    const enumItemMap = new Map<keyof ZoneModel, ISelectItem<number>[]>();

    const dataCells = this.gridColConfigToTableColConfig(
      (await firstValueFrom(store.select(getReservoirGridConfig))).filter((col) => col.visible),
    );
    const tableData: ITableData<ZoneModel> = {
      dataRows: zoneData,
      dataCells,
    };

    return { tableData, enumItemMap };
  }

  public static createLowerCompletionTableData(lowerCompletionData: IDataRowWithCode<Pipe>[]): TableDataWithEnumMaps<Pipe> {
    const enumItemMap = new Map<keyof Pipe, ISelectItem<any>[]>();
    enumItemMap.set('PipeType', EnumHelpers.EnumToISelectItemArray(PipeType));

    const tableData: ITableData<Pipe> = {
      dataRows: lowerCompletionData,
      dataCells: this.gridColConfigToTableColConfig(lowerCompletionGridConfig),
    };

    return { tableData, enumItemMap };
  }

  public static createRunningStringTableData(runningStringData: IDataRowWithCode<Pipe>[]): TableDataWithEnumMaps<Pipe> {
    const enumItemMap = new Map<keyof Pipe, ISelectItem<any>[]>();
    enumItemMap.set('PipeType', EnumHelpers.EnumToISelectItemArray(PipeType));

    const tableData: ITableData<Pipe> = {
      dataRows: runningStringData,
      dataCells: this.gridColConfigToTableColConfig(runningStringGridConfig),
    };

    return { tableData, enumItemMap };
  }

  public static createShuntTubeSystemConfigurationKeyValueData(
    completionState: CompletionModuleState,
  ): KeyValueDataWithEnumMaps<ReportShuntTube> {
    const shuntTube = completionState.ShuntTube;
    const booleanToText = (value: boolean): string => (value ? 'Yes' : 'No');

    const enumItemMap = new Map<keyof ReportShuntTube, ISelectItem<any>[]>();
    enumItemMap.set('Shape', EnumHelpers.EnumToISelectItemArray(TubeShape));

    const keyValueData: IDataRowWithCode<ReportShuntTube> = {
      ...shuntTube,
      Shroud: booleanToText(shuntTube.Shroud.IsShroudPresent),
      IsInternalReservoir: booleanToText(shuntTube.IsInternalReservoir),
      IsTransportTubePresent: booleanToText(shuntTube.IsTransportTubePresent),
      IsExternal: shuntTube.IsExternal ? 'External' : 'Internal',
    };

    return { keyValueData, enumItemMap };
  }

  public static createVolumesGaugeSectionTableData(
    volumeGaugeSection: ITableState<SectionInformationRow>,
    currentUnitSystem: IUnitSystemDto,
    extraConfig: ExtraConfig,
  ): TableDataWithEnumMaps<SectionInformationRow> {
    const convertUnitPipe = new ConvertUnitPipe();

    const gaugeSection = this.generateCodeValues(
      volumeGaugeSection.rows.map((row) => ({
        ...row.rowData,
        Name: convertUnitPipe.transform(row.rowData.Name, currentUnitSystem),
      })),
    );

    const tableData: ITableData<SectionInformationRow> = {
      dataRows: gaugeSection,
      dataCells: this.gridColConfigToTableColConfig(getGaugeSectionInfoGridConfig(extraConfig.isFluidPro).filter((col) => col.visible)),
    };

    return { tableData };
  }

  public static async createFluidsTableData(
    store: Store,
    fluidsState: FluidModuleState,
    wellState: WellModuleState,
    settings: ValidatedSettings,
  ): Promise<TableDataWithEnumMaps<ReportFluid>> {
    const enumItemMap = new Map<keyof Fluid, ISelectItem<any>[]>();
    enumItemMap.set('Type', EnumHelpers.EnumToISelectItemArray(FluidType));

    const surfaceTemp = wellState.TemperatureProfile.SurfaceTemp;
    const bhct = wellState.TemperatureProfile.BottomholeCirculating;
    const shearRate = settings.ShearRate;

    const isFluidPro = (await firstValueFrom(store.select(getCurrentAppModuleType))) === ModuleType.Simulate_Disp;

    const mappedFluids: ReportFluid[] = DictionaryWithArray.getArray(fluidsState.Fluids).map((fluid) => {
      const fluidRheologies =
        DictionaryWithArray.get(fluidsState.Rheologies, fluid.Id)
          ?.rows.filter((rh) => rh.rowType !== 'insert-row')
          .map((rheology) => rheology.rowData)
          .sort((a, b) => a.Temperature - b.Temperature) || [];

      return {
        ...fluid,
        ViscosityAtSurfaceTemp: RheologyCalculations.getViscosity(surfaceTemp, shearRate, fluidRheologies),
        ViscosityAtBHCT: RheologyCalculations.getViscosity(bhct, shearRate, fluidRheologies),
      };
    });

    const dataCells = fluidsProperties(shearRate).filter(
      (column) => (isFluidPro && column.colId !== 'IsGravelSettling') || (!isFluidPro && column.colId !== 'IsYieldStress'),
    );

    const tableData: ITableData<ReportFluid> = {
      dataRows: mappedFluids,
      dataCells,
    };

    return { tableData, enumItemMap };
  }

  public static async createImportedDataTableData(
    store: Store,
    currentUnitSystem: IUnitSystemDto,
  ): Promise<TableDataWithEnumMaps<IReportImportColumn>> {
    const importedColumns: ImportColumnDto[] = await firstValueFrom(store.select(getAllStorageColumnsArray));

    const enumItemMap = new Map<keyof ImportColumnDto, ISelectItem<any>[]>();
    enumItemMap.set('DataType', EnumHelpers.EnumToISelectItemArray(DataType));
    enumItemMap.set('MeasurementType', EnumHelpers.EnumToISelectItemArray(GaugeMeasurementType));

    const repImportedCols: IDataRowWithCode<IReportImportColumn>[] = importedColumns
      .filter((col) => col.DataType !== DataType.Time)
      .map((importedCol) => {
        const currentUnit = currentUnitSystem[UnitSystem[importedCol.UnitSystem] as keyof IUnitSystemDto];
        const unit = typeof currentUnit === 'number' ? currentUnit : 0;
        const siOffset = UnitConverterHelper.getUnitConverter(importedCol.UnitSystem).fromSi(0, unit);
        return {
          ...importedCol,
          VerticalOffset: `${DocumentGeneratorHelper.formatNumber(
            (UnitConverterHelper.getUnitConverter(importedCol.UnitSystem).fromSi(importedCol.VerticalOffset, unit) - siOffset).toFixed(2),
          )} ${CommonReportDataHelpers.getCellUnit(currentUnitSystem, importedCol.UnitSystem)}`,
        };
      });

    const tableData: ITableData<IReportImportColumn> = {
      dataRows: repImportedCols,
      dataCells: [
        { colId: 'ColumnName', headerText: 'Name', unitSystem: UnitSystem.None, type: ColumnType.text },
        { colId: 'DataType', headerText: 'Data Type', unitSystem: UnitSystem.None, type: ColumnType.select },
        {
          colId: 'MeasurementType',
          headerText: 'Measurement Type',
          unitSystem: UnitSystem.None,
          type: ColumnType.select,
          decimalPlaces: 2,
        },
        {
          colId: 'MeasuredDepth',
          headerText: 'MD',
          unitSystem: UnitSystem.Long_Length,
          type: ColumnType.number,
          decimalPlaces: 2,
        },
        {
          colId: 'VerticalOffset',
          headerText: 'Offset',
          unitSystem: UnitSystem.None,
          type: ColumnType.text,
          decimalPlaces: 2,
        },
        {
          colId: 'Multiplier',
          headerText: 'Multiplier',
          unitSystem: UnitSystem.None,
          type: ColumnType.number,
          decimalPlaces: 2,
        },
        {
          colId: 'SmoothingPoints',
          headerText: 'Averaging',
          unitSystem: UnitSystem.None,
          type: ColumnType.number,
          decimalPlaces: 2,
        },
      ],
    };

    return { tableData, enumItemMap };
  }

  public static createPumpingInitialDataTableData(
    pumpingInitialState: Pumping,
    currentUnitSystem: IUnitSystemDto,
  ): ITableData<KeyValueTableRow>[] {
    const enumItemMap = new Map<keyof Pumping, ISelectItem<any>[]>();
    enumItemMap.set('ToolPosition', EnumHelpers.EnumToISelectItemArray(ToolPosition));
    enumItemMap.set('PumpingDirection', EnumHelpers.EnumToISelectItemArray(PumpingDirection));
    enumItemMap.set('HydrilState', EnumHelpers.EnumToISelectItemArray(HydrilState));
    enumItemMap.set('ChokePosition', EnumHelpers.EnumToISelectItemArray(ChokeSetting));
    enumItemMap.set('VariableChoke', EnumHelpers.EnumToISelectItemArray(VariableChokeSetting));

    const tableFromProps = (props: IReportTableColumnConfig<Pumping>[]): ITableData<KeyValueTableRow> =>
      CommonReportDataHelpers.createGenericKeyValueTableData(props, pumpingInitialState, currentUnitSystem, enumItemMap);

    const bopSection: ITableData<KeyValueTableRow> =
      pumpingInitialState.HydrilState === HydrilState.Open ? tableFromProps(openBOPProperties) : tableFromProps(annularBOPProperties);

    const chokeSection: ITableData<KeyValueTableRow> = tableFromProps(chokeProperties(pumpingInitialState));

    return [
      tableFromProps(pumpingInitialProperties),
      tableFromProps(pumpingInitialLineProperties(pumpingInitialState.IsTreatingLine, pumpingInitialState.IsCoiledTubing)),
      bopSection,
      chokeSection,
    ];
  }

  public static createSimplePumpingWellFluidsTableData(
    pumpingInitialState: Pumping,
    fluidsSelectData: IFluidSelectorData,
    currentUnitSystem: IUnitSystemDto,
  ): ITableData<KeyValueTableRow> {
    const fluids: ReportPumpingSimpleFluid = {
      WorkstringFluidId: this.getFluid(pumpingInitialState.WorkstringFluidId, fluidsSelectData),
      UpperAnnulusFluidId: this.getFluid(pumpingInitialState.UpperAnnulusFluidId, fluidsSelectData),
      WashpipeFluidId: this.getFluid(pumpingInitialState.WashpipeFluidId, fluidsSelectData),
      LowerAnnulusFluidId: this.getFluid(pumpingInitialState.LowerAnnulusFluidId, fluidsSelectData),
    };

    return CommonReportDataHelpers.createGenericKeyValueTableData(simpleWellFluidsProperties, fluids, currentUnitSystem, null);
  }

  public static createDetailedPumpingWellFluidsTableData(
    pumpingInitialState: Pumping,
    pumpingState: ValidatedPumpingModuleState,
    fluidsSelectData: IFluidSelectorData,
    gravelsSelectData: IGravelSelectorData,
    extraConfig: ExtraConfig,
  ): DetailedPumpingSectionTableData[] {
    const detailedConfig = detailedWellFluidsProperties(pumpingInitialState.IsWellFluidDepthByVolume, extraConfig.isFluidPro);
    const dataRows = pumpingState.wellFluids.rows
      .map((row) => ({
        ...row.rowData,
        FluidId: CommonReportDataHelpers.getFluid(row.rowData.FluidId, fluidsSelectData),
        GravelId: this.getGravel(row.rowData.GravelId, gravelsSelectData),
      }))
      .sort((a, b) => a.SortOrder - b.SortOrder);

    const availableFlowDescriptions = dataRows.map((row) => row.FlowPathDescription);
    const sections = [
      { FlowPathDescription: 0, title: 'Workstring Fluids' },
      { FlowPathDescription: 1, title: 'Upper Annulus Fluids' },
      { FlowPathDescription: 2, title: 'Washpipe Fluids' },
      { FlowPathDescription: 3, title: 'Lower Annulus Fluids' },
    ].filter((section) => availableFlowDescriptions.includes(section.FlowPathDescription));

    return sections.map(({ title, FlowPathDescription }) => {
      const tableData: ITableData<ReportWellFluid> = {
        dataRows: dataRows.filter((row) => row.FlowPathDescription === FlowPathDescription),
        dataCells: this.gridColConfigToTableColConfig(detailedConfig, false),
      };
      return { tableData, title: title };
    });
  }

  public static createPumpingScheduleReferenceParametersTableData(
    refVariables: ReferenceVariableCalculatorResultsDto,
    currentUnitSystem: IUnitSystemDto,
    extraConfig: ExtraConfig,
  ): ITableData<KeyValueTableRow> {
    return CommonReportDataHelpers.createGenericKeyValueTableData(
      pumpingRefParamsProperties.filter((prop) => !extraConfig.isFluidPro || prop.colId === 'DisplacementToPacker'),
      refVariables,
      currentUnitSystem,
      null,
    );
  }

  public static createPumpingScheduleTableData(
    pumpingSchedule: PumpingSchedule[],
    fluidsSelectData: IFluidSelectorData,
    gravelsSelectData: IGravelSelectorData,
    pumping: Pumping,
    extraConfig: ExtraConfig,
  ): ITableData<ReportPumpingSchedule> {
    const showChokePressure = PumpingExtensions.getVariableChoke(pumping) === VariableChokeSetting.Managed_Pressure;

    const columnsToPrint = [
      'Name',
      'PumpRate',
      'ReturnRate',
      'FluidId',
      ...(!extraConfig.isFluidPro ? ['GravelId'] : []),
      ...(!extraConfig.isFluidPro ? ['GravelConcentration'] : []),
      ...(showChokePressure ? ['ChokePressure'] : []),
      'StageVolume',
    ];

    const filteredGridConfig = scheduleGridConfig(false, false, false, extraConfig.isFluidPro)
      .filter((col) => columnsToPrint.includes(col.colId))
      .map((col) => ({
        ...col,
        type: col.colId === 'FluidId' || col.colId === 'GravelId' ? ColumnType.text : col.type,
      }));

    return {
      dataRows: pumpingSchedule.map((row) => ({
        ...row,
        FluidId: CommonReportDataHelpers.getFluid(row.FluidId, fluidsSelectData),
        GravelId: CommonReportDataHelpers.getGravel(row.GravelId, gravelsSelectData),
      })),
      dataCells: this.gridColConfigToTableColConfig(filteredGridConfig, false),
    };
  }

  public static createSummaryWarningMessagesTableData(
    summaryState: SummaryModuleState,
    currentUnitSystem: IUnitSystemDto,
    isTestRun: boolean,
  ): ITableData<WarningMessage> {
    const messages: WarningMessage[] = (
      isTestRun ? hideWarningMessagesTime(summaryState.warningMessages) : summaryState.warningMessages
    ).rows.map((row) => row.rowData);
    const convertUnitPipe = new ConvertUnitPipe();

    // MessageText below is transformed because in older versions it could have numeric values inside.
    // Now numeric values are in Simulation Time, Simulation Volume and Measured Depth properties
    return {
      dataRows: messages.map((message) => ({
        ...message,
        MessageText: convertUnitPipe.transform(message.MessageText, currentUnitSystem),
      })),
      dataCells: [
        {
          colId: 'SimulationTime',
          type: ColumnType.number,
          headerText: 'Simulation Time',
          unitSystem: UnitSystem.Time,
        },
        {
          colId: 'SimulationVolume',
          type: ColumnType.number,
          headerText: 'Simulation Volume',
          unitSystem: UnitSystem.Liquid_Volume,
        },
        {
          colId: 'MeasuredDepth',
          type: ColumnType.number,
          headerText: 'MD',
          unitSystem: UnitSystem.Long_Length,
        },
        { colId: 'MessageText', type: ColumnType.text, headerText: 'Message' },
      ],
    };
  }

  public static createSummaryGravelPackEfficienciesSectionTableData(
    summary: SummaryDto,
    currentUnitSystem: IUnitSystemDto,
    extraConfig: ExtraConfig,
  ): ITableData<KeyValueTableRow> {
    return CommonReportDataHelpers.createGenericKeyValueTableData(
      summaryPackEfficienciesProperties(extraConfig.isOpenHole ?? false),
      summary,
      currentUnitSystem,
      null,
    );
  }

  public static createSummaryGravelBelowPackerSectionTableData(
    summary: SummaryDto,
    currentUnitSystem: IUnitSystemDto,
  ): ITableData<KeyValueTableRow> {
    return CommonReportDataHelpers.createGenericKeyValueTableData(summaryGravelBelowPackerProperties, summary, currentUnitSystem, null);
  }

  public static createSummaryMaxTotalPressureSectionTableData(
    summary: SummaryDto,
    currentUnitSystem: IUnitSystemDto,
    extraConfig: ExtraConfig,
  ): ITableData<KeyValueTableRow> {
    return CommonReportDataHelpers.createGenericKeyValueTableData(
      summaryMaxTotalPressureProperties(extraConfig.isFluidPro ?? false),
      summary,
      currentUnitSystem,
      null,
    );
  }

  public static createSummaryMaxTotalECDsSectionTableData(summary: SummaryDto, currentUnitSystem: IUnitSystemDto): ITableData<KeyValueTableRow> {
    return CommonReportDataHelpers.createGenericKeyValueTableData(summaryMaxTotalECDsProperties, summary, currentUnitSystem, null);
  }

  public static createSummaryFinalFracDataSectionTableData(
    summary: SummaryDto,
    currentUnitSystem: IUnitSystemDto,
  ): ITableData<KeyValueTableRow> {
    return CommonReportDataHelpers.createGenericKeyValueTableData(summaryFinalFracDataProperties, summary, currentUnitSystem, null);
  }

  public static createSummaryMaxFrictionPressureSectionTableData(
    summary: SummaryDto,
    currentUnitSystem: IUnitSystemDto,
    extraConfig: ExtraConfig,
  ): ITableData<KeyValueTableRow> {
    return CommonReportDataHelpers.createGenericKeyValueTableData(
      summaryMaxFrictionPressureProperties(extraConfig.isFluidPro),
      summary,
      currentUnitSystem,
      null,
    );
  }

  public static getFluid = (id: number | null, fluidsSelectData: IFluidSelectorData): string =>
    id != null ? (fluidsSelectData.items.find((fl) => fl.value === id)?.text ?? '-') : '-';

  public static getGravel = (id: number | null, gravelsSelectData: IGravelSelectorData): string =>
    id != null ? (gravelsSelectData.items.find((fl) => fl.value === id)?.text ?? '-') : '-';

  public static async createPsdInputData(
    store: Store,
    currentUnitSystem: IUnitSystemDto,
    convertUnitPipe: ConvertUnitPipe,
    images: ReportScenarioImages,
  ): Promise<PsdInputData[]> {
    const psdDict = await firstValueFrom(store.select(getPsdDict));
    const psdReadingsDict = await firstValueFrom(store.select(getPsdReadingsDict));
    const result: PsdInputData[] = [];

    for (const key of Object.keys(psdDict)) {
      const psdData = psdDict[key];
      if (psdData == null || !psdData.IsSelected) {
        continue;
      }

      const chartData = images.psdInputChart && images.psdInputChart[key];
      if (chartData == null) {
        continue;
      }

      const psdDefinitionTableData = this.createGenericKeyValueTableData(psdDefinition(), psdData, currentUnitSystem, null);

      const psdReadingsData = psdReadingsDict[key];
      if (psdReadingsData == null) {
        continue;
      }

      const isWeight = psdData.PSDInputType === PSDInputType.Weight;
      const isPercent = psdData.PSDInputType === PSDInputType.PercentWeight;
      const isCumulative = psdData.PSDInputType === PSDInputType.CumulativePercentWeight;

      const psdReadingTableData: ITableData<PsdReading> = {
        dataRows: CommonReportDataHelpers.generateCodeValues(getRowsForCalculations(psdReadingsData.rows)),
        dataCells: CommonReportDataHelpers.gridColConfigToTableColConfig(
          getPsdDataGridConfig(isWeight, isPercent, isCumulative).filter((c) => c.visible),
        ),
      };

      const psdProperties = this.createGenericKeyValueTableData(
        getPsdPropertiesGridConfig(currentUnitSystem, convertUnitPipe),
        psdData.result,
        currentUnitSystem,
        null,
      );

      const psdName = psdData.Description;

      result.push({
        psdName,
        psdDefinitionTableData,
        psdReadingTableData,
        chartData,
        psdPropertiesTableData: psdProperties,
      });
    }

    return result;
  }

  public static createPsdSandProductionTableData(
    psdAnalysis: PsdSandProductionInputs,
    currentUnitSystem: IUnitSystemDto,
  ): ITableData<KeyValueTableRow> {
    return CommonReportDataHelpers.createGenericKeyValueTableData(psdSandProduction(), psdAnalysis, currentUnitSystem, null);
  }

  public static createPsdSummaryTableData(
    psdGrid: ITableState<PSDResult>,
    currentUnitSystem: IUnitSystemDto,
    convertUnitPipe: ConvertUnitPipe,
  ): ITableData<PSDResult> {
    return {
      dataRows: CommonReportDataHelpers.generateCodeValues(getRowsForCalculations(psdGrid.rows)),
      dataCells: CommonReportDataHelpers.gridColConfigToTableColConfig(
        getSummaryGridConfig(PSDValidation.getPercentFinesLabel(currentUnitSystem, convertUnitPipe)),
      ),
    };
  }

  public static createTrendAnalysisInputsTableData(
    trendAnalysisRange: IValidatedScenarioRangeProperties,
    currentUnitSystem: IUnitSystemDto,
  ): ITableData<KeyValueTableRow> {
    const enumItemMap = new Map<keyof IValidatedScenarioRangeProperties, ISelectItem<any>[]>();
    enumItemMap.set('TrendAnalysisToolPosition', EnumHelpers.EnumToISelectItemArray(ToolPosition));
    enumItemMap.set('TrendAnalysisPumpingDirection', EnumHelpers.EnumToISelectItemArray(PumpingDirection));

    return CommonReportDataHelpers.createGenericKeyValueTableData(trendAnalysisInputs, trendAnalysisRange, currentUnitSystem, enumItemMap);
  }

  public static createPsdScreenTableData(
    psdGrid: ITableState<PSDResult>,
    psdAnalysis: PSDAnalysis,
    currentUnitSystem: IUnitSystemDto,
    convertUnitPipe: ConvertUnitPipe,
  ): ITableData<PSDResult> {
    return {
      dataRows: CommonReportDataHelpers.generateCodeValues(getRowsForCalculations(psdGrid.rows)),
      dataCells: CommonReportDataHelpers.gridColConfigToTableColConfig(
        getStandaloneScreensGridConfig(
          psdAnalysis?.IsCalculateSandProduction ?? true,
          PSDValidation.getProducedSandMassLabel(currentUnitSystem, convertUnitPipe, psdAnalysis.ScreenSlotSize),
        ).filter((c) => c.visible),
        // this filtering visible probably should be handled globally for all columns,
        // but it can potentially affect many tables.
      ),
    };
  }

  public static createPsdGravelTableData(psdGrid: ITableState<PSDResult>): ITableData<PSDResult> {
    return {
      dataRows: CommonReportDataHelpers.generateCodeValues(getRowsForCalculations(psdGrid.rows)),
      dataCells: CommonReportDataHelpers.gridColConfigToTableColConfig(gravelPackGridConfig),
    };
  }

  public static getTableCellValue<T>(
    dataCell: IReportTableColumnConfig<T>,
    tableDataEntry: IDataRowWithCode<T>,
    enumItemMap: IReportEnumItemMap<T> | null | undefined,
    currentUnitSystem: IUnitSystemDto,
    addUnit = false,
  ): string {
    if (dataCell.colId === ' ') {
      return dataCell.colId;
    }
    if (dataCell.type === ColumnType.text || dataCell.colId === 'Code' || dataCell.colId === 'Name') {
      return String(tableDataEntry[dataCell.colId]);
    }
    if (dataCell.type === ColumnType.select && enumItemMap != null) {
      return this.getTextValue(+tableDataEntry[dataCell.colId], enumItemMap.get(dataCell.colId));
    }
    if (dataCell.type === ColumnType.boolean) {
      const value: boolean = tableDataEntry[dataCell.colId] as unknown as boolean;
      return value ? 'Yes' : 'No';
    }
    if (dataCell.type === ColumnType.number && tableDataEntry[dataCell.colId] == undefined) {
      return '-';
    }

    if (dataCell.allowOptionalStringValue && typeof tableDataEntry[dataCell.colId] === 'string') {
      return tableDataEntry[dataCell.colId] as string;
    }

    return DocumentGeneratorHelper.convertValue(+tableDataEntry[dataCell.colId], dataCell, currentUnitSystem, addUnit);
  }

  public static createGenericKeyValueTableData<T = any>(
    gridConfig: IReportTableColumnConfig<T>[],
    tableDataEntry: IDataRowWithCode<T>,
    currentUnitSystem: IUnitSystemDto,
    enumItemMap: IReportEnumItemMap<T> | null | undefined,
  ): ITableData<KeyValueTableRow> {
    return {
      dataRows: gridConfig.map((cell: IReportTableColumnConfig<T>) => ({
        name: cell.reportingHeaderName ?? cell.headerText ?? '',
        value: CommonReportDataHelpers.getTableCellValue(cell, tableDataEntry, enumItemMap, currentUnitSystem, true),
      })),
      dataCells: keyValueGridConfig,
    };
  }

  public static gridColConfigToTableColConfig<T>(gridConfig: IGridColumnConfig<T>[], addCodeCol = true): IReportTableColumnConfig<T>[] {
    if (!addCodeCol) {
      gridConfig = gridConfig.filter((col) => col.colId !== ' ');
    }
    return gridConfig.map((cell) => {
      if (cell.colId === ' ') {
        return { ...cell, colId: 'Code', headerText: 'Code', type: ColumnType.number };
      }
      return cell as IReportTableColumnConfig<T>;
    });
  }

  public static generateCodeValues<T>(arr: IDataRowWithCode<T>[]): IDataRowWithCode<T>[] {
    return arr.map((obj, index) => ({
      ...obj,
      Code: index + 1,
    }));
  }

  public static getCellUnit(currentUnitSystem: IUnitSystemDto, unitSystem: UnitSystem | -1, unitLabel?: string): string {
    if (unitLabel == null && (unitSystem === -1 || unitSystem === UnitSystem.None)) {
      return '';
    }

    const currUnit = UnitConverterHelper.getCurrentUnit(unitSystem, currentUnitSystem);
    return unitLabel ?? UnitConverterHelper.getUnitConverter(unitSystem).getSymbol(currUnit || NoneUnit.None);
  }

  public static getTextValue(value: number, enumItems?: ISelectItem<number>[]): string {
    if (enumItems == null) {
      return '';
    }

    return getSelectItemValue<number>(value, enumItems);
  }

  public static createNoResultsMessage(sectionName: string): Paragraph[] {
    return [
      DocumentGeneratorHelper.createParagraphHeader(sectionName, HeadingLevel.HEADING_2, 1),
      new Paragraph({
        children: [new TextRun({ text: `${sectionName} is not available - results are needed.`, font: mainFont })],
      }),
    ];
  }

  public static generateColumnsWidths(cellsQty: number, isCodeColPresent: boolean, presetColumns: PresetColumnsWidths = {}): number[] {
    // create array with same length as all cells, and fill it with zeros
    let columnsWidths = Array(cellsQty).fill(0);

    // if any columns should have custom width, update columns sizes
    // custom widths are set like : {columnIndex: size}
    Object.keys(presetColumns).forEach((key) => (columnsWidths[+key] = presetColumns[+key]));

    // if columns contain 'Code' column, and it's not set already, set first col to as defaultIndexCellWidth ( narrower )
    if (isCodeColPresent && columnsWidths[0] === 0) {
      columnsWidths[0] = defaultIndexCellWidth;
    }

    // spread remaining table width between remaining zeros
    const remainingTableWidth = 100 - columnsWidths.reduce((prev, next) => prev + next);
    const unsetColumnsQty = columnsWidths.filter((val) => val === 0).length;

    columnsWidths = columnsWidths.map((width) => (width === 0 ? remainingTableWidth / unsetColumnsQty : width));

    return columnsWidths;
  }
}

export interface PsdInputData {
  psdName: string;
  psdDefinitionTableData: ITableData<KeyValueTableRow>;
  psdReadingTableData: ITableData<PsdReading>;
  chartData: Base64Image;
  psdPropertiesTableData: ITableData<KeyValueTableRow>;
}

export interface IBulletPoint {
  label: string;
  value: string;
  level: number;
  tabulations?: number;
}

export interface TableDataWithEnumMaps<T> {
  tableData: ITableData<T>;
  enumItemMap?: IReportEnumItemMap<T>;
}

export interface KeyValueDataWithEnumMaps<T> {
  keyValueData: IDataRowWithCode<T>;
  enumItemMap: IReportEnumItemMap<T>;
}

export interface ReportFluid extends Fluid {
  ViscosityAtSurfaceTemp: number;
  ViscosityAtBHCT: number;
}

export interface KeyValueTableRow {
  name: string;
  value: string;
}

export const keyValueGridConfig: IReportTableColumnConfig<KeyValueTableRow>[] = [
  {
    colId: 'name',
    headerText: 'Name',
    type: ColumnType.text,
    unitSystem: UnitSystem.None,
  },
  {
    colId: 'value',
    headerText: 'Value',
    type: ColumnType.text,
    unitSystem: UnitSystem.None,
  },
];

export interface DetailedPumpingSectionTableData {
  title: string;
  tableData: ITableData<ReportWellFluid>;
}

export interface ITableData<T> {
  dataRows: IDataRowWithCode<T>[];
  dataCells: IReportTableColumnConfig<T>[];
}

export type IDataRowWithCode<T> = T & {
  Code?: number;
  Name?: string;
  Description?: string;
};

export interface ExtraConfig {
  isFluidPro?: boolean;
  isOpenHole?: boolean;
}

export interface IReportTableColumnConfig<T> extends Omit<IGridColumnConfig<T>, 'colId' | 'disabled' | 'visible'> {
  colId: keyof T | 'Code' | 'Name';
}

export type IReportEnumItemMap<T> = Map<keyof T, ISelectItem<number>[]>;

export const disclaimer1 =
  'This information is presented in good faith based on calculations produced by a computer model ' +
  'including various assumptions on the well, reservoir, completion and treatment. The results depend on, and can be' +
  ' no more accurate than, input data provided by the client and estimates of unknown data so should be used for ' +
  'guidance and comparison purposes only rather than taken as accurate values. DuneFront does not give any warranties' +
  ' regarding and assumes no liability for any advice or recommendations made concerning the results.';
export const disclaimer2 =
  'The quality of input data, modeling assumptions and results may be improved through the use of ' +
  'certain tests, procedures and calibration processes with which DuneFront can assist. The client has superior ' +
  'knowledge of and greater access to data regarding the well, reservoir and field so is responsible for identifying' +
  ' any potential problems or adverse conditions which may affect the results, advice or recommendations.';
