import { createReducer, on } from '@ngrx/store';
import * as actions from './reporting.actions';
import {
  calculationProgressUpdatedCurrentScenarioAction,
  getChartSeriesTemplatesSuccess,
  getSourceReportingTabsSuccess,
  ReportingCalculationJobStatus,
  setChartResultColumns,
  setCrosshairModeAction,
  setGaugeDataRequestedAfterFileImport,
  setMaxSeriesInTooltipAction,
  setSelectedReportingChartTabId,
  setSelectedSimulationTime,
  setTooltipPositionAction,
  updateChartResultColumnRow,
  updateVisibleDrawables,
} from './reporting.actions';
import { ReportingModuleReducerHelper } from './reporting.reducer.helper';
import {
  dataFailed,
  deleteRowsSuccess,
  insertRowsSuccess,
  loadScenarioDataSuccessAction,
  reorderRowSuccess,
  resetScenarioState,
  scenarioOrRangeLoadedAction,
  updateRowSuccess,
} from '../app.actions';
import { clearCalculationQueueAction } from '../calculation-engine/calculation-engine.actions';
import { ReportingCalculationProgressModuleReducerHelper } from './reporting-calculation-progress.reducer.helper';
import { ReportingChartConfigModuleReducerHelper } from './reporting-chart-config.reducer.helper';
import { reportingInitialState, ReportingModuleState } from './reporting-module.state';
import { ReportingFactory } from './model/reporting.factory';
import * as dbConnectionActions from '../backend-connection/backend-connection.actions';
import { removeDataResultsFromStore, removeDataResultsFromStoreIfNeeded } from '../data-storage/data-storage.actions';
import { DictionaryWithArray } from '@dunefront/common/common/state.helpers';
import { loadCommonDbInitialSuccess } from '../common-db/common-db.actions';

export const reportingModuleReducer = createReducer<ReportingModuleState>(
  { ...reportingInitialState },
  on(
    resetScenarioState,
    (state): ReportingModuleState => ({
      ...reportingInitialState,
      chartSeriesTemplates: state.chartSeriesTemplates,
      selectedReportingTabId: state.selectedReportingTabId,
      tooltipPosition: state.tooltipPosition,
      crosshairMode: state.crosshairMode,
      maxSeriesInTooltip: state.maxSeriesInTooltip,
      sourceReportingTabDtos: state.sourceReportingTabDtos,
    }),
  ),
  on(
    loadScenarioDataSuccessAction,
    (state, action): ReportingModuleState =>
      ReportingModuleReducerHelper.onLoadScenarioDataSuccessAction(state, action.loadScenarioResponse.reportingModule),
  ),
  on(scenarioOrRangeLoadedAction, (state): ReportingModuleState => ReportingModuleReducerHelper.resetSelectedSimulationTime(state)),
  on(
    actions.getTimeVolChartData,
    (state, action): ReportingModuleState =>
      ReportingModuleReducerHelper.getTimeVolChartData(
        state,
        action.getChartDataParams,
        action.reportingTabId,
        action.gaugeDataRequestedAfterFileImport ?? false,
      ),
  ),
  on(
    actions.getTimeVolChartDataSuccess,
    (state, action): ReportingModuleState =>
      ReportingModuleReducerHelper.getTimeVolChartDataSuccess(state, action.response, action.reportingTabId),
  ),
  on(
    actions.getReportingColumnsSuccess,
    (state, action): ReportingModuleState =>
      ReportingChartConfigModuleReducerHelper.getChartReportingColumnsSuccess(state, action.result, action.ranges),
  ),
  on(
    actions.getChartUserAddonsSuccess,
    (state, action): ReportingModuleState => ReportingModuleReducerHelper.getChartUserAddonsSuccess(state, action),
  ),
  on(
    actions.requestedDepthData,
    (state, action): ReportingModuleState =>
      ReportingModuleReducerHelper.requestedDepthData(state, action.lowerCompletionRange, action.depthDataKey),
  ),
  on(
    actions.getDepthDataSuccess,
    (state, action): ReportingModuleState => ReportingModuleReducerHelper.getDepthDataSuccess(state, action.depthDataKey, action.response),
  ),
  on(
    actions.getSinglePointDepthDataSuccess,
    (state, action): ReportingModuleState => ReportingModuleReducerHelper.getSinglePointDepthDataSuccess(state, action.depthResults),
  ),
  on(actions.clearReportingChartData, (state): ReportingModuleState => ReportingModuleReducerHelper.clearReportingChartData(state)),
  on(actions.clearResultsChartsData, (state): ReportingModuleState => ReportingModuleReducerHelper.clearResultsChartsData(state)),
  on(insertRowsSuccess, (state, action): ReportingModuleState => ReportingModuleReducerHelper.insertRowsSuccess(state, action)),
  on(deleteRowsSuccess, (state, action): ReportingModuleState => ReportingModuleReducerHelper.deleteRowsSuccess(state, action)),
  on(updateRowSuccess, (state, action): ReportingModuleState => ReportingModuleReducerHelper.updateRowsSuccess(state, action)),
  on(reorderRowSuccess, (state, action): ReportingModuleState => ReportingModuleReducerHelper.reorderRowSuccess(state, action)),
  on(
    updateChartResultColumnRow,
    (state, action): ReportingModuleState => ReportingChartConfigModuleReducerHelper.updatedChartResultColumn(state, action),
  ),
  on(
    setChartResultColumns,
    (state, action): ReportingModuleState => ReportingChartConfigModuleReducerHelper.setChartResultColumns(state, action.updateType),
  ),
  on(
    setSelectedReportingChartTabId,
    (state, action): ReportingModuleState => ({
      ...state,
      chartState: ReportingFactory.getEmptyChartState(),
      selectedReportingTabId: action.tabId,
      prevSelectedReportingTabIds: ReportingModuleReducerHelper.pushPrevSelectedReportingTabIds(state, action.tabId),
    }),
  ),
  on(
    calculationProgressUpdatedCurrentScenarioAction,
    (state, action): ReportingModuleState =>
      ReportingCalculationProgressModuleReducerHelper.calculationProgressUpdatedCurrentScenarioAction(state, action),
  ),
  on(
    clearCalculationQueueAction,
    (state): ReportingModuleState => ({
      ...state,
      calculationJobId: undefined,
      calculationJobMessage: undefined,
      calculationJobProgress: undefined,
      calculationJobStatus: ReportingCalculationJobStatus.notActive,
    }),
  ),
  on(
    setSelectedSimulationTime,
    (state, action): ReportingModuleState => ({
      ...state,
      selectedSimulationTime: action.selectedSimulationTime,
    }),
  ),
  on(
    setGaugeDataRequestedAfterFileImport,
    (state, action): ReportingModuleState => ({
      ...state,
      gaugeDataRequestedAfterFileImport: action.gaugeDataRequestedAfterFileImport,
    }),
  ),
  on(removeDataResultsFromStoreIfNeeded, (state, action): ReportingModuleState => {
    const { deleteResultsFilter, currentData } = action;

    const isCurrentModuleExcluded =
      deleteResultsFilter.moduleTypes != null &&
      currentData?.currentAppModuleType != null &&
      !deleteResultsFilter.moduleTypes.includes(currentData.currentAppModuleType);

    const isCurrentScenarioExcluded =
      deleteResultsFilter.scenarioIds != null &&
      currentData?.currentScenarioId != null &&
      !deleteResultsFilter.scenarioIds.includes(currentData.currentScenarioId);

    const isCurrentRangeExcluded =
      deleteResultsFilter.rangeIds != null &&
      currentData?.currentRangeId != null &&
      !deleteResultsFilter.rangeIds.includes(currentData.currentRangeId);

    if (isCurrentModuleExcluded || isCurrentScenarioExcluded || isCurrentRangeExcluded) {
      return state;
    }

    return emptyResultState(state);
  }),
  on(removeDataResultsFromStore, (state) => emptyResultState(state)),
  on(
    setCrosshairModeAction,
    (state, action): ReportingModuleState => ({
      ...state,
      crosshairMode: action.mode,
    }),
  ),
  on(
    setTooltipPositionAction,
    (state, action): ReportingModuleState => ({
      ...state,
      tooltipPosition: action.position,
    }),
  ),
  on(
    setMaxSeriesInTooltipAction,
    (state, action): ReportingModuleState => ({
      ...state,
      maxSeriesInTooltip: action.maxSeriesInTooltip,
    }),
  ),
  on(
    actions.loadChartTemplatesSuccess,
    (state, action): ReportingModuleState => ({
      ...state,
      chartTemplates: action.charts,
    }),
  ),
  on(
    actions.startExportDataJobAction,
    (state, action): ReportingModuleState => ({
      ...state,
      exportDataJobPayload: action.payload.payloads[0],
    }),
  ),
  on(
    actions.exportDataSuccessAction,
    dataFailed,
    (state): ReportingModuleState => ({
      ...state,
      exportDataJobPayload: undefined,
    }),
  ),
  on(
    updateVisibleDrawables,
    (state, { currentlyVisibleDrawables }): ReportingModuleState => ({
      ...state,
      currentlyVisibleDrawables,
    }),
  ),
  on(
    getChartSeriesTemplatesSuccess,
    (state, action): ReportingModuleState => ({
      ...state,
      chartSeriesTemplates: action.chartSeriesTemplates,
    }),
  ),
  on(
    getSourceReportingTabsSuccess,
    (state, action): ReportingModuleState => ({
      ...state,
      sourceReportingTabDtos: action.sourceReportingTabs,
    }),
  ),
  on(
    dbConnectionActions.dbConnectedSuccessAction,
    (state, action): ReportingModuleState => ReportingModuleReducerHelper.onDbConnectedSuccessAction(state, action.payload),
  ),
  on(
    loadCommonDbInitialSuccess,
    (state, action): ReportingModuleState => ReportingModuleReducerHelper.onloadCommonDbInitialSuccess(state, action.payload),
  ),
);

export const emptyResultState = (state: ReportingModuleState): ReportingModuleState => ({
  ...state,
  resultsTimeChartState: ReportingFactory.getEmptyChartState(),
  resultsDepthChartState: ReportingFactory.getEmptyChartState(),
  resultsEvaluatePressureChartState: ReportingFactory.getEmptyChartState(),
  resultsEvaluateFrictionChartState: ReportingFactory.getEmptyChartState(),
  depthDataForScenarios: DictionaryWithArray.clear(),
  simulationDuration: 0,
  selectedSimulationTime: 0,
});
