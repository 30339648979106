import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { BaseWsEffects } from '../base-ws.effects';
import { BackendConnectionService } from '../../shared/backend-connection/backend-connection.service';
import { ModalService } from '../../common-modules/modals/modal.service';
import { Store } from '@ngrx/store';
import {
  electronGetTargetDirAndExportData,
  electronOpenItemAction,
  electronSaveCSVAsFileAction,
  electronSaveProjectAsFileAction,
  electronSaveReportAsFileAction,
  showFileOpenDialog,
  showFileOpenDialogSuccess,
  showFileSaveDialog,
  showFileSaveDialogSuccess,
} from './electron-main.actions';
import { copyFileReturnFile } from '../file-manager/file-manager.actions';
import { ProjectFileHelpers } from '@dunefront/common/common/project-file-helpers';
import { FileManagerHelper } from '../file-manager/file-manager.helper';
import { Router } from '@angular/router';

import { IFile } from '@dunefront/common/dto/file.dto';
import {
  ElectronMainModuleName,
  ElectronMainOpenItemAction,
  ElectronMainSaveAsCSVFileAction,
  ElectronMainSaveAsProjectFileAction,
  ElectronMainSaveReportAsFileAction,
  ElectronMainSelectTargetDirectoryAction,
  ElectronMainShowFileOpenAction,
  ElectronMainShowFileOpenSaveResponse,
  ElectronMainShowFileSaveAction,
} from '@dunefront/common/common/electron/electron.actions';
import { noopAction } from '../app.actions';
import { validateAndStartExportDataJobAction } from '../reporting/reporting.actions';

@Injectable()
export class ElectronMainEffects extends BaseWsEffects {
  constructor(
    actions$: Actions,
    wsService: BackendConnectionService,
    modalService: ModalService,
    store: Store,
    private router: Router,
  ) {
    super(actions$, wsService, ElectronMainModuleName, false, false, modalService, store);
  }

  public showFileOpenDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showFileOpenDialog),
      mergeMap(() =>
        this.emit<ElectronMainShowFileOpenSaveResponse>(new ElectronMainShowFileOpenAction()).pipe(
          map((result) => showFileOpenDialogSuccess({ filePath: result.payload.filePath })),
        ),
      ),
    ),
  );

  public showFileOpenDialogSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showFileOpenDialogSuccess),
        filter((action) => action.filePath != null),
        map(async (action) => {
          await FileManagerHelper.navigateToFile(this.router, ProjectFileHelpers.getElectronFileFromPath(action.filePath as string));
        }),
      ),
    { dispatch: false },
  );

  public showFileSaveDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showFileSaveDialog),
      mergeMap((action) =>
        this.emit<ElectronMainShowFileOpenSaveResponse>(new ElectronMainShowFileSaveAction(action.source.Name)).pipe(
          map((result) => showFileSaveDialogSuccess({ source: action.source, filePath: result.payload.filePath })),
        ),
      ),
    ),
  );

  public showFileSaveDialogSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showFileSaveDialogSuccess),
      filter((action) => action.filePath != null),
      map(({ source, filePath }) =>
        copyFileReturnFile({
          source,
          target: ProjectFileHelpers.getElectronFileFromPath(filePath as string),
        }),
      ),
    ),
  );

  public openItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(electronOpenItemAction),
        filter((action) => action.filePath != null),
        mergeMap((action) => this.emit<ElectronMainShowFileOpenSaveResponse>(new ElectronMainOpenItemAction(action.filePath))),
      ),
    { dispatch: false },
  );

  public saveAsFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(electronSaveCSVAsFileAction),
        filter((action) => action.fileName != null),
        mergeMap((action) =>
          this.emit<ElectronMainShowFileOpenSaveResponse>(new ElectronMainSaveAsCSVFileAction(action.fileName, action.openFile, action.dirPath)),
        ),
      ),
    { dispatch: false },
  );

  public saveReportAsFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(electronSaveReportAsFileAction),
        mergeMap((action) => this.emit(new ElectronMainSaveReportAsFileAction(action.payload))),
      ),
    { dispatch: false },
  );

  private saveAsFileElectron$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(electronSaveProjectAsFileAction),
        mergeMap((action) =>
          this.emit<IFile>(new ElectronMainSaveAsProjectFileAction(action)).pipe(
            tap((res) => {
              if (res.payload != null) {
                // payload is null when user click cancel on "Save as" dialog
                FileManagerHelper.switchFile(this.router, res.payload).then();
              }
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  private getTargetFolderAndExportData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(electronGetTargetDirAndExportData),
      mergeMap((action) =>
        this.emit<{ filePath: string | null }>(new ElectronMainSelectTargetDirectoryAction()).pipe(
          map(
            (result) =>
              result.payload?.filePath != null
                ? validateAndStartExportDataJobAction({
                    payload: {
                      dirPath: result.payload.filePath,
                      payloads: action.payloads,
                      totalPayloads: action.payloads.length,
                    },
                  })
                : noopAction(), // this means user pressed 'cancel' on 'open folder' modal
          ),
        ),
      ),
    ),
  );
}
