<div class="main-panel" *ngrxLet="{selection: selection$, items: items$} as vm">
  <div class="left-button-menu">
    <div>
      <app-button
        dataCy="scenario-manager-create-btn"
        [disabled]="isReorderOn"
        [isDeleteResultsEnabled]="false"
        (buttonClick)="createScenario()"
        [buttonWidth]="100"
        text="Create"
      >
      </app-button>
      <app-button
        dataCy="scenario-manager-copy-btn"
        [disabled]="vm.selection.GroupIds.length !== 1 || isReorderOn"
        [isDeleteResultsEnabled]="false"
        (buttonClick)="copyScenario(vm.selection.GroupIds)"
        [buttonWidth]="100"

        text="Copy"
      >
      </app-button>
      <app-button
        dataCy="scenario-manager-rename-btn"
        [disabled]="vm.selection.GroupIds.length !== 1 || isReorderOn"
        [isDeleteResultsEnabled]="false"
        (buttonClick)="editScenario(vm.selection.GroupIds)"
        text="Rename"
        [buttonWidth]="100"
      >
      </app-button>
      <app-button
        dataCy="scenario-manager-delete-btn"
        [disabled]="vm.selection.GroupIds.length === 0 || isReorderOn"
        [isDeleteResultsEnabled]="false"
        (buttonClick)="deleteScenarios(vm.selection.GroupIds, vm.items)"
        text="Delete"
        [buttonWidth]="100"
      >
      </app-button>

      <app-button
        dataCy="scenario-manager-switch"
        (buttonClick)="switchScenario(vm.selection.GroupIds)"
        [isUiLockable]="false"
        [disabled]="vm.selection.GroupIds.length !== 1 || vm.selection.GroupIds[0] === currentScenarioId"
        text="Switch"
        [buttonWidth]="100"
      >
      </app-button>
    </div>
    <div *ngIf="isSimulateBased(currentAppModuleType)">
      <app-button
        dataCy="scenario-manager-simulate-btn"
        (buttonClick)="runSimulations(vm.selection.GroupIds, vm.items)"
        [isUiLockable]="false"
        [disabled]="vm.selection.GroupIds.length === 0 || isReorderOn"
        text="Simulate"
        [buttonWidth]="100"
      >
      </app-button>
      <app-button
        dataCy="scenario-manager-export-btn"
        (buttonClick)="exportData(vm.selection.GroupIds, vm.items)"
        [isUiLockable]="false"
        [disabled]="vm.selection.GroupIds.length === 0 || isReorderOn"
        text="Export"
        [buttonWidth]="100"
      >
      </app-button>
    </div>

  </div>
  <app-page-panel header="Select Scenario(s)" class="scenarios-selector-container" [allowFullScreen]="false">
    <app-filterable-tree
      [items$]="items$"
      [selection$]="selection$"
      [config$]="config$"
      [filter$]="filter$"
      (updateSelectedIds)="updateSelectedIds($event)"
      (dropped)="onDropped($event)"
      (reorderChanged)="this.isReorderOn = $event"
      dataCy="scenario-selection"
    ></app-filterable-tree>
  </app-page-panel>
</div>
