<div class="wrapper with-help" [class.help-open]="isHelpOpen">
  <div class="dialog-header">
    <h4>
      Import Data
      <ng-container *ngIf="gridConfig.headerText"> - {{ gridConfig.headerText }}</ng-container>
    </h4>

    <app-modal-help-button
      (helpClick)="isHelpOpen = $event.isHelpOpen"
    ></app-modal-help-button>
  </div>
  <div class="dialog-content">
    <div class="grid-2-col">
      <app-paste-area
        data-cy="import-grid-paste"
        [parsedResult]="FileParser.parsedResult"
        (pastedTextChanged)="onPastedTextChanged($event)"
      ></app-paste-area>

      <app-page-panel header="Column Delimiters">
        <app-column-delimiter
          [delimiterConfig]="delimiterConfig"
          (delimiterConfigChange)="onDelimiterConfigChanged($event)"
        ></app-column-delimiter>
      </app-page-panel>
    </div>

    <app-page-panel header="Preview Import Data" class="no-padding">
      <app-paste-data-grid
        #gridComponent
        *ngIf="!dataError"
        [parsedResult]="FileParser.parsedResult"
        [gridConfig]="config.data.gridConfig"
        (columnSelectionsChanged)="columnSelections = $event"
        (rowsToIncludeChanged)="rowsToInclude = $event"
      >
      </app-paste-data-grid>
      <div data-cy="paste-data-error-panel" class="error-panel" *ngIf="dataError">{{ dataError }}</div>
    </app-page-panel>
  </div>
  <div class="dialog-footer">
    <app-button
      dataCy="reverse-paste-data"
      [isUiLockable]="false"
      (buttonClick)="onReverseClick()"
      [disabled]="FileParser.parsedResult.data.length < 2"
      text="Reverse"
    >
    </app-button>
    <app-button
      dataCy="cancel-paste-data"
      [isUiLockable]="false"
      (buttonClick)="onCancelClick()"
      text="Cancel"
    >
    </app-button>
    <app-button

      dataCy="import-paste-data"
      [isUiLockable]="false"
      (buttonClick)="onImportClick()"
      [disabled]="isImportDisabled"
      text="Import"
    >

    </app-button>
  </div>
</div>

<div class="modal-help" [class.open]="isHelpOpen">
  <div class="help-content">

    <ng-container *ngIf="helpContentUrl | helpUrl | async as url">
      <iframe [src]="url"></iframe>
    </ng-container>

  </div>
</div>
