import { LicenseFeature } from '@dunefront/common/modules/licensing/licensing.interfaces';
import { isElectron } from '@dunefront/common/common/electron/is-electron';
import { APP_CODE } from '@dunefront/common/common/app-target-config';

interface IModuleSelectorItem {
  feature: LicenseFeature;
  name: string;
  description: string;

  icon: string;
  isAddon?: boolean;
  appFilter?: APP_CODE[];
}

export interface IModuleSelectorGroup {
  name: string;
  items: IModuleSelectorItem[];
  appFilter?: APP_CODE[];
}

export const getModuleSelectorConfig = (appCode: string): IModuleSelectorGroup[] => {
  const fullConfig = [
    {
      name: 'Design',
      items: [
        {
          feature: LicenseFeature.PSD_Analysis,
          name: 'PSD Analysis',
          icon: 'icon-PSD-Analysis',
          description: 'Select optimal screen and gravel for stand-alone screen and gravel pack completions',
          appFilter: ['pack-pro'],
        },
        {
          feature: LicenseFeature.Simulate,
          name: 'Simulate',
          icon: 'icon-Simulate',
          description: 'Design open and cased hole sand control treatments using the latest technology',
          appFilter: ['pack-pro'],
        },
        {
          feature: LicenseFeature.Simulate_CH,
          name: 'Simulate CH',
          icon: 'icon-simulate-ch',
          description: 'Design cased hole sand control treatments, below and above frac pressure',
          appFilter: ['pack-pro'],
        },
        {
          feature: LicenseFeature.Simulate_Disp,
          name: 'FluidPro',
          icon: 'icon-fluid',
          description: 'Optimize fluid displacement operations with non-uniform flow modeling',
        },
      ],
      appFilter: ['pack-pro', 'fluid-pro'],
    },
    {
      name: 'Evaluation',
      items: [
        {
          feature: LicenseFeature.Evaluate,
          name: 'Evaluate',
          icon: 'icon-Evaluate',
          description: 'Advanced data analysis and model calibration for sand control treatments',
          appFilter: ['pack-pro'],
        },
        {
          feature: LicenseFeature.Trend_Analysis,
          name: 'Trend Analysis',
          icon: 'icon-Trend-Analysis',
          description: 'Simplified data analysis workflow for sand control treatments in horizontal wells',
          appFilter: ['pack-pro'],
        },
        {
          feature: LicenseFeature.Data_Analysis,
          name: 'DataPro',
          icon: 'icon-Data-Analysis',
          description: 'Handle large amounts of numerical data easily and visually',
        },
      ],
      appFilter: ['pack-pro', 'data-pro'],
    },
    {
      name: 'Tools',
      items: [
        {
          feature: LicenseFeature.Calculators,
          name: 'Calculators',
          icon: 'icon-Calculators',
          description: 'Perform a range of useful calculations quickly with these powerful tools',
        },
      ],
      appFilter: ['pack-pro'],
    },
    {
      name: 'Add-ons (click to enable)',
      items: [
        {
          feature: LicenseFeature.MPGP,
          name: 'Managed Pressure Gravel Pack',
          icon: 'icon-dashboard',
          isAddon: true,
          description: 'Simulate and evaluate sand control operations using MPD technology',
          appFilter: ['pack-pro'],
        },
        ...(isElectron()
          ? [
              {
                feature: LicenseFeature.Detaching,
                name: 'Network License Detaching',
                icon: 'icon-key-circle',
                isAddon: true,
                description: 'Use network licenses offline without an internet connection',
              },
            ]
          : []),
      ],
    },
  ];

  return fullConfig
    .filter((group) => !group.appFilter || group.appFilter.includes(appCode))
    .map(
      (group) =>
        ({
          ...group,
          items: group.items.filter((item) => !item.appFilter || item.appFilter.includes(appCode)),
        }) as IModuleSelectorGroup,
    )
    .filter((group) => group.items.length > 0);
};
