import { FileOperationErrorState } from '../../../+store/file-manager/file-manager.reducer';
import { stringContainsSubstrings } from '@dunefront/common/common/helpers';
import { IError } from '@dunefront/common/common/common-state.interfaces';

export class FileErrorHelper {
  public static getFileErrorMessage(state: FileOperationErrorState): string | undefined {
    const fileErrorType = state.error?.data?.fileErrorType;
    const fileErrorFile = state.error?.data?.file;
    switch (fileErrorType) {
      case 'not-found':
        return 'Not found: ' + fileErrorFile?.Name;
      case 'busy':
        return 'Locked: ' + fileErrorFile?.Name;
      case 'not-permitted':
        return 'Unable to access: ' + fileErrorFile?.Name;
      case 'already-exists':
        return 'Name already exists';
      case 'invalid-filename':
        return 'Invalid name';
      case 'folder-not-empty':
        return 'Cannot delete a folder with projects or folders in it.';
      case 'unknown':
        return 'Project or folder error';
      default:
        return undefined;
    }
  }

  public static validateFileFolderName(name: IFileFolderName, nameList: string[] = [], fileSuffix = ''): IFileFolderName {
    const forbiddenCharacters = ['/', '*', ',', '"', '/', '\\', '[', ']', ':', ';', '|', ',', '<', '>'];

    let error = '';

    if (name.targetName.trim().length < 1) {
      error = 'The project name cannot be empty.';
    }
    if (stringContainsSubstrings(name.targetName, forbiddenCharacters)) {
      error = `The following characters are forbidden:\n${forbiddenCharacters.join('  ')}`;
    }
    if (nameList.map((nl) => nl.toLowerCase()).includes(name.targetName.toLowerCase() + fileSuffix)) {
      error = 'This name already exists in a target folder.';
    }
    return {
      ...name,
      error: { targetName: error },
    };
  }
}

export interface IFileFolderName {
  targetName: string;
  error: IError<IFileFolderName>;
}
