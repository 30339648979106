import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { IFile } from '@dunefront/common/dto/file.dto';
import { Store } from '@ngrx/store';
import { copyFileReturnFile } from '../../../../+store/file-manager/file-manager.actions';
import { BaseFileActionDialogComponent } from '../base-file-action.dialog.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileErrorHelper } from '../file-error.helper';
import {
  CY_NEW_FILE_OK,
  defaultModalCancelButton,
  defaultModalConfirmButton,
  ModalButtonConfig,
} from '../../../../common-modules/modals/generic-modal/generic-modal.component';
import { AppTargetConfig } from '../../../../shared/services/app-target-config';
import { LicenseFeature } from '@dunefront/common/modules/licensing/licensing.interfaces';
import { ProjectFileHelpers } from '@dunefront/common/common/project-file-helpers';

@Component({
  selector: 'app-new-project-dialog',
  templateUrl: './new-project.dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewProjectDialogComponent extends BaseFileActionDialogComponent implements OnInit {
  private readonly fileNameList!: string[];
  // targetFolder required for New file which goes into current folder. Not required for Demo or Shared which go into root
  public targetFolder?: IFile;
  public modalButtonsConfigs: ModalButtonConfig[] = [];

  constructor(
    activeModal: DynamicDialogRef,
    config: DynamicDialogConfig,
    store: Store,
    cdRef: ChangeDetectorRef,
    private appTargetConfig: AppTargetConfig,
  ) {
    super(activeModal, config, store, cdRef);
    this.file = config.data.file;
    this.fileNameList = config.data.fileNameList;
    this.targetFolder = config.data.targetFolder;
    this.contextFileSuffix = `.${this.appTargetConfig.fileTypeExtension}`;
  }

  public ngOnInit(): void {
    if (this.file.Repository === 'template') {
      this.contextFileSuffix = ProjectFileHelpers.getProjectNameSuffix(this.file.Name);
    } else {
      this.fileName = { ...this.fileName, targetName: this.file.Name.trim() };

      if (ProjectFileHelpers.isValidProjectFile(this.fileName.targetName)) {
        this.contextFileSuffix = ProjectFileHelpers.getProjectNameSuffix(this.fileName.targetName);
        this.fileName.targetName = ProjectFileHelpers.getProjectNameWithoutSuffix(this.fileName.targetName);
      }
    }

    this.modalButtonsConfigs = [
      defaultModalCancelButton((): void => this.cancelClicked(), 'Cancel', 'new-file-cancel'),
      defaultModalConfirmButton((): void => this.okClicked(), 'OK', CY_NEW_FILE_OK, this.fileName.targetName === ''),
    ];
  }

  public textValueChanged(textValue: string): void {
    this.fileName = FileErrorHelper.validateFileFolderName(
      {
        ...this.fileName,
        targetName: textValue,
      },
      this.fileNameList,
      this.contextFileSuffix,
    );
    this.errorMessage = this.fileName.error.targetName;
    const okButton = this.modalButtonsConfigs.find((conf) => conf.dataCy === CY_NEW_FILE_OK);
    if (okButton) {
      okButton.disabled = !!this.errorMessage;
    }
  }

  @HostListener('document:keydown.enter')
  public okClicked(): void {
    this.fileName = FileErrorHelper.validateFileFolderName(this.fileName, this.fileNameList, this.contextFileSuffix);
    if (this.fileName.error.targetName !== '') {
      return;
    }
    const fileName = this.fileName.targetName.trim();
    if (this.targetFolder) {
      this.store.dispatch(
        copyFileReturnFile({
          source: this.file,
          target: {
            Repository: 'personal',
            Name: fileName + this.contextFileSuffix,
            Folder: this.getFolderArrayFromParent(this.targetFolder),
            FileType: this.file.FileType,
          },
        }),
      );
    } else {
      this.store.dispatch(
        copyFileReturnFile({
          source: this.file,
          target: {
            Repository: 'personal',
            Name: fileName + this.contextFileSuffix,
            Folder: [],
            FileType: this.file.FileType,
          },
        }),
      );
    }
  }

  public get modalTitle(): string {
    let modalTitle = '';

    switch (this.file.Repository) {
      case 'template':
        modalTitle = 'New Project';
        break;
      case 'demo':
        modalTitle = 'Open Demo Project';
        break;
      case 'shared':
        modalTitle = 'Open Shared Project';
        break;
    }

    return modalTitle;
  }

  protected readonly LicenseFeature = LicenseFeature;
  protected readonly defaultModalCancelButton = defaultModalCancelButton;
}
