<ng-container *ngIf="isPumpingEnabled$ | async; else pageDisabled">
  <ng-container *ngIf="pumpingState.isLoaded">
    <app-page-panel class="grid-panel" header="Schedule" (click)="onHelpChange('pumping', 'schedule-simulate')"
                    [panelHelpMode]="PanelHelpMode.GRID">
      <app-schedule-grid
        *ngIf="scheduleTableState$ | async as scheduleTableState"
        #gridComponent
        [pumpingSchedule]="scheduleTableState"
      ></app-schedule-grid>
    </app-page-panel>
    <div class="buttons-panel">
      <div>
        <app-button (buttonClick)="onSetFullReturns()" text="Set Full Returns"></app-button>
      </div>
      <div>
        <app-button
          dataCy="schedule-import"
          (buttonClick)="onImport()"
          [isDeleteResultsEnabled]="false"
          text="Import"
        >

        </app-button>
        <app-button
          dataCy="schedule-insert"
          (buttonClick)="onInsert()"
          [isDeleteResultsEnabled]="false"
          text="Insert"
        >
        </app-button>
        <app-button
          dataCy="schedule-delete"
          (buttonClick)="onDelete()"
          [isDeleteResultsEnabled]="false"
          text="Delete"
        >
        </app-button>
      </div>
    </div>
    <div [class.bottom-panel]="!isFluidPro" [class.grid-2-col]="!isFluidPro"
         *ngIf="scheduleReferenceVariables$ | async as refVar">
      <app-page-panel *ngIf="!isFluidPro" header="Gravel Required" (click)="onHelpChange('pumping', 'gravel-required')">
        <app-gravel-required-panel [refVariables]="refVar" [pumping]="pumpingState.pumping"
                                   (dataChange)="onPumpingPropertyChanged($event)">
        </app-gravel-required-panel>
      </app-page-panel>
      <app-page-panel header="Reference Parameters" (click)="onHelpChange('pumping', 'reference-parameters-simulate')">
        <app-reference-parameters-panel [refVariables]="refVar"></app-reference-parameters-panel>
      </app-page-panel>
    </div>
  </ng-container>
</ng-container>
<ng-template #pageDisabled>
  <div class="centered-message">The Schedule screen is not available for the Entire Range - select another range to
    update the data
  </div>
</ng-template>
