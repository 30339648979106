import { createAction, props } from '@ngrx/store';
import {
  AllFilesAndFoldersActionResponse,
  FileActionResponse,
  RecentFilesActionResponse,
} from '@dunefront/common/modules/file-manager/file-manager.actions';
import { FileMoveMode, IFile, IFileSourceAndTarget } from '@dunefront/common/dto/file.dto';
import { IHttpError } from '@dunefront/common/exceptions/http.errors';
import { AppError } from '@dunefront/common/exceptions/IAppError';
import { PackProProfileErrorData } from '@dunefront/common/exceptions/file.errors';
import { IUserProfile } from '@dunefront/common/modules/auth/auth.interfaces';

export const loadAllFilesAndFolders = createAction('[file-manager] loadAllFilesAndFoldersAction');
export const loadAllFilesAndFoldersSuccess = createAction(
  '[file-manager] loadAllFilesAndFoldersSuccessAction',
  props<{ allFilesAndFoldersActionResponse: AllFilesAndFoldersActionResponse }>(),
);
export const loadAllFilesAndFoldersFailure = createAction('[file-manager] loadAllFilesAndFoldersFailureAction', props<IHttpError>());
export const loadOrganizationUsers = createAction('[file-manager] loadOrganizationUsers');
export const loadOrganizationUsersSuccess = createAction(
  '[file-manager] loadOrganizationUsersSuccessAction',
  props<{ organizationUsers: IUserProfile[] }>(),
);
export const loadOrganizationUsersFailure = createAction('[file-manager] loadOrganizationUsersAction', props<Error>());

export const selectUser = createAction('[file-manager] selectUserAction', props<{ id: string }>());
export const clearSelectUser = createAction('[file-manager] clearSelectUserAction');

export const deleteFileOrFolder = createAction('[file-manager] deleteFileAction', props<{ target: IFile; isAdminPanel: boolean }>());
export const moveFileOrFolder = createAction(
  '[file-manager] moveFileOrFolderAction',
  props<{ source: IFile; target: IFile; mode: FileMoveMode }>(),
);
export const copyFileReturnList = createAction('[file-manager] copyFileReturnListAction', props<IFileSourceAndTarget>());

export const copyFileReturnFile = createAction('[file-manager] copyFileReturnFileAction', props<IFileSourceAndTarget>());
export const copyFileReturnFileSuccess = createAction(
  '[file-manager] copyFileReturnFileSuccessAction',
  props<{ fileActionResponse: FileActionResponse }>(),
);

export const newFolder = createAction('[file-manager] newFolderAction', props<{ target: IFile }>());

export const loadRecentFiles = createAction('[file-manager] loadRecentFilesAction');
export const loadRecentFilesSuccess = createAction(
  '[file-manager] loadRecentFilesSuccessAction',
  props<{ recentFilesActionResponse: RecentFilesActionResponse }>(),
);
export const loadRecentFilesFailure = createAction('[file-manager] loadRecentFilesFailureAction', props<IHttpError>());

export const clearRecentFiles = createAction('[file-manager] clearRecentFilesAction');
export const clearRecentFilesSuccess = createAction('[file-manager] clearRecentFilesSuccessAction');
export const clearRecentFilesFailure = createAction(
  '[file-manager] clearRecentFilesFailureAction',
  props<{ error: AppError<PackProProfileErrorData> }>(),
);

export const restoreFile = createAction('[file-manager] restoreFileAction', props<{ source: IFile; target: IFile }>());
export const restoreFileSuccess = createAction('[file-manager] restoreFileSuccessAction', props<{ fileActionResponse: FileActionResponse }>());

export const fileOperationFailure = createAction(
  '[file-manager] fileOperationFailureAction',
  props<{ error: AppError<PackProProfileErrorData> }>(),
);
export const clearFileOperationFailure = createAction('[file-manager] clearFileOperationFailure');

export const emptyTrashAction = createAction('[file-manager] emptyTrashAction');
export const emptyTrashActionSuccess = createAction('[file-manager] emptyTrashActionSuccess');

export const deleteSelectedUser = createAction('[file-manager] deleteSelectedUserAction');
export const deleteUserSuccess = createAction('[file-manager] deleteSelectedUserSuccessAction', props<{ user: IUserProfile }>());
export const deleteUserFailure = createAction('[file-manager] deleteSelectedUserFailureAction', props<Error>());
export const restoreOrgDBAction = createAction('[BackendConnection] restoreOrgDBAction', props<{ backup: IFile }>());

export const updateSearchPhrase = createAction('[file-manager] updateSearchPhraseAction', props<{ searchPhrase: string }>());
