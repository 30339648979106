import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit } from '@angular/core';
import { createTableRow, createTableState, ITableRow, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { PumpedFluidAndGravelDto } from '@dunefront/common/modules/pumping/dto/pumped-fluid-and-gravel.dto';
import { GridContainerComponent } from '../../../../../shared/components/grid/grid-container.component';
import { ColumnType, IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { GridConfig } from '../../../../../shared/components/grid/grid-config';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import { getFluidsSelectData } from '../../../../../+store/fluid/fluid.selectors';
import { getGravelsSelectData } from '../../../../../+store/gravel/gravel.selectors';
import { ScreenService } from '../../../../../shared/services';
import {
  deletePumpedFluidAndGravelRowsAction,
  insertPumpedFluidAndGravelRowsAction,
  updatePumpedFluidAndGravelRowsAction,
} from '../../../../../+store/pumping/pumping.actions';
import { GridResizeService } from '../../../../../shared/services/grid-resize.service';
import { IDeleteRowsProps, IInsertRowsProps, IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { InsertLocation } from '@dunefront/common/modules/common.interfaces';
import { PumpedFluidAndGravelFactory } from '@dunefront/common/modules/pumping/model/pumped-fluid-and-gravel/pumped-fluid-and-gravel.factory';

const PUMPED_FLUID_TYPES_LOOKUP_SRC_ID = 'FLUID';
const PUMPED_GRAVEL_TYPES_LOOKUP_SRC_ID = 'GRAVEL';

@Component({
  selector: 'app-pumped-fluid-and-gravel-grid',
  templateUrl: './pumped-fluid-and-gravel-grid.component.html',
  styleUrls: ['./pumped-fluid-and-gravel-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PumpedFluidAndGravelGridComponent extends GridContainerComponent<PumpedFluidAndGravelDto> implements OnInit {
  @Input()
  public tableState: ITableState<PumpedFluidAndGravelDto> = createTableState([]);

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    el: ElementRef,
    protected resizeService: ScreenService,
    gridResizeService: GridResizeService,
  ) {
    super(store, cdRef, el, new PumpedFluidAndGravelGridConfig(store, modalService), gridResizeService);
    this.margin = 45;
  }

  protected getColumns(): IGridColumnConfig<PumpedFluidAndGravelDto>[] {
    return [
      { disabled: false, visible: true, colId: ' ', type: ColumnType.selection },
      {
        disabled: false,
        visible: true,
        headerText: 'Fluid',
        unitSystem: UnitSystem.None,
        colId: 'FluidId',
        lookupDataSourceType: PUMPED_FLUID_TYPES_LOOKUP_SRC_ID,
        type: ColumnType.select,
        width: 180,
        matchingStrings: ['fluid'],
      },
      {
        disabled: false,
        visible: true,
        headerText: 'Gravel',
        unitSystem: UnitSystem.None,
        colId: 'GravelId',
        lookupDataSourceType: PUMPED_GRAVEL_TYPES_LOOKUP_SRC_ID,
        type: ColumnType.select,
        width: 180,
        matchingStrings: ['gravel'],
      },
      {
        disabled: false,
        visible: true,
        headerText: 'Slurry Volume',
        type: ColumnType.number,
        unitSystem: UnitSystem.Liquid_Volume,
        colId: 'Volume',
        matchingStrings: ['slurry', 'vol'],
      },
    ];
  }

  public async onImportClick(): Promise<void> {
    await this.grid.onImportClicked();
  }

  public async onInsertClick(): Promise<void> {
    await this.grid.onInsertClicked();
  }

  public async onDeleteClick(): Promise<void> {
    await this.grid.onDeleteClicked();
  }
}

export class PumpedFluidAndGravelGridConfig extends GridConfig<PumpedFluidAndGravelDto> {
  private fluids: ISelectItem<number>[] = [];
  private gravels: ISelectItem<number>[] = [];

  constructor(
    private store: Store,
    modalService: ModalService,
  ) {
    super(modalService);
    this.headerText = 'Pumped Fluid and Gravel';
    this.subscription.add(
      this.store.select(getFluidsSelectData).subscribe((fluids) => {
        this.fluids = fluids.items;
      }),
    );

    this.subscription.add(
      this.store.select(getGravelsSelectData).subscribe((gravels) => {
        this.gravels = gravels.items;
      }),
    );
  }

  public override getLookupDataSource = (type: string): ISelectItem<number>[] => {
    if (type === PUMPED_FLUID_TYPES_LOOKUP_SRC_ID) {
      return this.fluids;
    }
    if (type === PUMPED_GRAVEL_TYPES_LOOKUP_SRC_ID) {
      return this.gravels;
    }
    return [];
  };

  public override isCellDisabled(rows: ITableRow<PumpedFluidAndGravelDto>[], rowIndex: number, cellIndex: number): boolean {
    if (rows.length === 0) {
      return false;
    }

    if (rows[rowIndex].rowType === 'insert-row' && this.columns[cellIndex].colId !== 'FluidId') {
      return true;
    }
    return false;
  }

  public override async deleteRowsAction(props: IDeleteRowsProps): Promise<void> {
    this.store.dispatch(deletePumpedFluidAndGravelRowsAction(props));
  }

  public override insertRowAction(props: IInsertRowsProps<PumpedFluidAndGravelDto>, refRow: ITableRow<PumpedFluidAndGravelDto>): void {
    this.store.dispatch(insertPumpedFluidAndGravelRowsAction(props));
  }

  public override updateRowsAction(props: IUpdateTableRowsProps<PumpedFluidAndGravelDto>): void {
    this.store.dispatch(updatePumpedFluidAndGravelRowsAction(props));
  }

  public override getLookupDataPlaceholder(type: string): string {
    if (type === PUMPED_FLUID_TYPES_LOOKUP_SRC_ID) {
      return 'Select Fluid';
    }

    if (type === PUMPED_GRAVEL_TYPES_LOOKUP_SRC_ID) {
      return 'Select Gravel';
    }

    return '';
  }

  public override createEmptyModel(scenarioId: number, rangeId: number): PumpedFluidAndGravelDto {
    return PumpedFluidAndGravelFactory.createEmpty(scenarioId, rangeId);
  }

  public override createDefaultTableRows(scenarioId: number, noOfRows: number, rangeId?: number): ITableRow<PumpedFluidAndGravelDto>[] {
    const newTableRows = [];
    for (let index = 0; index < noOfRows; index++) {
      if (rangeId != null) {
        newTableRows.push(createTableRow(this.createEmptyModel(scenarioId, rangeId), 'data', index, false));
      }
    }
    return newTableRows;
  }

  public override replaceGridAction(rows: ITableRow<PumpedFluidAndGravelDto>[]): void {
    if (rows.length > 0) {
      const props: IInsertRowsProps<any> = {
        rows,
        refId: 0,
        insertLocation: 'replace' as InsertLocation,
        shouldResetResults: true,
      };
      this.store.dispatch(insertPumpedFluidAndGravelRowsAction(props));
    }
  }
}
