import { ModuleType } from '../modules/scenario/scenario.dto';
import { LicensingErrorType } from './errors';
import { ConnectionLicensingConfig } from '../modules/db-connection/db-connection.actions';
import { HaspStatus } from '../modules/licensing/licensing.interfaces';
import { EnumHelpers } from '../utils/enum.helpers';

export interface IAppError extends Error {
  message: string;
  isHandled?: boolean; //when true ui will not crash but show alert. Sentry won't be notified
  stack?: string;
  moduleType?: ModuleType;
  data?: any;
  sendToSentry?: boolean; //error will be sent to sentry even if isHandled===true
  dialogTitle?: string;
  customHandle?: string; //it's used when we want to manually handle this error
}

export class AppError<T> extends Error implements IAppError {
  public customHandle: string | undefined;

  constructor(
    message: string,
    public readonly data: T,
    public readonly isHandled = false,
    public readonly errorType?: AppErrorType,
    public readonly moduleType?: ModuleType,
    public readonly sendToSentry?: boolean,
    public readonly dialogTitle?: string,
  ) {
    super(message);
  }
}

export const isErrorOfType = (exception: any, errorType: string): boolean => exception?.data?.errorType === errorType;

export interface LicensingErrorData {
  licenseErrorType: LicensingErrorType;
  haspErrorMessage: string;
  licensingConfig?: ConnectionLicensingConfig;
}

export const createLicensingError = (
  licenseErrorType: LicensingErrorType,
  haspErrorMessage = '',
  licensingConfig?: ConnectionLicensingConfig,
): AppError<LicensingErrorData> =>
  new AppError<LicensingErrorData>(licenseErrorType, { licenseErrorType, haspErrorMessage, licensingConfig }, true);

export function isLicensingError(err: any): err is AppError<LicensingErrorData> {
  return err?.data?.licenseErrorType != null;
}

export function tryParseCalcEngineLicenseError(err: unknown): AppError<LicensingErrorData> {
  if (String(err).includes(LicensingErrorType.ERROR_LICENSE_SERVER_NOT_AVAILABLE)) {
    return createLicensingError(LicensingErrorType.ERROR_LICENSE_SERVER_NOT_AVAILABLE);
  }

  if (String(err).includes(EnumHelpers.enumToText(HaspStatus[HaspStatus.FeatureNotFound]))) {
    return createLicensingError(LicensingErrorType.ERROR_LICENSE_FEATURE_NOT_FOUND);
  }

  return createLicensingError(LicensingErrorType.ERROR_LICENSE_HASP, String(err));
}

export function getLicenseErrorTextFromError(licensingError: AppError<LicensingErrorData>): string {
  return getLicenseErrorText(licensingError.data?.licenseErrorType, licensingError.data?.haspErrorMessage);
}

export function getLicenseErrorText(licenseErrorType: LicensingErrorType | undefined, haspErrorMessage: string | undefined): string {
  switch (licenseErrorType) {
    case LicensingErrorType.ERROR_LICENSE_SERVER_NOT_AVAILABLE:
      return `Can't connect to Sentinel LDK License Manager`;
    case LicensingErrorType.ERROR_LICENSE_FEATURE_NOT_FOUND:
      return 'License feature not found';
    case LicensingErrorType.ERROR_LICENSE_SESSION_EXPIRED:
    case LicensingErrorType.ERROR_LOGGED_IN_LICENSE_FEATURE_NOT_FOUND:
      return `Connection to the license failed: <br>- Click retry to attempt to reconnect <br>- Click cancel to close the file`;
    case LicensingErrorType.ERROR_LICENSE_LOGIN_NOT_FOUND:
      return 'Licensing login not found';
    case LicensingErrorType.ERROR_LICENSE_HASP:
      return haspErrorMessage ?? '';
    default:
      return 'Unknown License Error';
  }
}

export type AppErrorType =
  | 'file-error'
  | 'short-worker-timeout'
  | 'job-result-error'
  | 'psd-id-not-found'
  | 'get-initial-data-failed'
  | 'common-db-error'
  | 'data-storage-file-not-found'
  | 'results-db-not-found'
  | 'import-data-db-not-found'
  | 'licensing-error';

export const tryParseCalcEngineError = <T>(error: any): AppError<T> => {
  if (error.textMessage) {
    try {
      const parsed = JSON.parse(error.textMessage);
      return {
        isHandled: parsed.isHandled ?? false,
        moduleType: parsed.moduleType ?? ModuleType.Error,
        name: 'CalcEngineError',
        message: parsed.message ?? '',
        stack: parsed.stack ?? '',
        data: parsed.data ?? {},
        dialogTitle: parsed.isHandled ? 'Warning' : '',
        customHandle: parsed.handleModule,
      };
    } catch {
      return error;
    }
  } else {
    return error;
  }
};
