<div class="drop-zone-container" [ngClass]="{ 'drop-zone-container-visible': isDropZoneVisible }" (drop)="onDrop($event)">
  <ngx-file-drop
    dropZoneLabel="Drop files here"
    [multiple]="true"
    (onFileDrop)="uploadFiles($event)"
    [accept]=getAllowedFileSuffixes()
    dropZoneClassName="drop-zone"
    contentClassName="drop-zone-content"
    (onFileLeave)="isDropZoneVisible = false"
  >
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
      <span class="icon-delete" (click)="isDropZoneVisible = false"></span>
      <div class="drop-zone-text">
        <span class="icon-Upload"></span>
        <span>Drag and drop your</span>
        <span>project anywhere</span>
      </div>
      <div #openFileSelector (click)="openFileSelector()"></div>
      <ng-content></ng-content>
    </ng-template>
  </ngx-file-drop>
</div>
