<ng-container *ngrxLet="vm$ as vm">
  <div class="dialog-header">
    <img [src]="appIconWhitePath" alt="App Logo">
    <p><b>Select Module </b> | Only licensed modules are available for selection</p>
  </div>
  <div class="module-selector-info">Only licensed modules are available for selection</div>
  <div class="dialog-content">
    <div class="module-selector-wrapper">
      <ng-template #moduleSelectorItem
                   let-feature="feature"
                   let-name="name"
                   let-icon="icon"
                   let-isAddon="isAddon"
                   let-description="description"

      >
        <div class="module-selector-item"
             *ngrxLet="getItemInfo(vm, feature) as info"
             [ngClass]="{'disabled' : info.isDisabled, 'selected' : isItemHighlighted(vm, feature, isAddon), 'three-cols': isThreeColsLayout()}"
             [attr.data-cy]="'module-selector-item_' + feature"
             [pTooltip]="info.message"
             positionStyle="absolute"
             tooltipPosition="top"
        >
          <i
            [ngClass]="info.icon"
            class="status-icon"
          >
          </i>
          <a (click)="onClick($event, info.isDisabled, feature, vm.accessibleLicenseIds, isAddon)">
            <div [class.warning]="isItemHighlighted(vm, feature, isAddon)" class="module-icon">
              <span class="{{ icon }}"></span>
            </div>
            <span>
          <p class="module-name">{{ name }}</p>
          <p class="module-desc">{{ description }}</p>
        </span>
          </a>
        </div>
      </ng-template>


      <div class="module-selector-grid">

        <div class="module-group" *ngFor="let group of moduleSelectorConfig">
          <h4><span class="orange-bullet"></span>{{ group.name }}</h4>

          <div class="modules-row">
            <ng-container
              *ngFor="let item of group.items"
              [ngTemplateOutlet]="moduleSelectorItem"
              [ngTemplateOutletContext]="item"
            ></ng-container>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="dialog-footer left-right">

    <img src="assets/dunefront-logo-white.svg" alt="DuneFront">
    <div class="footer-right">

      <p>Get in touch with us at <a target="_blank" href="mailto:sales@dunefront.com">sales&#64;dunefront.com</a> to
        upgrade your license or
        purchase add-ons</p>
      <app-button
        (buttonClick)="cancelClicked()"
        [dialogRef]="activeModal"
        [isUiLockable]="false"
        buttonType="cancel"
        dataCy="module-selector-cancel-btn"
        text="Cancel"
      >
      </app-button>
    </div>

  </div>
</ng-container>
