<app-grid
  *ngIf="height > 0"
  [isFirstRowInsertingAllowed]="true"
  [isFirstRowDeleteAllowed]="true"
  [minRowCount]="1"
  [dataSource]="commonGravelData"
  [gridConfig]="gridConfig"
  [columns]="gridConfig.columns"
  [height]="height"
  [showCrudControls]="false"
  [singleRowSelectionMode]="true"
  [customKeyboardHandlers]="customKeyboardHandlers"
  dataCy="database-gravel-grid"
  elementId="database-gravel-grid"
  (selectedRowsChanged)="selectedRowsChanged.emit($event)"
  [groupByColumns]="['commonDbType']"
></app-grid>
