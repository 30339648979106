import { Injectable } from '@angular/core';
import { firstValueFrom, fromEvent, of, Subject, Subscription, timeout } from 'rxjs';
import {
  getDepthDataSimulationDuration,
  isDepthDataForCurrentSimulationTimeLoaded,
} from '../../+store/reporting/reporting-get-depth-chart-state.selector';
import { setSelectedSimulationTime } from '../../+store/reporting/reporting.actions';
import { Store } from '@ngrx/store';
import { Base64Media } from './image-provider.helpers';
import { selectVideoFramerate, selectVideoSteps } from '../../+store/common-db/common-db.selectors';
import { getSelectedSimulationTime } from '../../+store/reporting/reporting.selectors';
import { ElectronService } from '../../shared/services/electron-service/electron.service';
import { ModalService } from '../modals/modal.service';
import { ImageProviderMode, SelectProviderComponent } from '../modals/select-image-provider/select-provider.component';
import { AppError } from '@dunefront/common/exceptions/IAppError';
import { OneTimeInstructionType } from '../../+store/ui/ui-module.state';
import { videoFromImageArray } from '../../shared/whammyts/whammy';
import { isElectron } from '@dunefront/common/common/electron/is-electron';
import { ELECTRON_ACTION_SAVE_VIDEO, ELECTRON_ACTION_SAVE_VIDEO_FINISHED } from '@dunefront/common/common/electron/electron.actions';
import { DrawableRegistryService, DrawableVideoProvider } from '../../shared/services/drawable-registry.service';
import { catchError, filter } from 'rxjs/operators';
import { wait } from '@dunefront/common/common/helpers';

enum PlayerState {
  INACTIVE = 0,
  RECORDING = 1,
  PLAYING = 2,
}

const ERROR_WINDOW_RESIZED = 'Window was resized, recording has been cancelled';
const ERROR_CANT_LOAD_DATA = 'Cannot load data for current frame. Please try again or contact support@dunefront.com';

@Injectable({ providedIn: 'root' })
export class VideoRecorderService {
  private simulationDuration = 0;
  private videoFrames = 0;
  private selectedSimulationTime = 0;
  private framerate = 0;
  private initialSimulationTime = 0;
  private providerRecording$ = new Subject<string | null>();
  private windowResizeSubscription$: Subscription | null = null;
  private providerName: string | null = null;
  private context: HTMLCanvasElement | null = null;
  private snapshots: string[] = [];
  private snapshotTimestamps: number[] = [];
  private playerMode: PlayerState = PlayerState.INACTIVE;
  private recordVideoResolve?: (value: Blob) => void;
  private recordVideoReject?: () => void;

  constructor(
    private store: Store,
    private electronService: ElectronService,
    private modal: ModalService,
    private drawableRegistryService: DrawableRegistryService,
  ) {}

  public getCurrentProviderIdRecording(): Subject<string | null> {
    return this.providerRecording$;
  }

  public async recordAndDownloadVideo(): Promise<void> {
    const provider = await this.selectProviderForRecording();
    if (provider == null) {
      return;
    }

    await this.modal.showOneTimeMessageIfNeeded(OneTimeInstructionType.videoRecordingResizeInfo);

    try {
      this.watchWindowResize();

      const videoData = await this.recordVideo(provider);

      this.downloadVideo(videoData, provider.id);
    } catch (_) {
      this.showRecordingError();
    }
  }

  public async recordBase64Video(provider: DrawableVideoProvider): Promise<Base64Media | undefined> {
    // read current simulation time from and store in temp variable
    const simulationTime = await firstValueFrom(this.store.select(getSelectedSimulationTime));
    // set simulation time 0
    this.store.dispatch(setSelectedSimulationTime({ selectedSimulationTime: 0 }));

    try {
      const videoData = await this.recordVideo(provider);
      const base64str = await this.blobToBase64(videoData);
      const videoDataBase64 = `data:video/webm;base64,${base64str}`;

      // take image after video recording (simulation time at the end)
      const image = await provider.getBase64Image();
      if (image == null) {
        return undefined;
      }

      return { ...image, videoDataBase64 };
    } catch (_) {
      return undefined;
    } finally {
      // restore original simulation time
      this.store.dispatch(setSelectedSimulationTime({ selectedSimulationTime: simulationTime }));
    }
  }

  private async recordVideo(provider: DrawableVideoProvider): Promise<Blob> {
    if (provider.getCanvasForRecording?.() == null) {
      return Promise.reject();
    }

    this.providerRecording$.next(provider.id);
    this.playerMode = PlayerState.RECORDING;

    const canvas = provider.getCanvasForRecording?.();
    this.providerName = provider.id;
    this.context = canvas;

    await this.initPlayer();

    this.updateSimulationTime(this.initialSimulationTime === this.simulationDuration);

    return new Promise<Blob>((resolve, reject) => {
      this.recordVideoResolve = (data): void => {
        resolve(data);

        this.recordVideoResolve = undefined;
        this.recordVideoReject = undefined;
      };
      this.recordVideoReject = (): void => {
        reject();

        this.recordVideoResolve = undefined;
        this.recordVideoReject = undefined;
      };
    });
  }

  public async play(): Promise<void> {
    this.playerMode = PlayerState.PLAYING;
    this.providerRecording$.next('playing');

    await this.initPlayer();

    this.updateSimulationTime();

    // in Playing mode, we don't have an image provider to subscribe, so we have to update slider manually
    const timer = setInterval(() => {
      if (this.selectedSimulationTime >= this.simulationDuration || this.playerMode === PlayerState.INACTIVE) {
        clearInterval(timer);
        this.cleanup();
        return;
      }
      this.updateSimulationTime();
    }, 40);
  }

  private async initPlayer(): Promise<void> {
    this.snapshots = [];
    this.snapshotTimestamps = [];

    // fps
    this.framerate = await firstValueFrom(this.store.select(selectVideoFramerate));

    // get total animation length
    this.simulationDuration = await firstValueFrom(this.store.select(getDepthDataSimulationDuration));

    // number of frames to record
    this.videoFrames = await firstValueFrom(this.store.select(selectVideoSteps));

    // set start time - if slider is currently at the end, start from 0, otherwise start from current position
    this.initialSimulationTime = await firstValueFrom(this.store.select(getSelectedSimulationTime));

    this.selectedSimulationTime = this.initialSimulationTime >= this.simulationDuration ? 0 : this.initialSimulationTime;
  }

  private async onHandleChartUpdatedPlaying(): Promise<void> {
    this.updateSimulationTime();
  }

  private async onHandleChartUpdatedRecording(): Promise<void> {
    const storeSimulationTime = await firstValueFrom(this.store.select(getSelectedSimulationTime));

    if (this.selectedSimulationTime !== storeSimulationTime) {
      return;
    }

    // wait until data is actually loaded, cancel recording after 10secs if data is not loaded
    const dataLoaded = await firstValueFrom(
      this.store.select(isDepthDataForCurrentSimulationTimeLoaded).pipe(
        filter((isDataLoaded) => isDataLoaded),
        timeout(10000),
        catchError(() => of(false)),
      ),
    );

    await wait(1);

    if (!dataLoaded) {
      this.cancelRecording(ERROR_CANT_LOAD_DATA);
      return;
    }

    const snapshot = this.createSnapshot();
    // skip snapshot if it's not valid
    if (snapshot == null) {
      return;
    }

    if (this.snapshotTimestamps.includes(storeSimulationTime)) {
      return;
    }

    this.snapshotTimestamps.push(storeSimulationTime);
    this.snapshots.push(snapshot);

    // finish
    if (this.selectedSimulationTime >= this.simulationDuration) {
      this.addBuffer();
      this.onFinish().then();
      return;
    }

    this.updateSimulationTime();
  }

  public async handleChartUpdated(providerName: string): Promise<void> {
    if (!this.context || providerName !== this.providerName) {
      return;
    }

    switch (this.playerMode) {
      case PlayerState.PLAYING:
        await this.onHandleChartUpdatedPlaying();
        break;
      case PlayerState.RECORDING:
        await this.onHandleChartUpdatedRecording();
        break;
    }
  }

  private updateSimulationTime(isStartFromZero = false): void {
    const nextStep = this.selectedSimulationTime + this.simulationDuration / this.videoFrames;
    const updatedSimulationTime = nextStep <= this.simulationDuration ? nextStep : this.simulationDuration;

    this.selectedSimulationTime = isStartFromZero ? 0 : updatedSimulationTime;

    this.store.dispatch(setSelectedSimulationTime({ selectedSimulationTime: this.selectedSimulationTime }));
  }

  // finish region
  public async onFinish(): Promise<void> {
    if (this.playerMode === PlayerState.RECORDING) {
      try {
        const blob = videoFromImageArray(this.snapshots, this.framerate) as Blob;

        if (this.recordVideoResolve) {
          this.recordVideoResolve(blob);
        }
      } catch (e) {
        if (this.recordVideoReject) {
          this.recordVideoReject();
        }
        console.warn(e);
      }
    }

    this.cleanup();
    return;
  }

  private downloadVideo(videoData: Blob, name: string): void {
    try {
      const fileName = `${name}.webm`;

      if (isElectron()) {
        this.sendFileToElectron(videoData, fileName);
      } else {
        const url = window.URL.createObjectURL(videoData);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = fileName;
        anchor.click();
      }
    } catch (e) {
      console.warn(e);
      this.showRecordingError();
    }
  }

  private blobToBase64(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = (): void => {
        const base64String = reader.result as string;
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  private cleanup(): void {
    // reset all variables and state
    this.windowResizeSubscription$?.unsubscribe();
    this.playerMode = PlayerState.INACTIVE;
    this.snapshots = [];
    this.snapshotTimestamps = [];
    this.providerName = null;
    this.simulationDuration = 0;
    this.videoFrames = 0;
    this.selectedSimulationTime = 0;
    this.initialSimulationTime = 0;
    this.context = null;
    this.providerRecording$.next(null);
  }

  private sendFileToElectron(blob: Blob, fileName: string): void {
    const reader = new window.FileReader();
    reader.onload = (): void => {
      if (this.electronService.instance) {
        if (reader.readyState == 2) {
          const buffer = Buffer.from(reader.result as any);

          this.electronService.instance.ipcRenderer.send(ELECTRON_ACTION_SAVE_VIDEO, {
            buffer,
            fileName,
          });

          this.electronService.instance.ipcRenderer.on(ELECTRON_ACTION_SAVE_VIDEO_FINISHED, (_, result) => {
            if (result.error) {
              this.showRecordingError();
            }
          });
        }
      }
    };
    reader.readAsArrayBuffer(blob);
  }

  private async selectProviderForRecording(): Promise<DrawableVideoProvider | null> {
    const videoProviders = await firstValueFrom(this.drawableRegistryService.videoProviders$);

    // if there is only one provider, skip opening modal
    const selectedVideoProvider =
      videoProviders.length === 1
        ? videoProviders[0]
        : await firstValueFrom(
            this.modal.open(
              SelectProviderComponent,
              {
                providers: videoProviders,
                mode: ImageProviderMode.VIDEO,
              },
              undefined,
              undefined,
              undefined,
              false,
            ).onClose,
          );

    if (selectedVideoProvider === null) {
      // on cancel
      return null;
    }

    if (selectedVideoProvider === undefined) {
      throw new AppError('No ImageProviders found!', null);
    }

    if (selectedVideoProvider.getCanvasForRecording == null) {
      throw new AppError('Canvas not found!', null);
    }
    return selectedVideoProvider;
  }

  public isRecordingAnything(): boolean {
    return this.providerName != null;
  }

  private watchWindowResize(): void {
    this.windowResizeSubscription$ = fromEvent(window, 'resize').subscribe(() => {
      this.cancelRecording(ERROR_WINDOW_RESIZED);
    });
  }

  private cancelRecording(reason: string): void {
    this.cleanup();
    this.modal.showAlert(reason).then();
  }

  private createSnapshot(): string | null {
    if (!this.context) {
      return null;
    }

    const snapshotQuality = 0.98; // quality for snapshot (0-1) ( can't be 1, as webp doesn't support lossless compression
    const snapshot = this.context.toDataURL('image/webp', snapshotQuality);

    // In some specific cases, snapshot may not contain VP8/VP8L information, which will cause error on parsing video
    const encoded = atob(snapshot.slice(23));
    const hasVP8Information = encoded.includes('VP8') || encoded.includes('VP8L');

    if (hasVP8Information) {
      return snapshot;
    } else {
      console.warn("Snapshot doesn't contain VP8 information. Skipping this frame");
      return null;
    }
  }

  private addBuffer(): void {
    // add 1 second of current view to video as a buffer
    const framesQty = this.framerate;
    const screenshot = this.createSnapshot();
    if (screenshot != null) {
      this.snapshots.push(...Array(framesQty).fill(screenshot));
    }
  }

  private showRecordingError(): void {
    this.modal
      .showAlert('There was a problem with recording. Please try again or contact support@dunefront.com for help.', 'Recording error')
      .then();
  }
}
