<app-page-panel header="Performance" *ngIf="settings && defaultSettings" [allowFullScreen]="false">
  <div class="grid-form-container">
    <app-form-input
      class="grid-two-one"
      appUnwrap
      label="Max Volume Step"
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'MaxVolumeStep'"
      [decimalPlaces]="4"
      [unitType]="UnitType.Liquid_Volume"

      [warning]="settings.MaxVolumeStep !== defaultSettings.MaxVolumeStep"
      (valueChanged)="onValueChange($event)"
      dataCy="max-volume-step"
    >
    </app-form-input>
    <app-form-input
      class="grid-two-one"
      appUnwrap
      label="Rate Tolerance"
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'RateChangeTolerance'"
      [unitType]="UnitType.Rate"
      [decimalPlaces]="4"

      [warning]="settings.RateChangeTolerance !== defaultSettings.RateChangeTolerance"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      class="grid-two-one"
      appUnwrap
      label="Gravel Concentration Tolerance"
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'GravelConcentrationChangeTolerance'"
      [decimalPlaces]="4"

      [unitType]="UnitType.Solid_Concentration"
      [warning]="settings.GravelConcentrationChangeTolerance !== defaultSettings.GravelConcentrationChangeTolerance"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      class="grid-two-one"
      appUnwrap
      label="Choke Pressure Tolerance"
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'ChokePressureChangeTolerance'"
      [decimalPlaces]="4"

      [unitType]="UnitType.Pressure"
      [warning]="settings.ChokePressureChangeTolerance !== defaultSettings.ChokePressureChangeTolerance"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      class="grid-two-one"
      appUnwrap
      label="Max No. of Reservoir Cells"
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'MaxNumberOfReservoirCells'"
      [decimalPlaces]="0"

      [unitType]="UnitType.None"
      [warning]="settings.MaxNumberOfReservoirCells !== defaultSettings.MaxNumberOfReservoirCells"
      (valueChanged)="onValueChange($event)"
      dataCy="max-reservoir-cells"
    >
    </app-form-input>
  </div>
</app-page-panel>
