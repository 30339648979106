<div class="grid-form-container no-padding">
  <app-font-style
    appUnwrap
    [source]="annotationStyle"
    [sourceDefaults]="defaultAnnotationStyle"
    [fontSizeKey]="'AnnotationFontSize'"
    [fontColorKey]="'AnnotationFontColour'"
    [isBoldKey]="'AnnotationFontBold'"
    [isItalicKey]="'AnnotationFontItalic'"
    [idPrefix]="'annotation'"
    [disabled]="disabled"
    (valueChanged)="valueChanged.emit($event)"
  >
  </app-font-style>

  <label [class.warning]="isValueDiffThanDefault('AnnotationFillColour')">Fill Color</label>
  <app-select
    [source]="annotationStyle"
    [sourceDefaults]="defaultAnnotationStyle"
    [key]="'AnnotationFillColour'"
    [items]="Colors"
    [colorPicker]="true"
    [isUiLockable]="false"
        [disabled]="disabled"
    [warning]="isValueDiffThanDefault('AnnotationFillColour')"
    dataCy="annotation-details-fill-colour"
    (valueChanged)="valueChanged.emit($event)"
  >
  </app-select>

  <app-form-input
    appUnwrap
    class="grid-two-one percentage"
    label="Transparency"
    dataCy="annotation-details-transparent"
    [isUiLockable]="false"
    [source]="annotationStyle"
    [sourceDefaults]="defaultAnnotationStyle"
    [key]="'AnnotationFillTransparency'"
    [decimalPlaces]="1"
    [min]="0"
    [max]="100"
    [unitType]="UnitType.None"
    unitLabel="%"

    [disabled]="disabled"
    [warning]="isValueDiffThanDefault('AnnotationFillTransparency')"
    (valueChanged)="valueChanged.emit($event)"
  >
  </app-form-input>

  <label
    [class.warning]="isValueDiffThanDefault('AnnotationTailVisibility')">Tail</label>
  <app-check-box
    [source]="annotationStyle"
    [sourceDefaults]="defaultAnnotationStyle"
    [key]="'AnnotationTailVisibility'"
    [isUiLockable]="false"
    id="AnnotationTailVisibilityId"
    dataCy="annotation-details-show-tail"
    [disabled]="disabled"
    [warning]="isValueDiffThanDefault('AnnotationTailVisibility')"
    (valueChanged)="valueChanged.emit($event)"
  >
  </app-check-box>
</div>
