<app-radio-group
  [isUiLockable]="false"
  id="time-volume"
  [items]="timeVolumeRadio"
  [value]="axisSelectionProps.chartTimeVolMode"
  (primitiveValueChanged)="timeVolModeChanged($event.value)"
  [layout]="'vertical'"
  dataCy="axis-selection--time-vol-radio"
  [disabled]="!axisSelectionProps.timeVolEnabled"
>
</app-radio-group>
<app-button
  class="big-button"
  [isAdvancedButton]="true"
  [isAdvancedButtonOnly]="true"
  [advancedButtonWidth]="'236px'"
  [isUiLockable]="false"
  [advancedButtonContextMenuItems]="advancedButtonMenuItems"
  dataCy="axis-selection--time-vol-radio-advanced-btn"
  [disabled]="!axisSelectionProps.timeVolEnabled"
></app-button>
