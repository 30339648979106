<div class="dialog-header" *ngIf="title">
  <h4>{{ title }}</h4>
</div>
<div class="dialog-content">
  <p>Please enter equation file name:</p>
  <app-form-input
    [isUiLockable]="isUiLockable"
    [isTextInput]="isTextInput"
    [label]="label"
    [autoFocus]="true"
    [triggerOnKeyPress]="true"
    [source]="inputValue"
    [min]="min"
    [key]="'value'"
    [decimalPlaces]="decimalPlaces"
    dataCy="new-equation-file-name-input"
    (valueChanged)="onValueChanged($event)"
    [showSymbol]="false"
  ></app-form-input>

  <p>Please enter equation range:</p>
  <app-select
    *ngIf="selectRangeData$ | async as selectRangeData"
    [items]="selectRangeData"
    [isUiLockable]="false"
    [disableSorting]="true"
    [value]="selectedRangeId"
    (primitiveValueChanged)="onRangeChanged($event.value)"
    [dataCy]="'new-equation-dialog-select-range'"
    [showSymbol]="false"
  >
  </app-select>
</div>
<div class="dialog-footer">
  <app-button
    [dialogRef]="ref"
    buttonType="cancel"
    dataCy="prompt-modal-reject"
    (buttonClick)="ref.close(null)"
    [isUiLockable]="false"
    [text]="cancelButtonText"
  >
  </app-button>
  <app-button
    [dialogRef]="ref"
    dataCy="prompt-modal-confirm"
    (buttonClick)="okClicked()"
    [isUiLockable]="false"
    [disabled]="!isOkEnabled"
    [text]="okButtonText"
  >
  </app-button>
</div>
