import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { getIsRibbonDisplayed } from '../../+store/ui/ui.selectors';
import { GridResizeService } from '../../shared/services/grid-resize.service';
import { skip } from 'rxjs/operators';
import { HelpButtonComponent, PanelHelpMode } from '../../shared/components/help-button/help-buton.component';

@Component({
  selector: 'app-page-panel',
  templateUrl: './page-panel.component.html',
  styleUrls: ['./page-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PagePanelComponent implements OnInit, OnDestroy {
  @Input() public panelHelpMode?: PanelHelpMode;
  @Input() public header = '';
  @Input() public headerOnTheRight: string | null = null;
  @Input() public allowFullScreen = true;
  @Input() public noHeader = false;
  @Input() public class = '';

  @HostBinding('class.no-help')
  public get CssNoHelp(): boolean {
    return this.panelHelpMode == null;
  }

  @HostBinding('class.no-full-screen')
  public get CssNoFullScreen(): boolean {
    return !this.allowFullScreen;
  }

  private subscription = new Subscription();
  public isFullScreen = false;

  public get showHeader(): boolean {
    if (this.noHeader) {
      return false;
    }

    return this.header.length > 0 || this.panelHelpMode !== undefined;
  }

  constructor(
    private store: Store,
    private gridResizeService: GridResizeService,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2
  ) {
  }

  public ngOnInit(): void {
    if (this.allowFullScreen) {
      this.subscription.add(
        this.store
          .select(getIsRibbonDisplayed)
          .pipe(skip(1))
          .subscribe(() => {
            this.changeDetectorRef.markForCheck();
            this.gridResizeService.resize();
          })
      );
    }
  }

  public ngOnDestroy(): void {
    this.isFullScreen = false;
    this.updateBodyClass();
  }

  @HostBinding('class.full-screen')
  public get className(): boolean {
    return this.isFullScreen;
  }

  public toggleMode(): void {
    this.isFullScreen = !this.isFullScreen;
    this.gridResizeService.resize();
    this.updateBodyClass();
  }

  public switchToPanelMode(): void {
    this.isFullScreen = false;
    this.gridResizeService.resize();
    this.changeDetectorRef.markForCheck();
    this.updateBodyClass();
  }

  private updateBodyClass(): void {
    if (this.isFullScreen) {
      this.renderer.addClass(document.body, 'panel-full-screen');
    } else {
      this.renderer.removeClass(document.body, 'panel-full-screen');
    }
  }

  public get showHelpBtn(): boolean {
    return this.panelHelpMode != null;
  }

  protected readonly toolbar = toolbar;
}

@NgModule({
  imports: [CommonModule, HelpButtonComponent],
  declarations: [PagePanelComponent],
  exports: [PagePanelComponent]
})
export class PagePanelModule {
}
