import { DbDependentComponent } from '../../../common-modules/db-connection/db-dependent.component';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { GridComponent, ISelectedRowProps } from './grid.component';
import { Store } from '@ngrx/store';
import { IIndexedDataType } from '@dunefront/common/dto/common-dto.interfaces';
import { GridConfig } from './grid-config';
import { IGridColumnConfig } from './grid.interfaces';
import { IScreenSize } from '../../services';
import { GridResizeService } from '../../services/grid-resize.service';

@Component({
  template: '',
})
export abstract class GridContainerComponent<T extends IIndexedDataType>
  extends DbDependentComponent
  implements AfterViewInit, OnChanges, OnDestroy, OnInit
{
  @ViewChild(GridComponent)
  public grid!: GridComponent<T>;

  public height = 0;

  // sum of heights of surrounding elements in this container
  public margin = 2;

  @Output()
  public selectedRowsChanged = new EventEmitter<ISelectedRowProps[]>();

  protected constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected el: ElementRef,
    public gridConfig: GridConfig<T>,
    protected gridResizeService: GridResizeService,
  ) {
    super(store, cdRef);
    this.subscription.add(gridResizeService.getResizeSubject().subscribe(() => this.resize()));
  }

  public override async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.updateColumns(await this.getColumns());
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.updateColumns(await this.getColumns());
  }

  public resize(screenSize?: IScreenSize): void {
    setTimeout(() => {
      if (this.el != null) {
        // set real visible height of grid to (clientHeight or offsetHeight) - sum of surrounding elements

        let padding = 0;

        const elementParent = this.el.nativeElement.parentElement;
        // const isGridInPanel = elementParent.classList.contains('app-panel-content');
        // if (isGridInPanel) {
        const paddingTop = parseInt(window.getComputedStyle(elementParent, null).getPropertyValue('padding-top'));
        const paddingBottom = parseInt(window.getComputedStyle(elementParent, null).getPropertyValue('padding-bottom'));
        padding = paddingBottom + paddingTop;
        // }

        this.height = (this.el.nativeElement.clientHeight || this.el.nativeElement.offsetHeight) - this.margin - padding;

        this.cdRef.detectChanges();
        return;
      }
      this.resize();
    }, 10);
  }

  public updateColumns(columns: IGridColumnConfig<T>[]): void {
    this.gridConfig.columns = columns;
  }

  protected abstract getColumns(): IGridColumnConfig<T>[] | Promise<IGridColumnConfig<T>[]>;

  public ngAfterViewInit(): void {
    this.resize();
    this.cdRef.markForCheck();
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.gridConfig?.dispose();
  }
}
