import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CheckboxModule } from 'primeng/checkbox';
import { UnitsModule } from '../../../common-modules/units/units.module';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { flatten } from 'lodash';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '../button/button.module';
import { ListboxModule } from 'primeng/listbox';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { LetDirective } from '@ngrx/component';
import { NgIf } from '@angular/common';
import { SelectItem } from 'primeng/api';

export interface UpdateTreeSelection {
  selectionState: SelectionState;
  order: Array<number>;
}
export interface UpdateTreeGroupSelection extends UpdateTreeSelection {
  groupId: number;
}

export interface SelectionState {
  GroupIds: number[];
  ItemIds: number[];
}

export interface TreeListConfig {
  itemsOrder: number[];
}

export interface TreeSelectorListItem {
  label: string;
  value: number;
  items: SelectItem[];
}

export interface GroupedItemsIds {
  [key: number]: number[];
}

interface EntityWithID {
  Id: number;
  [key: string]: any; // Allows additional properties.
}

interface GroupedEntityWithId {
  [key: number]: EntityWithID[];
}

@Component({
  selector: 'app-filterable-tree',
  styleUrls: ['./filterable-tree.component.scss'],
  templateUrl: './filterable-tree.component.html',
  standalone: true,
  imports: [CheckboxModule, UnitsModule, FormsModule, ButtonModule, ListboxModule, CdkDropList, CdkDrag, LetDirective, NgIf],
})
export class FilterableTreeComponent implements OnInit {
  @Input({ required: true }) public items$!: Observable<TreeSelectorListItem[]>;
  @Input({ required: true }) public selection$!: Observable<SelectionState>;
  @Input({ required: true }) public config$!: Observable<TreeListConfig>;
  @Input({ required: true }) public filter$!: BehaviorSubject<string>;

  @Input() public groupedItems$: Observable<GroupedEntityWithId> = of([]);
  @Input() public canSelectGroups = true;
  @Input() public dataCy = '';

  @Output() public updateSelectedIds = new EventEmitter<UpdateTreeSelection>();
  @Output() public dropped = new EventEmitter();
  @Output() public groupSelection = new EventEmitter<UpdateTreeGroupSelection>();
  @Output() public reorderChanged = new EventEmitter<boolean>();

  public isReorderMode = false;

  public filteredItems$: Observable<TreeSelectorListItem[]> = of([]);

  public ngOnInit(): void {
    this.filteredItems$ = combineLatest([this.items$, this.filter$.pipe(distinctUntilChanged())]).pipe(
      map(([items, filter]) => {
        const itemsWithFilteredChildren = items.map((item) => {
          return {
            ...item,
            items: item.items.filter((ch) => (ch.label ?? '').toLowerCase().includes(filter.toLowerCase())),
          };
        });
        // Show only ranges that contains typed string, or ones that contains children with provided string
        return itemsWithFilteredChildren.filter((item) => {
          return item.label.toLowerCase().includes(filter.toLowerCase()) || item.items.length;
        });
      }),
    );
  }

  public isEverythingVisibleChecked(items: TreeSelectorListItem[], selectionState: SelectionState): boolean {
    const visibleGroupIds = items.map((r) => r.value);
    const visibleItemIds = flatten(items.map((r) => r.items.map((i) => i.value)));

    return visibleGroupIds.every((r) => selectionState.GroupIds.includes(r)) && visibleItemIds.every((c) => selectionState.ItemIds.includes(c));
  }

  public onToggleAll(items: TreeSelectorListItem[], selectionState: SelectionState, order: number[], selectAll: boolean): void {
    const visibleGroupIds = items.map((r) => r.value);
    const visibleItemIds = flatten(items.map((r) => r.items.map((i) => i.value)));

    if (selectAll) {
      // build a list of all ranges to be selected
      selectionState.GroupIds = visibleGroupIds;
      // create selection list basing on original list, to keep order
      selectionState.ItemIds = visibleItemIds;
    } else {
      selectionState.ItemIds = selectionState.ItemIds.filter((c) => !visibleItemIds.includes(c));
      selectionState.GroupIds = selectionState.GroupIds.filter((r) => !visibleGroupIds.includes(r));
    }

    this.updateSelectedIds.emit({ selectionState, order });
  }

  public async onDrop(event: CdkDragDrop<string[]>, items: TreeSelectorListItem[], selectionState: SelectionState): Promise<void> {
    const newSortOrder = items.map((r) => r.value);
    moveItemInArray(newSortOrder, event.previousIndex, event.currentIndex);

    this.dropped.emit(newSortOrder);
    this.updateSelectedIds.emit({ selectionState, order: newSortOrder });
  }

  public onItemSelection(itemIds: number[], allItems: TreeSelectorListItem[], selection: SelectionState, order: number[]): void {
    // when item (subitem) is clicked, we need to also select parent item ( range )
    const groupIds = allItems.filter((r) => r.items.some((item) => itemIds.includes(item.value))).map((r) => r.value);

    const groupIdsToSelect = Array.from(new Set([...(selection?.GroupIds ?? []), ...groupIds]));

    this.updateSelectedIds.emit({ selectionState: { GroupIds: groupIdsToSelect, ItemIds: itemIds }, order });
  }

  public onGroupSelection(groupId: number, selection: SelectionState, order: number[], groupedItems: GroupedEntityWithId): void {
    const isUnselect = selection.GroupIds.includes(groupId);

    const groupedItemsEntities = groupedItems[groupId] ?? [];
    const groupedItemsIds = groupedItemsEntities.map((item: any) => item.Id);

    if (isUnselect) {
      selection.GroupIds = selection.GroupIds.filter((r) => r !== groupId);
      selection.ItemIds = selection.ItemIds.filter((r) => !groupedItemsIds.includes(r));
    } else {
      selection.GroupIds = [groupId, ...selection.GroupIds];
      selection.ItemIds = Array.from(new Set([...selection.ItemIds, ...groupedItemsIds]));
    }

    this.updateSelectedIds.emit({ selectionState: selection, order });
  }

  public toggleReorder(): void {
    this.isReorderMode = !this.isReorderMode;
    this.reorderChanged.emit(this.isReorderMode);
  }
}
