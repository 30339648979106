<div class="dialog-header">
  <h4>Data Chart Configuration</h4>
</div>
<div class="dialog-content" *ngIf="resultColumnsTableState.rows.length">
  <app-chart-configuration-grid #gridComponent [chartSeries]="resultColumnsTableState"></app-chart-configuration-grid>
</div>
<div class="dialog-content" *ngIf="!resultColumnsTableState.rows.length">No data available.</div>
<div class="dialog-footer buttons-panel">
  <div>
    <app-button
      (click)="onMenuToggle($event, menu)"
      [isUiLockable]="false"
      [disabled]="!resultColumnsTableState.rows.length"
      text="Select Template"
    >
    </app-button>
    <p-menu #menu [popup]="true" [appendTo]="'body'" [model]="templatesList" [style]="{ width: '13rem' }"></p-menu>
    <app-button
      [isUiLockable]="false"
      (buttonClick)="onClearClicked()"
      [disabled]="!resultColumnsTableState.rows.length"
      text="Clear All"
    >
    </app-button>
  </div>
  <div>
    <app-button
      [isUiLockable]="false"
      (buttonClick)="onCancelClicked()"
      [disabled]="isInitialConfiguration"
      text="Cancel"
    >
    </app-button>
    <app-button
      dataCy="data-chart-configuration-generate-chart-btn"
      [isUiLockable]="false"
      (buttonClick)="onOkClicked()"
      [disabled]="!isSomethingSelected"
      text="Generate Chart"
    >
    </app-button>
  </div>
</div>
