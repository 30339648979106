<div class="ribbon-multi-column-panel">
  <div class="ribbon-col grid-form-container range-grid-form-container">

    <ng-container *ngIf="!rangeMenuProps.isEditing ">
      <label>Range</label>
      <app-select
        *ngIf="selectRangeData$ | async as selectRangeData"
        [items]="selectRangeData"
        class="ribbon-select range-selector"
        [isUiLockable]="false"
        [disableSorting]="true"
        [value]="currentRange?.Id"
        [disabled]="!rangeMenuProps.comboBoxEnabled || (isCalcEngineActive$ | async) === true"
        (primitiveValueChanged)="onRangeChanged($event.value)"
        dataCy="select-range"
        [showSymbol]="false"
      >
      </app-select>
      <app-button
        *ngIf="rangeMenuProps.addVisible"
        text="Add"
        textIcon="icon-plus-add"
        class="top-menu-button"
        [disabled]="!rangeMenuProps.addEnabled"
        (buttonClick)="onAddRange()"
        dataCy="add-range"
        [isUiLockable]="false"
      >
      </app-button>
    </ng-container>

    <ng-container *ngIf="rangeMenuProps.isEditing">
      <label>Name</label>
      <app-input
        class="ribbon-input"
        [maxWidth]="200"
        [showSymbol]="false"
        [align]="'left'"
        [value]="currentRangeName"
        [isTextInput]="true"
        [autoFocus]="true"
        [isUiLockable]="false"
        (primitiveValueChanged)="onRangeNameChanged($event.value)"
        dataCy="range-name"
      ></app-input>
    </ng-container>
    <app-button
      *ngIf="rangeMenuProps.saveVisible"
      text="Save"
      textIcon="icon-save"
      class="top-menu-button"
      [isDisabledOnRequest]="true"
      [disabled]="!rangeMenuProps.saveEnabled"
      (buttonClick)="onSaveRange()"
      [isUiLockable]="(shouldDeleteResults$ | async) === true"
      dataCy="save-range"
    ></app-button>
    <app-button
      *ngIf="!rangeMenuProps.saveVisible"
      [text]="rangeMenuProps.saveLabel"
      text="Edit"
      textIcon="icon-pen"
      class="top-menu-button"
      [disabled]="!rangeMenuProps.editEnabled"
      (buttonClick)="onEditRange()"
      dataCy="edit-range"
      [isUiLockable]="false"
    >
    </app-button>
    <app-button
      *ngIf="rangeMenuProps.saveVisible"
      text="Cancel"
      textIcon="icon-delete"
      class="top-menu-button"
      (buttonClick)="onCancel()"
      [isUiLockable]="false"
      dataCy="cancel-range"
    >
    </app-button>

    <label>Labels</label>
    <app-labels-selector
      [isDisabled]="!rangeMenuProps.isEditing"
      [rangeLabels]="currentRange?.Labels"
      (labelsUpdated)="onLabelsUpdated($event);"
    ></app-labels-selector>

    <app-button
      text="Delete"
      textIcon="icon-trashcan"
      class="top-menu-button"
      [disabled]="!rangeMenuProps.deleteEnabled"
      (buttonClick)="onDeleteRange()"
      dataCy="delete-range"
    ></app-button>
    <app-button
      text="Delete Data"
      textIcon="icon-list-delete"
      class="top-menu-button"
      [disabled]="!rangeMenuProps.deleteDataEnabled"
      (buttonClick)="onDeleteData()"
      dataCy="delete-range-data"
    ></app-button>
  </div>
</div>
