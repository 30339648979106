<div class="grid-form-container">
  <app-form-input
    appUnwrap
    [source]="pumping"
    label="Max Surface Pressure"
    key="MaxSurfacePressure"
    [decimalPlaces]="2"
    [unitType]="UnitType.Pressure"
    (valueChanged)="dataChange.emit($event)"
    dataCy="max-surface-pressure"
  >
  </app-form-input>
</div>