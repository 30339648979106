<div class="file-list-file" [class.recent]="isRecentMode">


<div class="file-item" >

  <div class="icon-wrapper">
    <img [src]="iconPath" alt="PackPro File">
  </div>
  <div class="file-item-content" >
    <span class="filename" data-cy="file-name" *ngIf="isFolder" [innerHTML]="(file.Name.length ? file.Name : '/') | HighlightPhrasePipe: searchPhrase "></span>
    <span class="filename" data-cy="file-name" *ngIf="!isFolder" [innerHTML]="file | fileDisplayName | HighlightPhrasePipe: searchPhrase"></span>
    <span class="file-size" *ngIf="!isRecentMode && !isFolder && file.FileSize"> - ( {{ file.FileSize | fileSize }} )</span>
    <div class="file-location" data-cy="file-location" *ngIf="isRecentMode && repository !== 'trash'">
      {{ file | fileLocation }}
    </div>
  </div>
</div>


<ng-container *ngIf="!isReadOnly(file)">
  <div class="trash-date" *ngIf="repository === 'trash'">{{ file.TrashTimestamp | date: 'medium' }}</div>

  <div class="file-item-date" *ngIf="file.LastOpenTimestamp">
    {{ file.LastOpenTimestamp | date: 'medium' }}
  </div>

  <div class="file-item-date" *ngIf="repository === 'backup' && file.LastChangedTime">
    {{ file.LastChangedTime | date: 'medium' }}
  </div>

  <div *ngIf="showRowContextMenu" class="file-button">
    <button class="context-menu-button" (click)="contextMenuClick.emit({file, event: $event})">&#8942;</button>
  </div>
</ng-container>

<ng-container *ngIf="isReadOnly(file)">
  <div class="file-item-date">
    {{ getReadOnlyFileDescription(file) }}
  </div>

  <div *ngIf="showRowContextMenu" class="file-button">
    <button class="context-menu-button disabled">&#8942;</button>
  </div>
</ng-container>
</div>
