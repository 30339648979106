<ng-container>
  <div class="user-panel">
    <div class="user-info">
      <ng-container *ngIf="backendConnectionState$ | async as backendConnectionState">
        <div class="info-container">
          <app-license-expiration-warning
            [isSaved]="(file$ | async) && backendConnectionState.backendConnectionActiveRequests.length === 0"
          ></app-license-expiration-warning>
          <div *ngIf="(file$ | async) && backendConnectionState.backendConnectionActiveRequests.length === 0"
               class="file-saved">
            <span class="icon-checkmark-circle-filled"></span> Saved
          </div>
        </div>

        <div *ngIf="!isElectron" class="icon-container network">
          <span class="icon icon-signal" data-cy="icon-signal">
            <span
              *ngIf="
                backendConnectionState.backendConnectionStatus === ConnectionStatus.connected &&
                backendConnectionState.dbConnectionStatus === ConnectionStatus.connected
              "
              class="connection-status-connected"
            >
              <span
                *ngIf="backendConnectionState.backendConnectionActiveRequests.length > 0"
                data-cy="connection-status-requests-count"
                class="connection-status-request"
              ></span>
            </span>
            <span
              *ngIf="
                backendConnectionState.backendConnectionStatus === ConnectionStatus.connected &&
                backendConnectionState.dbConnectionStatus === ConnectionStatus.disconnected
              "
              data-cy="connected-db-disconnected"
              class="connected-db-disconnected"
            ></span>
            <span
              *ngIf="
                backendConnectionState.backendConnectionStatus === ConnectionStatus.disconnected ||
                backendConnectionState.backendConnectionStatus === ConnectionStatus.failed
              "
              class="connection-status-disconnected"
            >
            </span>
          </span>
        </div>
      </ng-container>



      <div class="icon-container" (click)="openLicensing()" data-cy="btn-licensing-open">
        <span class="icon icon-licensing"></span>
      </div>

      <div class="icon-container" (click)="openAbout()" data-cy="btn-about-open">
        <span class="icon icon-information"></span>
      </div>

      <div class="icon-container" (click)="openNotifications()" data-cy="notifications-button">
        <span class="icon icon-notification" ><span data-cy="unread-notifications-indicator" *ngIf="(unreadNotifications$ | async)?.length" class="path1"></span></span>
      </div>
      <div class="icon-container" (click)="openCalculationQueue()" data-cy="calculations-button">
          <span class="icon icon-clock">
            <span *ngIf="userHasAnyWaitingOrRunningJobs$ | async" class="calculations-present">
            </span>
          </span>
      </div>
      <div class=" icon-container" *ngIf="!isElectron && (user$ | async)" (click)="userProfileMenu.toggle($event)">
        <span data-cy="user-profile-menu-link" class="icon icon-user">
      </span>
      </div>
    </div>
  </div>

  <p-menu #userProfileMenu [popup]="true" [model]="contextMenuItems" [styleClass]="'user-profile-menu'"
          appendTo="body"></p-menu>
</ng-container>
