import { createReducer, on } from '@ngrx/store';
import * as dbConnectionActions from '../backend-connection/backend-connection.actions';
import * as actions from './scenario.actions';
import { updateScenarioOrder, updateScenariosSelection } from './scenario.actions';
import { ScenarioReducerHelper } from './scenario.reducer.helper';
import { deleteRowsSuccess, insertRowsSuccess, loadScenarioDataAction, loadScenarioDataSuccessAction, updateRowSuccess } from '../app.actions';
import { setAppModuleAction } from '../ui/ui.actions';
import { Scenario } from '@dunefront/common/modules/scenario/scenario';

export const ScenarioModuleFeatureName = 'scenario';
export const InitialScenarioId = -1;

export interface ScenarioModuleState {
  scenarios: Scenario[];
  currentScenarioId: number;
  isLoaded: boolean;
  isScenarioLoaded: boolean;
  selectedIds: number[];
}

const initialState: ScenarioModuleState = {
  scenarios: [],
  currentScenarioId: InitialScenarioId,
  isLoaded: false,
  isScenarioLoaded: false,
  selectedIds: [],
};

export const scenarioModuleReducer = createReducer<ScenarioModuleState>(
  initialState,
  on(dbConnectionActions.backendDisconnectedAction, (state): ScenarioModuleState => ({ ...state, isLoaded: false })),
  on(
    dbConnectionActions.dbConnectedSuccessAction,
    (state, action): ScenarioModuleState => ScenarioReducerHelper.scenarioModuleLoaded(state, action),
  ),
  on(
    loadScenarioDataAction,
    setAppModuleAction,
    (state): ScenarioModuleState => ({
      ...state,
    }),
  ),
  on(
    loadScenarioDataSuccessAction,
    (state, action): ScenarioModuleState => ScenarioReducerHelper.onScenarioLoaded(state, action.loadScenarioResponse.scenarioId),
  ),
  on(insertRowsSuccess, (state, action): ScenarioModuleState => ScenarioReducerHelper.insertScenarioSuccess(state, action)),
  on(updateRowSuccess, (state, action): ScenarioModuleState => ScenarioReducerHelper.updateScenarioSuccess(state, action)),
  on(deleteRowsSuccess, (state, action): ScenarioModuleState => ScenarioReducerHelper.deleteScenarioSuccess(state, action)),
  on(updateScenarioOrder, (state, action): ScenarioModuleState => ScenarioReducerHelper.reorderScenarios(state, action)),
  on(updateScenariosSelection, (state, action): ScenarioModuleState => ({ ...state, selectedIds: action.selectedIds })),
  on(
    actions.changeCurrentScenarioIdAction,
    (state, action): ScenarioModuleState => ({
      ...state,
      currentScenarioId: action.scenarioId ?? -1,
      isScenarioLoaded: action.scenarioId === -1,
    }),
  ),
);
