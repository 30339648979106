import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, NgModule, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ButtonModule } from '../button/button.module';
import { FileUploadModule } from 'primeng/fileupload';
import { ModalService } from '../../../common-modules/modals/modal.service';
import { firstValueFrom, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { IFile, Repository } from '@dunefront/common/dto/file.dto';
import { BackendConnectionService } from '../../backend-connection/backend-connection.service';
import {
  FileManagerModuleName,
  FileOverwriteAction,
  IUploadFile,
  UploadFileCancelAction,
  UploadFileChunkAction,
  UploadFileChunkResponseStatus,
} from '@dunefront/common/modules/file-manager/file-manager.actions';
import { dbDisconnectAction } from '../../../+store/backend-connection/backend-connection.actions';
import { getCurrentFolderState } from '../../../+store/file-manager/file-manager.selectors';
import { NgxFileDropEntry } from '../../../common-modules/ngx-file-drop/ngx-file-drop-entry';
import { FileSystemFileEntry } from '../../../common-modules/ngx-file-drop/dom.types';
import { NgxFileDropModule } from '../../../common-modules/ngx-file-drop/ngx-file-drop.module';
import { showFileOpenDialogSuccess } from '../../../+store/electron-main/electron-main.actions';
import { ElectronService } from '../../services/electron-service/electron.service';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { AppTargetConfig } from '../../services/app-target-config';
import { getFilesJobsTypes } from '../../../+store/calculation-engine/files-jobs.selectors';
import { FileJobTypesHelper } from '../../../+store/calculation-engine/file-job-types-helper';

const MIN_CHUNK_SIZE = 100 * 1024; // 100KB
const MAX_CHUNK_SIZE = 10 * 1024 * 1024; // 10MB
const NUMBER_OF_CHUNKS = 100;

export enum UploadStatus {
  SUCCESS = 0,
  FAILURE = 1,
  CANCELLED = 2,
  SAME_FILE_UPLOAD_EXISTS = 3,
}

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadComponent {
  @Input() public accept = [''];
  @Input() public fileListInFolder!: IFile[];
  @Output() public uploadFinished = new EventEmitter<UploadStatus>();
  @ViewChild('openFileSelector') public openFileSelector!: ElementRef;
  @Input() public folder?: IFile;
  @Input() public repository!: Repository;

  private allPpfFiles$ = notEmpty(this.store.select(getCurrentFolderState));
  private isUploadOnGoing = false;
  public isDropZoneVisible = false;
  private isUploadAlertDisplayed = false;

  @HostListener('document:dragenter', ['$event'])
  public onDragEnter(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    // prevent drop when another upload is in progress
    if (this.isUploadOnGoing) {
      if (!this.isUploadAlertDisplayed) {
        this.isUploadAlertDisplayed = true;
        this.modalService.showAlert('Please wait, another file is being uploaded.', 'Warning').then(() => (this.isUploadAlertDisplayed = false));
      }
      return;
    }

    // don't show drop overlay for content other than files
    if (this.dragEventHasFiles(event) === false) {
      return;
    }

    this.isDropZoneVisible = true;
  }

  @HostListener('body:dragover', ['$event'])
  public onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('body:drop', ['$event'])
  public onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  constructor(
    private modalService: ModalService,
    private store: Store,
    private backendConnectionService: BackendConnectionService,
    private electronService: ElectronService,
    public appConfig: AppTargetConfig,
  ) {}

  public async uploadFiles(ngxFileDropEntries: NgxFileDropEntry[]): Promise<void> {
    this.isDropZoneVisible = false;
    if (ngxFileDropEntries.length > 1) {
      await this.modalService.showAlert('You can only upload one file at a time.', 'Warning');
      return;
    }

    const fileSystemEntry = ngxFileDropEntries[0].fileEntry;

    const extension = fileSystemEntry.name.split('.').reverse()[0];
    if (!this.accept.includes(extension) || !fileSystemEntry.isFile) {
      await this.modalService.showAlert(`You can only upload ${this.accept.join(', ')} files.`, 'Warning');
      return;
    }

    if (this.electronService.isElectronApp) {
      this.store.dispatch(showFileOpenDialogSuccess({ filePath: fileSystemEntry.path }));
      return;
    }

    // check if file with the same name already exists the current folder
    const currentFolder = await firstValueFrom(this.allPpfFiles$);
    const existingFile = currentFolder.Children?.find(
      (ppfFile: IFile) => ppfFile.Name.toLowerCase() === fileSystemEntry.name.toLowerCase() && ppfFile.FileType === 'ppf-file',
    );
    if (existingFile !== undefined) {
      // check if existing file can be overwritten (no worker jobs are currently executed)
      const allFilesJobsTypes = await firstValueFrom(this.store.select(getFilesJobsTypes));
      const canOverwriteFile = FileJobTypesHelper.canUpdateFile(existingFile, allFilesJobsTypes);
      if (!canOverwriteFile) {
        await this.modalService.showAlert(
          `Project named ${fileSystemEntry.name} already exists. The file is currently in use and cannot be overwritten.`,
          'Warning',
        );

        // early return!
        return;
      }

      const overwrite = await this.modalService.showConfirm(
        `Project with the name ${fileSystemEntry.name} already exists, do you want to overwrite it?`,
        'Warning',
      );

      if (!overwrite) {
        // early return!
        return;
      }

      // file will be overwritten, enforce closing all db connections
      await this.backendConnectionService.emitAsync(FileManagerModuleName, false, new FileOverwriteAction(existingFile));
      this.store.dispatch(dbDisconnectAction());
    }

    // upload file
    const fileEntry = fileSystemEntry as FileSystemFileEntry;
    await fileEntry.file(async (file: File) => {
      this.uploadFile(file).then();
    });
  }

  private async uploadFile(file: File): Promise<void> {
    this.isUploadOnGoing = true;
    const progressSubject: Subject<number> = new Subject();
    const progressDialogRef = this.modalService.showProgressIndicator('Uploading file', progressSubject, true);

    firstValueFrom(progressDialogRef.onClose).then((hasFinished) => {
      if (!hasFinished) {
        // indicates user cancellation
        this.isUploadOnGoing = false;
      }
    });

    // Calculate the optimal chunk size for file upload. The chunk size is determined by
    // dividing the file size by the NUMBER_OF_CHUNKS and keeping it within the bounds
    // of MIN_CHUNK_SIZE and MAX_CHUNK_SIZE.
    const chunkSize = Math.max(Math.min(Math.ceil(file.size / NUMBER_OF_CHUNKS), MAX_CHUNK_SIZE), MIN_CHUNK_SIZE);
    const chunksTotal = Math.ceil(file.size / chunkSize);

    const uploadFile: IUploadFile = {
      Name: file.name,
      Folder: this.folder ? [...this.folder.Folder, this.folder.Name] : [],
      Repository: this.repository,
    };

    for (let chunkIndex = 0; chunkIndex < chunksTotal; chunkIndex++) {
      const responseStatus = await this.uploadChunk(chunkIndex, file, chunkSize, chunksTotal, uploadFile);

      const hasUserCancelled = !this.isUploadOnGoing;

      if (responseStatus == UploadFileChunkResponseStatus.Ok && !hasUserCancelled) {
        // all good, keep sending chunks
        progressSubject.next(Math.round(((chunkIndex + 1) * 100) / chunksTotal));
        continue;
      }

      // handle cancellation or error

      // don't need to send UploadFileCancelAction when ErrorFileUploadExists
      const sendUploadFileCancelAction = responseStatus !== UploadFileChunkResponseStatus.ErrorFileUploadExists;
      if (sendUploadFileCancelAction) {
        try {
          await this.backendConnectionService.emitAsync(FileManagerModuleName, false, new UploadFileCancelAction(uploadFile));
        } catch (e) {
          console.warn('UploadFileCancelAction error!', e);
        }
      }

      // close progress dialog
      progressDialogRef.close();

      // notify listeners with correct UploadStatus
      let uploadStatus = UploadStatus.FAILURE;
      if (hasUserCancelled) {
        uploadStatus = UploadStatus.CANCELLED;
      }
      if (responseStatus === UploadFileChunkResponseStatus.ErrorFileUploadExists) {
        uploadStatus = UploadStatus.SAME_FILE_UPLOAD_EXISTS;
      }
      this.uploadFinished.emit(uploadStatus);

      // set flag
      this.isUploadOnGoing = false;

      // break loop (don't send subsequent chunks)
      break;
    }

    if (this.isUploadOnGoing) {
      this.isUploadOnGoing = false;
      this.uploadFinished.emit(UploadStatus.SUCCESS);
    }
  }

  private async uploadChunk(
    chunkIndex: number,
    file: File,
    chunkSize: number,
    chunksTotal: number,
    uploadFile: IUploadFile,
  ): Promise<UploadFileChunkResponseStatus> {
    const start = chunkIndex * chunkSize;
    const end = Math.min(start + chunkSize, file.size);
    const chunkDataBlob = file.slice(start, end);
    const chunkDataBuffer = await chunkDataBlob.arrayBuffer();

    let result: UploadFileChunkResponseStatus;

    try {
      const { payload } = await this.backendConnectionService.emitAsync<UploadFileChunkResponseStatus>(
        FileManagerModuleName,
        false,
        new UploadFileChunkAction(uploadFile, chunkIndex, chunksTotal, chunkDataBuffer),
      );
      result = payload;
    } catch (e) {
      result = UploadFileChunkResponseStatus.ErrorOther;
    }

    return result;
  }

  public fileSelection(): void {
    this.openFileSelector.nativeElement.click();
  }

  public getAllowedFileSuffixes(): string {
    return this.accept.map((type) => '.' + type).join(',');
  }

  private dragEventHasFiles(event: DragEvent): boolean {
    const dataTransfer = event.dataTransfer;
    if (dataTransfer == null) {
      return false;
    }

    if (dataTransfer.files.length > 0) {
      return true;
    }

    return Array.from(dataTransfer.items).some((f) => f.kind.toLowerCase() === 'file');
  }
}

@NgModule({
  declarations: [UploadComponent],
  exports: [UploadComponent],
  imports: [CommonModule, FormsModule, NgxFileDropModule, ButtonModule, FileUploadModule],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class UploadModule {}
