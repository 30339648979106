<ng-container *ngIf="psd">
  <div class="input-type-radio-container">
    <div>Input Type</div>
    <app-radio-group
      [source]="psd"
      [key]="'PSDInputType'"
      [items]="gridRadioItems"
      id="psd-data-grid-radio"
      [dataCy]="'psd-data-grid-input-type-radio'"
      (valueChanged)="onInputTypeChanged($event)"
    >
    </app-radio-group>
  </div>
  <app-grid
    #grid
    [dataSource]="psdReadings"
    [gridConfig]="gridConfig"
    [columns]="gridConfig.columns"
    [height]="height"

    [showCrudControls]="false"
    [minRowCount]="3"
    [isFirstRowInsertingAllowed]="true"
    elementId="psd-data-grid"
    [isFirstRowDeleteAllowed]="true"
    [isPastingEnabled]="true"
    dataCy="psd-data-grid"
  ></app-grid>
  <div class="buttons-panel">
    <div></div>
    <div>
      <app-button (buttonClick)="onImport()"
                  dataCy="psd-data-import" text="Import">
      </app-button>
      <app-button (buttonClick)="onInsert()"
                  dataCy="psd-readings-add-row" text="Add Row">
      </app-button>
      <app-button (buttonClick)="onDelete()"
                  dataCy="psd-readings-delete-row" text="Delete Row(s)">
      </app-button>
    </div>
  </div>
</ng-container>
