<div [class.selected-error]="isCurrentValueInvalid"  [class]="class" [class.error]="isInErrorState" [class.flex]="!isGridInput"
>
  <p-dropdown
    [class.error]="isInErrorState"
    [class.some-items-invalid-error]="someItemsInvalidError"
    [class.warning]="warning"
    [class.ribbon]="isRibbonMenu"
    [class.disabled]="isDisabled"
    [class.is-grid-input]="isGridInput"
    [showClear]="clearable"
    #dropdown
    *ngIf="items"
    [style]="{ width: componentWidth }"
    class="custom"
    [options]="options"
    [optionLabel]="'text'"
    [optionValue]="'value'"
    [(ngModel)]="valueIfAvailable"
    (onChange)="onSelectChange()"
    [disabled]="isDisabled"
    [attr.data-cy]="dataCy"
    [placeholder]="selectPlaceholder"
    [group]="isGrouped"
    tooltipPosition="top"
    positionStyle="absolute"
    [tooltipStyleClass]="tooltipStyle"
    [tooltipDisabled]="!tooltipMessage"
    [pTooltip]="tooltipMessage + ''"
    [appendTo]="'body'"
    [filter]="colorPicker"
    (onHide)="onHide()"
  >
    <ng-template let-group pTemplate="group">
      <div class="p-d-flex p-ai-center">
        <span [innerHTML]="group.label"></span>
      </div>
    </ng-template>

    <ng-template let-item pTemplate="selectedItem">
      <div *ngIf="colorPicker">
        <div class="color-picker-container">
          <div [style.background]="item.text" class="color-picker-option"></div>
          <div [innerHTML]="item.text"></div>
        </div>
      </div>
      <div *ngIf="!colorPicker" [innerHTML]="getItemText(item.text)"></div>
    </ng-template>

    <ng-template let-item pTemplate="item">
      <span *ngIf="colorPicker">
        <div class="color-picker-container">
          <div [style.background]="item.text" class="color-picker-option"></div>
          <div [attr.data-cy]="dataCy + '_' + encodeCyData(item.value)" [innerHTML]="item.text"></div>
        </div>
      </span>
      <span *ngIf="!colorPicker">
        <span *ngIf="item?.isInvalid" class="error-row-icon"></span>
        <span [class.error]="item?.isInvalid" [attr.data-cy]="dataCy + '_' + encodeCyData(item.value)" [innerHTML]="getItemText(item.text)">
        </span>
        <span class="second-line" *ngIf="item.additionalText">{{ item.additionalText }}</span>
      </span>
    </ng-template>

    <ng-template ng-label-tmp let-item="item">
      <span *ngIf="item.isInvalid" class="error-row-icon"></span>
      <span *ngIf="colorPicker">
        <div class="color-picker-container">
          <div class="color-picker-option" [style.background]="item.text"></div>
          <div [innerHTML]="item.text"></div>
        </div>
      </span>
      <span *ngIf="!colorPicker" [class.error]="item.isInvalid" [innerHTML]="getItemText(item.text)"></span>
    </ng-template>
  </p-dropdown>
  <div class="form-component-symbol" *ngIf="showSymbol">
    <span>{{ unitLabel }}</span>
  </div>
</div>
<input type="text" #hiddenInput appInputGridNavigation [attr.data-cy]="dataCy + '-hidden-input'" (changeFocusCell)="changeFocusCell.emit($event)" class="hidden-input" />
