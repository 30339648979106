import { Component, Input, OnInit } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import {
  getReportInfoFields,
  getReportInfoRangesConfig,
  getReportingInfoRangesSelectionComponentItems,
} from '../../../../+store/report-info/report-info.selectors';
import {
  reportInfoSelectAllClearAllAction,
  updateReportInfoFieldsAction,
  updateReportInfoRangesConfigAction,
} from '../../../../+store/report-info/report-info.actions';
import { isSimulateBased, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UpdateTreeSelection } from '../../../../shared/components/filterable-tree/filterable-tree.component';
import { getGroupedReportingTabs } from '../../../../+store/reporting/reporting.selectors';

const getRangesAndReportingTabsSelection = createSelector(getReportInfoFields, (infoFields) => ({
  GroupIds: infoFields.RangeIds,
  ItemIds: infoFields.ReportingTabIds,
}));

@Component({
  selector: 'app-reporting-range-selection',
  templateUrl: './reporting-range-selection.component.html',
})
export class ReportingRangeSelectionComponent implements OnInit {
  @Input({ required: true }) public moduleType!: ModuleType;

  public filter$ = new BehaviorSubject<string>('');
  public items$ = this.store.select(getReportingInfoRangesSelectionComponentItems);
  public config$ = this.store.select(getReportInfoRangesConfig);
  public selection$ = this.store.select(getRangesAndReportingTabsSelection);
  public groupedReportingTabs$ = this.store.select(getGroupedReportingTabs);
  public isSimulateBased = false;

  constructor(
    private store: Store,
    private actions$: Actions,
  ) {
    this.actions$.pipe(ofType(reportInfoSelectAllClearAllAction), takeUntilDestroyed()).subscribe(() => this.filter$.next(''));
  }

  public ngOnInit(): void {
    this.isSimulateBased = isSimulateBased(this.moduleType);
  }

  public updateSelectedIds(selection: UpdateTreeSelection): void {
    const { GroupIds, ItemIds } = selection.selectionState;

    const sortedRangesIds = selection.order.length ? selection.order.filter((r) => GroupIds.includes(r)) : GroupIds.sort();

    this.store.dispatch(
      updateReportInfoFieldsAction({
        changes: [
          { value: sortedRangesIds, changedKey: 'RangeIds' },
          { value: ItemIds, changedKey: 'ReportingTabIds' },
        ],
      }),
    );
  }

  public onDropped(newSortOrder: number[]): void {
    this.store.dispatch(updateReportInfoRangesConfigAction({ value: newSortOrder, changedKey: 'itemsOrder' }));
  }
}
