<ng-container *ngIf="pumpingState">
  <div class="grid-form-container" >
    <label>Define by:</label>
    <app-radio-group
      [source]="pumpingState.pumping"
      [key]="'IsWellFluidDepthByVolume'"
      (valueChanged)="switchDetailedFluidDefinitionType($event)"
      [disabled]="pumpingState.pumping.IsWellFluidSelectionSimple"
      [items]="items"
      dataCy="detailed-fluid-definition-radio"
      id="detailed-fluid-definition-radio"
      [value]="isWellFluidDepthByVolume"
    >
    </app-radio-group>
  </div>
  <div class="grid-panel">
    <app-detailed-fluid-definition-grid
      #gridComponent
      [wellFluids]="pumpingState.wellFluids"
      [isWellFluidDepthByVolume]="pumpingState.pumping.IsWellFluidDepthByVolume"
      [disabled]="pumpingState.pumping.IsWellFluidSelectionSimple"
      (selectedRowsChanged)="onSelectedRowsChanged($event)"
    >
    </app-detailed-fluid-definition-grid>
  </div>
  <div class="buttons-panel">
    <div>
      <app-well-fluid-volume
        [pumpingState]="pumpingState"
        [focusedWellFluidRow]="selectedRow"
        [referenceVariablesData]="referenceVariablesData"
      >
      </app-well-fluid-volume>
    </div>
    <div>
      <app-button
        dataCy="detailed-fluid-definition-import"
        (buttonClick)="onImport(true)"
        [disabled]="pumpingState.pumping.IsWellFluidSelectionSimple"
        [isDeleteResultsEnabled]="false"
        text="Import"
      >

      </app-button>
      <app-button

        dataCy="detailed-fluid-definition-add"
        (buttonClick)="onAdd()"
        [disabled]="pumpingState.pumping.IsWellFluidSelectionSimple"
        [isDeleteResultsEnabled]="false"
        text="Add"
      >

      </app-button>
      <app-button
        dataCy="detailed-fluid-definition-insert"
        (buttonClick)="onInsert()"
        [disabled]="pumpingState.pumping.IsWellFluidSelectionSimple"
        [isDeleteResultsEnabled]="false"
        text="Insert"
      >
      </app-button>
      <app-button
        dataCy="detailed-fluid-definition-delete"
        (buttonClick)="onDelete()"
        [disabled]="pumpingState.pumping.IsWellFluidSelectionSimple"
        [isDeleteResultsEnabled]="false"
        text="Delete"
      >
      </app-button>
    </div>
  </div>
</ng-container>
