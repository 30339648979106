<h3>Welcome to the Data Import Wizard</h3>
<div>
  <app-page-panel header="Select Data File">
    <ngx-file-drop dropZoneLabel="Drop file here" [multiple]="false" (onFileDrop)="dropped($event)">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <div class="selected-file-panel" *ngIf="state.fileName">
          <span>Selected File: {{ state.fileName ? state.fileName : 'None' }}</span>
        </div>
        <div class="file-browser-container">
          <div>Drop a file here or</div>
          <app-button (click)="openFileSelector()" text="Browse Files"></app-button>
        </div>
      </ng-template>
    </ngx-file-drop>
  </app-page-panel>
</div>
<app-page-panel header="Template" class="small-padding">
  <app-template-manager [resetTemplateOnInit]="true"></app-template-manager>
</app-page-panel>
<app-page-panel header="Data Type" class="small-padding">
  <div class="data-type-panel grid-form-container no-padding">
    <app-radio-group
      [items]="dataTypeRadioItems"
      [source]="state"
      [key]="'importDataType'"
      layout="vertical"
      id="file-loader-radio"
      (valueChanged)="onImportDataTypeChange($event)"
      [disabled]="!state.fileName"
    >
    </app-radio-group>
    <div class="import-md-container">
      <app-form-input
        label="Measured Depth"
        [source]="state"
        key="measuredDepth"
        [decimalPlaces]="2"
        [min]="0"
        [unitType]="UnitType.Long_Length"
        [disabled]="!isMeasuredDepthEnabled"
        (valueChanged)="onMeasuredDepthChanged($event)"
      >
      </app-form-input>
    </div>
  </div>
</app-page-panel>
