<app-button
  class="big-button reporting-tools-compare-scenarios-btn"
  [isToggled]="chartMenuProps.isCompareScenariosActive"
  text="Compare Scenarios"
  [isUiLockable]="false"
  textIcon="icon-documents-file-copy"
  (buttonClick)="compareScenarioMainClick(!chartMenuProps.isCompareScenariosActive)"
  [disabled]="!chartMenuProps.isCompareScenariosEnabled"
  [advancedButtonContextMenuItems]="chartMenuProps.isCompareScenariosActive ? compareScenarioAdvancedButtonMenuItemsWhenEnabled : compareScenarioAdvancedButtonMenuItemsWhenDisabled"
  dataCy="reporting-tools-compare-scenarios-btn"
  [isAdvancedButton]="true"
  [advancedButtonWidth]="'210px'"
></app-button>
