import { DocumentGeneratorHelper } from '../document-generator.helper';
import { HeadingLevel, Paragraph, Table } from 'docx';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { getValidatedPumping, getValidatedPumpingModuleState } from '../../../../../../+store/pumping/selectors/pumping-validation.selectors';
import { Pumping } from '@dunefront/common/modules/pumping/model/pumping/pumping';
import { DocumentTableGeneratorHelper } from '../document-table-generator.helper';
import { getFluidsSelectData, IFluidSelectorData } from '../../../../../../+store/fluid/fluid.selectors';
import { ValidatedPumpingModuleState } from '@dunefront/common/modules/pumping/pumping-module.state';
import { WellFluid } from '@dunefront/common/modules/pumping/model/well-fluid/well-fluid';
import { getGravelsSelectData, IGravelSelectorData } from '../../../../../../+store/gravel/gravel.selectors';
import { getReferenceVariables } from '../../../../../../+store/reference-variables/reference-variables.selectors';
import { ReferenceVariableCalculatorResultsDto } from '@dunefront/common/modules/reference-variables/dto/reference-variable-calculator-results.dto';
import { getCurrentAppModuleType } from '../../../../../../+store/ui/ui.selectors';
import { getCalculatedSimulatePumpingSchedule } from '../../../../../../+store/pumping/selectors/pumping-schedule.selectors';
import { PumpingSchedule } from '@dunefront/common/modules/pumping/model/pumping-schedule/pumping-schedule';

import { isSimulateBased } from '@dunefront/common/modules/scenario/scenario.dto';
import { CommonReportDataHelpers } from '../../common/common-report-data-helpers';
import { isSimulateDisp } from '../../../../../../+store/menu-selectors/menu-selectors.helpers';

export class PumpingReportGeneratorHelper {
  public static async createPumpingDataSection(
    store: Store,
    currentUnitSystem: IUnitSystemDto,
    rangeName: string,
  ): Promise<Array<Paragraph | Table>> {
    const pumpingInitialState: Pumping = await firstValueFrom(store.select(getValidatedPumping));
    const pumpingState: ValidatedPumpingModuleState = await firstValueFrom(store.select(getValidatedPumpingModuleState));
    const fluidsSelectData: IFluidSelectorData = await firstValueFrom(store.select(getFluidsSelectData));
    const gravelsSelectData: IGravelSelectorData = await firstValueFrom(store.select(getGravelsSelectData));
    const refVariables: ReferenceVariableCalculatorResultsDto | undefined = await firstValueFrom(store.select(getReferenceVariables));
    const currentModuleType = await firstValueFrom(store.select(getCurrentAppModuleType));
    const isSimulate = isSimulateBased(currentModuleType);
    const isFluidPro = isSimulateDisp(currentModuleType);
    const pumpingSchedule: PumpingSchedule[] = (await firstValueFrom(store.select(getCalculatedSimulatePumpingSchedule))).rows
      .filter((row) => row.rowType !== 'insert-row')
      .map((row) => row.rowData);

    const rows: Array<Paragraph | Table> = [
      DocumentGeneratorHelper.createParagraphHeader(
        DocumentGeneratorHelper.addRangeNameToTitle(`Pumping`, rangeName),
        HeadingLevel.HEADING_2,
        1,
      ),
      DocumentGeneratorHelper.createParagraphHeader(`Initial Data`, HeadingLevel.HEADING_3, 2),
      ...this.createPumpingInitialData(pumpingInitialState, currentUnitSystem),
      DocumentGeneratorHelper.createParagraphHeader(`Well Fluids`, HeadingLevel.HEADING_3, 2),
      ...this.createPumpingWellFluids(pumpingInitialState, pumpingState, fluidsSelectData, gravelsSelectData, currentUnitSystem, isFluidPro),
    ];

    if (isSimulate && refVariables) {
      rows.push(
        ...[
          DocumentGeneratorHelper.createParagraphHeader(`Schedule Reference Parameters`, HeadingLevel.HEADING_3, 2),
          this.createPumpingScheduleReferenceParameters(refVariables, currentUnitSystem, isFluidPro),
        ],
      );
    }

    if (isSimulate) {
      rows.push(
        ...[
          DocumentGeneratorHelper.createParagraphHeader(`Schedule`, HeadingLevel.HEADING_3, 2),
          this.createPumpingSchedule(pumpingSchedule, fluidsSelectData, gravelsSelectData, currentUnitSystem, pumpingState.pumping, isFluidPro),
        ],
      );
    }

    return rows;
  }

  private static createPumpingInitialData(pumpingInitialState: Pumping, currentUnitSystem: IUnitSystemDto): Array<Paragraph | Table> {
    const pumpingInitialDataTableData = CommonReportDataHelpers.createPumpingInitialDataTableData(pumpingInitialState, currentUnitSystem);
    const result: Array<Paragraph | Table> = [];

    for (const tableData of pumpingInitialDataTableData) {
      result.push(DocumentTableGeneratorHelper.createGenericKeyValueTableFromTableData(tableData, currentUnitSystem));
    }

    return result;
  }

  private static createPumpingWellFluids(
    pumpingInitialState: Pumping,
    pumpingState: ValidatedPumpingModuleState,
    fluidsSelectData: IFluidSelectorData,
    gravelsSelectData: IGravelSelectorData,
    currentUnitSystem: IUnitSystemDto,
    isFluidPro: boolean,
  ): Array<Paragraph | Table> {
    if (pumpingInitialState.IsWellFluidSelectionSimple) {
      const tableData = CommonReportDataHelpers.createSimplePumpingWellFluidsTableData(pumpingInitialState, fluidsSelectData, currentUnitSystem);
      return [DocumentTableGeneratorHelper.createGenericKeyValueTableFromTableData(tableData, currentUnitSystem)];
    }

    const sections = CommonReportDataHelpers.createDetailedPumpingWellFluidsTableData(
      pumpingInitialState,
      pumpingState,
      fluidsSelectData,
      gravelsSelectData,
      { isFluidPro },
    );

    const tables = sections.map(({ title, tableData }) => [
      DocumentGeneratorHelper.createParagraphHeader(title, HeadingLevel.HEADING_2, 2),
      DocumentTableGeneratorHelper.createTable(tableData, null, currentUnitSystem),
    ]);

    return tables.flat();
  }

  private static createPumpingScheduleReferenceParameters(
    refVariables: ReferenceVariableCalculatorResultsDto,
    currentUnitSystem: IUnitSystemDto,
    isFluidPro: boolean,
  ): Table {
    const tableData = CommonReportDataHelpers.createPumpingScheduleReferenceParametersTableData(refVariables, currentUnitSystem, { isFluidPro });

    return DocumentTableGeneratorHelper.createGenericKeyValueTableFromTableData(tableData, currentUnitSystem);
  }

  private static createPumpingSchedule(
    pumpingSchedule: PumpingSchedule[],
    fluidsSelectData: IFluidSelectorData,
    gravelsSelectData: IGravelSelectorData,
    currentUnitSystem: IUnitSystemDto,
    pumping: Pumping,
    isFluidPro: boolean,
  ): Table {
    const tableData = CommonReportDataHelpers.createPumpingScheduleTableData(pumpingSchedule, fluidsSelectData, gravelsSelectData, pumping, {
      isFluidPro,
    });

    return DocumentTableGeneratorHelper.createTable(tableData, null, currentUnitSystem, undefined, {
      0: 16,
      1: 10,
      2: 10,
      3: 16,
      4: 16,
      5: 10,
      6: 10,
    });
  }
}

export interface ReportPumpingSimpleFluid {
  WorkstringFluidId: string;
  UpperAnnulusFluidId: string;
  WashpipeFluidId: string;
  LowerAnnulusFluidId: string;
}

export interface ReportWellFluid extends Omit<WellFluid, 'FluidId' | 'GravelId'> {
  FluidId: string;
  GravelId: string;
}

export interface ReportPumpingSchedule extends Omit<PumpingSchedule, 'FluidId' | 'GravelId'> {
  FluidId: string;
  GravelId: string;
}
