import { IFile, Repository, validProjectFileExtensions } from '../dto/file.dto';

export class ProjectFileHelpers {
  public static getRootFolder(repository: Repository): IFile {
    return { Repository: repository, FileType: 'folder', Name: '', Folder: [] };
  }

  public static getParentFolder(file: IFile): IFile {
    if (file.Folder.length === 0) {
      return {
        FileType: 'folder',
        Folder: [],
        Name: '',
        Repository: file.Repository,
      };
    }
    return {
      FileType: 'folder',
      Folder: file.Folder.slice(0, file.Folder.length - 1),
      Name: file.Folder[file.Folder.length - 1],
      Repository: file.Repository,
    };
  }

  public static getElectronFileFromPath(absoluteFilePath: string): IFile {
    return {
      FileType: 'type-in-filename',
      Folder: [],
      Name: absoluteFilePath,
      Repository: 'electron',
    };
  }

  public static getFileDisplayName(fileName: string): string {
    // splitting by \\ or / is needed for electron
    let lastComponent = fileName.split('\\').slice(-1)[0];

    // last path component for slash based paths (Linux/macOS)
    lastComponent = lastComponent.split('/').slice(-1)[0];

    // remove extension
    if (validProjectFileExtensions.includes(lastComponent.split('.').slice(-1)[0].toLowerCase())) {
      lastComponent = lastComponent.substring(0, lastComponent.length - 4);
    }

    return lastComponent;
  }

  /**
   * For Cloud it returns folder path as relative to root,
   * For Electron it returns absolute path extracted from filename
   * @param file
   */
  public static getFileLocationToDisplay(file: IFile): string {
    // for Electron
    if (file.Repository == 'electron') {
      // it extracts file name out of fill path
      const displayName = this.getFileDisplayName(file.Name);
      // slice out filepath up without filename and extension
      return file.Name.slice(0, file.Name.lastIndexOf(displayName));
    }
    // for Cloud
    else {
      const pathComponents = [file.Repository, ...file.Folder];
      return `/${pathComponents.join('/')}`;
    }
  }

  public static isValidProjectFile(fileName: string): boolean {
    return validProjectFileExtensions.some((ext) => fileName.endsWith(`.${ext}`));
  }

  public static getProjectNameWithoutSuffix(fileName: string): string {
    return fileName.substring(0, fileName.length - 4);
  }

  public static getProjectNameSuffix(fileName: string): string {
    return fileName.substring(fileName.length - 4);
  }
}
