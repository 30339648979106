<div class="grid-form-container main">
  <div class="grid-form-container no-padding inline">
    <app-check-box [source]="completionData" [key]="'HasCentralizers'" label="Centralizer OD" (valueChanged)="onValueChange($event)">
    </app-check-box>
    <app-form-input
      [source]="completionData"
      [key]="'CentralizerOD'"
      appUnwrap
      [disabled]="!completionData?.HasCentralizers"
      [unitType]="UnitType.Diameter"
      (valueChanged)="onValueChange($event)"
      [decimalPlaces]="3"
    >
    </app-form-input>
  </div>
</div>
