<app-generic-modal [title]="modalTitle" [modalButtonsConfigs]="modalButtonsConfigs" [showHelpBtn]="true"
                   (helpClick)="isHelpOpen = $event">
  <div class="modal-content-container" data-cy="calculation-queue-main-container">
    <app-page-panel header="Calculations" [headerOnTheRight]="workersUsageMessage$ | async" [allowFullScreen]="false"
                    class="no-padding">
      <div class="scrollable-content-container">
        <ng-container *ngIf="allJobs$ | async as jobs else noCalculations">
          <table *ngIf="jobs.length > 0 else noCalculations"
                 class="table table-sm table-bordered"
                 data-cy="calculation-queue-table">
            <thead>
            <tr>
              <th></th>
              <th style="width: 70px">Queue Position</th>
              <th>File</th>
              <th>Scenario</th>
              <th style="width: 100px">Module</th>
              <th style="width: 100px">Status</th>
              <th style="width: 140px">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let job of jobs; index as index; trackBy: trackByJob"
                [ngClass]="{alternate: index % 2 === 0}">
              <td></td>
              <td class="centered">{{ job.positionInRemoteQueue ?? '-' }}
                <div class="overlay" [style.width]="job.progress + '%'"></div>
              </td>
              <td>{{ fileName(job.dbFile) }}</td>
              <td>{{ scenarioName(job.scenarioId) }}</td>
              <td class="centered">{{ moduleName(job.moduleType) }}</td>
              <td class="centered">{{ statusAsString(job) }} <span *ngIf="job.progress">({{ job.progress }}%)</span>
              </td>
              <td class="centered no-wrap">
                <app-button *ngIf="job.status === CalculationStatus.running"
                            class="no-bg"
                            dataCy="calculation-queue-stop-btn"
                            [isUiLockable]="false"
                            [isDisabledWhenCalculationActive]="false"
                            [disabled]="isActionButtonDisabled(job)"
                            (buttonClick)="onCancelJob(job)"
                            text="Stop"
                ></app-button>
                <app-button *ngIf="job.status !== CalculationStatus.running"
                            class="no-bg"
                            dataCy="calculation-queue-cancel-btn"
                            [isUiLockable]="false"
                            [isDisabledWhenCalculationActive]="false"
                            [disabled]="isActionButtonDisabled(job)"
                            (buttonClick)="onCancelJob(job)"
                            text="Cancel"
                ></app-button>
                <app-button *ngIf="isGoToAvailable(job)"
                            class="no-bg with-margin-left"
                            dataCy="calculation-queue-goto-btn"
                            [isUiLockable]="false"
                            [isDisabledWhenCalculationActive]="false"
                            [disabled]="isActionButtonDisabled(job)"
                            (buttonClick)="onGoTo(job)"
                            text="Open"
                ></app-button>
              </td>
            </tr>
            </tbody>
          </table>
        </ng-container>
        <ng-template #noCalculations>
          <div class="no-calculations-container" data-cy="no-calculations-container">
            No calculations
          </div>
        </ng-template>
      </div>
    </app-page-panel>
  </div>
</app-generic-modal>
<div class="modal-help" [class.open]="isHelpOpen">
  <div class="help-content">
    <ng-container *ngIf="'assets/help-files/top-bar/calculation-queue.html' | helpUrl | async as url">
      <iframe [src]="url"></iframe>
    </ng-container>
  </div>
</div>
