import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-page-content',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <router-outlet></router-outlet>`,
  styles: [
    `
      :host {
        height: calc(100% - var(--component-height) - var(--default-layout-padding));
        display: block;
        position: relative;
      }
    `,
  ],
})
export class PageContentComponent {}

@NgModule({
  imports: [RouterOutlet],
  declarations: [PageContentComponent],
  exports: [PageContentComponent],
})
export class PageContentModule {}
