<div class="grid-form-container" [class.render-2-rows]="renderAs2Rows">
  <label>Pump Rate</label>
  <app-select
    [items]="state.pumpRateItems"
    (primitiveValueChanged)="onPumpRateChange($event.value)"
    [value]="selectedValues.selectedPumpRateId"
    [disabled]="!getIsPumpRateEnabled"
    [overrideErrorMessage]="state.error.pumpRateItems"
    dataCy="analysis-data-pump-rate-select"
    [showSymbol]="false"
  ></app-select>
  <label class="lbl-pump-pressure">Pump Pressure</label>
  <app-select
    [items]="state.pumpPressureItems"
    (primitiveValueChanged)="onPumpPressureChange($event.value)"
    [value]="selectedValues.selectedPumpPressureId"
    [disabled]="!getIsPumpPressureEnabled"
    [overrideErrorMessage]="state.error.pumpPressureItems"
    dataCy="analysis-data-pump-pressure-select"
    [showSymbol]="false"
  ></app-select>

  <label>Return Rate</label>
  <app-select
    [items]="state.returnRateItems"
    (primitiveValueChanged)="onReturnRateChange($event.value)"
    [value]="selectedValues.selectedReturnRateId"
    [disabled]="!getIsReturnRateEnabled"
    [overrideErrorMessage]="state.error.returnRateItems"
    dataCy="analysis-data-return-rate-select"
    [showSymbol]="false"
  ></app-select>

  <label class="lbl-gravel-conc">Gravel Conc</label>
  <app-select
    [items]="state.gravelConcItems"
    (primitiveValueChanged)="onGravelConcChange($event.value)"
    [value]="selectedValues.selectedGravelConcId"
    [disabled]="!getIsGravelConcEnabled"
    [overrideErrorMessage]="state.error.gravelConcItems"
    dataCy="analysis-data-gravel-conc-select"
    [showSymbol]="false"
  ></app-select>
</div>
