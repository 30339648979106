import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DbDependentComponent } from '../../../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import {
  getChartTimeVolMode,
  getIsCurrentScenarioCalculationActive,
  getSelectedSimulationTime,
} from '../../../../../+store/reporting/reporting.selectors';
import { TimeConverter } from '@dunefront/common/unit-converters/converters/time/time.converter';
import { setSelectedSimulationTime } from '../../../../../+store/reporting/reporting.actions';
import {
  selectUserGlobalOptions,
  selectValidatedUserGlobalOptions
} from '../../../../../+store/common-db/common-db.selectors';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import {
  getDepthDataForCurrentScenarioRange,
  getDepthDataSimulationDuration,
} from '../../../../../+store/reporting/reporting-get-depth-chart-state.selector';
import { firstValueFrom, merge, Subject, throttleTime } from 'rxjs';
import { PrimarySecondaryArgumentConverter } from '@dunefront/common/modules/reporting/dto/primary-secondary-argument-converter';
import { VolumeConverter } from '@dunefront/common/unit-converters/converters/volume/volume.converter';
import { ChartTimeVolMode } from '@dunefront/common/modules/reporting/reporting-module.actions';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IDepthDataForRange } from '../../../../../+store/reporting/reporting-module.state';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import { VideoRecorderService } from '../../../../../common-modules/chart/video-recorder.service';
import { ErrorHelper } from '@dunefront/common/common/common-state.interfaces';

const SLIDER_LABEL_HIDDEN_STYLE = 'display:none;';
const INPUT_RANGE_DOT_SIZE = 16;
export const SLIDER_MAX_VALUE = 1000000;

@Component({
  selector: 'app-chart-slider',
  templateUrl: './chart-slider.component.html',
  styleUrls: ['./chart-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartSliderComponent extends DbDependentComponent implements AfterViewInit, OnDestroy {
  @ViewChild('inputElement') public inputElement!: ElementRef;
  @ViewChild('sliderWrapperElement') public sliderWrapperElement!: ElementRef<HTMLDivElement>;
  @ViewChild('sliderLabelContainer') public sliderLabelContainerElement!: ElementRef<HTMLDivElement>;
  public labelDisplayValue = '';
  public labelStyle = SLIDER_LABEL_HIDDEN_STYLE;
  public sliderValue = SLIDER_MAX_VALUE;

  private unlistenMouseUp!: () => void;
  private unlistenMouseDown!: () => void;
  private unlistenMouseOut!: () => void;

  private simulationDuration = 0;
  public UnitType = UnitSystem;
  public currentCalculationActive = false;

  private chartSliderUpdateInterval = 0.1;
  private notifySliderValueChanged$ = new Subject<number>();
  private depthData?: IDepthDataForRange;

  private timeVolMode?: ChartTimeVolMode;

  public recordingState$ = this.videoRecorder.getCurrentProviderIdRecording();

  public get disabled(): boolean {
    return this.currentCalculationActive || !this.simulationDuration;
  }

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected ngZone: NgZone,
    protected renderer: Renderer2,
    private modalService: ModalService,
    private videoRecorder: VideoRecorderService,
  ) {
    super(store, cdRef);

    this.subscription.add(
      this.store.select(getDepthDataSimulationDuration).subscribe((simulationDuration) => {
        const newSimulationDuration = simulationDuration ?? 0;

        if (newSimulationDuration !== this.simulationDuration) {
          this.simulationDuration = newSimulationDuration;

          // set slider position to 100%
          this.sliderValue = SLIDER_MAX_VALUE;
          this.notifySliderValueChanged$.next(this.selectedTime);

          this.cdRef.markForCheck();
        }
      }),
    );

    this.subscription.add(
      this.store.select(getSelectedSimulationTime).subscribe((selectedSimulationTime) => {
        let newSliderValue = SLIDER_MAX_VALUE;
        if (this.simulationDuration > 0) {
          newSliderValue = Math.round((SLIDER_MAX_VALUE * selectedSimulationTime) / this.simulationDuration);
        }
        if (this.sliderValue !== newSliderValue) {
          this.sliderValue = newSliderValue <= SLIDER_MAX_VALUE ? newSliderValue : SLIDER_MAX_VALUE;
          this.cdRef.markForCheck();
        }
      }),
    );

    this.subscription.add(
      this.store.select(getIsCurrentScenarioCalculationActive).subscribe((currentCalculationActive) => {
        this.currentCalculationActive = currentCalculationActive;
        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(
      this.store.select(selectUserGlobalOptions).subscribe((globalOptions) => {
        this.chartSliderUpdateInterval = globalOptions.ChartSliderUpdateInterval;
      }),
    );

    this.subscription.add(
      merge(
        this.notifySliderValueChanged$.pipe(throttleTime(this.chartSliderUpdateInterval * 1000)),
        this.notifySliderValueChanged$.pipe(debounceTime(this.chartSliderUpdateInterval * 1000)),
      )
        .pipe(distinctUntilChanged())
        .subscribe((selectedSimulationTime) => this.store.dispatch(setSelectedSimulationTime({ selectedSimulationTime }))),
    );

    this.subscription.add(this.store.select(getDepthDataForCurrentScenarioRange).subscribe((deptData) => (this.depthData = deptData)));
    this.subscription.add(this.store.select(getChartTimeVolMode).subscribe((mode) => (this.timeVolMode = mode)));
  }

  public ngAfterViewInit(): void {
    this.unlistenMouseDown = this.renderer.listen(this.inputElement.nativeElement, 'mousedown', () => this.showLabel());
    this.unlistenMouseUp = this.renderer.listen(document, 'mouseup', () => this.hideLabel());
    this.unlistenMouseOut = this.renderer.listen(document, 'mouseout', () => this.hideLabel());
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();

    this.unlistenMouseDown();
    this.unlistenMouseUp();
    this.unlistenMouseOut();
  }

  public hideLabel(): void {
    if (this.labelStyle === SLIDER_LABEL_HIDDEN_STYLE) {
      return;
    }

    this.labelStyle = SLIDER_LABEL_HIDDEN_STYLE;
    this.cdRef.markForCheck();
  }

  public sliderValueChanged($event: any): void {
    this.sliderValue = $event.target.value;

    this.showLabel();

    this.notifySliderValueChanged$.next(this.selectedTime);
  }

  public showLabel(): void {
    {
      const { left: sliderLeft, width: sliderWidth } = this.inputElement.nativeElement.getBoundingClientRect();
      const { left: wrapperLeft } = this.sliderWrapperElement.nativeElement.getBoundingClientRect();
      const leftOffset = sliderLeft - wrapperLeft;
      const labelY = -30;
      const positionOnSlider = ((sliderWidth - INPUT_RANGE_DOT_SIZE) * this.sliderValue) / SLIDER_MAX_VALUE;

      this.labelDisplayValue = this.getSecondaryArgDisplayText() ?? this.getPrimaryArgDisplayText();

      // first step: show label in the middle of slider (no chance for text wrapping),
      const labelXMiddle = leftOffset + sliderWidth / 2;

      this.labelStyle = `left: ${labelXMiddle}px; top: ${labelY}px`;
      this.cdRef.detectChanges();

      // second step: read actual tooltip width, then apply proper x position (with tooltip fitting into container bounds)
      const { width: tooltipWidth } = this.sliderLabelContainerElement.nativeElement.getBoundingClientRect();

      const tooltipOffset = tooltipWidth / 2 - INPUT_RANGE_DOT_SIZE / 2;
      const labelX = leftOffset + positionOnSlider - tooltipOffset;
      const maxLabelX = leftOffset + sliderWidth - tooltipWidth;
      const restrictedLabelX = Math.min(labelX, maxLabelX);

      this.labelStyle = `left: ${restrictedLabelX}px; top: ${labelY}px`;
      this.cdRef.detectChanges();
    }
  }

  public onMouseDown(): void {
    window.getSelection()?.removeAllRanges();
  }

  private get selectedTime(): number {
    return (this.simulationDuration * this.sliderValue) / SLIDER_MAX_VALUE;
  }

  private numberToString(value: number): string {
    return '' + Math.round(value * 100) / 100;
  }

  private getPrimaryArgDisplayText(): string {
    const timeValue = TimeConverter.fromSi(this.selectedTime, this.currentUnitSystem.Time);
    const timeUnit = TimeConverter.getSymbol(this.currentUnitSystem.Time);

    return `${this.numberToString(timeValue)} ${timeUnit}`;
  }

  private getSecondaryArgDisplayText(): string | null {
    const depthData = this.depthData;

    if (this.timeVolMode === ChartTimeVolMode.time || !depthData || depthData.allDataPoints?.length <= 2) {
      return null;
    }

    const volumeValueSi = PrimarySecondaryArgumentConverter.convertIfNeededFromTimeVolData(
      depthData.allDataPoints,
      this.selectedTime,
      'primary-to-secondary',
    );

    const volumeValue = VolumeConverter.fromSi(volumeValueSi, this.currentUnitSystem.Liquid_Volume);
    const volumeUnit = VolumeConverter.getSymbol(this.currentUnitSystem.Liquid_Volume);

    return `${this.numberToString(volumeValue)} ${volumeUnit}`;
  }

  public async checkSliderOptionsAndRun(cb: ()=> Promise<void>): Promise<void> {
    const {error} = await firstValueFrom(this.store.select(selectValidatedUserGlobalOptions))

    if(error.VideoSteps) {
      let message = ErrorHelper.ERROR_CURRENT_SCREENS_MESSAGE_HEADER;
      message += '<br/> - ' + error.VideoSteps;

      await this.modalService.showAlert(message)
      return
    }

    cb().then()
  }

  public onPlay(): void {
    this.checkSliderOptionsAndRun(() => this.videoRecorder.play()).then()
  }

  public async onRecord(): Promise<void> {
    this.checkSliderOptionsAndRun(() => this.videoRecorder.recordAndDownloadVideo()).then()
  }

  public onStop(): void {
    this.videoRecorder.onFinish().then();
  }
}
