import { CompletionModuleState } from '../../completion-module.state';
import { PipeType } from '../../../../dto/pipe.dto';
import { CompletionCalculations } from '../completion.calculations';
import { ArrayHelpers } from '../../../../common/array-helpers';
import { GaugeMeasurementType } from '../../../../dto/running-string-pipe.dto';
import { getRowsForCalculations, ITableRow, ITableState } from '../../../../common/common-grid.interfaces';
import { Pipe } from '../../../pipes/pipe';
import { RunningStringPipe } from '../../../pipes/running-string-pipes/running-string-pipes.factory';
import { IError } from '../../../../common/common-state.interfaces';
import { noErrors } from '../../../../common/state.helpers';
import { isWorkstringPipe, isWorkstringPipeRow, WorkstringPipe } from '../../../pipes/running-string-pipes/pipes/workstring-pipe';
import { ToolJoint } from '../../../pipes/tool-joint/tool-joint';
import { isServiceToolPipeRow, ServiceToolPipe } from '../../../pipes/running-string-pipes/pipes/service-tool-pipe';
import { BypassAnnulus } from '../../../pipes/running-string-pipes/bypass-annulus';
import {
  ConcentricGaugeCarrierPipe,
  isConcentricGaugeCarrierPipe,
  isConcentricGaugeCarrierPipeRow,
} from '../../../pipes/running-string-pipes/pipes/concentric-gauge-carrier-pipe';
import { BypassPorts } from '../../../pipes/bypass-ports';
import { GaugeCarrierPipe, isGaugeCarrierPipeRow } from '../../../pipes/running-string-pipes/pipes/gauge-carrier-pipe';
import { isPressureAttenuatorPipeRow, PressureAttenuatorPipe } from '../../../pipes/running-string-pipes/pipes/pressure-attenuator-pipe';
import { isICDScreenRow } from '../../../pipes/lower-completion-pipes/pipes/icd-screen-pipe';
import { ICompletionValidationDependencies } from '../completion.validation';
import { PumpingStateByRange } from '../../../pumping/pumping-module.state';
import { validateFeatureNotSupportedInFluidPro, validateFeatureRequired } from '../../../licensing/licensing.validator';
import { LicenseFeature } from '../../../licensing/licensing.interfaces';
import { ToolPosition } from '../../../pumping/dto/pumping.dto';
import { ModuleType } from '../../../scenario/scenario.dto';
import { CasingPipe } from '../../../pipes/casing-pipes/casing-pipes.factory';
import { FluidProValidation } from '../../../fluid-pro/fluid-pro.validation';

export class RunningStringValidation {
  public static ValidateRunningStringGrid(completion: CompletionModuleState, deps: ICompletionValidationDependencies): ITableState<Pipe> {
    let isValid = true;
    let isWarning = false;

    const runningStringRows = getRowsForCalculations(completion.RunningString.rows);
    const lowerCompletions = getRowsForCalculations(completion.LowerCompletion.rows);
    const updatedRows = completion.RunningString.rows.map((row, rowIndex) => {
      if (row.rowType === 'insert-row') {
        return row;
      }
      const newRow = this.ValidateRunningStringGridRow(completion, runningStringRows, lowerCompletions, deps, rowIndex);
      isValid = isValid && newRow.isValid;
      isWarning = isWarning || (newRow.isWarning ?? false);
      return newRow;
    });
    return { ...completion.RunningString, rows: updatedRows, isValid, isWarning };
  }

  public static CanDelete(completion: CompletionModuleState, rowIds: number[]): string {
    return '';
  }

  private static ValidateRunningStringGridRow(
    completion: CompletionModuleState,
    runningStrings: Pipe[],
    lowerCompletions: Pipe[],
    deps: ICompletionValidationDependencies,
    rowIndex: number,
  ): ITableRow<Pipe> {
    let row: ITableRow<RunningStringPipe> = { ...completion.RunningString.rows[rowIndex] };

    const { currentModuleType, settings } = deps;
    const { IsToolJointAnalysed } = settings;

    const error: IError<RunningStringPipe> = {
      PipeType: this.ValidateRunningStringComponentName(completion, deps.pumpingState, runningStrings, row.rowData, rowIndex, deps),
      BottomMD: this.ValidateRunningStringBottomMD(completion, runningStrings, lowerCompletions, row.rowData, rowIndex, deps),
      OuterDiameter: this.ValidateRunningStringOD(row.rowData, IsToolJointAnalysed),
      InnerDiameter: this.ValidateRunningStringID(row.rowData, IsToolJointAnalysed),
      Weight: this.ValidateRunningStringWeight(row.rowData),
    };

    let warning: IError<CasingPipe> = {};
    if (currentModuleType === ModuleType.Simulate_Disp) {
      const componentSizeWarning = FluidProValidation.ValidateFluidProComponentSize(runningStrings, rowIndex, settings);
      warning = {
        ...warning,
        PipeType: componentSizeWarning,
        BottomMD: componentSizeWarning,
        TopMD: componentSizeWarning,
        Length: componentSizeWarning,
      };
    }

    row = {
      ...row,
      error,
      warning,
      isValid: noErrors(error),
      isWarning: !noErrors(warning),
    };

    if (isWorkstringPipeRow(row)) {
      const toolJointError: IError<ToolJoint> = IsToolJointAnalysed
        ? {
            InnerDiameter: this.ValidateWorkstringToolJointID(row.rowData),
            OuterDiameter: this.ValidateWorkstringToolJointOD(row.rowData),
            SingleJointLength: this.ValidateWorkstringSingleJointLength(row.rowData),
            ToolJointLength: this.ValidateWorkstringToolJointLength(row.rowData),
          }
        : {};

      row = {
        ...row,
        isValid: row.isValid && noErrors(toolJointError),
        rowData: { ...row.rowData, ToolJoint: { ...row.rowData.ToolJoint, error: toolJointError } },
      };
    }

    if (isServiceToolPipeRow(row)) {
      const downFlowError: IError<BypassAnnulus> = {
        NoOfPorts: this.ValidateServiceToolFlowNumberOfPorts(row.rowData.DownFlow),
        PortDiameter: this.ValidateServiceToolFlowPortDiameter(row.rowData, row.rowData.DownFlow),
        PortLength: this.ValidateServiceToolFlowPortLength(row.rowData, row.rowData.DownFlow, row.rowData.IsDownFlowPresent),
        AnnulusOD: this.ValidateServiceToolFlowAnnulusOD(row.rowData.DownFlow, row.rowData.IsDownFlowPresent),
        AnnulusID: this.ValidateServiceToolFlowAnnulusID(row.rowData.DownFlow, row.rowData.IsDownFlowPresent),
        AnnulusLength: this.ValidateServiceToolFlowAnnulusLength(row.rowData, row.rowData.DownFlow, row.rowData.IsDownFlowPresent),
      };

      const upFlowError: IError<BypassAnnulus> = {
        NoOfPorts: this.ValidateServiceToolFlowNumberOfPorts(row.rowData.UpFlow),
        PortDiameter: this.ValidateServiceToolFlowPortDiameter(row.rowData, row.rowData.UpFlow),
        PortLength: this.ValidateServiceToolFlowPortLength(row.rowData, row.rowData.UpFlow, row.rowData.IsUpFlowPresent),
        AnnulusOD: this.ValidateServiceToolFlowAnnulusOD(row.rowData.UpFlow, row.rowData.IsUpFlowPresent),
        AnnulusID: this.ValidateServiceToolFlowAnnulusID(row.rowData.UpFlow, row.rowData.IsUpFlowPresent),
        AnnulusLength: this.ValidateServiceToolFlowAnnulusLength(row.rowData, row.rowData.UpFlow, row.rowData.IsUpFlowPresent),
      };

      row = {
        ...row,
        isValid: row.isValid && noErrors(downFlowError) && noErrors(upFlowError),
        rowData: {
          ...row.rowData,
          DownFlow: { ...row.rowData.DownFlow, error: downFlowError },
          UpFlow: { ...row.rowData.UpFlow, error: upFlowError },
        },
      };
    }

    if (isConcentricGaugeCarrierPipeRow(row)) {
      const flowPortsError: IError<BypassPorts> = {
        NoOfPorts: this.ValidateGaugeCarrierNumberOfPorts(row.rowData),
        PortDiameter: this.ValidateGaugeCarrierPortDiameter(row.rowData),
        PortLength: this.ValidateGaugeCarrierPortLength(row.rowData),
      };

      row = {
        ...row,
        isValid: row.isValid && noErrors(flowPortsError),
        rowData: { ...row.rowData, FlowPorts: { ...row.rowData.FlowPorts, error: flowPortsError } },
      };
    }

    if (isGaugeCarrierPipeRow(row)) {
      row = {
        ...row,
        error: { ...row.error, GaugeMeasurementType: this.ValidateGaugeCarrierMeasurementType(row.rowData) },
      };
    }

    if (isPressureAttenuatorPipeRow(row)) {
      row = {
        ...row,
        error: { ...row.error, ActivationPressure: this.ValidateActivationPressure(row.rowData) },
      };
    }

    row = { ...row, isValid: row.isValid && !Object.values(row.error).some((err) => err) };
    return row;
  }

  public static ValidateRunningStringComponentName(
    completion: CompletionModuleState,
    pumping: PumpingStateByRange,
    rows: Pipe[],
    pipe: Pipe,
    rowIndex: number,
    { currentLicenseFeatures, currentModuleType }: ICompletionValidationDependencies,
  ): string {
    if (completion.RunningString.rows.every((row) => row.rowData.PipeType !== PipeType.Service_Tool)) {
      return 'At least one service tool must be defined';
    }

    if (pipe.PipeType === PipeType.Pressure_Attenuator) {
      const notSupportedInFluidProError = validateFeatureNotSupportedInFluidPro(currentModuleType);
      if (notSupportedInFluidProError) {
        return notSupportedInFluidProError;
      }

      const requiredFeatureError = validateFeatureRequired([LicenseFeature.Simulate, LicenseFeature.Evaluate], currentLicenseFeatures);
      if (requiredFeatureError) {
        return requiredFeatureError;
      }
    }

    if (rowIndex > rows.findIndex((row) => row.PipeType === PipeType.Service_Tool)) {
      // after service tool
      if (pipe.PipeType === PipeType.Workstring && rowIndex > rows.findIndex((row) => row.PipeType === PipeType.Service_Tool)) {
        return "This component can't be placed below the service tool";
      }
    } else {
      // before service tool
      if (
        pipe.PipeType !== PipeType.Service_Tool &&
        pipe.PipeType !== PipeType.Workstring &&
        pipe.PipeType !== PipeType.Eccentric_Gauge_Carrier
      ) {
        return "This component can't be placed above the service tool";
      }
    }

    if (pipe.PipeType === PipeType.Pressure_Attenuator) {
      if (completion.LowerCompletion.rows.some((row) => isICDScreenRow(row))) {
        return "ICDs and pressure attenuators can't be run together";
      }

      if (pumping.pumping.IsAutoPressureAttenuatorLocation) {
        return "Pressure attenuators can't be run when the auto location option is enabled as they will be placed automatically";
      }

      if (CompletionCalculations.HasShunts(completion)) {
        return "Pressure attenuators can't be run in shunted completions";
      }

      if (pumping.pumping.ToolPosition === ToolPosition.Reverse_Port_GP) {
        return "Pressure attenuators can't be run in reverse-port gravel pack position";
      }
    }

    return '';
  }

  private static ValidateRunningStringBottomMD(
    completion: CompletionModuleState,
    runningStrings: Pipe[],
    lowerCompletions: Pipe[],
    currentPipe: Pipe,
    rowIndex: number,
    { currentModuleType }: ICompletionValidationDependencies,
  ): string {
    if (currentPipe.PipeType === PipeType.Service_Tool) {
      return '';
    }

    const topMD = currentPipe.TopMD;
    const bottomMD = currentPipe.BottomMD;
    const maxLowerCompletionMD = CompletionCalculations.MaxLowerCompletionMD(lowerCompletions);
    if (topMD >= bottomMD) {
      return 'Bottom MD must be greater than top MD';
    }
    if (currentModuleType === ModuleType.Simulate_Disp && rowIndex === runningStrings.length - 1 && bottomMD !== maxLowerCompletionMD) {
      return 'Bottom MD must be equal to maximum lower completion MD';
    } else if (bottomMD > maxLowerCompletionMD) {
      return 'Bottom MD must be less than or equal to maximum lower completion MD';
    } else if (rowIndex === runningStrings.length - 1) {
      const lastICDScreen = ArrayHelpers.findLast(completion.LowerCompletion.rows, (row) => isICDScreenRow(row));
      if (lastICDScreen && bottomMD < lastICDScreen.rowData.BottomMD) {
        return 'Running string must extend below the last ICD component';
      }
    }
    return '';
  }

  private static ValidateRunningStringOD(pipe: Pipe, isToolJointAnalysed: boolean): string {
    if (pipe.PipeType === PipeType.Service_Tool) {
      return '';
    }
    if (pipe.OuterDiameter <= 0) {
      return 'OD must be greater than zero';
    }
    if (pipe.InnerDiameter >= pipe.OuterDiameter) {
      return 'OD must be greater than ID';
    }
    if (isToolJointAnalysed && isWorkstringPipe(pipe) && pipe.OuterDiameter > pipe.ToolJoint.OuterDiameter) {
      return 'Workstring OD must be less than or equal to tool joint OD';
    }
    if (isConcentricGaugeCarrierPipe(pipe) && pipe.OuterDiameter <= pipe.FlowPorts.PortDiameter) {
      return 'Gauge carrier OD must be greater than port diameter';
    }
    return '';
  }

  private static ValidateRunningStringID(pipe: Pipe, isToolJointAnalysed: boolean): string {
    if (pipe.InnerDiameter <= 0) {
      return 'ID must be greater than zero';
    }
    if (pipe.InnerDiameter >= pipe.OuterDiameter) {
      return 'ID must be less than OD';
    }
    if (isToolJointAnalysed && isWorkstringPipe(pipe) && pipe.InnerDiameter < pipe.ToolJoint.InnerDiameter) {
      return 'Workstring ID must be greater than or equal to tool joint ID';
    }
    return '';
  }

  private static ValidateRunningStringWeight(pipe: Pipe): string {
    if (pipe.Weight <= 0) {
      return 'Weight must be greater than zero';
    }
    return '';
  }

  // region workstring properties

  private static ValidateWorkstringToolJointID(pipe: WorkstringPipe): string {
    if (pipe.ToolJoint.InnerDiameter <= 0) {
      return 'Tool joint ID must be greater than zero';
    }
    if (pipe.ToolJoint.InnerDiameter > pipe.InnerDiameter) {
      return 'Tool joint ID must be less than or equal to workstring ID';
    }
    return '';
  }

  private static ValidateWorkstringToolJointOD(workstring: WorkstringPipe): string {
    if (workstring.ToolJoint.OuterDiameter <= 0) {
      return 'Tool joint OD must be greater than zero';
    }
    if (workstring.ToolJoint.OuterDiameter < workstring.OuterDiameter) {
      return 'Tool joint OD must be greater than or equal to workstring OD';
    }
    return '';
  }

  private static ValidateWorkstringSingleJointLength(workstring: WorkstringPipe): string {
    if (workstring.ToolJoint.SingleJointLength <= 0) {
      return 'Single joint length must be greater than zero';
    }
    if (workstring.ToolJoint.SingleJointLength <= workstring.ToolJoint.ToolJointLength) {
      return 'Single joint length must be greater than tool joint length';
    }
    if (workstring.ToolJoint.SingleJointLength > workstring.Length) {
      return 'Single joint length must be less than or equal to total length';
    }
    return '';
  }

  private static ValidateWorkstringToolJointLength(workstring: WorkstringPipe): string {
    if (workstring.ToolJoint.ToolJointLength <= 0) {
      return 'Tool joint length must be greater than zero';
    }
    if (workstring.ToolJoint.ToolJointLength >= workstring.ToolJoint.SingleJointLength) {
      return 'Tool joint length must be less than single joint length';
    }
    return '';
  }

  // endregion

  // region service tool properties

  private static ValidateServiceToolFlowNumberOfPorts(flow: BypassAnnulus): string {
    if (flow.NoOfPorts <= 0) {
      return 'Number of ports must be greater than zero';
    }
    return '';
  }

  private static ValidateServiceToolFlowPortDiameter(serviceTool: ServiceToolPipe, flow: BypassAnnulus): string {
    if (flow.PortDiameter <= 0) {
      return 'Port diameter must be greater than zero';
    }
    if (flow.PortDiameter >= serviceTool.OuterDiameter) {
      return 'Port diameter must be less than service tool OD';
    }

    return '';
  }

  private static ValidateServiceToolFlowPortLength(serviceTool: ServiceToolPipe, flow: BypassAnnulus, isFlowPresent: boolean): string {
    if (flow.PortLength <= 0) {
      return 'Port length must be greater than zero';
    }
    if (isFlowPresent && flow.PortLength + flow.AnnulusLength >= serviceTool.Length) {
      return 'Combined port and annulus length must be less than total length';
    }
    if (flow.PortLength >= serviceTool.Length) {
      return 'Port length must be less than total length';
    }

    return '';
  }

  private static ValidateServiceToolFlowAnnulusOD(flow: BypassAnnulus, isFlowPresent: boolean): string {
    if (!isFlowPresent) {
      return '';
    }
    if (flow.AnnulusOD <= 0) {
      return 'Annulus OD must be greater than zero';
    }
    if (flow.AnnulusOD <= flow.AnnulusID) {
      return 'Annulus OD must be greater than annulus ID';
    }
    return '';
  }

  private static ValidateServiceToolFlowAnnulusID(flow: BypassAnnulus, isFlowPresent: boolean): string {
    if (!isFlowPresent) {
      return '';
    }
    if (flow.AnnulusID <= 0) {
      return 'Annulus ID must be greater than zero';
    }
    if (flow.AnnulusID >= flow.AnnulusOD) {
      return 'Annulus ID must be less than annulus OD';
    }
    return '';
  }

  private static ValidateServiceToolFlowAnnulusLength(serviceTool: ServiceToolPipe, flow: BypassAnnulus, isFlowPresent: boolean): string {
    if (!isFlowPresent) {
      return '';
    }
    if (flow.AnnulusLength <= 0) {
      return 'Annulus length must be greater than zero';
    }
    if (flow.PortLength + flow.AnnulusLength >= serviceTool.Length) {
      return 'Combined port and annulus length must be less than total length';
    }
    return '';
  }

  // endregion

  // region gauge carrier properties

  private static ValidateGaugeCarrierNumberOfPorts(gaugeCarrier: ConcentricGaugeCarrierPipe): string {
    if (gaugeCarrier.FlowPorts.NoOfPorts <= 0) {
      return 'Number of ports must be greater than zero';
    }
    return '';
  }

  private static ValidateGaugeCarrierPortDiameter(gaugeCarrier: ConcentricGaugeCarrierPipe): string {
    if (gaugeCarrier.FlowPorts.PortDiameter <= 0) {
      return 'Port diameter must be greater than zero';
    }
    if (gaugeCarrier.FlowPorts.PortDiameter >= gaugeCarrier.OuterDiameter) {
      return 'Port diameter must be less than gauge carrier OD';
    }

    return '';
  }

  private static ValidateGaugeCarrierPortLength(gaugeCarrier: ConcentricGaugeCarrierPipe): string {
    if (gaugeCarrier.FlowPorts.PortLength <= 0) {
      return 'Port length must be greater than zero';
    } else if (gaugeCarrier.FlowPorts.PortLength >= gaugeCarrier.Length) {
      return 'Port length must be less than total length';
    }

    return '';
  }

  private static ValidateGaugeCarrierMeasurementType(gaugeCarrier: GaugeCarrierPipe): string {
    if (gaugeCarrier.GaugeMeasurementType === GaugeMeasurementType.None) {
      return 'Gauge measurement type must be defined';
    }

    return '';
  }

  // endregion

  // region pressure attenuator properties

  private static ValidateActivationPressure(pressureAttenuator: PressureAttenuatorPipe): string {
    if (pressureAttenuator.ActivationPressure <= 0) {
      return 'Activation pressure must be greater than zero';
    }

    return '';
  }

  // endregion
}
