<div class="search-container" *ngrxLet="searchPhrase$ as searchPhrase">
<app-input
    [maxWidth]="400"
    [width]="400"
    [showSymbol]="false"
    [align]="'left'"
    [isTextInput]="true"
    [isUiLockable]="false"
    [placeholder]="'Search'"
    [triggerOnKeyPress]="true"
    [icon]="'icon-search'"
    [value]="searchPhrase"
    (primitiveValueChanged)="onChange($event)"
    class="home-search-input"
    dataCy="home-search-input"
  ></app-input>

  <app-button text="clear" data-cy="home-search-clear" *ngIf="searchPhrase" (click)="clearSearchPhrase()"></app-button>
</div>
