import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UiModuleFeatureName } from './ui.reducer';
import { Notification, NotificationWithStatus, UiMode, UiState } from './ui-module.state';
import { isSimulateBased, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { isEvaluate, isTrendAnalysis } from '../menu-selectors/menu-selectors.helpers';
import { NotificationState, NotificationStatus } from '@dunefront/common/dto/custom-settings.dto';
import { NOTIFICATIONS_REMIND_ME_LATER_DAYS } from '@dunefront/common/common/constants';
import { APP_CODE } from '@dunefront/common/common/app-target-config';

export const getUiState = createFeatureSelector<UiState>(UiModuleFeatureName);
export const getUiChartZoomMode = createSelector(getUiState, (state) => state.chartZoomMode);
export const getAppModuleType = createSelector(getUiState, (state) => state.appModuleType);
export const getUiChartZoomOriginalSize = createSelector(getUiState, (state) => state.chartZoomOriginalSize);
export const getUiCopyImage = createSelector(getUiState, (state) => state.copyImage);
export const getUiChartMode = createSelector(getUiState, (state) => state.chartMode);
export const getCurrentAppModuleType = createSelector(getUiState, (state): ModuleType => state.appModuleType);
export const getXYAxisShift = createSelector(getUiState, (state) => state.xyAxisShift);
export const getEvaluateResetAutoShift = createSelector(getUiState, (state) => state.evaluateResetAutoShift);
export const getYAutoShift = createSelector(getUiState, (state) => state.isYAutoShiftActive);
export const getXAutoShift = createSelector(getUiState, (state) => state.isXAutoShiftActive);
export const selectShouldShowBeforeUnloadWarning = createSelector(getUiState, (state) => state.shouldShowBeforeUnloadWarning);
export const getIsRibbonDisplayed = createSelector(getUiState, (state) => state.isRibbonDisplayed);
export const getAnnotationsVisible = createSelector(getUiState, (state) => state.annotationsVisible);
export const getGradientLinesVisible = createSelector(getUiState, (state) => state.gradientLinesVisible);
export const getMarkersVisible = createSelector(getUiState, (state) => state.markersVisible);
export const getIsSideNavPinned = createSelector(getUiState, (state) => state.isSideNavPinned);
export const getIsHelpPinned = createSelector(getUiState, (state) => state.isHelpPinned);
export const getPinSettings = createSelector(getUiState, (state) => ({
  isHelpPinned: state.isHelpPinned,
  isSideNavPinned: state.isSideNavPinned,
}));
export const getIsReady = createSelector(getUiState, (state) => state.uiMode === UiMode.ready);
export const getIsOneTimeInstructionShown = createSelector(getUiState, (state) => state.isOneTimeInstructionShown);
export const getCurrentMenuTab = createSelector(getUiState, (state) => state.currentMenuTab);

export const getIsCompareScenariosEnabled = createSelector(
  getUiState,
  (uiState) => uiState.appModuleSection === 'reporting' && isSimulateBased(uiState.appModuleType),
);

export const isSimulateEvaluateTrendAnalysis = createSelector(
  getCurrentAppModuleType,
  (moduleType): boolean => isSimulateBased(moduleType) || isEvaluate(moduleType) || isTrendAnalysis(moduleType),
);

export const getExportDataUseTimestamps = createSelector(getUiState, (state) => state.exportDataUseTimestamps);

export const getCurrentAppCodeBySelectedAppModule = createSelector(getCurrentAppModuleType, (state) => {
  let appCode: APP_CODE | null = 'pack-pro';
  if (state === ModuleType.None) {
    appCode = null;
  } else if (state === ModuleType.Data_Analysis) {
    appCode = 'data-pro';
  } else if (state === ModuleType.Simulate_Disp) {
    appCode = 'fluid-pro';
  }
  return appCode;
});

export const getUnreadNotifications = (notifications: Notification[], notificationsStatus: NotificationState[]): Notification[] => {
  return notifications.filter((notification) => {
    const nStatus = notificationsStatus?.find((stat) => stat.id === notification.id);

    if (!nStatus) {
      return true;
    }

    return nStatus.status === NotificationStatus.REMIND_LATER && isTimeToRemind(nStatus.date);
  });
};

export const isTimeToRemind = (date: Date): boolean => {
  const remindMeLaterDate = new Date(date);
  remindMeLaterDate.setDate(remindMeLaterDate.getDate() + NOTIFICATIONS_REMIND_ME_LATER_DAYS);

  return remindMeLaterDate <= new Date();
};

export const selectNotificationsWithStatus = createSelector(getUiState, (state) =>
  state.notifications.map((notification) => {
    const nStatus = state.notificationsStates.find((stat) => stat.id === notification.id);

    return {
      ...notification,
      status: nStatus?.status ?? NotificationStatus.UNREAD,
      date: nStatus?.date ?? null,
    } as NotificationWithStatus;
  }),
);
export const selectUnreadNotifications = createSelector(selectNotificationsWithStatus, (state) =>
  state
    .filter((n) => n.status === NotificationStatus.UNREAD || (n.status === NotificationStatus.REMIND_LATER && isTimeToRemind(n.date)))
    .sort((a, b) => {
      // in unread notifications - release notes should be always on top
      if (b.id.startsWith('release-notes')) {
        return 1;
      } else if (a.id.startsWith('release-notes')) {
        return -1;
      } else {
        return new Date(a.showDate).getTime() - new Date(b.showDate).getTime();
      }
    }),
);

export const selectSeenNotifications = createSelector(selectNotificationsWithStatus, (state) =>
  state
    .filter(
      (n) =>
        n.status === NotificationStatus.OPENED ||
        n.status === NotificationStatus.DISMISSED ||
        (n.status === NotificationStatus.REMIND_LATER && !isTimeToRemind(n.date)),
    )
    .sort((a, b) => {
      // release notes notification should be sorted by action time ( when user pressed any button on it ), other ones by showDate
      const getDateForSort = (notification: NotificationWithStatus): Date =>
        new Date(notification.id.startsWith('release-notes') ? notification.date : notification.showDate);

      return getDateForSort(a).getTime() - getDateForSort(b).getTime();
    }),
);

export const selectNotificationStates = createSelector(getUiState, (state) => state.notificationsStates);

export const getIsSimulationAnimationVisible = createSelector(
  getUiState,
  (state) => state.appModuleSection === 'results' && state.resultsTab === 'simulation-animation',
);
export const getIsEvaluationAnimationVisible = createSelector(
  getUiState,
  (state) => state.appModuleSection === 'results' && state.resultsTab === 'evaluation-animation',
);
export const getIsWellVisualizationVisible = createSelector(
  getUiState,
  (state) => state.appModuleSection === 'results' && state.resultsTab === 'well-visualization',
);

export const getCurrentAppModuleSection = createSelector(getUiState, (state) => state.appModuleSection);
