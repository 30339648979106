import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appStopKeyPropagation]',
  standalone: true,
})
export class StopKeyPropagationDirective {
  @Input() public appStopKeyPropagation: string[] = [];

  @HostListener('keydown', ['$event'])
  public handleKeydown(event: KeyboardEvent): void {
    if (this.appStopKeyPropagation.includes(event.key)) {
      event.stopPropagation();
    }
  }
}
