import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagePanelModule } from '../../../common-modules/page-panel/page-panel.component';
import { TabbedPageModule } from '../../../common-modules/tabbed-page/tabbed-page.component';
import { UnitsModule } from '../../../common-modules/units/units.module';
import { FormsModule } from '@angular/forms';
import { GridSelectComponentModule } from '../../../common-modules/grid-select/grid-select.component';
import { ReportingComponent } from './reporting.component';
import { ReportingChartComponent } from './chart/reporting-chart.component';
import { ReportComponent } from './report/report.component';
import { ChartModule } from '../../../common-modules/chart/chart.module';
import { RadioGroupModule } from '../../../shared/components/form-components/radio-group/radio-group.component';
import { ResultsModule } from '../../simulate-evaluate-page/results/results.module';
import { ReportTestComponent } from './report-test/report-test.component';
import { RouterModule } from '@angular/router';
import { ReportingChartConfigurationComponent } from './chart/reporting-chart-configuration/reporting-chart-configuration.component';
import { ButtonModule } from '../../../shared/components/button/button.module';
import { FormInputModule } from '../../../shared/components/form-components/form-input/form-input.component';
import { UnwrapDirectiveModule } from '../../../shared/directives/unwrap.directive';
import { CheckBoxModule } from '../../../shared/components/form-components/check-box/check-box.component';
import { CalendarModule } from 'primeng/calendar';
import { RenameReportingTabComponent } from './rename-reporting-tab.component';
import { DateTimeInputModule } from '../../../shared/components/form-components/date-time-input/date-time-input.component';
import { CompletionModule } from '../../simulate-evaluate-page/completion/completion.module';
import { ModalsModule } from '../../../common-modules/modals/modals.module';
import { FileDisplayNamePipe } from '../../../shared/pipes/file/file-display-name.pipe';
import { SelectModule } from '../../../shared/components/form-components/select/select.module';
import { ReportingRangeSelectionComponent } from './reporting-range-selection/reporting-range-selection.component';
import { ListboxModule } from 'primeng/listbox';
import { CheckboxModule } from 'primeng/checkbox';
import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { LetDirective } from '@ngrx/component';
import { FilterableTreeComponent } from '../../../shared/components/filterable-tree/filterable-tree.component';
import { HideOnFluidProModule } from '../../../shared/directives/hide-on-fluidpro';

@NgModule({
  declarations: [
    ReportingComponent,
    ReportingChartComponent,
    ReportComponent,
    ReportTestComponent,
    ReportingChartConfigurationComponent,
    RenameReportingTabComponent,
    ReportingRangeSelectionComponent,
  ],
  imports: [
    PagePanelModule,
    CommonModule,
    TabbedPageModule,
    UnitsModule,
    FormsModule,
    GridSelectComponentModule,
    ChartModule,
    RadioGroupModule,
    ResultsModule,
    RouterModule,
    FormInputModule,
    ButtonModule,
    UnwrapDirectiveModule,
    CheckBoxModule,
    CalendarModule,
    DateTimeInputModule,
    CompletionModule,
    ModalsModule,
    SelectModule,
    ListboxModule,
    CheckboxModule,
    CdkDropList,
    CdkDrag,
    LetDirective,
    FilterableTreeComponent,
    HideOnFluidProModule,
  ],
  providers: [FileDisplayNamePipe],
})
export class ReportingModule {}
