import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { getIsUiLocked } from '../../../+store/ui/calc-engine-ui.selectors';
import { InputsHelperService } from '../../services/inputs-helper.service';
import { ButtonBaseComponent } from './button.base.component';
import { MenuItem } from 'primeng/api/menuitem';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent extends ButtonBaseComponent {
  @ViewChild('button')
  public override buttonRef!: ElementRef;

  @Input() public class!: string;
  @Input() public icon!: string;
  @Input() public iconTransformation = 'none';
  @Input() public text = '';
  @Input() public textIcon = '';
  @Input() public subIcon = '';
  @Input() public iconAsText = '';
  @Input() public isValid = true;
  @Input() public dataCy = '';
  @Input() public keyboardEnabled = false;

  @HostBinding('class.hidden')
  @Input()
  public hidden = false;

  @HostBinding('class.advanced-button')
  @Input()
  public isAdvancedButton = false;
  @Input() public isAdvancedButtonOnly = false;
  @Input() public advancedButtonWidth = '150px';
  @Input() public advancedButtonContextMenuItems: MenuItem[] = [];
  @Input() public showExclamationMark = false;
  @Input() public buttonWidth?: number;

  constructor(
    store: Store,
    private cdRef: ChangeDetectorRef,
    inputsHelperService: InputsHelperService,
    rendered: Renderer2,
    el: ElementRef,
  ) {
    super(store, inputsHelperService, rendered, el);
    this.trackIsUiBlocked();
  }

  private _isToggled = false;

  public get isToggled(): boolean {
    return this._isToggled && !this.isDisabled;
  }

  @Input()
  @HostBinding('class.toggled')
  public set isToggled(value: boolean) {
    this._isToggled = value;
  }

  public get isDisabled(): boolean {
    return this.disabled || (this.isUiLockable && this.isUiLocked);
  }

  @HostListener('document:keydown', ['$event'])
  public async onKeyDown(event: KeyboardEvent): Promise<void> {
    if (this.keyboardEnabled) {
      await this.onKeyPressed(event);
    }
  }

  private trackIsUiBlocked(): void {
    this.subscription.add(
      this.store.select(getIsUiLocked).subscribe((isUiBlocked) => {
        if (this.isUiLocked !== isUiBlocked) {
          this.isUiLocked = isUiBlocked;
          this.cdRef.markForCheck();
        }
      }),
    );
  }
}

export interface IAdvancedButtonActionItem {
  label: string;
  action: () => void;
}
