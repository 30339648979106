import { GaugeMeasurementType } from '../../../dto/running-string-pipe.dto';
import { TimeUnit, UnitSystem } from '../../../dto/unit-system.dto';
import { DataType } from '../../../dto/data-storage';
import { ScenarioConstants } from '../../scenario/scenario.dto';
import { EquationColumn, EquationColumnFactory } from '../../equation/equation-column';
import { UnitConverterHelper } from '../../../unit-converters/unit.converter.helper';
import { equationEvaluate, IEquationVariable, isEquationFormulaConstant } from '../../equation/equation-variable.dto';

export interface ImportColumnBaseDto {
  Id: number;
  ColumnName: string;
  GroupType: ImportColumnGroupType;
  DataType: DataType;
}

export interface ImportColumnDto extends ImportColumnBaseDto {
  IsXAxis: ArgumentType;
  FileId: number;
  MeasuredDepth: number;
  EndMeasuredDepth: number;
  MeasurementType: GaugeMeasurementType;
  UnitSystem: UnitSystem;
  Unit: number;
  SmoothingPoints: number;
  VerticalOffset: number;
  ScenarioId: number;
  SortOrder: number;
  CustomFormat: string | null;
  Multiplier: number;
  Equation: string | null;
  StartDate: number | null;
  UpdateDate: string;
}

/// need to find out that is that and convert db
export enum ImportColumnGroupType {
  None = -1,
  Miscellaneous = 0,
  Surface_Data = 1,
  Heel_Data = 2,
  Toe_Data = 3,
  Friction_Data = 4,
  Shunt_Data = 5,
  Pressure_Gauge_Data = 6,
  Temperature_Gauge_Data = 7,
  Section_Friction_Data = 8,
  Imported_Data = 9,
  Flow_Data = 10,
  Top_Perf_Data = 11,
  Bottom_Perf_Data = 12,
  Frac_Data = 13,
  Packer_Data = 14,
  Concentration_Gauge_Data = 15,
}

export enum ArgumentType {
  Value = 0,
  Primary_Argument = 1,
  Secondary_Argument = 2,
}

export enum GaugeAnnotation {
  AllGaugesBelowPacker = 6,
  AllSectionsBelowPacker = 7,
  BottomWorkstringInternalGauge = 2,
  TopWashpipeInternalGauge = 4,
  BottomWashpipeInternalGauge = 5,
  BottomWashpipeExternalGauge = 1,
  TopWashpipeExternalGauge = 0,
  BottomAnnulusExternalGauge = 3,
}

export class ImportColumnFactory {
  public static create(fileId: number): ImportColumnDto {
    return {
      ColumnName: '',
      CustomFormat: null,
      DataType: -1,
      EndMeasuredDepth: 0,
      FileId: fileId,
      GroupType: ImportColumnGroupType.None,
      Id: -1,
      IsXAxis: ArgumentType.Value,
      MeasuredDepth: 0,
      MeasurementType: -1,
      Multiplier: 1,
      ScenarioId: ScenarioConstants.EmptyScenarioId,
      SmoothingPoints: 0,
      SortOrder: 0,
      Unit: 0,
      UnitSystem: -1,
      VerticalOffset: 0,
      Equation: null,
      StartDate: null,
      UpdateDate: new Date().toISOString(),
    };
  }

  public static getPrimaryArgumentColumn(columns: ImportColumnDto[]): ImportColumnDto | undefined {
    return columns.find((col) => col.IsXAxis === ArgumentType.Primary_Argument);
  }

  public static areConstantValueOnlyEquationColumns(columns: ImportColumnDto[]): boolean {
    return columns.filter((col) => col.IsXAxis === ArgumentType.Value).every((col) => this.isConstantValueEquationColumn(col));
  }

  public static evaluateConstantValue(
    column: EquationColumn | ImportColumnDto,
    equationFormula: string,
    variables: IEquationVariable[],
  ): number | undefined {
    const constantValue = isEquationFormulaConstant(equationFormula, variables) ? equationEvaluate(equationFormula) : undefined;
    if (column.UnitSystem === -1) {
      return undefined;
    }
    const siConstantValue =
      constantValue != null ? UnitConverterHelper.convertToSiForUnit(column.UnitSystem, column.Unit, constantValue) : undefined;

    return siConstantValue;
  }

  public static isConstantValueEquationColumn(column: ImportColumnDto): boolean {
    if (column.IsXAxis !== ArgumentType.Value || column.Equation == null) {
      return false;
    }

    return EquationColumnFactory.getConstantValue(column) != null;
  }

  public static createXAxisColumn(fileId: number): ImportColumnDto {
    return {
      ...this.create(fileId),
      IsXAxis: ArgumentType.Primary_Argument,
      UnitSystem: UnitSystem.Time,
      Unit: TimeUnit.Second,
      ColumnName: 'Time',
    };
  }
}
