<div
  class="input-container"
  [class.error]="!!errorMessage"
  [class.warning]="!!warningMessage || warning"
  [class.disabled]="disabled"
  [class.grid-input]="isGridInput"
  [class.align-center]="align === 'center'"
  [class.align-left]="align === 'left'"
  (mouseleave)="onTooltipHidden()"
>
  <input
    *ngIf="!renderAsLabel"
    type="text"
    [value]="formattedValue()"
    (input)="onUserInput($event)"
    (keydown)="onInputKeyDown($event)"
    (keypress)="onInputKeyPress($event)"
    (keyup)="onInputKeyUp($event)"
    (keyup.enter)="onKeyupEnter()"
    (paste)="onPaste($event)"
    (click)="onInputClick()"
    (focus)="onInputFocus($event)"
    (blur)="onInputBlur($event)"
    tooltipPosition="top"
    positionStyle="absolute"
    [tooltipStyleClass]="tooltipStyle"
    [tooltipDisabled]="!tooltipText"
    [pTooltip]="tooltipText"
    [placeholder]="placeholder || ''"
    [class.no-border]="noBorderRadius"
    [class.disabled]="disabled"
    [class.character-count-input]="maxLength && isTextInput"
    [class.no-bg]="isGridInput"
    [attr.data-cy]="dataCy"
    [disabled]="disabled"
    [maxLength]="maxLength"
    [attr.id]="elementId"
    [style.max-width.px]="maxWidth"
    [style.width.px]="width"
    [class]="class"
    appInputGridNavigation
    (changeFocusCell)="changeFocusCell.emit($event)"
    #input
  />

  <i class="input-icon" *ngIf="!renderAsLabel && icon" [ngClass]="icon"></i>

  <span
    *ngIf="renderAsLabel"
    [tooltipDisabled]="!tooltipText"
    [pTooltip]="tooltipText"
    [tooltipStyleClass]="tooltipStyle"
    positionStyle="absolute"
    [attr.id]="elementId"
    [attr.data-cy]="dataCy"
    [style.text-align]="align || 'right'"
    #inputAsLabel
    >{{ textDisplayValue }}</span
  >
  <div
    *ngIf="!isGridInput && showSymbol"
    class="form-component-symbol"
    [class.no-unit]="!getSymbol().length"
    [attr.data-cy]="dataCy + '-unit-label'"
  >
    <span *ngIf="isSymbolVisible || forceShowSymbol" [innerHTML]="getSymbol()"></span>
  </div>
</div>
