import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges } from '@angular/core';
import { createTableRow, ITableRow, ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { ColumnType, IGridColumnConfig } from '../../../../../../shared/components/grid/grid.interfaces';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../../../common-modules/modals/modal.service';
import { GridConfig } from '../../../../../../shared/components/grid/grid-config';
import * as actions from '../../../../../../+store/fluid/fluid.actions';
import { RheometerConstants, RheometerTestType, SpeedType } from '@dunefront/common/modules/fluid/dto/rheometer.dto';
import { getSelectedFluidData, ISelectedFluidData } from '../../../../../../+store/fluid/fluid.selectors';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { GridContainerComponent } from '../../../../../../shared/components/grid/grid-container.component';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { RheometerReading } from '@dunefront/common/modules/fluid/model/rheometer-reading/rheometer-reading';
import { Rheometer } from '@dunefront/common/modules/fluid/model/rheometer/rheometer';
import { Fluid } from '@dunefront/common/modules/fluid/model/fluid';
import { RheometerReadingFactory } from '@dunefront/common/modules/fluid/model/rheometer-reading/rheometer-reading.factory';
import { GridResizeService } from '../../../../../../shared/services/grid-resize.service';
import { IDeleteRowsProps, IInsertRowsProps, IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { InsertLocation } from '@dunefront/common/modules/common.interfaces';

@Component({
  selector: 'app-rheometer-test-data-grid',
  templateUrl: './rheometer-test-data-grid.component.html',
  styleUrls: ['./rheometer-test-data-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RheometerTestDataGridComponent extends GridContainerComponent<RheometerReading> implements OnChanges {
  @Input() public rheometerReadingData: ITableState<RheometerReading> = {
    rows: [],
    isValid: false,
    error: '',
    selectedRowId: -1,
  };

  @Input() public rheometer!: ITableRow<Rheometer>;
  @Input() public fluid!: Fluid;

  public get isSpeedTypeCustom(): boolean {
    return this.rheometer?.rowData.SpeedType === SpeedType.Custom;
  }

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    protected modalService: ModalService,
    el: ElementRef,
    gridResizeService: GridResizeService,
  ) {
    super(store, cdRef, el, new RheometerReadingGridConfig(store, modalService), gridResizeService);
  }

  protected getColumns(): IGridColumnConfig<RheometerReading>[] {
    const isRheometerTestTypeShearRate = this.fluid?.RheometerTestType === RheometerTestType.Shear_Rate_Viscosity;
    const isSpeedTypeCustom = this.rheometer?.rowData.SpeedType === SpeedType.Custom ?? false;

    return [
      { disabled: false, visible: true, colId: ' ', type: ColumnType.selection },
      {
        disabled: !isRheometerTestTypeShearRate,
        visible: isRheometerTestTypeShearRate,
        colId: 'ShearRate',
        type: ColumnType.number,
        headerText: 'Shear Rate',
        unitSystem: UnitSystem.None,
        matchingStrings: ['shear', 'rate'],
        overrideUnitSymbol: '1/s',
      },
      {
        disabled: !isRheometerTestTypeShearRate,
        visible: isRheometerTestTypeShearRate,
        colId: 'Viscosity',
        type: ColumnType.number,
        unitSystem: UnitSystem.Viscosity,
        matchingStrings: ['visc'],
      },
      {
        disabled: isRheometerTestTypeShearRate || !isSpeedTypeCustom,
        visible: !isRheometerTestTypeShearRate,
        colId: 'RPM',
        type: ColumnType.number,
        unitSystem: UnitSystem.None,
        decimalPlaces: 1,
        matchingStrings: ['rpm'],
      },
      {
        disabled: isRheometerTestTypeShearRate,
        visible: !isRheometerTestTypeShearRate,
        colId: 'DialReading',
        type: ColumnType.number,
        headerText: 'Dial Reading',
        unitSystem: UnitSystem.None,
        matchingStrings: ['dial', 'read'],
      },
    ];
  }

  public async onDelete(): Promise<void> {
    await this.grid.onDeleteClicked();
  }
}

export class RheometerReadingGridConfig extends GridConfig<RheometerReading> {
  private fluidData!: ISelectedFluidData;

  constructor(
    protected store: Store,
    modalService: ModalService,
  ) {
    super(modalService);
    this.headerText = 'Test Data';
    this.subscription.add(notEmpty(store.select(getSelectedFluidData)).subscribe((selectedFluidData) => (this.fluidData = selectedFluidData)));
  }

  public override updateRowsAction(props: IUpdateTableRowsProps<RheometerReading>): void {
    this.store.dispatch(actions.updateRheometerReadingRow(props));
  }

  public override insertRowAction(props: IInsertRowsProps<RheometerReading>): void {
    this.store.dispatch(actions.insertRheometerReadingRow(props));
  }

  public override async canRowsBeDeleted(props: IDeleteRowsProps): Promise<boolean> {
    return true;
  }

  public override deleteRowsAction(props: IDeleteRowsProps): void {
    this.store.dispatch(actions.deleteRheometerReadingRows(props));
  }

  public override createEmptyModel(scenarioId: number): RheometerReading {
    return RheometerReadingFactory.createEmpty(scenarioId, this.fluidData.rheometer.rowData.Id);
  }

  public override createDefaultTableRows(scenarioId: number, noOfRows: number): ITableRow<RheometerReading>[] {
    const newTableRows = [];
    const rheometerNoOfRows =
      this.fluidData.rheometer.rowData.SpeedType === SpeedType.Six_Speed
        ? 6
        : this.fluidData.rheometer.rowData.SpeedType === SpeedType.Twelve_Speed
          ? 12
          : noOfRows;

    for (let index = 0; index < rheometerNoOfRows; index++) {
      newTableRows.push(createTableRow(this.createEmptyModel(scenarioId), 'data', index, false));
      if (this.fluidData.rheometer.rowData.SpeedType === SpeedType.Six_Speed) {
        newTableRows[index].rowData.RPM = RheometerConstants.SixSpeedRheometerReadings[index];
      } else if (this.fluidData.rheometer.rowData.SpeedType === SpeedType.Twelve_Speed) {
        newTableRows[index].rowData.RPM = RheometerConstants.TwelveSpeedRheometerReadings[index];
      }
    }

    return newTableRows;
  }

  public override replaceGridAction(rows: ITableRow<RheometerReading>[]): void {
    if (rows.length > 0) {
      const props: IInsertRowsProps<any> = {
        rows,
        refId: 0,
        insertLocation: 'replace' as InsertLocation,
        shouldResetResults: true,
      };
      this.store.dispatch(actions.insertRheometerReadingRow(props));
    }
  }
}
