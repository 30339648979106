import { ColumnType, IGridColumnConfig } from '../../../../../shared/components/grid/grid.interfaces';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { PumpingSchedule } from '@dunefront/common/modules/pumping/model/pumping-schedule/pumping-schedule';
import { ReportPumpingSchedule } from '../../../../../shared/helpers/document-generator/generators/doc/sections/pumping-report-generator.helper';

export const GRAVEL_TYPES_LOOKUP_SRC_ID = 'GRAVEL';
export const FLUID_TYPES_LOOKUP_SRC_ID = 'FLUID';

export const scheduleGridConfig = (
  isReadOnlyMode: boolean,
  showChokePressure: boolean,
  isAutoManagePressure: boolean,
  isFluidPro = false,
): IGridColumnConfig<PumpingSchedule | ReportPumpingSchedule>[] => [
  { disabled: isReadOnlyMode, visible: true, colId: ' ', type: ColumnType.selection },
  {
    disabled: isReadOnlyMode,
    visible: true,
    headerText: 'Stage Name',
    reportingHeaderName: 'Stage',
    type: ColumnType.text,
    unitSystem: UnitSystem.None,
    align: 'left',
    colId: 'Name',
    width: 150,
    placeholder: 'Enter Name',
    isNotUiLockable: true,
    matchingStrings: ['stage name'],
  },
  {
    disabled: isReadOnlyMode,
    visible: true,
    headerText: 'Pump Rate',
    type: ColumnType.number,
    unitSystem: UnitSystem.Rate,
    colId: 'PumpRate',
    matchingStrings: ['pump'],
  },
  {
    disabled: isReadOnlyMode,
    visible: true,
    headerText: 'Return Rate',
    type: ColumnType.number,
    unitSystem: UnitSystem.Rate,
    colId: 'ReturnRate',
    matchingStrings: ['return'],
  },
  {
    disabled: isReadOnlyMode,
    visible: true,
    headerText: 'Fluid Name',
    reportingHeaderName: 'Fluid',
    unitSystem: UnitSystem.None,
    colId: 'FluidId',
    lookupDataSourceType: FLUID_TYPES_LOOKUP_SRC_ID,
    type: ColumnType.select,
    width: 180,
    matchingStrings: ['fluid'],
  },
  {
    disabled: isReadOnlyMode,
    visible: !isFluidPro,
    headerText: 'Gravel Name',
    reportingHeaderName: 'Gravel',
    unitSystem: UnitSystem.None,
    colId: 'GravelId',
    lookupDataSourceType: GRAVEL_TYPES_LOOKUP_SRC_ID,
    type: ColumnType.select,
    width: 180,
    matchingStrings: ['gravel name'],
  },
  {
    disabled: isReadOnlyMode,
    visible: !isFluidPro,
    headerText: 'Gravel Conc.',
    type: ColumnType.number,
    unitSystem: UnitSystem.Solid_Concentration,
    colId: 'GravelConcentration',
    matchingStrings: ['conc'],
  },
  {
    disabled: isReadOnlyMode || isAutoManagePressure,
    visible: showChokePressure,
    headerText: 'Choke Pressure',
    type: ColumnType.number,
    unitSystem: UnitSystem.Pressure,
    colId: 'ChokePressure',
    matchingStrings: ['choke', 'pressure'],
  },
  {
    disabled: isReadOnlyMode,
    visible: true,
    headerText: 'Slurry Volume',
    reportingHeaderName: 'Slurry Vol.',
    type: ColumnType.number,
    unitSystem: UnitSystem.Liquid_Volume,
    colId: 'StageVolume',
    matchingStrings: ['slurry'],
  },
  {
    disabled: isReadOnlyMode,
    visible: true,
    headerText: 'Cumulative Volume',
    type: ColumnType.number,
    unitSystem: UnitSystem.Liquid_Volume,
    colId: 'CumulativeVolume',
    matchingStrings: ['cumulative vol'],
  },
  {
    disabled: isReadOnlyMode,
    visible: !isFluidPro,
    headerText: 'Stage Gravel Mass',
    type: ColumnType.number,
    unitSystem: UnitSystem.Mass,
    colId: 'StageGravelMass',
    matchingStrings: ['mass'],
  },
  {
    disabled: isReadOnlyMode,
    visible: !isFluidPro,
    headerText: 'Stage Gravel Rate',
    type: ColumnType.number,
    unitSystem: UnitSystem.Mass_Flow_Rate,
    colId: 'StageGravelRate',
    matchingStrings: ['gravel rate'],
  },
  {
    disabled: isReadOnlyMode,
    visible: true,
    headerText: 'Stage Time',
    type: ColumnType.number,
    unitSystem: UnitSystem.Time,
    colId: 'StageTime',
    matchingStrings: ['stage time'],
  },
  {
    disabled: isReadOnlyMode,
    visible: true,
    headerText: 'Cumulative Time',
    type: ColumnType.number,
    unitSystem: UnitSystem.Time,
    colId: 'CumulativeTime',
    matchingStrings: ['cumulative time'],
  },
];
