<app-generic-modal [title]="modalTitle" [modalButtonsConfigs]="modalButtonsConfigs">
  <app-page-panel class="options-panel" header="Production Coefficients">
    <div class="grid-form-container inline no-padding">
      <app-form-input
        appUnwrap
        label="A (Q^3)"
        [source]="screenPipeRow"
        [key]="'A_Coefficient_Production'"
        dataCy="a-coefficient-input"
        [unitType]="UnitType.Pressure_Rate_Cubed_Derivative"
        (valueChanged)="onValueChange($event)"
      ></app-form-input>
      <app-form-input
        appUnwrap
        label="B (Q^2)"
        [source]="screenPipeRow"
        [key]="'B_Coefficient_Production'"
        [unitType]="UnitType.Pressure_Rate_Squared_Derivative"
        (valueChanged)="onValueChange($event)"
      ></app-form-input>
      <app-form-input
        appUnwrap
        label="C (Q)"
        [source]="screenPipeRow"
        [key]="'C_Coefficient_Production'"
        [unitType]="UnitType.Pressure_Rate_Derivative"
        (valueChanged)="onValueChange($event)"
      ></app-form-input>
    </div>
  </app-page-panel>
  <app-page-panel class="options-panel" header="Injection Coefficients">
    <div class="grid-form-container inline no-padding">
      <app-form-input
        appUnwrap
        label="A (Q^3)"
        [source]="screenPipeRow"
        [key]="'A_Coefficient_Injection'"
        [unitType]="UnitType.Pressure_Rate_Cubed_Derivative"
        (valueChanged)="onValueChange($event)"
      ></app-form-input>
      <app-form-input
        appUnwrap
        label="B (Q^2)"
        [source]="screenPipeRow"
        [key]="'B_Coefficient_Injection'"
        [unitType]="UnitType.Pressure_Rate_Squared_Derivative"
        (valueChanged)="onValueChange($event)"
      ></app-form-input>
      <app-form-input
        appUnwrap
        label="C (Q)"
        [source]="screenPipeRow"
        [key]="'C_Coefficient_Injection'"
        [unitType]="UnitType.Pressure_Rate_Derivative"
        (valueChanged)="onValueChange($event)"
      ></app-form-input>
    </div>
  </app-page-panel>
</app-generic-modal>
