<app-page-panel header="Packing" *ngIf="settings && defaultSettings" [allowFullScreen]="false"  >
  <div class="grid-form-container">

    <ng-container *hideOnFluidPro>
      <div class="grid-two-one">Oroskar and Turian Diameter</div>
      <app-radio-group
        id="OroskarAndTurianDiameter"
        class="grid-two-one label-padding-left"
        [items]="[
        { value: 0, text: 'Equivalent Diameter' },
        { value: 1, text: 'Hydraulic Diameter' }
      ]"
        [source]="settings"
        [sourceDefaults]="defaultSettings"
        [key]="'OroskarAndTurianDiameter'"
        [warning]="settings.OroskarAndTurianDiameter !== defaultSettings.OroskarAndTurianDiameter"
        (valueChanged)="onValueChange($event)"
      >
      </app-radio-group>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Stokes Settling Multiplier"
        [source]="settings"
        [sourceDefaults]="defaultSettings"
        [key]="'StokesSettlingMultiplier'"
        [decimalPlaces]="4"
        [unitType]="UnitType.None"

        [warning]="settings.StokesSettlingMultiplier !== defaultSettings.StokesSettlingMultiplier"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Bridging Ratio"
        [source]="settings"
        [sourceDefaults]="defaultSettings"
        [key]="'BridgingRatio'"
        [decimalPlaces]="4"

        [unitType]="UnitType.None"
        [warning]="settings.BridgingRatio !== defaultSettings.BridgingRatio"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Max Perf Auto Pack Percentage"
        [source]="settings"
        [sourceDefaults]="defaultSettings"
        [key]="'PerfAutoPackPercentage'"
        [decimalPlaces]="4"
        [unitType]="UnitType.None"

        [warning]="settings.PerfAutoPackPercentage !== defaultSettings.PerfAutoPackPercentage"
        unitLabel="%"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Net Pressure for Annular Bridging"
        [source]="settings"
        [sourceDefaults]="defaultSettings"
        [key]="'NetPressureForBridging'"
        [decimalPlaces]="4"
        [unitType]="UnitType.Pressure"

        [warning]="settings.NetPressureForBridging !== defaultSettings.NetPressureForBridging"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <app-check-box
        [source]="settings"
        [sourceDefaults]="defaultSettings"
        [key]="'UseNumberOfGrainDiametersForBridging'"
        class="grid-two-one"
        label="Use Grain Diameter for Bridging"
        [warning]="settings.UseNumberOfGrainDiametersForBridging !== defaultSettings.UseNumberOfGrainDiametersForBridging"
        (valueChanged)="onValueChange($event)"
      >
      </app-check-box>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="No. of Grain Diameters"
        [labelPaddingLeft]="true"
        [source]="settings"
        [sourceDefaults]="defaultSettings"
        [key]="'NumberOfGrainDiametersToBridge'"
        [decimalPlaces]="0"

        [unitType]="UnitType.None"
        [disabled]="!settings.UseNumberOfGrainDiametersForBridging"
        [warning]="settings.UseNumberOfGrainDiametersForBridging && settings.NumberOfGrainDiametersToBridge !== defaultSettings.NumberOfGrainDiametersToBridge"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <app-check-box
        [source]="settings"
        [sourceDefaults]="defaultSettings"
        [key]="'DoesPackCureLosses'"
        class="grid-two-one"
        label="Pack Cure Losses"
        [warning]="settings.DoesPackCureLosses !== defaultSettings.DoesPackCureLosses"
        (valueChanged)="onValueChange($event)"
      >
      </app-check-box>
      <app-check-box
        [source]="settings"
        [sourceDefaults]="defaultSettings"
        [key]="'AreLossesRedistributed'"
        class="grid-two-one"
        label="Redistribute Losses (Specific Losses only)"
        [warning]="settings.AreLossesRedistributed !== defaultSettings.AreLossesRedistributed"
        (valueChanged)="onValueChange($event)"
      >
      </app-check-box>
    </ng-container>
    <app-check-box
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'ModelScreenHandlingLength'"
      class="grid-two-one"
      label="Model Screen Handling Length"
      [warning]="settings.ModelScreenHandlingLength !== defaultSettings.ModelScreenHandlingLength"
      (valueChanged)="onValueChange($event)"
    >
    </app-check-box>

    <ng-container *hideOnFluidPro>

      <div class="my-1">Shunt Nozzle Leakoff</div>
      <app-check-box
        [source]="settings"
        [sourceDefaults]="defaultSettings"
        [key]="'IsNozzleLeakoffConvergence'"
        class="grid-two-one label-padding-left"
        label="Enable Convergence"
        [warning]="settings.IsNozzleLeakoffConvergence !== defaultSettings.IsNozzleLeakoffConvergence"
        (valueChanged)="onValueChange($event)"
      >
      </app-check-box>
      <app-check-box
        [source]="settings"
        [sourceDefaults]="defaultSettings"
        [key]="'IsNozzleLeakoffBeta'"
        class="label-padding-left"
        label="Specify Forchheimer Beta"
        [warning]="settings.IsNozzleLeakoffBeta !== defaultSettings.IsNozzleLeakoffBeta"
        (valueChanged)="onValueChange($event)"
      >
      </app-check-box>
      <app-form-input
        [source]="settings"
        [sourceDefaults]="defaultSettings"
        [key]="'NozzleLeakoffBeta'"
        [disabled]="!settings.IsNozzleLeakoffBeta"
        [decimalPlaces]="4"
        [unitType]="UnitType.Reciprocal_Length"

        [warning]="settings.IsNozzleLeakoffBeta && settings.NozzleLeakoffBeta !== defaultSettings.NozzleLeakoffBeta"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
    </ng-container>
  </div>
</app-page-panel>
