import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PagePanelModule } from '../../../common-modules/page-panel/page-panel.component';
import { TabbedPageModule } from '../../../common-modules/tabbed-page/tabbed-page.component';
import { UnitsModule } from '../../../common-modules/units/units.module';
import { CompletionComponent } from './completion.component';
import { LowerCompletionComponent } from './lower-completion/lower-completion.component';
import { PipeDataComponent } from './lower-completion/pipe-data/pipe-data.component';
import { ScreenCentralizersComponent } from './lower-completion/screen-centralizers/screen-centralizers.component';
import { ScreenComponentComponent } from './lower-completion/screen-component/screen-component.component';
import { GridSelectComponentModule } from '../../../common-modules/grid-select/grid-select.component';
import { CheckBoxModule } from '../../../shared/components/form-components/check-box/check-box.component';
import { LowerCompletionGridComponent } from './lower-completion/lower-completion-grid/lower-completion-grid.component';
import { GridModule } from '../../../shared/components/grid/grid.module';
import { UnwrapDirectiveModule } from '../../../shared/directives/unwrap.directive';
import { FormInputModule } from '../../../shared/components/form-components/form-input/form-input.component';
import { RunningStringGridComponent } from './running-string/running-string-grid/running-string-grid.component';
import { WashpipePropertiesComponent } from './running-string/washpipe-properties/washpipe-properties.component';
import { WorkstringPropertiesComponent } from './running-string/workstring-properties/workstring-properties.component';
import { EccentricGaugeCarrierPropertiesComponent } from './running-string/gauge-carrier-properties/eccentric-gauge-carrier-properties/eccentric-gauge-carrier-properties.component';
import { ConcentricGaugeCarrierPropertiesComponent } from './running-string/gauge-carrier-properties/concentric-gauge-carrier-properties/concentric-gauge-carrier-properties.component';
import { ServiceToolPropertiesComponent } from './running-string/service-tool-properties/service-tool-properties.component';
import { RunningStringComponent } from './running-string/running-string.component';
import { PressureAttenuatorPropertiesComponent } from './running-string/pressure-attenuator-properties/pressure-attenuator-properties.component';
import { SystemConfigurationComponent } from './shunt-tubes/system-configuration/system-configuration.component';
import { RadioGroupModule } from '../../../shared/components/form-components/radio-group/radio-group.component';
import { ShuntTubesComponent } from './shunt-tubes/shunt-tubes.component';
import { ShroudPropertiesComponent } from './shunt-tubes/shroud-properties/shroud-properties.component';
import { NozzlePropertiesComponent } from './shunt-tubes/nozzle-properties/nozzle-properties.component';
import { PackingTubePropertiesComponent } from './shunt-tubes/packing-tube-properties/packing-tube-properties.component';
import { TransportTubePropertiesComponent } from './shunt-tubes/transport-tube-properties/transport-tube-properties.component';
import { PackingFactorPropertiesComponent } from './shunt-tubes/packing-factor/packing-factor.component';
import { SectionVolumeCalculatorComponent } from './volumes/section-volume-calculator/section-volume-calculator.component';
import { VolumesComponent } from './volumes/volumes.component';
import { GaugeSectionInfoGridComponent } from './volumes/gauge-section-info-grid/gauge-section-info-grid.component';
import { SchematicDataComponent } from './schematic/schematic-data/schematic-data.component';
import { SchematicComponent } from './schematic/schematic.component';
import { BhgeIcdOrAicdScreenModalComponent } from './lower-completion/screen-component/modals/bhge-icd-or-aicd-screen-modal/bhge-icd-or-aicd-screen.modal.component';
import { GenericIcdPortDataModalComponent } from './lower-completion/screen-component/modals/generic-icd-port-data-modal/generic-icd-port-data.modal.component';
import { IcdScreenComponentPropsComponent } from './lower-completion/screen-component/icd-screen-component-props/icd-screen-component-props.component';
import { GravelSelectorModule } from '../../common/gravel-selector/gravel-selector.module';
import { SelectModule } from '../../../shared/components/form-components/select/select.module';
import { ButtonModule } from '../../../shared/components/button/button.module';
import { TooltipModule } from 'primeng/tooltip';
import { RouterModule } from '@angular/router';
import { EqualizeSymbolsDirectiveModule } from '../../../shared/directives/equalize-symbols.directive';
import { ModalsModule } from '../../../common-modules/modals/modals.module';
import { HelpUrlPipeModule } from '../../../shared/pipes/help-url.pipe';
import { HideOnFluidProModule } from '../../../shared/directives/hide-on-fluidpro';

@NgModule({
  declarations: [
    RunningStringComponent,
    LowerCompletionComponent,
    ScreenCentralizersComponent,
    ScreenComponentComponent,
    CompletionComponent,
    PipeDataComponent,
    LowerCompletionGridComponent,
    RunningStringGridComponent,
    WashpipePropertiesComponent,
    WorkstringPropertiesComponent,
    EccentricGaugeCarrierPropertiesComponent,
    ConcentricGaugeCarrierPropertiesComponent,
    ServiceToolPropertiesComponent,
    PressureAttenuatorPropertiesComponent,
    ShuntTubesComponent,
    SystemConfigurationComponent,
    ShroudPropertiesComponent,
    NozzlePropertiesComponent,
    PackingTubePropertiesComponent,
    TransportTubePropertiesComponent,
    PackingFactorPropertiesComponent,
    VolumesComponent,
    SectionVolumeCalculatorComponent,
    GaugeSectionInfoGridComponent,
    PackingFactorPropertiesComponent,
    SchematicComponent,
    SchematicDataComponent,
    BhgeIcdOrAicdScreenModalComponent,
    GenericIcdPortDataModalComponent,
    IcdScreenComponentPropsComponent,
  ],
  imports: [
    GravelSelectorModule,
    PagePanelModule,
    CommonModule,
    TabbedPageModule,
    UnitsModule,
    FormsModule,
    GridSelectComponentModule,
    CheckBoxModule,
    GridModule,
    ButtonModule,
    SelectModule,
    UnwrapDirectiveModule,
    FormInputModule,
    RadioGroupModule,
    TooltipModule,
    RouterModule,
    EqualizeSymbolsDirectiveModule,
    ModalsModule,
    HelpUrlPipeModule,
    HideOnFluidProModule,
  ],
  exports: [SectionVolumeCalculatorComponent, SchematicComponent],
})
export class CompletionModule {}
