import { IIndexedDataType } from '../dto/common-dto.interfaces';
import { IError, ITypeWithError } from './common-state.interfaces';

export interface ITableState<T extends IIndexedDataType> extends IValidatedDataType {
  selectedRowId?: number;
  rows: ITableRow<T>[];
  error?: string;
  warning?: string;
}

export interface ITableRow<T> {
  rowType: GridRowType;
  isEditingDisabled: boolean;
  rowData: T;
  error: IError<T>;
  warning?: IError<T>;
  isValid: boolean;
  isWarning?: boolean;
  rowIndex: number;
}

export const isTableRowDataSource = <T>(src: (T & ITypeWithError<T>) | ITableRow<T>): src is ITableRow<T> => (src as any).rowData;

export interface IValidatedDataType {
  isValid: boolean;
  isWarning?: boolean;
}

export const implementsError = <T>(obj: any): obj is ITypeWithError<T> => obj.error !== undefined;
export const implementsWarning = <T>(obj: any): obj is ITypeWithError<T> => obj.warning !== undefined;

export const createTableState = <T extends IIndexedDataType>(rows: ITableRow<T>[], selectedRowId?: number, isValid = true): ITableState<T> => ({
  rows,
  selectedRowId,
  isValid,
});

export const createTableRow = <T>(
  rowData: T,
  rowType: GridRowType,
  rowIndex: number,
  isEditingDisabled = false,
  isValid = true,
): ITableRow<T> => ({
  rowData,
  rowType,
  isEditingDisabled,
  isValid,
  rowIndex,
  error: {} as IError<T>,
});

export type GridRowType = 'data' | 'first-row' | 'insert-row';

export const getRowsForCalculations: <T extends IIndexedDataType>(rows: ITableRow<T>[]) => T[] = <T extends IIndexedDataType>(
  rows: ITableRow<T>[],
) => getRowsWithoutInsertRow(rows).map((row) => row.rowData);

export const getRowsWithoutInsertRow = <T>(rows: ITableRow<T>[]): ITableRow<T>[] => rows.filter((row) => row.rowType !== 'insert-row');

export const getFirstDataRow: <T extends IIndexedDataType>(rows: ITableRow<T>[]) => ITableRow<T> | undefined = <T extends IIndexedDataType>(
  rows: ITableRow<T>[],
) => {
  const rowsWithoutInsertRow = getRowsWithoutInsertRow(rows);
  return rowsWithoutInsertRow.length > 0 ? rowsWithoutInsertRow[0] : undefined;
};

export const getLastDataRow: <T extends IIndexedDataType>(rows: ITableRow<T>[]) => ITableRow<T> | undefined = <T extends IIndexedDataType>(
  rows: ITableRow<T>[],
) => {
  const rowsWithoutInsertRow = getRowsWithoutInsertRow(rows);
  return rowsWithoutInsertRow.length > 0 ? rowsWithoutInsertRow[rowsWithoutInsertRow.length - 1] : undefined;
};
