import { NgModule } from '@angular/core';
import { FileDisplayNamePipe } from './file-display-name.pipe';
import { FileLocationPipe } from './file-location.pipe';
import { FileSizePipe } from './file-size.pipe';
import { HighlightPhrasePipePipe } from '../highlight-phrase.pipe';

@NgModule({
  declarations: [FileDisplayNamePipe, FileLocationPipe, FileSizePipe, HighlightPhrasePipePipe],
  exports: [FileDisplayNamePipe, FileLocationPipe, FileSizePipe, HighlightPhrasePipePipe],
})
export class FileDisplayNamePipeModule {}
