import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IFile, Repository } from '@dunefront/common/dto/file.dto';
import { FileJobTypesHelper } from '../../../../+store/calculation-engine/file-job-types-helper';
import { IFileJobTypes } from '../../../../+store/calculation-engine/files-jobs.selectors';
import { ASSETS_IMAGES_PATH } from '@dunefront/common/common/constants';

@Component({
  selector: 'app-files-list-file',
  templateUrl: 'file-list-file.component.html',
  styleUrls: ['./file-list-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileListFileComponent {
  @Input({ required: true }) public file!: IFile;
  @Input() public repository?: Repository;
  @Input() public isRecentMode = false;
  @Input() public showRowContextMenu = true;
  @Input() public searchPhrase: string | null = '';

  @Input() public allFilesJobTypes: IFileJobTypes[] = [];

  @Output() public contextMenuClick = new EventEmitter<{ file: IFile; event: MouseEvent }>();

  public isReadOnly(file: IFile): boolean {
    return !FileJobTypesHelper.canUpdateFile(file, this.allFilesJobTypes);
  }

  public getReadOnlyFileDescription(file: IFile): string {
    if (file.FileType === 'folder') {
      return 'Contains files with in progress actions';
    } else {
      return 'In progress: ' + FileJobTypesHelper.getJobTypesAsString(file, this.allFilesJobTypes);
    }
  }

  public get isFolder(): boolean {
    return this.file.FileType === 'folder';
  }

  public get iconPath(): string {
    const suffix = this.file.FileType === 'folder' ? '-folder' : '-file';
    const appIndicator = this.file.FileType === 'folder' ? 'blank' : this.file.Name.split('.').reverse()[0];
    return ASSETS_IMAGES_PATH + appIndicator + suffix + '.svg';
  }
}
