<app-page-panel header="Friction" *ngIf="settings && defaultSettings" [allowFullScreen]="false">
  <div class="grid-form-container">
    <ng-container *hideOnFluidPro>
      <app-form-input
        [source]="settings"
        [sourceDefaults]="defaultSettings"
        [key]="'DischargeCoefficient'"
        class="grid-two-one"
        appUnwrap
        label="Discharge Coefficient"
        [decimalPlaces]="4"

        [unitType]="UnitType.None"
        [warning]="settings.DischargeCoefficient !== defaultSettings.DischargeCoefficient"
        (valueChanged)="onValueChange($event)"
        dataCy="discharge-coefficient"
      >
      </app-form-input>
      <app-form-input
        [source]="settings"
        [sourceDefaults]="defaultSettings"
        [key]="'ICDRateDistributionMinTolerance'"
        class="grid-two-one"
        appUnwrap
        label="ICD Rate Distribution Min Tolerance"
        [decimalPlaces]="4"
        [unitType]="UnitType.Pressure"

        [warning]="settings.ICDRateDistributionMinTolerance !== defaultSettings.ICDRateDistributionMinTolerance"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
    </ng-container>

    <app-check-box
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'IsToolJointAnalysed'"
      class="grid-two-one"
      label="Analyze Tool Joints"
      [warning]="settings.IsToolJointAnalysed !== defaultSettings.IsToolJointAnalysed"
      (valueChanged)="onValueChange($event)"
      dataCy="isToolJointAnalysed-checkbox"
    >
    </app-check-box>
    <app-check-box
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'IsFrictionEccentric'"
      class="grid-two-one"
      label="Eccentric Friction"
      [warning]="settings.IsFrictionEccentric !== defaultSettings.IsFrictionEccentric"
      (valueChanged)="onValueChange($event)"
    >
    </app-check-box>
    <app-check-box
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'UseShuntVESFrictionCorrelations'"
      class="grid-two-one"
      label="Use Shunt Surfactant Gel Friction Correlations"
      [warning]="settings.UseShuntVESFrictionCorrelations !== defaultSettings.UseShuntVESFrictionCorrelations"
      (valueChanged)="onValueChange($event)"
      *hideOnFluidPro
    >
    </app-check-box>
    <app-check-box
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'IsRoughness'"
      class="grid-two-one"
      label="Use Roughness"
      [warning]="settings.IsRoughness !== defaultSettings.IsRoughness"
      (valueChanged)="onValueChange($event)"
    >
    </app-check-box>
    <app-form-input
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'TreatingLineRoughness'"
      class="grid-two-one"
      appUnwrap
      label="Treating Line Roughness"
      [labelPaddingLeft]="true"
      [decimalPlaces]="4"

      [unitType]="UnitType.Diameter"
      [warning]="settings.IsRoughness && settings.TreatingLineRoughness !== defaultSettings.TreatingLineRoughness"
      [disabled]="!settings.IsRoughness"
      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'ChokeLineRoughness'"
      class="grid-two-one"
      appUnwrap
      label="Choke Line Roughness"
      [labelPaddingLeft]="true"
      [decimalPlaces]="4"
      [unitType]="UnitType.Diameter"
      [warning]="settings.IsRoughness && settings.ChokeLineRoughness !== defaultSettings.ChokeLineRoughness"
      [disabled]="!settings.IsRoughness"

      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'WorkstringRoughness'"
      class="grid-two-one"
      appUnwrap
      label="Workstring Roughness"
      [labelPaddingLeft]="true"
      [decimalPlaces]="4"
      [unitType]="UnitType.Diameter"
      [warning]="settings.IsRoughness && settings.WorkstringRoughness !== defaultSettings.WorkstringRoughness"
      [disabled]="!settings.IsRoughness"

      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'ScreenAnnulusRoughness'"
      class="grid-two-one"
      appUnwrap
      label="Screen Annulus Roughness"
      [labelPaddingLeft]="true"
      [decimalPlaces]="4"
      [unitType]="UnitType.Diameter"
      [warning]="settings.IsRoughness && settings.ScreenAnnulusRoughness !== defaultSettings.ScreenAnnulusRoughness"
      [disabled]="!settings.IsRoughness"

      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'WashpipeRoughness'"
      class="grid-two-one"
      appUnwrap
      label="Washpipe Roughness"
      [labelPaddingLeft]="true"
      [decimalPlaces]="4"
      [unitType]="UnitType.Diameter"
      [warning]="settings.IsRoughness && settings.WashpipeRoughness !== defaultSettings.WashpipeRoughness"
      [disabled]="!settings.IsRoughness"

      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'BlankAnnulusRoughness'"
      class="grid-two-one"
      appUnwrap
      label="Blank Annulus Roughness"
      [labelPaddingLeft]="true"
      [decimalPlaces]="4"
      [unitType]="UnitType.Diameter"
      [warning]="settings.IsRoughness && settings.BlankAnnulusRoughness !== defaultSettings.BlankAnnulusRoughness"
      [disabled]="!settings.IsRoughness"

      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'WashpipeAnnulusRoughness'"
      class="grid-two-one"
      appUnwrap
      label="Washpipe Annulus Roughness"
      [labelPaddingLeft]="true"
      [decimalPlaces]="4"
      [unitType]="UnitType.Diameter"
      [warning]="settings.IsRoughness && settings.WashpipeAnnulusRoughness !== defaultSettings.WashpipeAnnulusRoughness"
      [disabled]="!settings.IsRoughness"

      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <app-form-input
      [source]="settings"
      [sourceDefaults]="defaultSettings"
      [key]="'WorkstringAnnulusRoughness'"
      class="grid-two-one"
      appUnwrap
      label="Workstring Annulus Roughness"
      [labelPaddingLeft]="true"
      [decimalPlaces]="4"
      [unitType]="UnitType.Diameter"
      [warning]="settings.IsRoughness && settings.WorkstringAnnulusRoughness !== defaultSettings.WorkstringAnnulusRoughness"
      [disabled]="!settings.IsRoughness"

      (valueChanged)="onValueChange($event)"
    >
    </app-form-input>
    <!--  This field will be used in the future  -->
    <!--    <app-form-input-->
    <!--      [source]="settings"-->
    <!--      [sourceDefaults]="defaultSettings"-->
    <!--      [key]="'ThirdAnnulusRoughness'"-->
    <!--      class="grid-two-one"-->
    <!--      appUnwrap-->
    <!--      label="Third Annulus Roughness"-->
    <!--      [labelPaddingLeft]="true"-->
    <!--      [decimalPlaces]="4"-->
    <!--      [unitType]="UnitType.Diameter"-->
    <!--      [highlight]="settings.IsRoughness && settings.ThirdAnnulusRoughness !== defaultSettings.ThirdAnnulusRoughness"-->
    <!--      [disabled]="!settings.IsRoughness"-->
    <!--      -->
    <!--      (valueChanged)="onValueChange($event)"-->
    <!--    >-->
    <!--    </app-form-input>-->
    <ng-container *hideOnFluidPro>

      <div class="grid-two-one">Washpipe Gauge Measurement Flow Path</div>
      <app-radio-group
        [source]="settings"
        [sourceDefaults]="defaultSettings"
        [key]="'WashpipeGaugeMeasurementFlowPath'"
        class="grid-two-one label-padding-left"
        [items]="[
        { value: 0, text: 'Internal (WP/BP)' },
        { value: 1, text: 'External (Screen/Wellbore)' }
      ]"
        [warning]="settings.WashpipeGaugeMeasurementFlowPath !== defaultSettings.WashpipeGaugeMeasurementFlowPath"
        id="WashpipeGaugeMeasurementFlowPath"
        (valueChanged)="onValueChange($event)"
      >
      </app-radio-group>
    </ng-container>
  </div>
</app-page-panel>
