import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { DbDependentComponent } from '../../../db-connection/db-dependent.component';
import { createSelector, Store } from '@ngrx/store';
import { ModalService } from '../../modal.service';
import { CreateEditCopyScenarioComponent } from './create-edit-copy-scenario/create-edit-copy-scenario.component';
import { deleteScenarioAction, updateScenarioOrder, updateScenariosSelection } from '../../../../+store/scenario/scenario.actions';
import { getAllScenarios, getScenarioState, getSelectedScenariosIds } from '../../../../+store/scenario/scenario.selectors';
import { isSimulateBased, ScenarioConstants } from '@dunefront/common/modules/scenario/scenario.dto';
import {
  CalculationContext,
  IScenarioAndRange,
  updateRefVariablesValidateAndAddCalculationToQueueAction,
} from '../../../../+store/calculation-engine/calculation-engine.actions';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { navigateToScenarioAction } from '../../../../+store/reporting/reporting.actions';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import {
  TreeListConfig,
  TreeSelectorListItem,
  UpdateTreeSelection,
} from '../../../../shared/components/filterable-tree/filterable-tree.component';
import { getCompletedResultsForCurrentModule } from '../../../../+store/calculation-engine/calculation-engine-results.selectors';
import { ExportDataComponent } from '../../export-data/export-data.component';

const getAllScenariosTreeItems = createSelector(getAllScenarios, (scenario) => {
  return scenario.map((s) => ({
    label: s.Name,
    value: s.Id,
    items: [],
  }));
});

const getSelectionState = createSelector(getSelectedScenariosIds, (selection) => ({
  GroupIds: selection,
  ItemIds: [],
}));

export const getScenariosOrder = createSelector(
  getScenarioState,
  (state): TreeListConfig => ({
    itemsOrder: state.scenarios.map((s) => s.Id),
  }),
);

@Component({
  selector: 'app-scenario-manager-basic-operations',
  templateUrl: './scenario-manager-basic-operations.component.html',
  styleUrls: ['./scenario-manager-basic-operations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioManagerBasicOperationsComponent extends DbDependentComponent implements OnDestroy {
  public isReorderOn = false;
  public isSimulateBased = isSimulateBased;

  public filter$ = new BehaviorSubject<string>('');
  public items$ = this.store.select(getAllScenariosTreeItems);
  public config$ = this.store.select(getScenariosOrder);
  public selection$ = this.store.select(getSelectionState);

  @Output() public simulationsStarted = new EventEmitter<void>();

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    private modalService: ModalService,
  ) {
    super(store, cdRef);
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store.dispatch(updateScenariosSelection({ selectedIds: [] }));
  }

  public async createScenario(): Promise<void> {
    const data = { mode: 'new' };
    this.modalService.open(CreateEditCopyScenarioComponent, data, 'scenario-create-modal', '400px');
  }

  public async editScenario(selectedScenariosIds: number[]): Promise<void> {
    const data = { mode: 'edit', selectedScenarioId: selectedScenariosIds[0] };
    this.modalService.open(CreateEditCopyScenarioComponent, data, 'scenario-create-modal', '400px');
  }

  public async copyScenario(selectedScenariosIds: number[]): Promise<void> {
    const data = { mode: 'copy', selectedScenarioId: selectedScenariosIds[0] };
    this.modalService.open(CreateEditCopyScenarioComponent, data, 'scenario-create-modal', '400px');
  }

  public async deleteScenarios(selectedScenariosIds: number[], scenarios: TreeSelectorListItem[]): Promise<void> {
    if (selectedScenariosIds.find((id) => id === this.currentScenarioId) != null) {
      const selectedScenarioName = scenarios.find((scenario) => scenario.value === this.currentScenarioId)?.label;
      await this.modalService.showAlert(`${selectedScenarioName} is the current working scenario and it can't be deleted.`);
      return;
    }
    const confirmResult = await this.modalService.showConfirm('All selected scenarios will be deleted. Do you want to continue?', 'Warning');
    if (confirmResult) {
      this.store.dispatch(
        deleteScenarioAction({
          rowIds: selectedScenariosIds,
          scenarioId: ScenarioConstants.EmptyScenarioId,
          shouldResetResults: false,
        }),
      );
      this.store.dispatch(updateScenariosSelection({ selectedIds: [] }));
    }
  }

  public async switchScenario(selectedScenariosIds: number[]): Promise<void> {
    this.store.dispatch(navigateToScenarioAction({ scenarioId: selectedScenariosIds[0] }));
  }

  public runSimulations(selectedScenariosIds: number[], scenarios: TreeSelectorListItem[]): void {
    if (!this.dbInfo) {
      return;
    }

    const scenariosAndRanges: IScenarioAndRange[] = scenarios
      .filter((scenario) => selectedScenariosIds.includes(scenario.value))
      .map((scenario) => ({
        scenarioId: scenario.value,
        rangeId: RangeConstants.EmptyRangeId,
      }));

    if (!isSimulateBased(this.currentAppModuleType)) {
      return;
    }

    this.store.dispatch(
      updateRefVariablesValidateAndAddCalculationToQueueAction({
        scenariosAndRanges,
        fileHash: this.dbInfo.fileHash,
        redirect: false,
        moduleType: this.currentAppModuleType,
        calculationContext: CalculationContext.SCENARIO_MANAGER,
        currentLicenseFeatures: this.currentLicenseFeatures,
      }),
    );

    this.simulationsStarted.emit();
  }

  public updateSelectedIds(selection: UpdateTreeSelection): void {
    const { GroupIds } = selection.selectionState;

    const sortedScenarios = selection.order.length ? selection.order.filter((r) => GroupIds.includes(r)) : GroupIds.sort();

    this.store.dispatch(updateScenariosSelection({ selectedIds: sortedScenarios }));
  }

  public onDropped(newSortOrder: number[]): void {
    this.store.dispatch(updateScenarioOrder({ order: newSortOrder }));
  }

  public async exportData(selectedScenariosIds: number[], scenarios: TreeSelectorListItem[]): Promise<void> {
    // list of results
    const resultsInfo = await firstValueFrom(this.store.select(getCompletedResultsForCurrentModule));

    // scenarios select items ( value, and label only ) for export
    const scenariosForExport = scenarios.filter((s) => selectedScenariosIds.includes(s.value));

    // scenarios that are not in resultsInfo
    const scenariosWithoutResults = scenariosForExport.filter((s) => resultsInfo.findIndex((result) => result.scenarioId === s.value) === -1);

    // if any selected scenario does not have result - show validation error and return
    if (scenariosWithoutResults.length !== 0) {
      let msg =
        'One or more of selected scenarios don’t have results. Please run the calculations for all selected options before exporting data.';
      msg += '<br/><br/>Scenarios without results:';
      scenariosWithoutResults.forEach((s) => (msg += '<br/> - ' + s.label));
      return await this.modalService.showAlert(msg);
    }

    this.modalService.open(ExportDataComponent, { scenariosForExport: scenariosForExport.map((s) => s.value) }, 'export-results-modal');
  }
}
