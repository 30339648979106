import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { Store } from '@ngrx/store';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { loadSummaryModuleAction } from '../../../../+store/summary/summary.actions';
import { getSummaryState } from '../../../../+store/summary/summary.selectors';
import { SummaryModuleState } from '../../../../+store/summary/summary.reducer';
import { getAreCurrentResultsPresent } from '../../../../+store/calculation-engine/calculation-engine-results.selectors';
import { Scenario } from '@dunefront/common/modules/scenario/scenario';
import { RangeConstants, RangeDto } from '@dunefront/common/dto/range.dto';
import { hideWarningMessagesTime } from '../../../../shared/helpers/e2e.helpers';
import { isRunningInCypress } from '../../../../shared/services/environment';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent extends DbDependentComponent implements OnInit {
  public summaryState!: SummaryModuleState;
  public getAreResultsPresent$ = this.store.select(getAreCurrentResultsPresent);

  constructor(store: Store, cdRef: ChangeDetectorRef) {
    super(store, cdRef);
    store.dispatch(uiActions.setResultsTabAction({ tab: 'simulation-summary' }));
    this.subscription.add(
      this.store.select(getSummaryState).subscribe((summaryState) => {
        // We need to hide simulation time for e2e tests. Otherwise we will have reports and screenshots mismatches.
        this.summaryState = isRunningInCypress()
          ? { ...summaryState, warningMessages: hideWarningMessagesTime(summaryState.warningMessages) }
          : summaryState;
        this.cdRef.markForCheck();
      }),
    );
    this.onHelpChange('results', 'summary');
  }

  public override onScenarioLoaded(currentScenario: Scenario): void {
    super.onScenarioLoaded(currentScenario);
    if (currentScenario.Id !== -1) {
      this.refreshContent();
    }
  }

  public override onCurrentScenarioChanged(currentScenario: Scenario): void {
    super.onCurrentScenarioChanged(currentScenario);
    if (currentScenario.Id !== -1) {
      this.refreshContent();
    }
  }

  protected override onRangeChanged(range: RangeDto | undefined): void {
    if (range != null && range.Id > RangeConstants.EntireRangeId) {
      this.refreshContent();
    }
  }

  private refreshContent(): void {
    this.store.dispatch(loadSummaryModuleAction());
  }
}
