<app-grid
  *ngIf="height > 0"
  [isFirstRowInsertingAllowed]="true"
  [isFirstRowDeleteAllowed]="true"
  [minRowCount]="1"
  [dataSource]="commonFluidData"
  [gridConfig]="gridConfig"
  [columns]="gridConfig.columns"
  [height]="height"
  [showCrudControls]="false"
  [singleRowSelectionMode]="true"
  (selectedRowsChanged)="selectedRowsChanged.emit($event)"
  [groupByColumns]="['commonDbType']"
  [customKeyboardHandlers]="customKeyboardHandlers"
  dataCy="database-fluids-grid"
></app-grid>
