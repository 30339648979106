<svg [class.expanded]="isExpanded" [class.home-page-mode]="isHomePageMode" width="162" height="40" viewBox="0 0 162 40"
     fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_1_92)">
    <path
      d="M59.6562 22.124V8.69069C59.6562 8.36567 59.9202 8.10168 60.2453 8.10168H65.0002C66.5593 8.10168 67.9057 8.41516 69.0391 9.04047C70.1726 9.66742 71.0569 10.5303 71.6905 11.6291C72.324 12.7279 72.6408 13.9885 72.6408 15.4074C72.6408 16.8262 72.324 18.0868 71.6905 19.1856C71.0569 20.2844 70.1726 21.1473 69.0391 21.7742C67.904 22.4012 66.5577 22.713 65.0002 22.713H60.2453C59.9202 22.713 59.6562 22.449 59.6562 22.124ZM63.1639 19.7696H64.9375C65.7443 19.7696 66.462 19.5964 67.0873 19.2483C67.7143 18.9001 68.2109 18.4002 68.5805 17.7452C68.9484 17.0919 69.1332 16.3115 69.1332 15.4074C69.1332 14.5032 68.9517 13.7063 68.5904 13.0596C68.229 12.4128 67.7308 11.9146 67.0972 11.5664C66.4637 11.2183 65.7443 11.0451 64.9359 11.0451H63.1622V19.7713L63.1639 19.7696Z"
      fill="white" />
    <path
      d="M79.6525 22.7121C78.5537 22.7121 77.6034 22.496 76.8032 22.0653C76.003 21.6347 75.3876 21.0606 74.9553 20.3429C74.5231 19.6268 74.3086 18.8151 74.3086 17.911V12.2519C74.3086 11.9763 74.533 11.752 74.8085 11.752H77.0441C77.3196 11.752 77.544 11.9763 77.544 12.2519V17.8268C77.544 18.203 77.6347 18.5478 77.8146 18.8596C77.9961 19.1731 78.2452 19.4239 78.5669 19.612C78.887 19.8001 79.2417 19.8941 79.6311 19.8941C80.0205 19.8941 80.3719 19.8001 80.6854 19.612C80.9989 19.4239 81.2447 19.1731 81.4262 18.8596C81.6077 18.5462 81.6984 18.2013 81.6984 17.8268V12.2519C81.6984 11.9763 81.9228 11.752 82.1983 11.752H84.4553C84.7309 11.752 84.9553 11.9763 84.9553 12.2519V17.911C84.9553 18.8151 84.7424 19.6268 84.3184 20.3429C83.8944 21.0606 83.2856 21.6347 82.492 22.0653C81.6984 22.496 80.7514 22.7121 79.6525 22.7121Z"
      fill="white" />
    <path
      d="M87.2524 22.7118C86.8762 22.7118 86.5693 22.4066 86.5693 22.0288V16.5528C86.5693 15.6619 86.7855 14.8551 87.2161 14.1308C87.6467 13.4082 88.2638 12.834 89.064 12.4083C89.8641 11.9843 90.8145 11.7715 91.9133 11.7715C93.0121 11.7715 93.9756 11.9843 94.7626 12.4083C95.548 12.8324 96.1535 13.4065 96.5791 14.1308C97.0032 14.8551 97.216 15.6619 97.216 16.5528V22.0288C97.216 22.405 96.9108 22.7118 96.533 22.7118H94.6405C94.2644 22.7118 93.9575 22.4066 93.9575 22.0288V16.637C93.9575 16.2608 93.8668 15.9209 93.6853 15.6141C93.5038 15.3072 93.2579 15.058 92.9445 14.8617C92.631 14.667 92.273 14.5697 91.8687 14.5697C91.4645 14.5697 91.1246 14.6637 90.8046 14.8518C90.4845 15.0399 90.2337 15.294 90.0522 15.6141C89.8707 15.9341 89.7816 16.2757 89.7816 16.637V22.0288C89.7816 22.405 89.4764 22.7118 89.0986 22.7118H87.2491H87.2524Z"
      fill="white" />
    <path
      d="M104.625 22.7124C103.414 22.7124 102.35 22.4864 101.431 22.0343C100.512 21.5822 99.7957 20.952 99.2809 20.1452C98.7662 19.3384 98.5088 18.4128 98.5088 17.3684C98.5088 16.1987 98.7563 15.1939 99.2496 14.3525C99.7429 13.511 100.398 12.8676 101.211 12.4221C102.026 11.9766 102.934 11.7539 103.935 11.7539C105.062 11.7539 106.003 11.9915 106.753 12.4634C107.506 12.9369 108.075 13.5836 108.466 14.4053C108.855 15.2269 109.05 16.1525 109.05 17.182C109.05 17.3767 109.04 17.5928 109.019 17.8288C109.019 17.8304 109.019 17.8337 109.019 17.8354C108.99 18.1521 108.718 18.3914 108.4 18.3914H101.911C102.021 18.7675 102.19 19.0777 102.412 19.3203C102.635 19.5644 102.93 19.7443 103.3 19.8631C103.668 19.9819 104.082 20.0396 104.542 20.0396H107.134C107.491 20.0396 107.779 20.3283 107.779 20.6847V22.0673C107.779 22.4237 107.491 22.7124 107.134 22.7124H104.627H104.625ZM101.848 16.367H105.918C105.89 16.1442 105.846 15.9281 105.783 15.7202C105.72 15.5107 105.636 15.3275 105.532 15.1675C105.428 15.0075 105.295 14.8639 105.136 14.7402C104.976 14.6148 104.792 14.5208 104.584 14.4581C104.376 14.3954 104.151 14.364 103.915 14.364C103.595 14.364 103.31 14.4234 103.059 14.5406C102.808 14.6593 102.6 14.8095 102.432 14.9893C102.266 15.1708 102.137 15.382 102.046 15.6262C101.955 15.8704 101.889 16.1162 101.848 16.367Z"
      fill="white" />
    <path
      d="M111.467 22.7126C110.995 22.7126 110.612 22.3298 110.612 21.858V12.4421C110.612 11.6205 110.81 10.8863 111.208 10.2395C111.604 9.59275 112.14 9.07469 112.815 8.68367C113.49 8.2943 114.245 8.09961 115.08 8.09961H119.797C120.19 8.09961 120.508 8.41804 120.508 8.81071V10.3335C120.508 10.7262 120.19 11.0446 119.797 11.0446H115.611C115.126 11.0446 114.648 11.2542 114.369 11.6534C114.204 11.8894 114.12 12.16 114.12 12.4652V14.384C114.12 14.4896 114.206 14.5737 114.31 14.5737H119.215C119.548 14.5737 119.819 14.8443 119.819 15.1776V16.8704C119.819 17.2036 119.548 17.4742 119.215 17.4742H114.31C114.204 17.4742 114.12 17.56 114.12 17.664V21.8596C114.12 22.3315 113.737 22.7142 113.265 22.7142H111.467V22.7126Z"
      fill="white" />
    <path
      d="M122.163 22.713C121.789 22.713 121.485 22.4094 121.485 22.0349V15.1549C121.485 14.1815 121.764 13.4159 122.32 12.8583C122.876 12.3006 123.648 12.0234 124.637 12.0234H127.349C127.58 12.0234 127.768 12.2115 127.768 12.4425V14.2343C127.768 14.4653 127.58 14.6533 127.349 14.6533H125.452C125.257 14.6533 125.09 14.7193 124.95 14.8513C124.81 14.9833 124.741 15.1533 124.741 15.3628V22.0333C124.741 22.4078 124.437 22.7114 124.062 22.7114H122.162L122.163 22.713Z"
      fill="white" />
    <path
      d="M134.442 22.8379C133.343 22.8379 132.355 22.5937 131.477 22.107C130.601 21.6203 129.905 20.9521 129.39 20.1024C128.876 19.2544 128.618 18.2942 128.618 17.2217C128.618 16.1493 128.876 15.2138 129.39 14.3724C129.905 13.531 130.605 12.866 131.487 12.3793C132.372 11.8926 133.355 11.6484 134.441 11.6484C135.526 11.6484 136.524 11.8926 137.394 12.3793C138.263 12.866 138.953 13.531 139.461 14.3724C139.969 15.2155 140.223 16.1708 140.223 17.2432C140.223 18.3156 139.969 19.2544 139.461 20.1024C138.953 20.9521 138.263 21.6203 137.394 22.107C136.524 22.5937 135.539 22.8379 134.441 22.8379H134.442ZM134.421 20.0199C134.936 20.0199 135.384 19.8945 135.767 19.6438C136.15 19.393 136.445 19.0597 136.655 18.6423C136.863 18.2249 136.968 17.7513 136.968 17.2234C136.968 16.6954 136.864 16.2417 136.655 15.8243C136.445 15.4069 136.15 15.0719 135.767 14.8228C135.384 14.572 134.936 14.4466 134.421 14.4466C133.906 14.4466 133.479 14.572 133.096 14.8228C132.713 15.0736 132.418 15.4069 132.208 15.8243C131.999 16.2417 131.895 16.7086 131.895 17.2234C131.895 17.7381 131.999 18.2249 132.208 18.6423C132.418 19.0597 132.713 19.3946 133.096 19.6438C133.479 19.8945 133.921 20.0199 134.421 20.0199Z"
      fill="white" />
    <path
      d="M142.563 22.7121C142.18 22.7121 141.87 22.402 141.87 22.0192V16.5548C141.87 15.6639 142.086 14.8571 142.517 14.1328C142.947 13.4101 143.565 12.836 144.365 12.4103C145.165 11.9863 146.115 11.7734 147.214 11.7734C148.313 11.7734 149.276 11.9863 150.063 12.4103C150.849 12.8343 151.454 13.4085 151.88 14.1328C152.304 14.8571 152.517 15.6639 152.517 16.5548V22.0192C152.517 22.402 152.207 22.7121 151.824 22.7121H149.955C149.572 22.7121 149.262 22.402 149.262 22.0192V16.6389C149.262 16.2628 149.171 15.9229 148.989 15.616C148.808 15.3091 148.562 15.06 148.249 14.8637C147.935 14.669 147.577 14.5716 147.173 14.5716C146.769 14.5716 146.429 14.6657 146.109 14.8538C145.789 15.0418 145.538 15.2959 145.356 15.616C145.175 15.9361 145.086 16.2776 145.086 16.6389V22.0192C145.086 22.402 144.776 22.7121 144.393 22.7121H142.565H142.563Z"
      fill="white" />
    <path
      d="M153.804 14.3049V12.3531C153.804 12.16 153.96 12.0033 154.153 12.0033H161.406C161.599 12.0033 161.756 12.16 161.756 12.3531V14.3049C161.756 14.4979 161.599 14.6546 161.406 14.6546H154.153C153.96 14.6546 153.804 14.4979 153.804 14.3049ZM158.709 22.7127C157.721 22.7127 156.948 22.4305 156.392 21.8679C155.835 21.3037 155.558 20.5431 155.558 19.5828V9.96404C155.558 9.65057 155.812 9.39648 156.125 9.39648H158.245C158.559 9.39648 158.813 9.65057 158.813 9.96404V19.3799C158.813 19.5366 158.867 19.6934 158.974 19.8072C159.095 19.9359 159.243 20.0003 159.418 20.0003H161.189C161.502 20.0003 161.756 20.2543 161.756 20.5678V22.1468C161.756 22.4602 161.502 22.7143 161.189 22.7143H158.709V22.7127Z"
      fill="white" />
    <path class="d-logo"
          d="M49.7408 9.55445C48.025 6.57642 45.631 4.24019 42.5606 2.54411C39.4868 0.848038 35.8406 0 31.6202 0H18.8881C17.9279 0 17.1475 0.778743 17.1475 1.74062V9.5165C17.1475 10.6681 17.8173 11.7009 18.8501 12.2091C18.8633 12.2157 18.8765 12.2223 18.8881 12.2289C20.0677 12.8163 21.485 12.6001 22.3974 11.6498C23.4038 10.6021 24.6049 9.74254 25.9281 9.10238C28.4673 7.87487 31.4486 7.97387 31.4486 7.97387C37.7941 7.97387 42.8113 13.8474 42.8113 19.7722C42.8113 25.1392 38.3022 31.5704 31.4486 31.5704C31.4486 31.5704 28.4656 31.6694 25.9281 30.4419C24.5901 29.7952 23.3791 28.9257 22.3677 27.8632C21.4767 26.9277 20.0793 26.7148 18.926 27.2956C18.9128 27.3022 18.9013 27.3088 18.8881 27.3154C17.8338 27.8401 17.1475 28.8927 17.1475 30.069V37.7344C17.1475 38.7326 17.9576 39.5427 18.9557 39.5427H31.6202C35.8406 39.5427 39.4868 38.6946 42.5589 36.9985C45.6293 35.3025 48.0233 32.9662 49.7392 29.9882C51.4518 27.0168 52.308 23.6098 52.3113 19.7738C52.308 15.9378 51.4518 12.5325 49.7392 9.5594L49.7408 9.55445Z"
          fill="white" />
    <path class="d-logo"
          d="M14.6856 24.5391C17.2971 24.5391 19.4141 22.4221 19.4141 19.8106C19.4141 17.1991 17.2971 15.082 14.6856 15.082C12.0741 15.082 9.95703 17.1991 9.95703 19.8106C9.95703 22.4221 12.0741 24.5391 14.6856 24.5391Z"
          fill="white" />
    <path class="d-logo"
          d="M3.16117 22.9708C4.90703 22.9708 6.32234 21.5555 6.32234 19.8096C6.32234 18.0637 4.90703 16.6484 3.16117 16.6484C1.4153 16.6484 0 18.0637 0 19.8096C0 21.5555 1.4153 22.9708 3.16117 22.9708Z"
          fill="white" />
    <path class="d-logo"
          d="M30.9204 27.6826C35.2678 27.6826 38.792 24.1584 38.792 19.811C38.792 15.4637 35.2678 11.9395 30.9204 11.9395C26.5731 11.9395 23.0488 15.4637 23.0488 19.811C23.0488 24.1584 26.5731 27.6826 30.9204 27.6826Z"
          fill="white" />
    <path d="M61.6149 35.129V29.9038H59.9072V29.168H64.1227V29.9038H62.415V35.129H61.6149Z" fill="white" />
    <path
      d="M65.0498 35.1287V28.9961H65.8335V31.7761L65.6718 31.6095C65.8599 31.3406 66.0859 31.1426 66.3516 31.0155C66.6172 30.8885 66.8927 30.8258 67.1798 30.8258C67.5576 30.8258 67.8711 30.9017 68.1235 31.0535C68.3743 31.2053 68.5624 31.4 68.6878 31.6392C68.8132 31.8784 68.8759 32.1226 68.8759 32.3767V35.127H68.0922V32.606C68.0922 32.2942 67.9932 32.0401 67.7936 31.8454C67.5956 31.6508 67.3283 31.5534 66.9934 31.5534C66.7723 31.5534 66.5743 31.6029 66.3994 31.7002C66.2245 31.7976 66.0876 31.9362 65.9869 32.116C65.8863 32.2959 65.8351 32.5021 65.8351 32.738V35.127H65.0515L65.0498 35.1287Z"
      fill="white" />
    <path
      d="M72.0632 35.2584C71.6211 35.2584 71.2301 35.166 70.8902 34.9812C70.5503 34.7964 70.2863 34.539 70.0982 34.209C69.9101 33.8791 69.8145 33.493 69.8145 33.0525C69.8145 32.734 69.8689 32.4387 69.9761 32.1665C70.0834 31.8943 70.2368 31.6583 70.4365 31.4603C70.6344 31.2623 70.8671 31.1073 71.1327 30.9967C71.3983 30.8862 71.6871 30.8301 71.9989 30.8301C72.3371 30.8301 72.639 30.8895 72.903 31.0083C73.167 31.1271 73.3881 31.2937 73.563 31.5082C73.7379 31.7227 73.8633 31.9734 73.9392 32.2622C74.015 32.5509 74.0315 32.8627 73.9853 33.201H70.5866C70.5783 33.4798 70.6344 33.7223 70.7565 33.9319C70.8786 34.1397 71.0552 34.3031 71.2861 34.4202C71.5171 34.5374 71.7927 34.5951 72.1094 34.5951C72.3421 34.5951 72.5698 34.5588 72.7908 34.4846C73.0119 34.4103 73.2083 34.3014 73.3782 34.1562L73.7098 34.7403C73.5729 34.8508 73.4128 34.9449 73.2264 35.0241C73.04 35.1016 72.8469 35.161 72.6456 35.2006C72.4444 35.2402 72.248 35.26 72.0583 35.26L72.0632 35.2584ZM70.6328 32.5921H73.205C73.1934 32.2407 73.0845 31.9652 72.8766 31.7689C72.6687 31.5709 72.3734 31.4735 71.9873 31.4735C71.6013 31.4735 71.3026 31.5725 71.0585 31.7689C70.8143 31.9668 70.6724 32.2407 70.6328 32.5921Z"
      fill="white" />
    <path
      d="M79.1451 35.2564C78.7442 35.2564 78.3531 35.2003 77.972 35.0881C77.5909 34.9759 77.2692 34.8142 77.0068 34.6047L77.4111 33.9794C77.5579 34.1015 77.7262 34.2054 77.9143 34.2896C78.1024 34.3754 78.3003 34.4397 78.5099 34.4859C78.7194 34.5321 78.9322 34.5536 79.1484 34.5536C79.5691 34.5536 79.9073 34.4711 80.1664 34.3061C80.4254 34.1411 80.5541 33.9002 80.5541 33.5818C80.5541 33.3492 80.4683 33.1528 80.295 32.9928C80.1218 32.8327 79.8017 32.6843 79.3365 32.5473L78.6897 32.3592C78.142 32.2008 77.7427 31.9913 77.4903 31.7339C77.2378 31.4765 77.1141 31.1499 77.1141 30.7588C77.1141 30.4982 77.1652 30.2622 77.2692 30.051C77.3731 29.8415 77.52 29.66 77.7113 29.5082C77.9027 29.3564 78.1288 29.241 78.3878 29.1618C78.6468 29.0826 78.9322 29.043 79.2424 29.043C79.6252 29.043 79.9832 29.0991 80.3132 29.2096C80.6432 29.3202 80.9171 29.4686 81.1332 29.6567L80.7207 30.2573C80.5937 30.15 80.4468 30.056 80.2818 29.9785C80.1169 29.9009 79.942 29.8415 79.7539 29.8003C79.5658 29.759 79.3761 29.7376 79.183 29.7376C78.924 29.7376 78.6963 29.7755 78.5 29.8531C78.302 29.929 78.1486 30.0411 78.0397 30.1847C77.9308 30.3299 77.8763 30.5064 77.8763 30.7176C77.8763 30.8661 77.911 30.9948 77.9836 31.107C78.0562 31.2192 78.1783 31.3215 78.3548 31.4155C78.5313 31.5095 78.7739 31.6019 79.0824 31.6927L79.772 31.8973C80.3033 32.054 80.6927 32.2586 80.9435 32.5127C81.1926 32.7667 81.318 33.1 81.318 33.5108C81.318 33.8573 81.2338 34.1625 81.0639 34.4249C80.8956 34.6872 80.6481 34.8918 80.3247 35.0386C80.0014 35.1855 79.607 35.2581 79.1451 35.2581V35.2564Z"
      fill="white" />
    <path
      d="M83.5965 35.2574C83.3045 35.2574 83.0487 35.2029 82.8293 35.0957C82.6099 34.9884 82.4416 34.835 82.3211 34.6387C82.2007 34.4407 82.1396 34.208 82.1396 33.9375C82.1396 33.7164 82.1974 33.5134 82.3129 33.3303C82.4284 33.1472 82.6066 32.9954 82.8507 32.8749C83.0949 32.7545 83.4117 32.6737 83.8011 32.6324C84.1904 32.5912 84.659 32.5994 85.2101 32.6555L85.2315 33.2297C84.8059 33.1736 84.4445 33.1505 84.1459 33.1637C83.8473 33.1769 83.6064 33.2165 83.4216 33.2825C83.2368 33.3485 83.1015 33.4376 83.0157 33.5464C82.9299 33.6553 82.8854 33.7791 82.8854 33.9144C82.8854 34.1338 82.9646 34.3037 83.1213 34.4275C83.2797 34.5512 83.4958 34.6123 83.7714 34.6123C84.0073 34.6123 84.2185 34.5694 84.4033 34.4819C84.5897 34.3961 84.7366 34.274 84.8438 34.1206C84.951 33.9672 85.0055 33.7857 85.0055 33.5811V32.4179C85.0055 32.2199 84.9626 32.0467 84.8784 31.9032C84.7943 31.7596 84.6673 31.6458 84.499 31.5666C84.3307 31.4874 84.1261 31.4478 83.8819 31.4478C83.6608 31.4478 83.4431 31.4808 83.2286 31.5484C83.0141 31.6161 82.8078 31.7217 82.6082 31.8652L82.369 31.2597C82.628 31.0832 82.8936 30.9594 83.1692 30.8885C83.4447 30.8175 83.7087 30.7812 83.9611 30.7812C84.3439 30.7812 84.6706 30.8423 84.9411 30.9627C85.2101 31.0832 85.4163 31.2647 85.5582 31.5055C85.7001 31.7464 85.771 32.0484 85.771 32.408V35.1254H85.0467V34.6139C84.9015 34.8218 84.7019 34.9802 84.4462 35.0891C84.1904 35.198 83.9067 35.2524 83.5948 35.2524L83.5965 35.2574Z"
      fill="white" />
    <path
      d="M87.0661 35.1297V30.9571H87.8498V31.5197C88.028 31.2755 88.2375 31.099 88.4751 30.9918C88.7143 30.8845 88.9585 30.8301 89.2076 30.8301C89.5855 30.8301 89.8989 30.906 90.1464 31.0578C90.3956 31.2096 90.5803 31.4042 90.7041 31.6385C90.8278 31.8745 90.8889 32.1153 90.8889 32.3628V35.1313H90.1052V32.607C90.1052 32.2919 90.0078 32.0378 89.8115 31.8464C89.6152 31.655 89.3495 31.5593 89.0113 31.5593C88.7902 31.5593 88.5922 31.6088 88.4174 31.7078C88.2425 31.8068 88.1039 31.9454 88.0016 32.1236C87.8993 32.3018 87.8481 32.508 87.8481 32.7423V35.1313H87.0645L87.0661 35.1297Z"
      fill="white" />
    <path
      d="M93.9307 35.2577C93.5166 35.2577 93.1519 35.1603 92.8368 34.9657C92.5217 34.771 92.2759 34.507 92.0977 34.1721C91.9195 33.8371 91.832 33.461 91.832 33.0436C91.832 32.7367 91.8832 32.4496 91.9838 32.184C92.0845 31.9183 92.228 31.6824 92.4161 31.4795C92.6042 31.2765 92.8253 31.1181 93.0826 31.0027C93.34 30.8872 93.6255 30.8294 93.9406 30.8294C94.3415 30.8294 94.683 30.9103 94.9685 31.0719C95.2539 31.2336 95.4882 31.4514 95.673 31.7236L95.5146 31.8985V28.998H96.2983V35.1306H95.5789V34.1506L95.6944 34.3288C95.5327 34.5961 95.31 34.8172 95.0278 34.9937C94.7457 35.1702 94.3795 35.2577 93.9307 35.2577ZM94.0709 34.5515C94.3514 34.5515 94.6005 34.4905 94.8183 34.3668C95.0361 34.243 95.206 34.0681 95.3314 33.8437C95.4568 33.6177 95.5195 33.3521 95.5195 33.0452C95.5195 32.7383 95.4568 32.471 95.3314 32.245C95.206 32.0173 95.0361 31.8441 94.8183 31.722C94.6005 31.5999 94.3514 31.5389 94.0709 31.5389C93.7904 31.5389 93.5413 31.5999 93.3235 31.722C93.1057 31.8441 92.9358 32.019 92.8121 32.245C92.6883 32.4727 92.6273 32.7383 92.6273 33.0452C92.6273 33.3521 92.6883 33.6177 92.8121 33.8437C92.9358 34.0698 93.1074 34.2447 93.3252 34.3668C93.543 34.4905 93.7921 34.5515 94.0709 34.5515Z"
      fill="white" />
    <path
      d="M102.605 35.2578C101.986 35.2578 101.446 35.1291 100.986 34.8717C100.526 34.6143 100.169 34.253 99.9153 33.7861C99.6612 33.3192 99.5342 32.773 99.5342 32.1494C99.5342 31.6973 99.6101 31.2832 99.7602 30.9037C99.9104 30.5243 100.123 30.1959 100.399 29.9171C100.674 29.6383 100.999 29.4238 101.374 29.2703C101.748 29.1169 102.161 29.041 102.61 29.041C103.058 29.041 103.434 29.0971 103.763 29.2077C104.091 29.3182 104.377 29.4749 104.614 29.6762L104.188 30.3279C103.992 30.1547 103.768 30.026 103.514 29.9402C103.26 29.8544 102.977 29.8132 102.669 29.8132C102.319 29.8132 102.002 29.8676 101.719 29.9748C101.435 30.0821 101.192 30.2388 100.993 30.4418C100.793 30.6447 100.638 30.8889 100.531 31.176C100.423 31.463 100.369 31.7864 100.369 32.1461C100.369 32.6378 100.465 33.0568 100.656 33.4066C100.847 33.7564 101.12 34.022 101.471 34.2051C101.823 34.3883 102.238 34.4807 102.718 34.4807C103.029 34.4807 103.326 34.4345 103.611 34.3421C103.896 34.2497 104.164 34.0979 104.415 33.8884L104.789 34.5698C104.497 34.7991 104.17 34.9723 103.807 35.0878C103.446 35.2033 103.045 35.2594 102.605 35.2594V35.2578Z"
      fill="white" />
    <path
      d="M107.587 35.258C107.155 35.258 106.771 35.1574 106.432 34.9561C106.094 34.7548 105.828 34.4859 105.635 34.151C105.442 33.8161 105.347 33.4465 105.347 33.0439C105.347 32.7453 105.403 32.4632 105.513 32.1959C105.624 31.9286 105.781 31.6927 105.982 31.4864C106.183 31.2802 106.421 31.1202 106.693 31.003C106.965 30.8859 107.265 30.8281 107.592 30.8281C108.024 30.8281 108.409 30.9288 108.747 31.1301C109.085 31.3313 109.351 31.6003 109.544 31.9352C109.737 32.2701 109.833 32.6397 109.833 33.0423C109.833 33.3409 109.778 33.623 109.666 33.8903C109.556 34.1576 109.399 34.3935 109.197 34.5981C108.996 34.8027 108.759 34.9627 108.486 35.0815C108.214 35.1987 107.914 35.258 107.587 35.258ZM107.592 34.5519C107.871 34.5519 108.118 34.4909 108.335 34.3671C108.552 34.2434 108.722 34.0685 108.848 33.8441C108.973 33.6181 109.036 33.3524 109.036 33.0456C109.036 32.7387 108.975 32.4714 108.851 32.2454C108.727 32.0177 108.556 31.8445 108.338 31.7224C108.118 31.6003 107.869 31.5392 107.589 31.5392C107.308 31.5392 107.059 31.6003 106.841 31.7224C106.624 31.8445 106.454 32.0193 106.33 32.2454C106.206 32.4731 106.145 32.7387 106.145 33.0456C106.145 33.3524 106.206 33.6181 106.33 33.8441C106.454 34.0701 106.625 34.245 106.843 34.3671C107.061 34.4909 107.312 34.5519 107.592 34.5519Z"
      fill="white" />
    <path
      d="M110.814 35.1297V30.9571H111.598V31.5197C111.776 31.2755 111.986 31.099 112.223 30.9918C112.462 30.8845 112.707 30.8301 112.956 30.8301C113.334 30.8301 113.647 30.906 113.894 31.0578C114.144 31.2096 114.328 31.4042 114.452 31.6385C114.576 31.8745 114.637 32.1153 114.637 32.3628V35.1313H113.853V32.607C113.853 32.2919 113.756 32.0378 113.56 31.8464C113.363 31.655 113.098 31.5593 112.759 31.5593C112.538 31.5593 112.34 31.6088 112.165 31.7078C111.991 31.8068 111.852 31.9454 111.75 32.1236C111.647 32.3018 111.596 32.508 111.596 32.7423V35.1313H110.812L110.814 35.1297Z"
      fill="white" />
    <path
      d="M115.342 31.6193V30.9544H116.414V31.6193H115.342ZM117.591 35.2556C117.201 35.2556 116.889 35.1418 116.652 34.9157C116.414 34.6881 116.295 34.3383 116.295 33.8631V29.7285H117.079V30.9544H118.302V31.6193H117.079V33.6684C117.079 33.9869 117.137 34.2096 117.251 34.3399C117.366 34.4686 117.534 34.533 117.756 34.533C117.855 34.533 117.949 34.5214 118.039 34.4983C118.13 34.4752 118.208 34.4455 118.275 34.4092L118.437 35.0527C118.32 35.1154 118.188 35.1649 118.041 35.2012C117.893 35.2375 117.742 35.2556 117.589 35.2556H117.591Z"
      fill="white" />
    <path
      d="M119.468 35.1291V30.9565H120.251V31.562C120.42 31.2898 120.618 31.0984 120.85 30.9895C121.081 30.8806 121.327 30.8262 121.59 30.8262C121.672 30.8262 121.751 30.8311 121.829 30.8394C121.905 30.8476 121.981 30.8592 122.055 30.874L121.939 31.6924C121.86 31.6676 121.779 31.6478 121.697 31.6379C121.614 31.6264 121.533 31.6214 121.454 31.6214C121.104 31.6214 120.817 31.7287 120.591 31.9448C120.365 32.1609 120.253 32.4497 120.253 32.8126V35.134H119.469L119.468 35.1291Z"
      fill="white" />
    <path
      d="M124.659 35.258C124.226 35.258 123.842 35.1574 123.504 34.9561C123.165 34.7548 122.9 34.4859 122.707 34.151C122.514 33.8161 122.418 33.4465 122.418 33.0439C122.418 32.7453 122.474 32.4632 122.585 32.1959C122.695 31.9286 122.852 31.6927 123.053 31.4864C123.254 31.2802 123.492 31.1202 123.764 31.003C124.037 30.8859 124.337 30.8281 124.663 30.8281C125.096 30.8281 125.48 30.9288 125.818 31.1301C126.157 31.3313 126.422 31.6003 126.615 31.9352C126.808 32.2701 126.904 32.6397 126.904 33.0423C126.904 33.3409 126.85 33.623 126.737 33.8903C126.627 34.1576 126.47 34.3935 126.269 34.5981C126.068 34.8027 125.83 34.9627 125.558 35.0815C125.285 35.1987 124.985 35.258 124.659 35.258ZM124.663 34.5519C124.942 34.5519 125.19 34.4909 125.406 34.3671C125.624 34.2434 125.794 34.0685 125.919 33.8441C126.044 33.6181 126.107 33.3524 126.107 33.0456C126.107 32.7387 126.046 32.4714 125.922 32.2454C125.799 32.0177 125.627 31.8445 125.409 31.7224C125.19 31.6003 124.941 31.5392 124.66 31.5392C124.38 31.5392 124.131 31.6003 123.913 31.7224C123.695 31.8445 123.525 32.0193 123.401 32.2454C123.278 32.4731 123.217 32.7387 123.217 33.0456C123.217 33.3524 123.278 33.6181 123.401 33.8441C123.525 34.0701 123.697 34.245 123.914 34.3671C124.132 34.4909 124.383 34.5519 124.663 34.5519Z"
      fill="white" />
    <path d="M127.886 35.1287V28.9961H128.669V35.1287H127.886Z" fill="white" />
    <path
      d="M132.276 35.129V29.168H136.015V29.884H133.076V31.6593H135.635V32.3456H133.076V34.4113H136.039V35.1306H132.274L132.276 35.129Z"
      fill="white" />
    <path
      d="M136.769 35.1345L138.28 33.0177L136.858 30.957H137.846L138.813 32.4436L139.745 30.957H140.704L139.324 32.993L140.827 35.1312H139.822L138.783 33.5638L137.727 35.1263L136.769 35.1345Z"
      fill="white" />
    <path
      d="M141.645 36.9178V30.9568H142.365V31.5821C142.538 31.361 142.756 31.1795 143.018 31.0393C143.28 30.8991 143.612 30.8281 144.013 30.8281C144.414 30.8281 144.792 30.9255 145.107 31.1202C145.422 31.3148 145.668 31.5788 145.846 31.9137C146.024 32.2487 146.111 32.6248 146.111 33.0423C146.111 33.3458 146.06 33.6313 145.96 33.9002C145.859 34.1691 145.715 34.4034 145.527 34.608C145.339 34.8126 145.118 34.9693 144.861 35.0848C144.603 35.2003 144.318 35.2564 144.003 35.2564C143.651 35.2564 143.343 35.1904 143.081 35.0601C142.818 34.9297 142.599 34.7548 142.422 34.5371V36.9178H141.644H141.645ZM143.873 34.5503C144.153 34.5503 144.402 34.4892 144.62 34.3655C144.838 34.2417 145.008 34.0668 145.131 33.8425C145.255 33.6164 145.316 33.3508 145.316 33.0439C145.316 32.737 145.253 32.4698 145.131 32.2437C145.008 32.016 144.836 31.8428 144.618 31.7207C144.399 31.5986 144.151 31.5376 143.873 31.5376C143.594 31.5376 143.343 31.5986 143.125 31.7207C142.907 31.8428 142.737 32.0177 142.612 32.2437C142.487 32.4714 142.424 32.737 142.424 33.0439C142.424 33.3508 142.487 33.6164 142.612 33.8425C142.737 34.0685 142.907 34.2434 143.125 34.3655C143.343 34.4892 143.592 34.5503 143.873 34.5503Z"
      fill="white" />
    <path
      d="M149.015 35.2584C148.573 35.2584 148.182 35.166 147.842 34.9812C147.502 34.7964 147.238 34.539 147.05 34.209C146.862 33.8791 146.767 33.493 146.767 33.0525C146.767 32.734 146.821 32.4387 146.928 32.1665C147.036 31.8943 147.189 31.6583 147.389 31.4603C147.588 31.2623 147.819 31.1073 148.085 30.9967C148.35 30.8862 148.639 30.8301 148.951 30.8301C149.289 30.8301 149.591 30.8895 149.857 31.0083C150.122 31.1271 150.342 31.2937 150.517 31.5082C150.692 31.7227 150.817 31.9734 150.893 32.2622C150.969 32.5509 150.985 32.8627 150.939 33.201H147.54C147.532 33.4798 147.588 33.7223 147.71 33.9319C147.832 34.1414 148.009 34.3031 148.24 34.4202C148.471 34.5374 148.746 34.5951 149.063 34.5951C149.296 34.5951 149.524 34.5588 149.745 34.4846C149.966 34.4103 150.162 34.3014 150.332 34.1562L150.664 34.7403C150.527 34.8508 150.367 34.9449 150.18 35.0241C149.994 35.1016 149.801 35.161 149.599 35.2006C149.398 35.2402 149.202 35.26 149.012 35.26L149.015 35.2584ZM147.585 32.5921H150.157C150.146 32.2407 150.037 31.9652 149.829 31.7689C149.621 31.5709 149.326 31.4735 148.939 31.4735C148.553 31.4735 148.255 31.5725 148.011 31.7689C147.766 31.9668 147.625 32.2407 147.585 32.5921Z"
      fill="white" />
    <path
      d="M151.949 35.1291V30.9565H152.733V31.562C152.901 31.2898 153.099 31.0984 153.332 30.9895C153.563 30.8806 153.809 30.8262 154.071 30.8262C154.153 30.8262 154.233 30.8311 154.31 30.8394C154.386 30.8476 154.462 30.8592 154.536 30.874L154.421 31.6924C154.342 31.6676 154.261 31.6478 154.178 31.6379C154.096 31.6264 154.015 31.6214 153.936 31.6214C153.586 31.6214 153.299 31.7287 153.073 31.9448C152.847 32.1609 152.735 32.4497 152.735 32.8126V35.134H151.951L151.949 35.1291Z"
      fill="white" />
    <path
      d="M154.854 31.6194V30.9545H155.927V31.6194H154.854ZM157.103 35.2557C156.714 35.2557 156.402 35.1419 156.164 34.9159C155.927 34.6882 155.808 34.3384 155.808 33.8632V29.7286H156.592V30.9545H157.814V31.6194H156.592V33.6686C156.592 33.987 156.65 34.2097 156.763 34.3401C156.879 34.4687 157.047 34.5331 157.268 34.5331C157.367 34.5331 157.461 34.5215 157.552 34.4984C157.643 34.4753 157.72 34.4456 157.788 34.4093L157.95 35.0528C157.833 35.1155 157.701 35.165 157.554 35.2013C157.405 35.2376 157.255 35.2557 157.102 35.2557H157.103Z"
      fill="white" />
    <path
      d="M160.331 35.2527C160.001 35.2527 159.691 35.2032 159.395 35.1059C159.102 35.0085 158.857 34.8766 158.661 34.7116L158.985 34.1193C159.169 34.2727 159.38 34.3931 159.616 34.4789C159.854 34.5647 160.092 34.6093 160.329 34.6093C160.624 34.6093 160.854 34.5565 161.019 34.4492C161.184 34.342 161.266 34.1919 161.266 33.9955C161.266 33.814 161.195 33.6787 161.053 33.5913C160.912 33.5039 160.648 33.4131 160.262 33.3191C159.75 33.2019 159.379 33.0468 159.148 32.8522C158.917 32.6575 158.801 32.3869 158.801 32.0404C158.801 31.7962 158.869 31.5801 159.006 31.3904C159.143 31.2023 159.329 31.0538 159.565 30.9449C159.803 30.8376 160.075 30.7832 160.385 30.7832C160.695 30.7832 160.978 30.8261 161.243 30.9135C161.509 31.001 161.74 31.1264 161.934 31.2897L161.633 31.8903C161.534 31.7946 161.416 31.7121 161.281 31.6428C161.146 31.5735 161.002 31.5207 160.849 31.4828C160.695 31.4448 160.542 31.425 160.389 31.425C160.136 31.425 159.93 31.4762 159.77 31.5784C159.61 31.6807 159.529 31.8226 159.529 32.0041C159.529 32.1856 159.607 32.3341 159.762 32.4215C159.917 32.509 160.174 32.5964 160.534 32.6822C161.057 32.7994 161.431 32.9544 161.657 33.1491C161.885 33.3438 161.999 33.6111 161.999 33.9526C161.999 34.2166 161.93 34.4459 161.791 34.6406C161.652 34.8353 161.458 34.9854 161.207 35.0927C160.958 35.1983 160.664 35.2527 160.329 35.2527H160.331Z"
      fill="white" />
  </g>
  <defs>
    <clipPath id="clip0_1_92">
      <rect width="162" height="39.5394" fill="white" />
    </clipPath>
  </defs>
</svg>
