<app-filterable-tree
  [groupedItems$]="groupedReportingTabs$"
  [items$]="items$"
  [selection$]="selection$"
  [config$]="config$"
  [filter$]="filter$"
  [canSelectGroups]="!isSimulateBased"
  (updateSelectedIds)="updateSelectedIds($event)"
  (dropped)="onDropped($event)"
  dataCy="range-selection"
></app-filterable-tree>