import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { EnumHelpers } from '@dunefront/common/utils/enum.helpers';
import { FluidType } from '@dunefront/common/modules/fluid/dto/fluid.dto';
import { RheologyType } from '@dunefront/common/modules/fluid/dto/rheology/rheology.dto';
import { ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import { FluidRheologiesGridComponent } from './fluid-rheologies-grid/fluid-rheologies-grid.component';
import { createSelector, Store } from '@ngrx/store';
import * as actions from '../../../../../+store/fluid/fluid.actions';
import { updateRheologyRow } from '../../../../../+store/fluid/fluid.actions';
import { PageWithGridComponent } from '../../../../../shared/components/grid/page-with-grid.component';
import { ModalService } from '../../../../../common-modules/modals/modal.service';
import { ScreenService } from '../../../../../shared/services';
import { cancelChange, getCurrentValue } from '../../../../../+store/store.helpers';
import { getFluidNames, getRheologies } from '../../../../../+store/fluid/fluid.selectors';
import { GeneralCalculations } from '@dunefront/common/common/general.calculations';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { Rheology } from '@dunefront/common/modules/fluid/model/rheology/rheology';
import { Fluid } from '@dunefront/common/modules/fluid/model/fluid';
import { IRadioItem } from '@dunefront/common/common/radio.helpers';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { PanelHelpMode } from '../../../../../shared/components/help-button/help-buton.component';
import { RouterHelperService } from '../../../../../shared/services/router-helper.service';
import { fluidAdvOptionsValid } from '../../fluids.menu';
import { areStringsTheSame } from '@dunefront/common/common/helpers';
import { changeShearRateAction } from '../../../../../+store/settings/settings.actions';
import { SettingsDto, ValidatedSettings } from '@dunefront/common/modules/settings/dto/settingsDto';
import { getValidatedSettings } from '../../../../../+store/settings/validated-settings.selectors';
import { getAppModuleType } from '../../../../../+store/ui/ui.selectors';
import { isSimulateDisp } from '../../../../../+store/menu-selectors/menu-selectors.helpers';

const getFluidAdvancedOptionsAvailable = createSelector(getAppModuleType, (moduleType) => !isSimulateDisp(moduleType));

@Component({
  selector: 'app-fluid-properties',
  templateUrl: './fluid-properties.component.html',
  styleUrls: ['./fluid-properties.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FluidPropertiesComponent extends PageWithGridComponent<Rheology> implements OnChanges {
  @ViewChild(FluidRheologiesGridComponent) public grid!: FluidRheologiesGridComponent;

  public settings$ = this.store.select(getValidatedSettings);
  public fluidAdvancedOptionsAvailable$ = this.store.select(getFluidAdvancedOptionsAvailable);

  private _fluid!: Fluid;
  public fluidAdvOptionsValid = false;

  @Input()
  public set fluid(fluid: Fluid) {
    this._fluid = fluid;
    this.fluidAdvOptionsValid = fluidAdvOptionsValid(fluid);
  }

  public get fluid(): Fluid {
    return this._fluid;
  }

  public PanelHelpMode = PanelHelpMode;
  @Output() public fluidPropertyChange = new EventEmitter<ObjectChangeProp<Fluid>>();

  public UnitType = UnitSystem;
  public RheologyType = RheologyType;
  public FluidType = FluidType;
  public rheologies$ = this.store.select(getRheologies);

  public fluidTypes!: ISelectItem<FluidType>[];

  public isGravelSettlingRadio: IRadioItem<boolean>[] = [
    { value: true, text: 'Yes' },
    { value: false, text: 'No' },
  ];

  public isYieldStressRadio: IRadioItem<boolean>[] = [
    { value: true, text: 'Yes' },
    { value: false, text: 'No' },
  ];

  public rheologyRadio: IRadioItem<RheologyType>[] = [
    { value: RheologyType.NPrimeAndKPrime, text: "n' and k'" },
    { value: RheologyType.Rheometer, text: 'Rheometer' },
    { value: RheologyType.Viscosity, text: 'Viscosity' },
  ];

  public getRows(): ITableRow<Rheology>[] {
    return [];
  }

  protected updateRow(props: IUpdateTableRowsProps<Rheology>): void {
    this.store.dispatch(updateRheologyRow(props));
  }

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    modalService: ModalService,
    resizeService: ScreenService,
    private routerHelperService: RouterHelperService,
  ) {
    super(store, cdRef, modalService, resizeService);
    this.fluidTypes = EnumHelpers.EnumToISelectItemArray(FluidType);
    this.onHelpChange('fluids', 'fluid-properties');
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.fluid?.currentValue?.Type !== changes.fluid?.previousValue?.Type) {
      const rheologyRadio = this.rheologyRadio;
      rheologyRadio[1].disabled = changes.fluid.currentValue.Type === FluidType.Newtonian;
      rheologyRadio[2].disabled = changes.fluid.currentValue.Type !== FluidType.Newtonian;
      this.rheologyRadio = [...rheologyRadio];
    }
  }

  public override onDelete(): void {
    this.grid.onDelete();
  }

  public async emitValue(props: ObjectChangeProp<Fluid>): Promise<void> {
    const isValid = await this.validateFluidBeforeUpdate(props);
    if (!isValid) {
      return;
    }

    this.store.dispatch(
      actions.updateFluid({
        changedProp: props,
        fluidId: this.fluid.Id,
        shouldSaveRheologies: this.shouldSaveRheologies(props),
      }),
    );
  }

  private async validateFluidBeforeUpdate(props: ObjectChangeProp<Fluid>): Promise<boolean> {
    if (props.key === 'RheologyType' && props.value === RheologyType.Rheometer) {
      const result = await this.modalService.showConfirm(
        'Existing manual rheology data will be deleted - would you like to continue?',
        'Information',
      );
      if (!result) {
        const cancelGenerator = cancelChange(this.fluid, props, this.cdRef);
        this.fluid = cancelGenerator.next().value;
        this.fluid = cancelGenerator.next().value;
        return false;
      }
    }

    if (props.key === 'Name') {
      const fluidNames = (await getCurrentValue<string[]>(notEmpty(this.store.select(getFluidNames)))) || [];
      const isFluidNameAlreadyExist = GeneralCalculations.DoesArrayContainsString(fluidNames, props.value as string);
      const restorePrevName = (props.value as string).trim().length === 0 || areStringsTheSame(props.value as string, this.fluid.Name);

      if (isFluidNameAlreadyExist || restorePrevName) {
        if (isFluidNameAlreadyExist) {
          await this.modalService.showAlert('This name already exists - please use a different one.', 'Information');
        }
        const cancelGenerator = cancelChange(this.fluid, props, this.cdRef);
        this.fluid = cancelGenerator.next().value;
        this.fluid = cancelGenerator.next().value;
        return false;
      }
    }

    return true;
  }

  private shouldSaveRheologies(props: ObjectChangeProp<Fluid>): boolean {
    return (
      (props.key === 'RheologyType' && props.value !== RheologyType.Rheometer) || (props.key === 'Type' && props.value === FluidType.Newtonian)
    );
  }

  public openAdvancedFluidProperties(): void {
    this.routerHelperService.navigateToFluidAdvancedProperties(this.fluid.Id).then();
  }

  public changeShearRate(change: ObjectChangeProp<ValidatedSettings>): void {
    this.store.dispatch(changeShearRateAction(change as unknown as ObjectChangeProp<SettingsDto>));
  }
}
