import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { ModalService } from '../../../common-modules/modals/modal.service';
import { IFile, Repository } from '@dunefront/common/dto/file.dto';
import { NewProjectDialogComponent } from '../action-dialogs/open-new-shared-demo.dialog/new-project.dialog.component';
import { NewFolderDialogComponent } from '../action-dialogs/new-folder-dialog/new-folder.dialog.component';
import { Subscription } from 'rxjs';
import { getCurrentFolderState } from '../../../+store/file-manager/file-manager.selectors';
import { notEmpty } from '@dunefront/common/common/state.helpers';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { emptyTrashAction } from '../../../+store/file-manager/file-manager.actions';
import { showFileOpenDialog, showFileSaveDialog } from '../../../+store/electron-main/electron-main.actions';
import { isElectron } from '@dunefront/common/common/electron/is-electron';
import { getAccessibleFeaturesAndLicenses } from '../../../+store/licensing/licensing.selectors';
import { LicenseFeature } from '@dunefront/common/modules/licensing/licensing.interfaces';
import { AppTargetConfig } from '../../../shared/services/app-target-config';

@Component({
  selector: 'app-sub-home-header',
  templateUrl: './home-sub-header.component.html',
  styleUrls: ['./home-sub-header.component.scss'],
})
export class HomeSubHeaderComponent implements OnDestroy {
  @Input() public folder?: IFile;

  private _repository?: Repository;
  @Input()
  public set repository(repository: Repository) {
    this._repository = repository;
    this.updateContextMenu(repository);
  }

  public get repository(): Repository | undefined {
    return this._repository;
  }

  public plusContextMenu: MenuItem[] = [];

  public isRecentProjects = false;
  public isSettings = false;

  @Output() public uploadTriggered = new EventEmitter<void>();

  protected subscription = new Subscription();
  private fileNameList!: string[];
  private folderNameList!: string[];

  private isFluidPro = false;
  private isDataPro = false;
  private userFeatures: LicenseFeature[] = [];

  constructor(
    private store: Store,
    public modalService: ModalService,
    private router: Router,
    private appConfig: AppTargetConfig,
    private cdRef: ChangeDetectorRef,
  ) {
    this.isFluidPro = this.appConfig.isAppCode('fluid-pro');
    this.isDataPro = this.appConfig.isAppCode('data-pro');

    this.subscription.add(
      notEmpty(this.store.select(getCurrentFolderState)).subscribe((currentFolderState: IFile) => {
        if (currentFolderState?.Children) {
          this.fileNameList = currentFolderState.Children.filter((file) => file.FileType === 'ppf-file').map((file) => file.Name);
          this.folderNameList = currentFolderState.Children.filter((file) => file.FileType === 'folder').map((file) => file.Name);
          return;
        }
        this.fileNameList = [];
      }),
    );
    this.isRecentProjects = this.router.url === '/home';
    this.subscription.add(
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
        this.isRecentProjects = event.urlAfterRedirects.endsWith('/home');

        this.isSettings = event.urlAfterRedirects.endsWith('/file-options');
      }),
    );

    this.subscription.add(
      this.store.select(getAccessibleFeaturesAndLicenses).subscribe((userFeatures) => {
        this.userFeatures = userFeatures.features;
        if (this._repository) {
          this.updateContextMenu(this._repository);
        }
        this.cdRef.markForCheck();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public newProjectElectron(fileSuffix: '.ppf' | '.fpf' | '.dpf'): void {
    const source: IFile = { Repository: 'template', FileType: 'ppf-file', Name: `default${fileSuffix}`, Folder: [] };
    this.store.dispatch(showFileSaveDialog({ source }));
  }

  private openProjectElectron(): void {
    this.store.dispatch(showFileOpenDialog());
  }

  public newProject(fileSuffix: '.ppf' | '.fpf' | '.dpf'): void {
    const file: IFile = {
      Repository: 'template',
      FileType: 'ppf-file',
      Name: `default${fileSuffix}`,
      Folder: [],
    };
    this.modalService.open(
      NewProjectDialogComponent,
      {
        file,
        targetFolder: this.folder,
        fileNameList: this.fileNameList,
      },
      '',
      'sm',
    );
  }

  public newProjectFolder(): void {
    this.modalService.open(
      NewFolderDialogComponent,
      {
        targetFolder: this.folder,
        folderNameList: this.folderNameList,
      },
      '',
      'sm',
    );
  }

  private uploadFile(): void {
    this.uploadTriggered.emit();
  }

  public async onEmptyTrash(): Promise<void> {
    const confirm = await this.modalService.showConfirm('Are you sure you want to permanently erase the items in the Trash?', 'Empty trash');
    if (confirm) {
      this.store.dispatch(emptyTrashAction());
    }
  }

  private updateContextMenu(repository: Repository): void {
    this.plusContextMenu =
      repository === 'personal'
        ? [
            {
              label: 'New Project',
              icon: 'icon-file-blank',
              items: [
                {
                  label: 'PackPro',
                  icon: 'ppf-file',
                  command: (): void => (isElectron() ? this.newProjectElectron('.ppf') : this.newProject('.ppf')),
                  disabled: ![
                    LicenseFeature.Simulate_CH,
                    LicenseFeature.Simulate,
                    LicenseFeature.Evaluate,
                    LicenseFeature.Trend_Analysis,
                    LicenseFeature.PSD_Analysis,
                    LicenseFeature.Calculators,
                  ].some((feat) => this.userFeatures.includes(feat)),
                },
                {
                  label: 'FluidPro',
                  icon: 'fpf-file',
                  command: (): void => (isElectron() ? this.newProjectElectron('.fpf') : this.newProject('.fpf')),
                  disabled: !this.userFeatures.includes(LicenseFeature.Simulate_Disp),
                  visible: !this.isDataPro,
                },
                {
                  label: 'DataPro',
                  icon: 'dpf-file',
                  command: (): void => (isElectron() ? this.newProjectElectron('.dpf') : this.newProject('.dpf')),
                  disabled: !this.userFeatures.includes(LicenseFeature.Data_Analysis),
                  visible: !this.isFluidPro,
                },
              ],
            },
            {
              label: 'Open Project',
              icon: 'icon-folder',
              command: (): void => this.openProjectElectron(),
              visible: isElectron(),
            },
            {
              label: 'New Project Folder',
              icon: 'icon-folder',
              command: (): void => this.newProjectFolder(),
              visible: !isElectron(),
            },
            {
              label: 'Upload Project',
              icon: 'icon-Upload',
              command: (): void => this.uploadFile(),
              visible: !isElectron(),
            },
          ]
        : repository === 'shared'
          ? [{ label: 'New Project Folder', icon: 'icon-folder', command: (): void => this.newProjectFolder() }]
          : [];
  }

  protected readonly isElectron = isElectron;
}
