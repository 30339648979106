import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UploadModule } from '../../shared/components/upload/upload.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DeleteDialogComponent } from './action-dialogs/delete-dialog/delete.dialog.component';
import { MoveDialogComponent } from './action-dialogs/move-dialog/move.dialog.component';
import { CopyDialogComponent } from './action-dialogs/copy-dialog/copy.dialog.component';
import { FormsModule } from '@angular/forms';
import { RenameDialogComponent } from './action-dialogs/rename-dialog/rename.dialog.component';
import { ShareDialogComponent } from './action-dialogs/share/share.dialog.component';
import { NewFolderDialogComponent } from './action-dialogs/new-folder-dialog/new-folder.dialog.component';
import { NewProjectDialogComponent } from './action-dialogs/open-new-shared-demo.dialog/new-project.dialog.component';
import { FileFilterComponent } from './file-filter-component/file-filter.component';
import { HomeComponent } from './home.component';
import { RibbonMenuModule } from '../../common-modules/ribbon-menu/ribbon-menu.module';
import { RestoreDialogComponent } from './action-dialogs/restore-dialog/restore.dialog.component';
import { SelectModule } from '../../shared/components/form-components/select/select.module';
import { ButtonModule } from '../../shared/components/button/button.module';
import { DropdownModule } from 'primeng/dropdown';
import { ContextMenuModule } from 'primeng/contextmenu';
import { UserPanelModule } from '../../shared/components';
import { ModuleSelectorDialogComponent } from './module-selector-dialog/module-selector-dialog.component';
import { FileDisplayNamePipeModule } from '../../shared/pipes/file/file-display-name.pipe.module';
import { UnitsModule } from '../../common-modules/units/units.module';
import { ClickOutsideModule } from '../../shared/directives/click-outside.directive';
import { NgxFileDropModule } from '../../common-modules/ngx-file-drop/ngx-file-drop.module';
import { FileListModule } from './file-list-component/file-list.module';
import { ModalsModule } from '../../common-modules/modals/modals.module';
import { LetDirective } from '@ngrx/component';
import { HomeSubHeaderComponent } from './home-sub-header/home-sub-header.component';
import { HeaderComponent } from './home-header/header.component';
import { LeftNavAppLogoComponent } from '../../shared/components/left-nav/left-nav-app-logo/left-nav-app-logo.component';
import { LeftNavModule } from '../../shared/components/left-nav/left-nav.component';
import { FileOptionsComponentModule } from '../common/settings/file-options/file-options-component.module';
import { LeftNavDfLogoComponent } from '../../shared/components/left-nav/left-nav-df-logo/left-nav-df-logo.component';
import { RadioGroupModule } from '../../shared/components/form-components/radio-group/radio-group.component';
import { CascadeSelectModule } from 'primeng/cascadeselect';

@NgModule({
  declarations: [
    ModuleSelectorDialogComponent,
    DeleteDialogComponent,
    MoveDialogComponent,
    CopyDialogComponent,
    RenameDialogComponent,
    ShareDialogComponent,
    NewFolderDialogComponent,
    NewProjectDialogComponent,
    FileFilterComponent,
    RestoreDialogComponent,
    HomeComponent,
    HomeSubHeaderComponent,
  ],
  imports: [
    LeftNavModule,
    FileOptionsComponentModule,
    FormsModule,
    CommonModule,
    RouterModule,
    UploadModule,
    ScrollingModule,
    NgxFileDropModule,
    ButtonModule,
    SelectModule,
    RibbonMenuModule,
    DropdownModule,
    UserPanelModule,
    ContextMenuModule,
    FileDisplayNamePipeModule,
    UnitsModule,
    ClickOutsideModule,
    ModalsModule,
    FileListModule,
    LetDirective,
    HeaderComponent,
    LeftNavAppLogoComponent,
    LeftNavDfLogoComponent,
    RadioGroupModule,
    CascadeSelectModule,
  ],
  exports: [HomeComponent],
})
export class HomeModule {}
