import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { createSelector, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import {
  getAccessibleFeaturesAndLicenses,
  getLicenseInfo,
  getLicensingLogin,
  getLicensingSelectedAddons,
} from '../../../+store/licensing/licensing.selectors';
import {
  HaspStatus,
  isAddonFeature,
  LicenseFeature,
  LicenseInfo,
  LicensingLoginResponse,
  LoginInfo,
} from '@dunefront/common/modules/licensing/licensing.interfaces';
import { getAppModuleType } from '../../../+store/ui/ui.selectors';
import {
  isCalculator,
  isDataAnalysis,
  isEvaluate,
  isPsdAnalysis,
  isSimulate,
  isSimulateCH,
  isSimulateDisp,
  isTrendAnalysis,
} from '../../../+store/menu-selectors/menu-selectors.helpers';
import { getModuleSelectorConfig } from './get-module-selector.config';
import { toggleAddonAction } from '../../../+store/licensing/licensing.actions';
import { AppTargetConfig } from '../../../shared/services/app-target-config';
import { ASSETS_IMAGES_PATH } from '@dunefront/common/common/constants';

@Component({
  selector: 'app-module-selector',
  templateUrl: './module-selector-dialog.component.html',
  styleUrls: ['./module-selector-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleSelectorDialogComponent {
  public moduleSelectorConfig = getModuleSelectorConfig(this.appConfig.appCode);
  public vm$ = this.store.select(selectModuleSelectorVm);

  public LicenseFeature = LicenseFeature;

  constructor(
    public activeModal: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public store: Store,
    public router: Router,
    public readonly appConfig: AppTargetConfig,
  ) {}

  public isItemHighlighted(vm: IModuleSelectorVM, feature: LicenseFeature, isAddon: boolean): boolean {
    if (isAddon) {
      return vm.selectedAddons.includes(feature);
    }

    switch (feature) {
      case LicenseFeature.Simulate_CH:
        return isSimulateCH(vm.currentModuleType);
      case LicenseFeature.Simulate:
        return isSimulate(vm.currentModuleType);
      case LicenseFeature.Evaluate:
        return isEvaluate(vm.currentModuleType);
      case LicenseFeature.Simulate_Disp:
        return isSimulateDisp(vm.currentModuleType);
      case LicenseFeature.PSD_Analysis:
        return isPsdAnalysis(vm.currentModuleType);
      case LicenseFeature.Trend_Analysis:
        return isTrendAnalysis(vm.currentModuleType);
      case LicenseFeature.Calculators:
        return isCalculator(vm.currentModuleType);
      case LicenseFeature.Data_Analysis:
        return isDataAnalysis(vm.currentModuleType);

      default:
        return false;
    }
  }

  public isThreeColsLayout(): boolean {
    return this.moduleSelectorConfig.find((group) => group.items.length >= 3) != null;
  }

  private getLoginInfo(vm: IModuleSelectorVM, feature: LicenseFeature): LoginInfo | undefined {
    return vm.licensingLogin?.loginInfos.find((li) => li.licenseFeature === feature && li.haspStatus === HaspStatus.StatusOk);
  }

  public onClick(event: Event, isDisabled: boolean, licenseFeature: LicenseFeature, licenseIds: string[], isAddon = false): void {
    if (isDisabled) {
      return;
    }

    if (isAddon) {
      this.store.dispatch(toggleAddonAction({ licenseFeature }));
      return;
    }

    event.preventDefault();
    const result: ModuleSelectorDialogResult = { licenseFeature, licenseIds };
    this.activeModal.close(result);
  }

  public cancelClicked(): void {
    this.activeModal.close();
  }

  private isFeatureAvailableInLicense(vm: IModuleSelectorVM, feature: LicenseFeature): boolean {
    return vm.licenseInfo.features.includes(feature);
  }

  public getItemInfo(
    vm: IModuleSelectorVM,
    feature: LicenseFeature,
  ): {
    icon: string;
    message: string;
    isDisabled: boolean;
  } {
    const isConnected = this.getLoginInfo(vm, feature)?.isConnected;
    const isFeatureAvailableInLicense = this.isFeatureAvailableInLicense(vm, feature);
    const isFeatureCompatible = this.isFeatureCompatible(vm, feature);
    const isDisabled = !isFeatureAvailableInLicense || !isFeatureCompatible;

    const message = this.getMessage(feature, isFeatureAvailableInLicense, isFeatureCompatible);
    let icon = '';
    if (isConnected) {
      icon = 'icon-checkmark-circle';
    } else if (isDisabled) {
      icon = 'icon-delete-disabled';
    } else if (isFeatureAvailableInLicense && !isConnected) {
      icon = 'icon-circle';
    }

    return { icon, message, isDisabled };
  }

  private getMessage(feature: LicenseFeature, isFeatureAvailableInLicense: boolean, isCompatible: boolean): string {
    if (isFeatureAvailableInLicense && isCompatible) {
      return '';
    }

    if (!isFeatureAvailableInLicense) {
      return isAddonFeature(feature) ? 'You don’t have a license for this add-on' : 'You don’t have a license for this module';
    }

    if (!isCompatible) {
      return isAddonFeature(feature)
        ? 'This add-on is not compatible with the selected module'
        : 'This module is not compatible with the selected add-on(s)';
    }
    return '';
  }

  // checks if addon is compatible with selected features
  // or if formal feature is compatible with selected addons
  private isFeatureCompatible(vm: IModuleSelectorVM, feature: LicenseFeature): boolean {
    if (vm.licensingLogin == null || !vm.licensingLogin.isLoggedIn) {
      // user not logged in
      return vm.accessibleFeatures.includes(feature);
    } else {
      // user logged in
      if (isAddonFeature(feature)) {
        const loginInfo = vm.licensingLogin.loginInfos[0];
        const product = vm.licenseInfo.products.find((p) => p.id === loginInfo.productId && p.haspId === loginInfo.licenseId);
        return product?.productDetails.features.map((f) => f.id).includes(feature) ?? false;
      } else {
        const connectedFeatures = vm.licensingLogin.loginInfos.map((li) => li.licenseFeature);
        return [...connectedFeatures, feature].every((connectedFeature) => vm.accessibleFeatures.includes(connectedFeature));
      }
    }
  }

  public get appIconWhitePath(): string {
    return ASSETS_IMAGES_PATH + this.appConfig.appCode + '-white' + '.svg';
  }
}

export interface ModuleSelectorDialogResult {
  licenseFeature: LicenseFeature;
  licenseIds: string[];
}

export interface IModuleSelectorVM {
  selectedAddons: LicenseFeature[];
  accessibleFeatures: LicenseFeature[];
  accessibleLicenseIds: string[];
  licensingLogin: LicensingLoginResponse | undefined;
  currentModuleType: ModuleType;
  licenseInfo: LicenseInfo;
}

const selectModuleSelectorVm = createSelector(
  getLicenseInfo,
  getLicensingSelectedAddons,
  getAccessibleFeaturesAndLicenses,
  getLicensingLogin,
  getAppModuleType,
  (licenseInfo, selectedAddons, accessibleFeaturesWithLicenseIds, licensingLogin, currentModuleType) =>
    <IModuleSelectorVM>{
      licenseInfo,
      selectedAddons,
      accessibleFeatures: accessibleFeaturesWithLicenseIds.features,
      accessibleLicenseIds: accessibleFeaturesWithLicenseIds.licenseIds,
      licensingLogin,
      currentModuleType,
    },
);
