import { Store } from '@ngrx/store';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import * as uiActions from '../../+store/ui/ui.actions';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { getSimulateEvaluateLeftNavMenu } from './simulate-evaluate-module.menu';
import { ThemeService } from '../../shared/services/theme.service';
import { isSimulateDisp } from '../../+store/menu-selectors/menu-selectors.helpers';

export abstract class SimulateEvaluatePageComponent {
  public menuData$ = this.store.select(getSimulateEvaluateLeftNavMenu);
  public selectedUri: string | null = null;
  public showPrevNextNav = false;
  public isFluidPro = false;

  protected constructor(
    protected store: Store,
    public moduleType: ModuleType,
  ) {
    store.dispatch(uiActions.setAppModuleAction({ appModuleType: this.moduleType }));
    this.isFluidPro = isSimulateDisp(this.moduleType);
  }
}

@Component({
  selector: 'app-evaluate',
  templateUrl: './simulate-evaluate-page.component.html',
  styleUrls: ['./simulate-evaluate-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EvaluatePageComponent extends SimulateEvaluatePageComponent {
  constructor(store: Store) {
    super(store, ModuleType.Evaluate);
  }
}

@Component({
  selector: 'app-simulate',
  templateUrl: './simulate-evaluate-page.component.html',
  styleUrls: ['./simulate-evaluate-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimulatePageComponent extends SimulateEvaluatePageComponent {
  constructor(store: Store) {
    super(store, ModuleType.Simulate);
  }
}

@Component({
  selector: 'app-simulate-ch',
  templateUrl: './simulate-evaluate-page.component.html',
  styleUrls: ['./simulate-evaluate-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimulateCHPageComponent extends SimulateEvaluatePageComponent {
  constructor(store: Store) {
    super(store, ModuleType.Simulate_CH);
  }
}

@Component({
  selector: 'app-simulate-disp',
  templateUrl: './simulate-evaluate-page.component.html',
  styleUrls: ['./simulate-evaluate-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimulateDispPageComponent extends SimulateEvaluatePageComponent implements OnInit, OnDestroy {
  constructor(
    store: Store,
    private themeService: ThemeService,
  ) {
    super(store, ModuleType.Simulate_Disp);
  }

  public ngOnInit(): void {
    this.themeService.loadStyle('theme-fluid-pro', 'packpro-theme-fluidpro.css');
  }

  public ngOnDestroy(): void {
    this.themeService.removeStyle('theme-fluid-pro');
  }
}
