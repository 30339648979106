import { BasePptSectionGenerator, PptContext } from '../base-ppt-section-generator';
import { firstValueFrom } from 'rxjs';
import { selectCurrentUnitSystem } from '../../../../../../+store/units/units.selectors';
import { ValidatedCompletionModuleState } from '@dunefront/common/modules/completion/completion-module.state';
import { getValidatedCompletionModuleState } from '../../../../../../+store/completion/validated-completion.selectors';
import { CommonReportDataHelpers } from '../../common/common-report-data-helpers';
import { PptDocumentTableGeneratorHelper } from '../ppt-document-table-generator.helper';
import { completionGeneralConfig } from '../../common/grid-configs/completion-general';

export class CompletionGeneralDataPptGenerator extends BasePptSectionGenerator {
  constructor(context: PptContext) {
    super(context);
  }

  public async generate(): Promise<void> {
    const title = 'Completion - General Data';
    const currentUnitSystem = await firstValueFrom(this.store.select(selectCurrentUnitSystem));
    const completionState: ValidatedCompletionModuleState = await firstValueFrom(this.store.select(getValidatedCompletionModuleState));

    const { keyValueData, enumItemMap } = CommonReportDataHelpers.createCompletionGeneralData(completionState);

    const tableRows = PptDocumentTableGeneratorHelper.createGenericKeyValueTable<Partial<ValidatedCompletionModuleState>>(
      completionGeneralConfig(completionState.HasCentralizers),
      keyValueData,
      currentUnitSystem,
      enumItemMap,
    );

    this.addKeyValueTableSlide({ title, tableRows });
  }
}
