<ng-container *ngIf="isValid$ | async; else invalidInput">
  <app-grid
    dataCy="gauge-section-info-grid"
    *ngIf="height > 0 && (tableData$ | async) as tableData"
    [dataSource]="tableData"
    [columns]="gridConfig.columns"
    [gridConfig]="gridConfig"
    [showCrudControls]="false"
    [height]="height"
    [isUiLockable]="false"
  ></app-grid>
</ng-container>
<ng-template #invalidInput>
  <div class="centered-message">{{ VolumeCalculationErrorMessage(currentAppModuleType) }}</div>
</ng-template>
