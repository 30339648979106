import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GridSelectComponentModule } from '../../../common-modules/grid-select/grid-select.component';
import { PagePanelModule } from '../../../common-modules/page-panel/page-panel.component';
import { TabbedPageModule } from '../../../common-modules/tabbed-page/tabbed-page.component';
import { UnitsModule } from '../../../common-modules/units/units.module';
import { AnnularBopComponent } from './initial-data/annular-bop/annular-bop.component';
import { ChokeComponent } from './initial-data/choke/choke.component';
import { InitialDataComponent } from './initial-data/initial-data.component';
import { PumpPopOffComponent } from './initial-data/pump-pop-off/pump-pop-off.component';
import { PumpingDirectionComponent } from './initial-data/pumping-direction/pumping-direction.component';
import { SubseaBopComponent } from './initial-data/subsea-bop/subsea-bop.component';
import { ToolPositionComponent } from './initial-data/tool-position/tool-position.component';
import { TreatingLineCoiledTubingComponent } from './initial-data/treating-line-coiled-tubing/treating-line-coiled-tubing.component';
import { PumpingComponent } from './pumping.component';
import { ScheduleOptionsComponent } from './schedule-options/schedule-options.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { WellFluidVolumeComponent } from './well-fluids/detailed-fluid-definition/well-fluid-volume/well-fluid-volume.component';
import { SimpleFluidDefinitionComponent } from './well-fluids/simple-fluid-definition/simple-fluid-definition.component';
import { WellFluidsTypeComponent } from './well-fluids/well-fluids-type/well-fluids-type.component';
import { WellFluidsComponent } from './well-fluids/well-fluids.component';
import { CheckBoxModule } from '../../../shared/components/form-components/check-box/check-box.component';
import { RadioGroupModule } from '../../../shared/components/form-components/radio-group/radio-group.component';
import { DetailedFluidDefinitionComponent } from './well-fluids/detailed-fluid-definition/detailed-fluid-definition.component';
import { DetailedFluidDefinitionGridComponent } from './well-fluids/detailed-fluid-definition/detailed-fluid-definition-grid/detailed-fluid-definition-grid.component';
import { GridModule } from '../../../shared/components/grid/grid.module';
import { FormInputModule } from '../../../shared/components/form-components/form-input/form-input.component';
import { UnwrapDirectiveModule } from '../../../shared/directives/unwrap.directive';
import { FluidSelectorModule } from '../../common/fluid-selector/fluid-selector.module';
import { ScheduleGridComponent } from './schedule/schedule-grid/schedule-grid.component';
import { GravelRequiredPanelComponent } from './schedule/gravel-required-panel/gravel-required-panel.component';
import { ReferenceParametersPanelComponent } from './schedule/reference-parameters-panel/reference-parameters-panel.component';
import { ScheduleGeneratorComponent } from './schedule-options/schedule-generator/schedule-generator.component';
import { AutomaticSchedulingComponent } from './schedule-options/automatic-scheduling/automatic-scheduling.component';
import { GravelSelectorModule } from '../../common/gravel-selector/gravel-selector.module';
import { SelectModule } from '../../../shared/components/form-components/select/select.module';
import { ButtonModule } from '../../../shared/components/button/button.module';
import { EvaluateScheduleComponent } from './evaluate-schedule/evaluate-schedule.component';
import { PumpedFluidAndGravelGridComponent } from './evaluate-schedule/pumped-fluid-and-gravel/pumped-fluid-and-gravel-grid.component';
import { AnalysisDataModule } from '../../common/analysis-data/analysis-data.module';
import { RouterModule } from '@angular/router';
import { EqualizeSymbolsDirectiveModule } from '../../../shared/directives/equalize-symbols.directive';
import { HideOnFluidProModule } from '../../../shared/directives/hide-on-fluidpro';

@NgModule({
  declarations: [
    PumpingComponent,
    WellFluidsComponent,
    InitialDataComponent,
    ScheduleComponent,
    ScheduleOptionsComponent,
    SimpleFluidDefinitionComponent,
    WellFluidsTypeComponent,
    ToolPositionComponent,
    PumpingDirectionComponent,
    TreatingLineCoiledTubingComponent,
    PumpPopOffComponent,
    SubseaBopComponent,
    AnnularBopComponent,
    ChokeComponent,
    WellFluidVolumeComponent,
    DetailedFluidDefinitionGridComponent,
    DetailedFluidDefinitionComponent,
    ScheduleGridComponent,
    GravelRequiredPanelComponent,
    ReferenceParametersPanelComponent,
    ScheduleGeneratorComponent,
    AutomaticSchedulingComponent,
    EvaluateScheduleComponent,
    PumpedFluidAndGravelGridComponent,
  ],
  imports: [
    CommonModule,
    TabbedPageModule,
    PagePanelModule,
    UnitsModule,
    GridSelectComponentModule,
    CheckBoxModule,
    RadioGroupModule,
    GridModule,
    SelectModule,
    ButtonModule,
    FormInputModule,
    UnwrapDirectiveModule,
    FluidSelectorModule,
    GravelSelectorModule,
    AnalysisDataModule,
    RouterModule,
    EqualizeSymbolsDirectiveModule,
    HideOnFluidProModule,
  ],
})
export class PumpingModule {}
