import { ConvertUnitPipe } from '../../units/convert-unit.pipe/convert-unit.pipe';
import { SolidConcentrationConverter } from '../../../unit-converters/converters/solid-concentration/solid-concentration.converter';
import { RateConverter } from '../../../unit-converters/converters/rate/rate.converter';
import { PressureConverter } from '../../../unit-converters/converters/pressure/pressure.converter';
import { noErrors } from '../../../common/state.helpers';
import { IUnitSystemDto, PressureUnit, RateUnit, SolidConcentrationUnit, UnitSystem, VolumeUnit } from '../../../dto/unit-system.dto';
import { IError, IModuleState } from '../../../common/common-state.interfaces';
import { SettingsDto, ValidatedSettings } from '../dto/settingsDto';
import {
  ICD_RATE_DISTRIBUTION_MINIMUM_TOLERANCE,
  MAX_GRAVEL_CONCENTRATION_TOLERANCE,
  MAX_RATE_CHANGE_TOLERANCE,
} from '../../../common/constants';
import { ReferenceVariableCalculatorResultsDto } from '../../reference-variables/dto/reference-variable-calculator-results.dto';
import { VolumeConverter } from '../../../unit-converters/converters/volume/volume.converter';
import { MathHelpers } from '../../../common/math-helpers';
import { ModuleType } from '../../scenario/scenario.dto';
import { validateFeatureNotSupportedInFluidPro } from '../../licensing/licensing.validator';

export class SettingsValidation {
  public static validate(
    settings: SettingsDto & IModuleState,
    maxLowerCompletionBottomMD: number,
    referenceVariables: ReferenceVariableCalculatorResultsDto | undefined,
    currentModuleType: ModuleType,
    unitSystem?: IUnitSystemDto,
  ): ValidatedSettings {
    // if reference variables are not loaded yet, return invalid state
    if (referenceVariables == null) {
      return { ...settings, error: {}, isValid: false };
    }

    let error: IError<SettingsDto> = {
      MaxVolumeStep: this.validateMaxVolStep(settings),
      RateChangeTolerance: this.validateRateChangeTol(settings),
      GravelConcentrationChangeTolerance: this.ValidateGravelConcTolerance(settings),
      ChokePressureChangeTolerance: this.ValidateChokePressureTolerance(settings),
      RopingPercentage: this.ValidateRopingPercentage(settings),
      RopingVolume: this.ValidateRopingVolume(settings, referenceVariables.DisplacementToPacker, unitSystem?.Liquid_Volume),
      MaxNumberOfReservoirCells: this.ValidateMaxReservoirCells(settings),
      DischargeCoefficient: this.ValidateDischargeCoefficient(settings),
      ICDRateDistributionMinTolerance: this.ValidateICDRatDisMinTolerance(settings),
      StokesSettlingMultiplier: this.validateStokesSettlingMultiplier(settings),
      BridgingRatio: this.ValidateBridgingRatio(settings),
      NumberOfGrainDiametersToBridge: this.ValidateNumberOfGrainDiameters(settings),
      PerfAutoPackPercentage: this.ValidatePerfAutoPackPercentage(settings),
      NetPressureForBridging: this.ValidateNetPresForBridging(settings),
      NozzleLeakoffBeta: this.ValidateNozzleLeakoffBeta(settings),
      PipeThermalConductivity: this.ValidatePipeThermalConductivity(settings),
      CementThickness: this.ValidateCementThickness(settings),
      CementThermalConductivity: this.ValidateCementThermalConductivity(settings),
      FilterCakeThickness: this.ValidateFilterCakeThickness(settings),
      FilterCakeThermalConductivity: this.ValidateFilterCakeThermalConductivity(settings),
      MinThermalCellLength: this.ValidateMinThermalCellLength(settings),
      MaxThermalCellLength: this.ValidateMaxThermalCellLength(settings),
      MinDeviationForHorizontal: this.ValidateMinDeviationForHorizontal(settings),
      HorizontalThermalMultiplier: this.ValidateHorizontalThermalMultiplier(settings),
      ScreenThermalConductivity: this.ValidateScreenThermalConductivity(settings),
      PermeabilityRatio: this.ValidatePermeabilityRatio(settings),
      ShearRate: this.ValidateShearRate(settings),
      ModelScreenHandlingLength: this.ValidateModelScreenHandlingLength(settings, currentModuleType),

      TreatingLineRoughness: this.ValidateTreatingLineRoughness(settings),
      ChokeLineRoughness: this.ValidateChokeLineRoughness(settings),
      WorkstringRoughness: this.ValidateWorkstringRoughness(settings),
      ScreenAnnulusRoughness: this.ValidateScreenAnnRoughness(settings),
      WashpipeRoughness: this.ValidateWashpipeRoughness(settings),
      BlankAnnulusRoughness: this.ValidateBlankAnnRoughness(settings),
      WashpipeAnnulusRoughness: this.ValidateWashpipeAnnRoughness(settings),
      WorkstringAnnulusRoughness: this.ValidateWorkstringAnnRoughness(settings),
      ThirdAnnulusRoughness: this.ValidateThirdAnnulusRoughness(settings),
      BottomholeGravelConcMD: this.ValidateBottomholeGravelConcMD(settings, maxLowerCompletionBottomMD),
    };

    if (currentModuleType === ModuleType.Simulate_Disp) {
      error = {
        ...error,
        FpNumParamsNz: this.ValidateFpNumParamsNz(settings),
        FpNumParamsNtheta: this.ValidateFpNumParamsNtheta(settings),
        FpNumParamsTimeout: this.ValidateFpNumParamsTimeout(settings),
        FpNumParamsTolDP: this.ValidateFpNumParamsTolDP(settings),
        FpOptionsKinertialValue: this.ValidateFpOptionsKinertialValue(settings),
        FpOptionsDDF: this.ValidateFpOptionsDDF(settings),
        FpMinComputationalSections: this.validateFpMinComputationalSections(settings),
        FpMinCellLength: this.ValidateFpMinCellLength(settings),
        FpMergeCellLength: this.ValidateFpMergeCellLength(settings),
        FpMergeVolumeRatio: this.ValidateFpMergeVolumeRatio(settings),
        FpDataComparisonTolerance: this.ValidateFpDataComparisonTolerance(settings),
        FpNumParamsCFL: this.ValidateFpNumParamsCFL(settings),
        FpOptionsContentrationTolerance: this.ValidateFpOptionsContentrationTolerance(settings),
      };
    }
    return { ...settings, error, isValid: noErrors(error, ['ShearRate']) };
  }

  private static validateMaxVolStep(settings: SettingsDto): string {
    if (settings.MaxVolumeStep <= 0) {
      return 'Max volume step must be greater than 0';
    }
    return '';
  }

  private static validateRateChangeTol(settings: SettingsDto): string {
    if (settings.RateChangeTolerance <= 0) {
      return 'Rate tolerance must be greater than 0';
    }
    const maxRCT_BPM = RateConverter.fromSi(settings.RateChangeTolerance, RateUnit.Barrel_per_minute);
    if (maxRCT_BPM > MAX_RATE_CHANGE_TOLERANCE) {
      const encodeValue = ConvertUnitPipe.encode(UnitSystem.Rate, RateConverter.toSi(MAX_RATE_CHANGE_TOLERANCE, RateUnit.Barrel_per_minute));
      return `Rate tolerance must be less than or equal to ${encodeValue}`;
    }
    return '';
  }

  private static ValidateGravelConcTolerance(settings: SettingsDto): string {
    if (settings.GravelConcentrationChangeTolerance <= 0) {
      return 'Gravel concentration tolerance must be greater than 0';
    }
    const maxGCT = SolidConcentrationConverter.fromSi(settings.GravelConcentrationChangeTolerance, SolidConcentrationUnit.Pound_per_US_gallon);
    if (maxGCT > MAX_GRAVEL_CONCENTRATION_TOLERANCE) {
      const encodeValue = ConvertUnitPipe.encode(
        UnitSystem.Solid_Concentration,
        SolidConcentrationConverter.toSi(MAX_GRAVEL_CONCENTRATION_TOLERANCE, SolidConcentrationUnit.Pound_per_US_gallon),
      );
      return `Gravel concentration tolerance must be less than or equal to ${encodeValue}`;
    }
    return '';
  }

  private static ValidateChokePressureTolerance(settings: SettingsDto): string {
    if (settings.ChokePressureChangeTolerance <= 0) {
      return 'Choke pressure tolerance must be greater than 0';
    }
    return '';
  }

  private static ValidateRopingPercentage(settings: SettingsDto): string {
    if (settings.IsRopingPercentage && (settings.RopingPercentage < 0 || settings.RopingPercentage >= 100)) {
      return 'Roping percentage must be between 0 and 100';
    }

    return '';
  }

  private static ValidateRopingVolume(settings: SettingsDto, displacementToPacker: number, volumeUnit?: VolumeUnit): string {
    if (!settings.IsRopingPercentage && (settings.RopingVolume < 0 || settings.RopingVolume >= displacementToPacker)) {
      // when validating from backend, no need for formatting units
      if (volumeUnit == null) {
        return `Roping volume must be between 0 and ${displacementToPacker}}`;
      }

      const displacementPackerConverted = MathHelpers.round(VolumeConverter.fromSi(displacementToPacker, volumeUnit), 4);
      const volumeUnitSymbol = VolumeConverter.getSymbol(volumeUnit);

      return `Roping volume must be between 0 and ${displacementPackerConverted} ${volumeUnitSymbol}`;
    }
    return '';
  }

  private static ValidateMaxReservoirCells(settings: SettingsDto): string {
    if (settings.MaxNumberOfReservoirCells <= 0) {
      return 'Max number of reservoir cells must be greater than 0';
    }
    return '';
  }

  private static ValidateDischargeCoefficient(settings: SettingsDto): string {
    if (settings.DischargeCoefficient <= 0 || settings.DischargeCoefficient >= 1) {
      return 'Discharge coefficient must be between 0 and 1';
    }
    return '';
  }

  private static ValidateICDRatDisMinTolerance(settings: SettingsDto): string {
    if (settings.ICDRateDistributionMinTolerance <= 0) {
      return 'ICD Rate Distribution Minimum Tolerance must be greater than 0';
    }
    const maxRateDis = PressureConverter.fromSi(settings.ICDRateDistributionMinTolerance, PressureUnit.Pound_per_square_inch);
    if (maxRateDis > ICD_RATE_DISTRIBUTION_MINIMUM_TOLERANCE) {
      const encodeValue = ConvertUnitPipe.encode(
        UnitSystem.Pressure,
        PressureConverter.toSi(ICD_RATE_DISTRIBUTION_MINIMUM_TOLERANCE, PressureUnit.Pound_per_square_inch),
      );
      return `ICD Rate Distribution Minimum Tolerance must be less than or equal to ${encodeValue}`;
    }
    return '';
  }

  private static ValidateTreatingLineRoughness(settings: SettingsDto): string {
    if (settings.IsRoughness && settings.TreatingLineRoughness < 0) {
      return 'Treating line roughness must be greater than or equal to 0';
    }
    return '';
  }

  private static ValidateChokeLineRoughness(settings: SettingsDto): string {
    if (settings.IsRoughness && settings.ChokeLineRoughness < 0) {
      return 'Choke line roughness must be greater than or equal to 0';
    }
    return '';
  }

  private static ValidateWorkstringRoughness(settings: SettingsDto): string {
    if (settings.IsRoughness && settings.WorkstringRoughness < 0) {
      return 'Workstring roughness must be greater than or equal to 0';
    }
    return '';
  }

  private static ValidateScreenAnnRoughness(settings: SettingsDto): string {
    if (settings.IsRoughness && settings.ScreenAnnulusRoughness < 0) {
      return 'Screen annulus roughness must be greater than or equal to 0';
    }
    return '';
  }

  private static ValidateWashpipeRoughness(settings: SettingsDto): string {
    if (settings.IsRoughness && settings.WashpipeRoughness < 0) {
      return 'Washpipe roughness must be greater than or equal to 0';
    }
    return '';
  }

  private static ValidateBlankAnnRoughness(settings: SettingsDto): string {
    if (settings.IsRoughness && settings.BlankAnnulusRoughness < 0) {
      return 'Blank annulus roughness must be greater than or equal to 0';
    }

    return '';
  }

  private static ValidateWashpipeAnnRoughness(settings: SettingsDto): string {
    if (settings.IsRoughness && settings.WashpipeAnnulusRoughness < 0) {
      return 'Washpipe annulus roughness must be greater than or equal to 0';
    }
    return '';
  }

  private static ValidateWorkstringAnnRoughness(settings: SettingsDto): string {
    if (settings.IsRoughness && settings.WorkstringAnnulusRoughness < 0) {
      return 'Workstring annulus roughness must be greater than or equal to 0';
    }
    return '';
  }

  private static ValidateThirdAnnulusRoughness(settings: SettingsDto): string {
    if (settings.IsRoughness && settings.ThirdAnnulusRoughness < 0) {
      return 'Third annulus roughness must be greater than or equal to 0';
    }
    return '';
  }

  private static validateStokesSettlingMultiplier(settings: SettingsDto): string {
    if (settings.StokesSettlingMultiplier <= 0) {
      return 'Stokes settling multiplier must be greater than 0';
    }
    return '';
  }

  private static ValidateBridgingRatio(settings: SettingsDto): string {
    if (settings.BridgingRatio < 0.8 || settings.BridgingRatio >= 1) {
      return 'Bridging ratio must be between 0.8 and 1';
    }
    return '';
  }

  private static ValidateNumberOfGrainDiameters(settings: SettingsDto): string {
    if (settings.UseNumberOfGrainDiametersForBridging && settings.NumberOfGrainDiametersToBridge <= 0) {
      return 'Number of grain diameters must be greater than 0';
    }
    return '';
  }

  private static ValidatePerfAutoPackPercentage(settings: SettingsDto): string {
    if (settings.PerfAutoPackPercentage < 0 || settings.PerfAutoPackPercentage > 100) {
      return 'Perforation auto pack percentage must be between 0 and 100';
    }
    return '';
  }

  private static ValidateNetPresForBridging(settings: SettingsDto): string {
    if (settings.NetPressureForBridging < 0) {
      return 'Net pressure for bridging must be greater than or equal to 0';
    }
    return '';
  }

  private static ValidateNozzleLeakoffBeta(settings: SettingsDto): string {
    if (settings.IsNozzleLeakoffBeta && settings.NozzleLeakoffBeta <= 0) {
      return 'Nozzle leakoff beta must be greater than 0';
    }
    return '';
  }

  private static ValidatePipeThermalConductivity(settings: SettingsDto): string {
    if (settings.PipeThermalConductivity <= 0) {
      return 'Pipe thermal conductivity must be greater than 0';
    }
    return '';
  }

  private static ValidateCementThickness(settings: SettingsDto): string {
    if (settings.CementThickness <= 0) {
      return 'Cement thickness must be greater than 0';
    }
    return '';
  }

  private static ValidateCementThermalConductivity(settings: SettingsDto): string {
    if (settings.CementThermalConductivity <= 0) {
      return 'Cement thermal conductivity must be greater than 0';
    }
    return '';
  }

  private static ValidateFilterCakeThickness(settings: SettingsDto): string {
    if (settings.FilterCakeThickness <= 0) {
      return 'Filter cake thickness must be greater than 0';
    }
    return '';
  }

  private static ValidateFilterCakeThermalConductivity(settings: SettingsDto): string {
    if (settings.FilterCakeThermalConductivity <= 0) {
      return 'Filter cake thermal conductivity must be greater than 0';
    }
    return '';
  }

  private static ValidateMaxThermalCellLength(settings: SettingsDto): string {
    if (settings.MaxThermalCellLength <= 0) {
      return 'Thermal cell length must be greater than 0';
    } else if (settings.MaxThermalCellLength <= settings.MinThermalCellLength) {
      return 'Max thermal cell length must be greater than min thermal cell length';
    }
    return '';
  }

  private static ValidateMinThermalCellLength(settings: SettingsDto): string {
    if (settings.MinThermalCellLength <= 0) {
      return 'Min thermal cell length must be greater than 0';
    } else if (settings.MinThermalCellLength >= settings.MaxThermalCellLength) {
      return 'Min thermal cell length must be less than max thermal cell length';
    }
    return '';
  }

  private static ValidateMinDeviationForHorizontal(settings: SettingsDto): string {
    const encodeValueMin = ConvertUnitPipe.encode(UnitSystem.Angle, 0);
    const encodeValueMax = ConvertUnitPipe.encode(UnitSystem.Angle, 180);
    if (settings.MinDeviationForHorizontal < 0 || settings.MinDeviationForHorizontal > 180) {
      return `Min deviation for horizontal must be between ${encodeValueMin} and ${encodeValueMax}`;
    }
    return '';
  }

  private static ValidateHorizontalThermalMultiplier(settings: SettingsDto): string {
    if (settings.HorizontalThermalMultiplier <= 0) {
      return 'Horizontal thermal multiplier must be greater than 0';
    }
    return '';
  }

  private static ValidateScreenThermalConductivity(settings: SettingsDto): string {
    if (settings.ScreenThermalConductivity <= 0) {
      return 'Screen thermal conductivity must be greater than 0';
    }
    return '';
  }

  private static ValidatePermeabilityRatio(settings: SettingsDto): string {
    if (settings.PermeabilityRatio <= 0) {
      return 'Permeability ratio must be greater than 0';
    }
    return '';
  }

  private static ValidateShearRate(settings: SettingsDto): string {
    if (settings.ShearRate <= 0) {
      return 'Shear rate must be greater than 0';
    }
    return '';
  }

  private static ValidateBottomholeGravelConcMD(settings: SettingsDto, maxLowerCompletionBottomMD: number): string {
    if (!settings.IsBottomholeGravelConcMD) {
      return '';
    }

    if (settings.BottomholeGravelConcMD <= 0) {
      return 'Bottomhole Gravel Conc MD must be greater than 0';
    }

    if (settings.BottomholeGravelConcMD >= maxLowerCompletionBottomMD) {
      return 'Bottomhole Gravel Conc MD must be less than lower completion bottom MD';
    }

    return '';
  }

  //region FluidPro

  private static ValidateFpNumParamsNz(settings: SettingsDto): string {
    if (this.isFluidProValidatorsDisabled()) {
      return '';
    }
    if (settings.FpNumParamsNz < 200 || settings.FpNumParamsNz > 10000) {
      return 'No. of Axial Cells must be between 200 and 10000';
    }

    return '';
  }

  private static ValidateFpNumParamsNtheta(settings: SettingsDto): string {
    if (this.isFluidProValidatorsDisabled()) {
      return '';
    }

    if (settings.FpNumParamsNtheta < 10 || settings.FpNumParamsNtheta > 30) {
      return 'No. of Radial Cells must be between 10 and 30';
    }

    return '';
  }

  private static ValidateFpNumParamsTimeout(settings: SettingsDto): string {
    const encodeValueMin = ConvertUnitPipe.encode(UnitSystem.Time, 1);
    const encodeValueMax = ConvertUnitPipe.encode(UnitSystem.Time, 100);
    if (settings.FpNumParamsTimeout < 1 || settings.FpNumParamsTimeout > 100) {
      return `Output Time Interval must be between ${encodeValueMin} and ${encodeValueMax}`;
    }
    return '';
  }

  private static ValidateFpNumParamsTolDP(settings: SettingsDto): string {
    const encodeValueMin = ConvertUnitPipe.encode(UnitSystem.Pressure, 0.000001);
    const encodeValueMax = ConvertUnitPipe.encode(UnitSystem.Pressure, 1);

    if (settings.FpNumParamsTolDP < 0.000001 || settings.FpNumParamsTolDP > 1) {
      return `Pressure Tolerance must be between ${encodeValueMin} and ${encodeValueMax}`;
    }

    return '';
  }

  private static ValidateFpOptionsKinertialValue(settings: SettingsDto): string {
    if (settings.FpOptionsKinertialValue < 2 || settings.FpOptionsKinertialValue > 3000) {
      return 'Kinertial value must be between 2 and 3000';
    }

    return '';
  }

  private static ValidateFpOptionsDDF(settings: SettingsDto): string {
    if (settings.FpOptionsDDF < 1 || settings.FpOptionsDDF > 100) {
      return 'Diffusivity Multiplier must be between 1 and 100';
    }

    return '';
  }

  private static validateFpMinComputationalSections(settings: SettingsDto): string {
    if (settings.FpMinComputationalSections <= 0) {
      return 'Min Computational Sections must be greater than 0';
    }

    return '';
  }

  private static ValidateFpMinCellLength(settings: SettingsDto): string {
    if (settings.FpMinCellLength <= 0) {
      return 'Min Cell Length must be greater than 0';
    }
    return '';
  }

  private static ValidateFpMergeCellLength(settings: SettingsDto): string {
    if (settings.FpMergeCellLength <= settings.FpMinCellLength) {
      return 'Merge Cell Length must be greater than Min Cell Length';
    }
    return '';
  }

  private static ValidateFpMergeVolumeRatio(settings: SettingsDto): string {
    if (settings.FpMergeVolumeRatio <= 0 || settings.FpMergeVolumeRatio >= 1) {
      return 'Merge Volume Ratio must be between 0 and 1';
    }
    return '';
  }

  private static ValidateFpDataComparisonTolerance(settings: SettingsDto): string {
    if (settings.FpDataComparisonTolerance <= 0.00000001 || settings.FpDataComparisonTolerance >= 0.0001) {
      return 'Data Comparison Tolerance must be between 0.00000001 and 0.0001';
    }
    return '';
  }

  private static isFluidProValidatorsDisabled(): boolean {
    if (typeof window === 'undefined') {
      return false;
    }
    return window.localStorage['DisableFluidProSettingsValidation'] != null;
  }

  private static ValidateFpNumParamsCFL(settings: SettingsDto): string {
    if (settings.FpNumParamsCFL < 0.1 || settings.FpNumParamsCFL > 1) {
      return 'CFL must be between 0.1 and 1';
    }

    return '';
  }

  private static ValidateFpOptionsContentrationTolerance(settings: SettingsDto): string {
    if (settings.FpOptionsContentrationTolerance < 0.01 || settings.FpOptionsContentrationTolerance > 0.1) {
      return 'CT must be between 0.01 and 0.1';
    }

    return '';
  }

  private static ValidateModelScreenHandlingLength(settings: SettingsDto & IModuleState, appModuleType: ModuleType): string {
    const fluidProNotSupportedError = validateFeatureNotSupportedInFluidPro(appModuleType);
    if (settings.ModelScreenHandlingLength && fluidProNotSupportedError) {
      return fluidProNotSupportedError;
    }

    return '';
  }
}
