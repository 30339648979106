import { sha224 } from 'js-sha256';

export interface IFile {
  Repository: Repository;
  Name: string; // serves as full path on Electron
  OrgName?: string;
  Folder: string[];
  FileType: FileType;
  IsEmpty?: boolean;
  Children?: IFile[];
  TrashTimestamp?: number;
  LastOpenTimestamp?: number;
  FileSize?: number;
  LastChangedTime?: number;
}

export type FileType = 'ppf-file' | 'folder' | 'type-in-filename' | 'csv' | 'backup-file' | 'txt' | 'json';
export type Repository = 'personal' | 'temp' | 'shared' | 'demo' | 'template' | 'trash' | 'electron' | 'backup';
export type FileMoveMode = 'delete' | 'move' | 'rename' | 'overwrite';

export interface IRecentDto {
  recentFiles: IFile[];
  recentFolders: IFile[];
}

export interface IFileSourceAndTarget {
  source: IFile;
  target: IFile;
  isSaveAs?: boolean;
}

export interface IMoveFilePayload extends IFileSourceAndTarget {
  mode: FileMoveMode;
}

export const validProjectFileExtensions = ['ppf', 'dpf', 'fpf'];

export const getFileHashFromFile = ({ Repository, Name, OrgName, Folder, FileType }: IFile): string =>
  sha224(`${Repository}${Name}${OrgName ?? ''}${Folder.join('|')}${FileType}`);

export const isFileDescendantOfFolder = (file: IFile, folder: IFile): boolean => {
  // eg: 'personal|folder1|folder2'
  const folderPath = [folder.Repository, ...folder.Folder, folder.Name].join('|');

  // eg: 'personal|folder1|folder2|folder3|file.ppf'
  const fileFolderPath = [file.Repository, ...file.Folder].join('|');

  return fileFolderPath.startsWith(folderPath);
};
