import { ColumnType, IGridColumnConfig } from '../../../../../../shared/components/grid/grid.interfaces';
import { UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { WellFluid } from '@dunefront/common/modules/pumping/model/well-fluid/well-fluid';
import { ReportWellFluid } from '../../../../../../shared/helpers/document-generator/generators/doc/sections/pumping-report-generator.helper';

export const GRAVEL_TYPES_LOOKUP_SRC_ID = 'GRAVEL';
export const FLUID_TYPES_LOOKUP_SRC_ID = 'FLUID';
export const FLUID_DESCRIPTION_LOOKUP_SRC_ID = 'FLUID_DESCRIPTION';

export const detailedFluidDefinitionConfig = (
  disabled: boolean,
  isWellFluidDepthByVolume: boolean,
  isFluidPro: boolean,
): IGridColumnConfig<WellFluid | ReportWellFluid>[] => [
  {
    disabled: false,
    visible: true,
    colId: ' ',
    type: ColumnType.selection,
  },
  {
    disabled: disabled,
    visible: false,
    colId: 'FlowPathDescription',
    lookupDataSourceType: FLUID_DESCRIPTION_LOOKUP_SRC_ID,
    type: ColumnType.select,
  },
  {
    disabled: disabled,
    visible: isWellFluidDepthByVolume,
    colId: 'Volume',
    type: ColumnType.number,
    unitSystem: UnitSystem.Liquid_Volume,
    headerText: 'Volume (top to bottom)',
    reportingHeaderName: 'Volume',
    matchingStrings: ['vol'],
  },
  {
    disabled: true,
    visible: isWellFluidDepthByVolume,
    colId: 'CumulativeVolume',
    type: ColumnType.number,
    unitSystem: UnitSystem.Liquid_Volume,
    headerText: 'Cumulative Volume',
  },
  {
    disabled: true,
    visible: !isWellFluidDepthByVolume,
    colId: 'TopMD',
    unitSystem: UnitSystem.Long_Length,
    type: ColumnType.number,
    headerText: 'Top MD',
    matchingStrings: ['top'],
  },
  {
    disabled: disabled,
    visible: !isWellFluidDepthByVolume,
    colId: 'BottomMD',
    type: ColumnType.number,
    unitSystem: UnitSystem.Long_Length,
    headerText: 'Bottom MD',
    matchingStrings: ['bottom'],
  },
  {
    disabled: disabled,
    visible: true,
    colId: 'FluidId',
    lookupDataSourceType: FLUID_TYPES_LOOKUP_SRC_ID,
    type: ColumnType.select,
    headerText: 'Fluid Name',
    reportingHeaderName: 'Fluid',
    matchingStrings: ['fluid'],
  },
  {
    disabled: disabled,
    visible: !isFluidPro,
    colId: 'GravelId',
    lookupDataSourceType: GRAVEL_TYPES_LOOKUP_SRC_ID,
    type: ColumnType.select,
    headerText: 'Gravel Name',
    reportingHeaderName: 'Gravel',
    matchingStrings: ['gravel name'],
  },
  {
    disabled: disabled,
    visible: !isFluidPro,
    colId: 'GravelConcentration',
    type: ColumnType.number,
    unitSystem: UnitSystem.Solid_Concentration,
    headerText: 'Gravel Concentration',
    reportingHeaderName: 'Gravel Conc.',
    matchingStrings: ['conc'],
  },
  {
    disabled: disabled,
    visible: !isFluidPro,
    colId: 'IsPacked',
    type: ColumnType.checkbox,
    headerText: 'Is Packed',
    width: 100,
    matchingStrings: ['pack'],
  },
];
