import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-left-nav-df-logo',
  templateUrl: 'left-nav-df-logo.component.html',
  styleUrls: ['./left-nav-df-logo.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [],
})
export class LeftNavDfLogoComponent {
  @Input() public isExpanded = false;
  @Input() public isHomePageMode = false;
}
