import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ReportInfoModuleFeatureName, ReportInfoModuleState } from './report-info.reducer';
import { getCurrentRangeId, getRanges } from '../range/range.selectors';
import { getCurrentAppModuleType } from '../ui/ui.selectors';
import { getGroupedReportingTabs } from '../reporting/reporting.selectors';
import { isSimulateBased, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { TreeSelectorListItem } from '../../shared/components/filterable-tree/filterable-tree.component';

export const getReportInfoState = createFeatureSelector<ReportInfoModuleState>(ReportInfoModuleFeatureName);

export const getReportInfo = createSelector(getReportInfoState, (state) => state.reportInfo);
export const getReportInfoRangesConfig = createSelector(getReportInfoState, (state) => state.rangesConfig);
export const getReportInfoFields = createSelector(
  getReportInfoState,
  getCurrentRangeId,
  getGroupedReportingTabs,
  (state, currentRangeId, groupedReportingTabs) => {
    const { RangeIds, ReportingTabIds } = state.reportInfoFields;

    // when RangeIds are set just return state content
    if (RangeIds != null) {
      return {
        ...state.reportInfoFields,
        RangeIds,
        ReportingTabIds: ReportingTabIds ?? [],
      };
    }

    // when RangeIds == null (not initialised) -> return current range and it's reporting tabs
    return {
      ...state.reportInfoFields,
      RangeIds: [currentRangeId],
      ReportingTabIds: (groupedReportingTabs[currentRangeId] ?? []).map((tab) => tab.Id),
    };
  },
);

export const getReportInfoIsCharts = createSelector(getReportInfoFields, (infoFields) => infoFields.IsCharts);

// Selector that returns all sorted ranges and reporting tabs (if selected in config)
export const getReportingInfoRangesSelectionComponentItems = createSelector(
  getReportInfoRangesConfig,
  getReportInfoIsCharts,
  getCurrentAppModuleType,
  getRanges,
  getGroupedReportingTabs,
  (rangesConfig, isCharts, moduleType, ranges, groupedReportingTabs) => {
    const rangesSelectionOrder = rangesConfig.itemsOrder;
    const storedOrderMatchesRangeItems =
      rangesSelectionOrder.length === ranges.ids.length && ranges.ids.every((r) => rangesSelectionOrder.includes(+r));

    const sortedRangeIds = storedOrderMatchesRangeItems ? rangesSelectionOrder : Object.keys(groupedReportingTabs).map((r) => parseInt(r));
    const rangesToDisplay: number[] = isSimulateBased(moduleType) ? [RangeConstants.EmptyRangeId] : sortedRangeIds;

    const items: TreeSelectorListItem[] = rangesToDisplay.map((rangeId: number) => {
      // add charts items only when showCharts is enabled
      const chartItems = isCharts
        ? groupedReportingTabs[rangeId].map((chart) => ({
            label: chart.TabName,
            value: chart.Id,
          }))
        : [];

      return {
        label: isSimulateBased(moduleType) ? 'Simulate' : (ranges.dict[rangeId]?.Name ?? ''),
        value: rangeId,
        items: chartItems,
      };
    });
    return items;
  },
);

export const getIsRangesSelectionComponentVisible = createSelector(
  getCurrentAppModuleType,
  getReportInfoFields,
  (appModule, reportInfoFields) => {
    if (isSimulateBased(appModule)) {
      return reportInfoFields.IsCharts;
    }
    const isAvailableForModule =
      appModule === ModuleType.Evaluate || appModule === ModuleType.Data_Analysis || appModule === ModuleType.Trend_Analysis;
    const isPumping = reportInfoFields.IsPumping;
    const isTrendAnalysis = reportInfoFields.IsTrendAnalysisInputs;
    const isAnyResult =
      reportInfoFields.IsEvaluationAnimation ||
      reportInfoFields.IsSimulationAnimation ||
      reportInfoFields.IsWellVisualization ||
      reportInfoFields.IsSummary ||
      reportInfoFields.IsCharts;

    return isAvailableForModule && (isPumping || isAnyResult || isTrendAnalysis);
  },
);
