import { getRowsForCalculations, ITableRow, ITableState } from '../../../../common/common-grid.interfaces';
import { PumpingSchedule } from './pumping-schedule';
import { Pumping } from '../pumping/pumping';
import { IPumpingValidationDependencies, PumpingStateByRange, ValidatedPumpingModuleState } from '../../pumping-module.state';
import { IDictionaryWithArray, noErrors } from '../../../../common/state.helpers';
import { RateConverter } from '../../../../unit-converters/converters/rate/rate.converter';
import { RateUnit, UnitSystem } from '../../../../dto/unit-system.dto';
import { ConvertUnitPipe } from '../../../units/convert-unit.pipe/convert-unit.pipe';
import { PumpingDirection, PumpingDto, PumpingExtensions, ToolPosition, VariableChokeSetting } from '../../dto/pumping.dto';
import { WellCalculations } from '../../../well/model/well.calculations';
import { IError } from '../../../../common/common-state.interfaces';
import { MAX_GRAVEL_CONCENTRATION_SI, MaxRate_BPM } from '../../../../common/constants';
import { ValidateGravel, ValidateGravelDensity } from '../../../gravel/model/gravel.validation';
import { Gravel } from '../../../gravel/model/gravel';
import { isFluidValid } from '../../../fluid/model/fluid.validation';
import { Fluid } from '../../../fluid/model/fluid';
import { RangeConstants } from '../../../../dto/range.dto';
import { PumpedFluidAndGravelDto } from '../../dto/pumped-fluid-and-gravel.dto';
import { PumpingScheduleCalculations } from './pumping-schedule.calculations';

import { isSimulateBased, ModuleType } from '../../../scenario/scenario.dto';

export class PumpingScheduleValidation {
  public static validatePumpingScheduleTab(
    pumpingState: ValidatedPumpingModuleState,
    pumpingSchedule: ITableState<PumpingSchedule>,
    deps: IPumpingValidationDependencies,
  ): ValidatedPumpingModuleState {
    if (deps.rangeId === RangeConstants.EntireRangeId) {
      return {
        ...pumpingState,
        pumpingSchedule: { ...pumpingState.pumpingSchedule, isValid: true },
        isScheduleScreenValid: true,
      };
    }

    const newPumpingState = { ...pumpingState };
    const newPumpingSchedule = isSimulateBased(deps.appModuleType)
      ? this.validatePumpingScheduleGrid(pumpingState, pumpingSchedule, deps)
      : { ...pumpingSchedule, isValid: true };

    const newPumpedFluidAndGravel = isSimulateBased(deps.appModuleType)
      ? { ...pumpingState.pumpedFluidAndGravel, isValid: true }
      : this.validatePumpedFluidAndGravelGrid(pumpingState, pumpingState.pumpedFluidAndGravel, deps);

    return {
      ...newPumpingState,
      pumpingSchedule: newPumpingSchedule,
      pumpedFluidAndGravel: newPumpedFluidAndGravel,
      isScheduleScreenValid: newPumpingSchedule.isValid && newPumpedFluidAndGravel.isValid,
    };
  }

  private static validatePumpingScheduleGrid(
    pumpingState: PumpingStateByRange,
    pumpingSchedule: ITableState<PumpingSchedule>,
    deps: IPumpingValidationDependencies,
  ): ITableState<PumpingSchedule> {
    let isValid = true;
    const newPumpingScheduleRows = pumpingSchedule.rows.map((row, rowIndex) => {
      if (row.rowType === 'insert-row') {
        return row;
      }
      const newRow = this.ValidatePumpingScheduleRow(pumpingState, pumpingSchedule, deps, rowIndex);
      isValid = isValid && newRow.isValid;
      return newRow;
    });

    return { ...pumpingSchedule, rows: newPumpingScheduleRows, isValid };
  }

  private static ValidatePumpingScheduleRow(
    pumpingState: PumpingStateByRange,
    pumpingSchedule: ITableState<PumpingSchedule>,
    deps: IPumpingValidationDependencies,
    rowIndex: number,
  ): ITableRow<PumpingSchedule> {
    const row = pumpingSchedule.rows[rowIndex];
    const { pumping } = pumpingState;
    const isFluidPro = deps.appModuleType === ModuleType.Simulate_Disp;

    const error: IError<PumpingSchedule> = {
      PumpRate: this.ValidatePumpRate(row),
      GravelId: isFluidPro ? '' : this.ValidateScheduleGravelName(row, pumping, deps.gravels, deps.fluids),
      FluidId: this.ValidateFluidId(row, deps.fluids),
      GravelConcentration: isFluidPro ? '' : this.ValidateScheduleGravelConcentration(row),
      StageVolume: this.ValidateStageVolume(row),
      ReturnRate: this.ValidateReturnRate(row, pumping, deps),
      ChokePressure: this.ValidateChokePressure(row, pumping),
    };
    const isValid = noErrors(error);
    return { ...row, isValid, error: error };
  }

  public static ValidateStageVolume(row: ITableRow<PumpingSchedule>): string {
    if (row.rowData.StageVolume <= 0) {
      return 'Slurry volume must be greater than zero';
    }

    return '';
  }

  public static validatePumpedFluidAndGravelGrid(
    pumpingState: PumpingStateByRange,
    gridState: ITableState<PumpedFluidAndGravelDto>,
    deps: IPumpingValidationDependencies,
  ): ITableState<PumpedFluidAndGravelDto> {
    let isValid = true;
    const newPumpingScheduleRows = gridState.rows.map((row, rowIndex) => {
      // At least one PumpedFluidAndGravel is required. In current app version empty entry is automatically added,
      // but files imported from PackPro 5, may have no rows. If so, we validate 'insert-row' to show errors and block evaluate

      if (row.rowType === 'insert-row' && gridState.rows.length > 1) {
        return row;
      }

      const newRow = this.validatePumpedFluidAndGravelGridRow(pumpingState, gridState, deps, rowIndex);
      isValid = isValid && newRow.isValid;
      return newRow;
    });

    return { ...gridState, rows: newPumpingScheduleRows, isValid };
  }

  public static validatePumpedFluidAndGravelGridRow(
    pumpingState: PumpingStateByRange,
    gridState: ITableState<PumpedFluidAndGravelDto>,
    deps: IPumpingValidationDependencies,
    rowIndex: number,
  ): ITableRow<PumpedFluidAndGravelDto> {
    const row = gridState.rows[rowIndex];
    const { pumping } = pumpingState;
    const error: IError<PumpedFluidAndGravelDto> = {
      FluidId: this.ValidatePumpedFluidId(row, deps.fluids),
      GravelId: this.ValidateScheduleGravelName(row, pumping, deps.gravels, deps.fluids),
      Volume: this.ValidateVolumePumped(row),
    };
    const isValid = noErrors(error);
    return { ...row, isValid, error: error };
  }

  public static ValidateVolumePumped(row: ITableRow<PumpedFluidAndGravelDto>): string {
    if (row.rowData.Volume <= 0) {
      return 'Volume Pumped must be greater than zero';
    }
    return '';
  }

  public static ValidatePumpRate(row: ITableRow<PumpingSchedule>): string {
    const pumpRate = row.rowData.PumpRate;
    const maxRateSi = RateConverter.toSi(MaxRate_BPM, RateUnit.Barrel_per_minute);
    if (pumpRate <= 0 || pumpRate > maxRateSi) {
      const encodedMaxRate = ConvertUnitPipe.encode(UnitSystem.Rate, maxRateSi);
      const encodedSymbol = ConvertUnitPipe.encodeUnitSymbol(UnitSystem.Rate);
      return `Pump rate must be between 0 and ${encodedMaxRate} ${encodedSymbol}`;
    }
    return '';
  }

  private static ValidateScheduleGravelName(
    row: ITableRow<PumpingSchedule> | ITableRow<PumpedFluidAndGravelDto>,
    pumping: Pumping,
    gravels: IDictionaryWithArray<Gravel>,
    fluids: IDictionaryWithArray<Fluid>,
  ): string {
    if (row.rowData.GravelId === null) {
      return 'Gravel must be selected.';
    }
    if (pumping.PumpingDirection === PumpingDirection.In_Annulus && row.rowData.GravelId !== 0) {
      return "Gravel can't be pumped when pumping direction is in annulus";
    }
    if (pumping.ToolPosition === ToolPosition.Washdown && row.rowData.GravelId !== 0) {
      return "Gravel can't be pumped when tool position is washdown";
    }

    const gravelError =
      ValidateGravel(gravels, row.rowData.GravelId) || ValidateGravelDensity(gravels, row.rowData.GravelId, fluids, row.rowData.FluidId);
    if (gravelError) {
      return gravelError;
    }
    return ValidateGravel(gravels, row.rowData.GravelId);
  }

  private static ValidateFluidId(row: ITableRow<PumpingSchedule>, fluids: IDictionaryWithArray<Fluid>): string {
    if (row.rowData.FluidId != null && row.rowData.FluidId > 0) {
      return isFluidValid(fluids, row.rowData.FluidId);
    }
    return 'Fluid must be selected.';
  }

  private static ValidatePumpedFluidId(row: ITableRow<PumpedFluidAndGravelDto>, fluids: IDictionaryWithArray<Fluid>): string {
    if (row.rowData.FluidId != null && row.rowData.FluidId > 0) {
      return isFluidValid(fluids, row.rowData.FluidId);
    }
    return 'Fluid must be selected.';
  }

  public static ValidateScheduleGravelConcentration(row: ITableRow<PumpingSchedule>): string {
    if ((row.rowData.GravelId ?? 0) > 0) {
      const gravelCon = row.rowData.GravelConcentration;
      const maxConcentrationSi = MAX_GRAVEL_CONCENTRATION_SI;

      if (gravelCon <= 0 || gravelCon > maxConcentrationSi) {
        const encodedMaxGravelConcentrationRate = ConvertUnitPipe.encode(UnitSystem.Solid_Concentration, maxConcentrationSi);
        const encodedSymbol = ConvertUnitPipe.encodeUnitSymbol(UnitSystem.Solid_Concentration);
        return `Gravel concentration must be between 0 and ${encodedMaxGravelConcentrationRate} ${encodedSymbol}`;
      }
    }

    return '';
  }

  private static ValidateReturnRate(row: ITableRow<PumpingSchedule>, pumping: Pumping, deps: IPumpingValidationDependencies): string {
    const { well, appModuleType } = deps;
    const returnRate = row.rowData.ReturnRate;
    const pumpRate = row.rowData.PumpRate;

    if (appModuleType === ModuleType.Simulate_Disp && returnRate !== pumpRate) {
      return 'Return rate must be equal to pump rate';
    }

    if (
      !PumpingScheduleCalculations.IsStageReturnRateZero(row.rowData, {
        isLossCalculated: well.IsLossRateCalculated,
        pumping,
      })
    ) {
      if (pumping.ToolPosition === ToolPosition.Reverse && returnRate !== pumpRate) {
        return 'Return rate must be equal to pump rate in reverse position';
      } else if (PumpingExtensions.getVariableChoke(pumping) !== VariableChokeSetting.Managed_Rate && returnRate <= 0) {
        //Return rate can only be zero if the variable choke option is used
        return 'Return rate must be greater than zero';
      } else if (PumpingExtensions.getVariableChoke(pumping) === VariableChokeSetting.Managed_Rate && returnRate < 0) {
        //Return rate can only be zero if the variable choke option is used
        return 'Return rate must be greater than or equal to zero';
      } else if (returnRate > pumpRate) {
        return 'Return rate must be less than or equal to pump rate';
      } else if (!well.IsLossRateCalculated) {
        const isLossSpecified = WellCalculations.IsLossSpecified(getRowsForCalculations(well.ZoneData.rows));
        if (!isLossSpecified && returnRate !== pumpRate) {
          return 'Return rate must be equal to pump rate when there are no loss zones defined';
        }
      }
    }
    return '';
  }

  private static ValidateChokePressure(row: ITableRow<PumpingSchedule>, pumping: Pumping): string {
    if (row.rowData.ChokePressure < 0 && pumping.PumpingDirection === PumpingDirection.In_Annulus) {
      return `Controlled Mud Level (CML) is not supported when pumping into the annulus`;
    }
    return '';
  }
}

export interface IPumpingScheduleValidationDeps {
  isLossCalculated: boolean;
  pumping: PumpingDto;
}
