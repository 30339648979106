<app-page-panel header="Chart Axis" [allowFullScreen]="false" (click)="onHelpChange('settings', 'chart-axis')">
  <div style="flex-direction: column">
    <app-radio-group
      [items]="[
        { value: false, text: 'Default' },
        { value: true, text: 'Custom' }
      ]"
      [source]="globalOptions"
      [key]="'IsOverrideChartAxis'"
      [isUiLockable]="false"
      id="IsOverrideChartAxis"
      dataCy="is-override-chart-axis-radio"
      class="grid-two-one"
      layout="horizontal"
      (valueChanged)="onValueChange($event)"
    >
    </app-radio-group>
    <app-chart-axis-style
      class="block m-top-1"
      [disabled]="!globalOptions.IsOverrideChartAxis"
      [axisStyle]="globalOptions"
      [defaultAxisStyle]="defaultGlobalOptions"
      (valueChanged)="onStyleValueChange($event)"
    ></app-chart-axis-style>


    <div class="mt-1">
      <label>X-Axis Margins:</label><label></label>

      <div class="axis-margins-row">
        <app-form-input
          label="Start"
          dataCy="axis-margin-x-start"
          unitLabel="%"
          appUnwrap
          [min]="0"
          [max]="50"
          [source]="globalOptions"
          [sourceDefaults]="defaultGlobalOptions"
          [disabled]="!globalOptions.IsOverrideChartAxis"
          [key]="'XAxisStartMarginPercent'"
          [decimalPlaces]="2"
          [unitType]="UnitType.None"
          [isUiLockable]="false"
          [warning]="isValueDiffThanDefault('XAxisStartMarginPercent')"
          (valueChanged)="onValueChange($event)"
        >
        </app-form-input>
        <app-check-box
          label="No margin when starting near 0"
          dataCy="axis-margin-x-no-margin-when-zero"
          [disabled]="!globalOptions.IsOverrideChartAxis || globalOptions.XAxisStartMarginPercent === 0"
          [source]="globalOptions"
          [sourceDefaults]="defaultGlobalOptions"
          [key]="'XAxisNoMarginWhenStartingNearZero'"
          [isUiLockable]="false"
          [id]="'XAxisNoMarginWhenStartingNearZero'"
          [warning]="isValueDiffThanDefault('XAxisNoMarginWhenStartingNearZero')"
          (valueChanged)="onValueChange($event)"
        >
        </app-check-box>


        <app-form-input
          label="End"
          dataCy="axis-margin-x-end"
          unitLabel="%"
          appUnwrap
          [min]="0"
          [max]="50"
          [labelWidth]="80"
          [isUiLockable]="false"
          [source]="globalOptions"
          [disabled]="!globalOptions.IsOverrideChartAxis"
          [sourceDefaults]="defaultGlobalOptions"
          [key]="'XAxisEndMarginPercent'"
          [decimalPlaces]="2"
          [unitType]="UnitType.None"
          [warning]="isValueDiffThanDefault('XAxisEndMarginPercent')"
          (valueChanged)="onValueChange($event)"
        >
        </app-form-input>
      </div>
    </div>
    <div class="mt-1">
      <label>Y-Axis Margins:</label><label></label>
      <div class="axis-margins-row">
        <app-form-input
          label="Start"
          unitLabel="%"
          dataCy="axis-margin-y-start"
          appUnwrap
          [min]="0"
          [max]="50"

          [isUiLockable]="false"
          [disabled]="!globalOptions.IsOverrideChartAxis"
          [source]="globalOptions"
          [sourceDefaults]="defaultGlobalOptions"
          [key]="'YAxisStartMarginPercent'"
          [decimalPlaces]="2"
          [unitType]="UnitType.None"
          [warning]="isValueDiffThanDefault('YAxisStartMarginPercent')"
          (valueChanged)="onValueChange($event)"
        >
        </app-form-input>
        <app-check-box
          label="No margin when starting near 0"
          dataCy="axis-margin-y-no-margin-when-zero"
          [disabled]="globalOptions.YAxisStartMarginPercent === 0 || !globalOptions.IsOverrideChartAxis"
          [source]="globalOptions"
          [sourceDefaults]="defaultGlobalOptions"
          [key]="'YAxisNoMarginWhenStartingNearZero'"
          [isUiLockable]="false"
          [id]="'YAxisNoMarginWhenStartingNearZero'"
          [warning]="isValueDiffThanDefault('YAxisNoMarginWhenStartingNearZero')"
          (valueChanged)="onValueChange($event)"
        >
        </app-check-box>


        <app-form-input
          label="End"
          dataCy="axis-margin-y-end"
          unitLabel="%"
          appUnwrap
          [min]="0"
          [max]="50"

          [isUiLockable]="false"
          [source]="globalOptions"
          [disabled]="!globalOptions.IsOverrideChartAxis"
          [sourceDefaults]="defaultGlobalOptions"
          [key]="'YAxisEndMarginPercent'"
          [decimalPlaces]="2"
          [unitType]="UnitType.None"
          [warning]="isValueDiffThanDefault('YAxisEndMarginPercent')"
          (valueChanged)="onValueChange($event)"
        >
        </app-form-input>

      </div>
    </div>

  </div>
</app-page-panel>
