import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'HighlightPhrasePipe',
})
export class HighlightPhrasePipePipe implements PipeTransform {
  public transform(filename: string, phrase?: string | null): string {
    if (phrase == null || phrase.length === 0) {
      return filename;
    }
    const regex = new RegExp(`(${phrase})`, 'gi');
    return filename.replace(regex, '<span class="highlight">$1</span>');
  }
}
