import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UnitConverter } from '@dunefront/common/unit-converters/converter.interfaces';
import { UnitConverterHelper } from '@dunefront/common/unit-converters/unit.converter.helper';
import { IUnitSystemDto, NoneUnit, UnitSystem } from '@dunefront/common/dto/unit-system.dto';
import { NoneConverter } from '@dunefront/common/unit-converters/converters/none-unit/none-unit.converter';
import { GridCellAlign } from '../../../../shared/components/grid/grid.interfaces';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import { selectCurrentUnitSystem } from '../../../../+store/units/units.selectors';
import { take } from 'rxjs/operators';
import { SurveyCalculations } from '@dunefront/common/modules/well/dto/survey/survey.dto';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BACKSPACE, DASH, DELETE, NUMPAD_MINUS, NUMPAD_PERIOD, PERIOD } from '@angular/cdk/keycodes';
import { DecimalNumberParser, ParsedValue } from '../decimal-number-parser';
import { areStringsTheSame } from '@dunefront/common/common/helpers';
import { getIsUiLocked } from '../../../../+store/ui/calc-engine-ui.selectors';
import { ModalService } from '../../../modals/modal.service';
import { InputsHelperService } from '../../../../shared/services/inputs-helper.service';
import { Survey } from '@dunefront/common/modules/well/model/survey/survey';
import { FormDataHelper } from '../../../../shared/components/form-components/base-form-component';
import {
  DataSource,
  DataSourceKey,
  DataSourceValue,
  ObjectChangeProp,
  PrimitiveChangeValue,
} from '@dunefront/common/common/common-state.interfaces';
import { getSurveyDataForCalculation } from '../../../../+store/well/well.selectors';
import { DEFAULT_INPUT_MAX_LENGTH } from '@dunefront/common/common/constants';

export const INPUTNUMBER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => Input2Component),
  multi: true,
};

export type StringOrNumber = string | number;

export enum AutoFocusPosition {
  START = 0,
  END = 1,
}

@Component({
  selector: 'app-input',
  templateUrl: 'input-2.component.html',
  styleUrls: ['input-2.component.scss'],
  providers: [INPUTNUMBER_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Input2Component<T> implements OnDestroy, OnChanges, OnInit, AfterViewInit {
  @Input() public unitSystem: UnitSystem = UnitSystem.None;
  public selectedUnitSystem: IUnitSystemDto | undefined;
  @ViewChild('input') public input: ElementRef | undefined;
  @ViewChild('inputAsLabel') public inputAsLabel: ElementRef | undefined;
  @Output() public primitiveValueChanged = new EventEmitter<PrimitiveChangeValue<DataSourceValue<T>>>();
  @Output() public valueChanged = new EventEmitter<ObjectChangeProp<T>>();

  @Output() public stopEditing = new EventEmitter<StopEditingKey>();
  @Output() public changeFocusCell = new EventEmitter();

  @Output() public keyPressed = new EventEmitter();

  @Input() public class = '';

  private _disabled = false;

  @Input() public showSymbol = true;
  @Input() public forceShowSymbol = false;

  @Input() public allowOptionalStringValue = false;
  @Input() public castNullToNumber = true;

  @Input()
  public set disabled(isDisabled: boolean) {
    this._disabled = isDisabled;
  }

  public get disabled(): boolean {
    return this._disabled || this.isReadOnlyMode;
  }

  @Input() public noBorderRadius = false; // remove
  @Input() public errorMessage: string | null | undefined = '';
  @Input() public warningMessage: string | null | undefined = '';
  @Input() public renderAsLabel = false;
  @Input() public alignCenter = false;
  @Input() public name: string | undefined;
  @Input() public unitLabel?: string;
  @Input() public warning = false;
  @Input() public placeholder?: string;
  @Input() public emitOnSaveChanges = false;
  @Input() public elementId!: string | undefined;
  @Input() public isGridInput = false;
  @Input() public align?: GridCellAlign;
  @Input() public isInsertRow!: boolean;
  @Input() public min?: number;
  @Input() public max?: number;
  @Input() public isStringComparisonStrict = false;
  @Input() public triggerOnKeyPress = false;
  @Input() public autoFocus: boolean | AutoFocusPosition = false;
  @Input() public overrideUnitType?: number;
  @Input() public isFullWidth = false;
  @Input() public width?: number;
  @Input() public isOnlyOneSelected = false;
  @Input() public selected = false;
  @Input() public trim = true;
  @Input() public maxVisibleNumberOfLetters = 25;
  @Input() public icon?: string;

  @Input()
  @HostBinding()
  public id = '';

  @Input() public offset: number | undefined;

  @Input()
  public value: StringOrNumber | undefined;

  // this is because there is a problem with change detection on grid when order is changed after update
  // grid should use those 2 properties instead of value
  @Input() public source: DataSource<T> | undefined;
  @Input() public sourceDefaults: DataSource<Partial<T>> | undefined;
  @Input() public key: DataSourceKey<T> | undefined;
  @Input() public initialInputValue?: number | string;

  @Input() public isTextInput?: boolean;
  protected subscription = new Subscription();
  @Input() public isUiLockable = true;
  @Input() public dataCy = '';
  public isFocused = false;

  private _originalValue: StringOrNumber | undefined;
  private _lastValue: any;
  private _isSpecialChar = false;
  private cachedText: string | null = null;
  private parser: DecimalNumberParser = new DecimalNumberParser();
  private isReadOnlyMode = false;
  private unlistenMouseOver!: () => void;
  private _maxLength: number | undefined;
  private _maxWidth = 100;
  private _tooltipText = '';

  constructor(
    private store: Store,
    private cdRef: ChangeDetectorRef,
    protected convertUnitPipe: ConvertUnitPipe,
    public modalService: ModalService,
    private inputsHelperService: InputsHelperService,
    private ngZone: NgZone,
    private renderer: Renderer2,
  ) {
    this.subscription.add(
      this.store.select(selectCurrentUnitSystem).subscribe((currentUnitSystem) => {
        if (!this.selectedUnitSystem || this.selectedUnitSystem !== currentUnitSystem) {
          this.selectedUnitSystem = currentUnitSystem;
          this.cachedText = null;
          this.cdRef.markForCheck();
        }
      }),
    );
  }

  private static deleteRange(value: string, start: number, end: number): string {
    let newValueStr;

    if (end - start === value.length) {
      newValueStr = '';
    } else if (start === 0) {
      newValueStr = value.slice(end);
    } else if (end === value.length) {
      newValueStr = value.slice(0, start);
    } else {
      newValueStr = value.slice(0, start) + value.slice(end);
    }

    return newValueStr;
  }

  @HostListener('document:keydown.f4', ['$event'])
  public onF4Pressed(e: KeyboardEvent): void {
    e.preventDefault();
    if (!this.canShowTooltip) {
      return;
    }

    this.displayUnitConversionToolTip();
  }

  @HostListener('document:keydown.f9', ['$event'])
  public onF9Pressed(e: KeyboardEvent): void {
    e.preventDefault();

    if (!this.canShowTooltip) {
      return;
    }

    this.displayMDTVDConversionToolTip();
  }

  @Input()
  public set decimalPlaces(decPlaces: number | undefined) {
    this.parser.decimalPlaces = decPlaces;
  }

  public get decimalPlaces(): number | undefined {
    return this.parser.decimalPlaces;
  }

  public get maxLength(): number {
    return this._maxLength != null && this._maxLength > 0 ? this._maxLength : this.isTextInput ? DEFAULT_INPUT_MAX_LENGTH : 16;
  }

  @Input()
  public set maxLength(length: number) {
    this._maxLength = length;
  }

  @HostBinding('style.max-width')
  public get getMaxWithStyle(): string | undefined {
    return this.maxWidth != null ? `${this.maxWidth}px` : undefined;
  }

  public get maxWidth(): number | undefined {
    return this.isGridInput || this.isFullWidth ? undefined : this._maxWidth;
  }

  @Input()
  public set maxWidth(width: number | undefined) {
    this._maxWidth = width ?? 100;
  }

  public get isSymbolVisible(): boolean {
    return this.showSymbol && (this.unitSystem !== UnitSystem.None || this.unitLabel !== undefined) && !this.isTextInput;
  }

  public get tooltipText(): string {
    const highlightMessage = this.warning ? this.getDefaultTooltip() : null;
    let tooltipText = this._tooltipText || this.errorMessage || this.warningMessage || highlightMessage;
    if (tooltipText && this.selectedUnitSystem) {
      tooltipText = this.convertUnitPipe.transform(tooltipText, this.selectedUnitSystem, this.parser.decimalPlaces);
    }

    // show full value in tooltip when it's too long
    if (
      !this.errorMessage &&
      !this.warningMessage &&
      !highlightMessage &&
      !(this.renderAsLabel && !this.isLabelCropped) &&
      this.value != null &&
      this.value?.toString().length > this.maxVisibleNumberOfLetters
    ) {
      return this.value.toString();
    }

    return tooltipText ?? '';
  }

  public getDefaultTooltip(): string {
    if (this.sourceDefaults == null || this.key == null) {
      return '';
    }
    const defaultValue = +(this.sourceDefaults as T)[this.key];
    const currentUnit = this.getCurrentUnitType();
    const currentUnitValue = currentUnit != null ? this.getUnitConverter().fromSi(defaultValue, currentUnit) : defaultValue;

    return currentUnitValue != null ? 'Default value: ' + this.formatValue(currentUnitValue) : '';
  }

  public get tooltipStyle(): string {
    if (this._tooltipText) {
      return 'regular-tooltip';
    }
    return this.errorMessage ? 'error-tooltip' : this.warningMessage ? 'warning-tooltip' : '';
  }

  public get textDisplayValue(): string {
    if (this.isInsertRow) {
      return this.placeholder ?? '';
    }
    if (this.isTextInput) {
      if (this.value != null && this.value != '' && this.selectedUnitSystem) {
        return this.convertUnitPipe.transform(this.value, this.selectedUnitSystem, this.parser.decimalPlaces);
      }
      return this.value === undefined ? '' : this.value + '';
    }

    if (this.cachedText) {
      return this.cachedText;
    }

    if (this.allowOptionalStringValue && typeof this.value === 'string') {
      return this.value;
    }

    return this.value != null ? this.formattedValue() : this.castNullToNumber ? (0).toFixed(this.parser.decimalPlaces) : '';
  }

  public onTooltipHidden(): void {
    this._tooltipText = '';
  }

  private get canShowTooltip(): boolean {
    if (this.isGridInput) {
      return (this.disabled || this.renderAsLabel) && this.selected && this.isOnlyOneSelected;
    }
    return this.isFocused;
  }

  private blur(updateValue: boolean): void {
    if (this.input != null && this.input.nativeElement) {
      if (updateValue) {
        this.input.nativeElement.value = this.formattedValue();
      }
      this.input.nativeElement.parentNode?.blur();
      this.input.nativeElement.blur();
    }
  }

  public setOriginalValue(): void {
    this.value = this._originalValue;
    this.cachedText = null;
    this.cdRef.markForCheck();
  }

  public getSymbol(unit = this.getCurrentUnitType()): string {
    return this.unitLabel ?? (unit != null && unit > 0 ? this.getUnitConverter().getSymbol(unit) : '');
  }

  public ngOnInit(): void {
    FormDataHelper.checkMissingInputs(this.source, this.key, this.valueChanged, this.dataCy, this.disabled);
    this.subscription.add(
      this.store.select(getIsUiLocked).subscribe((isUiLocked) => {
        this.isReadOnlyMode = isUiLocked && this.isUiLockable;
        this.cdRef.markForCheck();
      }),
    );
  }

  public ngAfterViewInit(): void {
    if (this.autoFocus !== false && !this.disabled) {
      this.autoFocusInput();
    }

    if (this.inputAsLabel) {
      this.inputAsLabel.nativeElement &&
        this.ngZone.runOutsideAngular(() => {
          this.unlistenMouseOver = this.renderer.listen(this.inputAsLabel?.nativeElement, 'mouseover', (event: MouseEvent) => {
            if ((event.target as HTMLElement).scrollWidth > (event.target as HTMLElement).clientWidth) {
              this.ngZone.run(() => {
                this._tooltipText = this.textDisplayValue;
                this.displayTooltip();
                this.cdRef.markForCheck();
              });
            }
          });
        });
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.onTooltipHidden();

    FormDataHelper.checkMissingInputs(this.source, this.key, this.valueChanged, this.dataCy, this.disabled);
    // setTimeout needs to be there because we cannot trigger change detection on nativeElement
    if (changes.renderAsLabel != null && changes.renderAsLabel.currentValue === false && !changes.renderAsLabel.firstChange) {
      setTimeout(() => {
        this.input?.nativeElement?.select();

        if (this.initialInputValue != null && this.input?.nativeElement) {
          if (this.isTextInput || !isNaN(+this.initialInputValue)) {
            this.updateValue(this.initialInputValue.toString(), null, 'insert');
            this.input.nativeElement.setSelectionRange(1, 1);
          }
        }
      }, 0);
    }
    if (this.autoFocus !== false && changes.disabled != null && changes.disabled.currentValue === false) {
      setTimeout(() => {
        this.autoFocusInput();
      }, 0);
    }
    if (changes.value != null) {
      this.cachedText = null;
      this._originalValue = this.value;
    }
    if ((changes.source != null || changes.key != null) && this.source != null && this.key != null) {
      const modelData = FormDataHelper.getSourceValue(this.source, this.key) as unknown as StringOrNumber;

      if (modelData != null && this.value !== modelData) {
        this.value = modelData;
        this.cachedText = null;
        this._originalValue = this.value;
        this.cdRef.markForCheck();
      }
    }
  }

  public ngOnDestroy(): void {
    this.unlistenMouseOver?.();
    this.subscription.unsubscribe();
  }

  public displayUnitConversionToolTip(): void {
    const unitCodesIds = Object.keys(this.getUnit())
      .filter((key) => !isNaN(+key))
      .map((unitCode) => +unitCode);
    if (unitCodesIds.length > 1) {
      const unitCodes = unitCodesIds.map(
        (unitCodeId) => this.getNumericDisplayValue(unitCodeId).toFixed(this.parser.decimalPlaces) + ' ' + this.getSymbol(unitCodeId),
      );
      this._tooltipText = unitCodes.join('\n');
      this.displayTooltip();
    }
  }

  public displayMDTVDConversionToolTip(): void {
    if (!this.name || this.value == null) {
      return;
    }

    const getSurveys$: () => Observable<Survey[]> = () => this.store.select(getSurveyDataForCalculation).pipe(take(1));

    const currentUnit = this.getCurrentUnitType();
    if (currentUnit === null) {
      return;
    }

    const getConvertedValue = (value: number): string =>
      `${this.getUnitConverter().fromSi(value, currentUnit).toFixed(this.parser.decimalPlaces)} ${this.getSymbol(currentUnit)}`;

    if (this.name.toLowerCase().includes('md')) {
      getSurveys$().subscribe((surveys) => {
        if (surveys.length && this.value != null && +this.value <= surveys[surveys.length - 1].MD) {
          const tvd: number = SurveyCalculations.MDToTVD(surveys as Survey[], +this.value);
          this._tooltipText = `TVD = ${getConvertedValue(tvd)}`;
        } else {
          this._tooltipText = 'MD not defined in survey';
        }
      });
    } else if (this.name.toLowerCase().includes('tvd')) {
      getSurveys$().subscribe((surveys) => {
        if (surveys.length && this.value != null && +this.value <= Math.max(...surveys.map((s) => s.TVD))) {
          const mdList = SurveyCalculations.TVDtoMD(surveys as Survey[], +this.value);
          const textLines = mdList.map((md) => (this._tooltipText = `MD = ${getConvertedValue(md)}`));
          this._tooltipText = textLines.join('\n');
        } else {
          this._tooltipText = 'TVD not defined in survey';
        }
      });
    }
    this.displayTooltip();
  }

  public formattedValue(): string {
    if (this.isTextInput) {
      return this.value + '';
    }
    const currentUnit = this.getCurrentUnitType();
    if (currentUnit === null || this.isTextInput || this.value == null) {
      return this.formatValue(this.value);
    }
    let siOffset = 0;
    if (this.offset !== undefined) {
      siOffset = this.getUnitConverter().fromSi(this.offset, currentUnit);
    }
    const currentUnitValue = this.getUnitConverter().fromSi(+this.value, currentUnit) - siOffset;
    return this.formatValue(currentUnitValue);
  }

  public onUserInput(event: Event): void {
    if (this.isTextInput) {
      return;
    }
    if (this._isSpecialChar && event.target) {
      (event.target as HTMLInputElement).value = this._lastValue;
    }
    this._isSpecialChar = false;
  }

  public onInputKeyPress(event: KeyboardEvent): void {
    if (!this.isTextInput) {
      event.preventDefault();
      const char = event.key;

      const isDecimalSign = this.parser.isDecimalSign(char);
      const isMinusSign = this.parser.isMinusSign(char);

      if (!isNaN(+event.key) || isDecimalSign) {
        this.insert(char, { isDecimalSign, isMinusSign });
      }
    }
  }

  public onPaste(event: Event & { clipboardData: DataTransfer | null }): void {
    if (!this.isTextInput) {
      event.preventDefault();
    }
    const data = event.clipboardData?.getData('Text');
    if (data != null) {
      const filteredData = this.isTextInput ? data : this.parser.parseValue(this.parser.translateNumber(data));
      if (filteredData != null) {
        this.insert(filteredData.toString());
      }
    }
  }

  public onInputClick(): void {
    this.isFocused = true;
    this.initCursor();
  }

  public onInputFocus(_: any): void {
    this.isFocused = true;
    this.initCursor();
  }

  public async onInputBlur(event: FocusEvent | KeyboardEvent | undefined): Promise<void> {
    if (!this.triggerOnKeyPress) {
      this.isFocused = false;
    }
    await this.emitNewValue(event);

    this.stopEditing.emit(event instanceof KeyboardEvent ? (event.key as StopEditingKey) : 'None');
  }

  public async emitNewValue(event: FocusEvent | KeyboardEvent | undefined): Promise<void> {
    if (!this.input) {
      return;
    }

    const originalTextValue = this.textDisplayValue;
    let inputValue: string = this.input.nativeElement.value;
    if (inputValue === originalTextValue && !this.triggerOnKeyPress) {
      return;
    }

    if (!this.isTextInput && inputValue === '') {
      inputValue = this.parser.formatValue('0');
    }

    let newValue: ParsedValue | string = inputValue;
    if (!this.isTextInput) {
      newValue = this.validateValue(this.parser.parseValue(inputValue));
      this.input.nativeElement.value = this.formatValue(newValue);
      this.input.nativeElement.setAttribute('aria-valuenow', newValue);
    }

    if (event) {
      this.cachedText = this.formatValue(newValue);
    }

    const isChanged = this.updateModel(newValue);

    if (isChanged) {
      if (await this.inputsHelperService.checkResultsAndDeleteIfNeeded(this.isUiLockable)) {
        const value = this.value as unknown as DataSourceValue<T>;
        if (this.source != null && this.key != null) {
          this.valueChanged.emit({
            key: this.key,
            value,
            shouldResetResults: this.isUiLockable,
          });
        }
        this.primitiveValueChanged.emit({ value, shouldResetResults: this.isUiLockable });
        this._originalValue = this.value;
      } else {
        this.setOriginalValue();
      }
    }
  }

  public onInputKeyDown(event: KeyboardEvent): void {
    if (!this.input) {
      return;
    }

    const target: HTMLInputElement = event.target as HTMLInputElement;
    this._lastValue = target.value as string;
    if (event.shiftKey || event.altKey) {
      this._isSpecialChar = true;
      return;
    }

    const selectionStart = target.selectionStart ?? 0;
    const selectionEnd = target.selectionEnd ?? 0;
    const inputValue: string = target.value;
    let newValueStr = null;

    if (event.altKey) {
      event.preventDefault();
    }

    if (event.ctrlKey && event.code === 'KeyV') {
      return;
    }

    if (this.isTextInput && event.key !== 'Escape') {
      return;
    }

    switch (event.which) {
      case NUMPAD_MINUS:
      case DASH:
        {
          if (this._lastValue.charAt(0) === '-') {
            newValueStr = this._lastValue.slice(1);
            this.updateValue(newValueStr, null, 'minus-remove');
          } else {
            if (inputValue.length === 0) {
              // add '-0.0' and put caret after '-';
              newValueStr = '-0.0';
              this.updateValue(newValueStr, null, 'minus-insert');
              this.input.nativeElement.setSelectionRange(1, 2);
            } else {
              // add - at the beginning and don't change caret position
              newValueStr = '-' + this._lastValue;
              this.updateValue(newValueStr, null, 'minus-insert');
            }
          }
        }
        break;

      case BACKSPACE: {
        event.preventDefault();

        if (selectionStart === selectionEnd) {
          const deleteChar = inputValue.charAt(selectionStart - 1);
          const decimalCharIndex = inputValue.search(this.parser._decimal);
          this.parser._decimal.lastIndex = 0;

          if (this.parser.isNumeralChar(deleteChar)) {
            if (this.parser._group.test(deleteChar)) {
              this.parser._group.lastIndex = 0;
              newValueStr = inputValue.slice(0, selectionStart - 2) + inputValue.slice(selectionStart - 1);
            } else if (this.parser._decimal.test(deleteChar)) {
              this.parser._decimal.lastIndex = 0;
              this.input.nativeElement.setSelectionRange(selectionStart - 1, selectionStart - 1);
            } else if (decimalCharIndex > 0 && selectionStart > decimalCharIndex) {
              newValueStr = inputValue.slice(0, selectionStart - 1) + '0' + inputValue.slice(selectionStart);
            } else if (decimalCharIndex > 0 && decimalCharIndex === 1) {
              newValueStr = inputValue.slice(0, selectionStart - 1) + '0' + inputValue.slice(selectionStart);
              newValueStr = ((this.parser.parseValue(newValueStr) ?? 0) as number) > 0 ? newValueStr : '';
            } else {
              newValueStr = inputValue.slice(0, selectionStart - 1) + inputValue.slice(selectionStart);
            }
          }

          this.updateValue(newValueStr, null, 'delete-single');
        } else {
          newValueStr = Input2Component.deleteRange(inputValue, selectionStart, selectionEnd);
          this.updateValue(newValueStr, null, 'delete-range');
        }

        break;
      }
      case NUMPAD_PERIOD:
      case PERIOD:
        // when input is not empty or all text in input is selected
        if (!inputValue.length || (selectionStart !== selectionEnd && inputValue.length === selectionEnd)) {
          this.updateValue('0.', null, 'insert');
          this.input.nativeElement.setSelectionRange(1, 1);
        }
        break;
      case DELETE:
        event.preventDefault();

        if (selectionStart === selectionEnd) {
          const deleteChar = inputValue.charAt(selectionStart);
          const decimalCharIndex = inputValue.search(this.parser._decimal);
          this.parser._decimal.lastIndex = 0;

          if (this.parser.isNumeralChar(deleteChar)) {
            if (this.parser._group.test(deleteChar)) {
              this.parser._group.lastIndex = 0;
              newValueStr = inputValue.slice(0, selectionStart) + inputValue.slice(selectionStart + 2);
            } else if (this.parser._decimal.test(deleteChar)) {
              this.parser._decimal.lastIndex = 0;
              this.input.nativeElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
            } else if (decimalCharIndex > 0 && selectionStart > decimalCharIndex) {
              newValueStr = inputValue.slice(0, selectionStart) + '0' + inputValue.slice(selectionStart + 1);
            } else if (decimalCharIndex > 0 && decimalCharIndex === 1) {
              newValueStr = inputValue.slice(0, selectionStart) + '0' + inputValue.slice(selectionStart + 1);
              newValueStr = ((this.parser.parseValue(newValueStr) ?? 0) as number) > 0 ? newValueStr : '';
            } else {
              newValueStr = inputValue.slice(0, selectionStart) + inputValue.slice(selectionStart + 1);
            }
          }

          this.updateValue(newValueStr, null, 'delete-back-single');
        } else {
          newValueStr = Input2Component.deleteRange(inputValue, selectionStart, selectionEnd);
          this.updateValue(newValueStr, null, 'delete-range');
        }
        break;
      default:
        break;
    }

    this.onKeyPressed(event);
  }

  protected getUnit(): any {
    return this.isTextInput ? NoneUnit : UnitConverterHelper.getUnitConverter(this.unitSystem).unit;
  }

  protected getUnitConverter(): UnitConverter {
    return this.isTextInput ? NoneConverter : UnitConverterHelper.getUnitConverter(this.unitSystem);
  }

  protected getCurrentUnitType(): number | null {
    if (this.selectedUnitSystem === undefined) {
      return null;
    }
    if (this.overrideUnitType != null) {
      return this.overrideUnitType;
    }

    return this.isTextInput ? NoneUnit.None : (UnitConverterHelper.getCurrentUnit(this.unitSystem, this.selectedUnitSystem) as number);
  }

  private onModelChange = (_: string | number): void => {
    /* this should be empty */
  };

  private onModelTouched = (): void => {
    /* this should be empty */
  };

  private updateValue(valueStr: string | null, insertedValueStr: string | null, operation: InputOperation): void {
    let newValue: ParsedValue = null;
    if (!this.input) {
      return;
    }

    if (this.isTextInput) {
      this.input.nativeElement.value = valueStr;
      return;
    }
    if (valueStr != null) {
      newValue = this.parser.parseValue(valueStr);
      this.updateInput(newValue, insertedValueStr, operation);
    }
  }

  private updateInput(value: ParsedValue, insertedValueStr: string | null, operation: InputOperation): void {
    if (!this.input) {
      return;
    }
    const inputValue = this.input.nativeElement.value;
    const newValue = this.formatValue(value);
    const currentLength = inputValue.length;

    if (currentLength === 0) {
      this.input.nativeElement.value = newValue;
      this.input.nativeElement.setSelectionRange(0, 0);
      this.initCursor();
      const prefixLength = (this.parser.prefix || '').length;
      const selectionEnd = prefixLength + (insertedValueStr ? insertedValueStr.length : 0);
      this.input.nativeElement.setSelectionRange(selectionEnd, selectionEnd);
    } else {
      const selectionStart = this.input.nativeElement.selectionStart;
      let selectionEnd = this.input.nativeElement.selectionEnd;
      if (this.maxLength && this.maxLength < newValue.length) {
        return;
      }

      this.input.nativeElement.value = newValue;
      const newLength = newValue.length;
      if (operation === 'minus-insert' && selectionStart !== selectionEnd) {
        this.input.nativeElement.setSelectionRange(selectionStart + 1, selectionEnd + 1);
      } else if (operation === 'minus-remove' && selectionStart !== selectionEnd) {
        //whole text is selected
        if (selectionStart === 0 && selectionEnd == currentLength) {
          // string length is lower by one and we still want to select whole text
          this.input.nativeElement.setSelectionRange(selectionStart, selectionEnd - 1);
        } else {
          // - is removed, so we need to move selection by 1 to left
          this.input.nativeElement.setSelectionRange(selectionStart - 1, selectionEnd - 1);
        }
      } else if (operation === 'range-insert') {
        const startValue = this.parser.parseValue((inputValue || '').slice(0, selectionStart));
        const startValueStr = startValue !== null ? startValue.toString() : '';
        const startExpr = startValueStr.split('').join('(,)?');
        const sRegex = new RegExp(startExpr, 'g');
        sRegex.test(newValue);

        const tExpr = insertedValueStr?.split('').join('(,)?') ?? '';
        const tRegex = new RegExp(tExpr, 'g');
        tRegex.test(newValue.slice(sRegex.lastIndex));

        selectionEnd = sRegex.lastIndex + tRegex.lastIndex;
        this.input.nativeElement.setSelectionRange(selectionEnd, selectionEnd);
      } else if (newLength === currentLength) {
        if (operation === 'insert' || operation === 'delete-back-single') {
          this.input.nativeElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
        } else if (operation === 'delete-single') {
          this.input.nativeElement.setSelectionRange(selectionEnd - 1, selectionEnd - 1);
        } else if (operation === 'delete-range' || operation === 'spin') {
          this.input.nativeElement.setSelectionRange(selectionEnd, selectionEnd);
        }
      } else if (operation === 'delete-back-single') {
        const prevChar = inputValue.charAt(selectionEnd - 1);
        const nextChar = inputValue.charAt(selectionEnd);
        const diff = currentLength - newLength;
        const isGroupChar = this.parser._group.test(nextChar);

        if (isGroupChar && diff === 1) {
          selectionEnd += 1;
        } else if (!isGroupChar && this.parser.isNumeralChar(prevChar)) {
          selectionEnd += -1 * diff + 1;
        }

        this.parser._group.lastIndex = 0;
        this.input.nativeElement.setSelectionRange(selectionEnd, selectionEnd);
      } else {
        selectionEnd = selectionEnd + (newLength - currentLength);
        this.input.nativeElement.setSelectionRange(selectionEnd, selectionEnd);
      }
    }

    this.input.nativeElement.setAttribute('aria-valuenow', value);
  }

  private initCursor(): void {
    if (this.isTextInput || !this.input) {
      return;
    }
    const selectionStart = this.input.nativeElement.selectionStart;
    const inputValue = this.input.nativeElement.value;
    const valueLength = inputValue.length;
    let index = null;

    let char = inputValue.charAt(selectionStart);
    if (this.parser.isNumeralChar(char)) {
      return;
    }

    //left
    let i = selectionStart - 1;
    while (i >= 0) {
      char = inputValue.charAt(i);
      if (this.parser.isNumeralChar(char)) {
        index = i;
        break;
      } else {
        i--;
      }
    }

    if (index !== null) {
      this.input.nativeElement.setSelectionRange(index + 1, index + 1);
    } else {
      i = selectionStart + 1;
      while (i < valueLength) {
        char = inputValue.charAt(i);
        if (this.parser.isNumeralChar(char)) {
          index = i;
          break;
        } else {
          i++;
        }
      }

      if (index !== null) {
        this.input.nativeElement.setSelectionRange(index, index);
      }
    }
  }

  private validateValue(value: ParsedValue): ParsedValue {
    if (value === '-' || value === null) {
      // Minus sign
      return null;
    }

    if (this.min !== undefined && value < this.min) {
      return this.min;
    }

    if (this.max !== undefined && value > this.max) {
      return this.max;
    }

    return value;
  }

  private insert(text: string, sign = { isDecimalSign: false, isMinusSign: false }): void {
    if (!this.input) {
      return;
    }
    if (this.isTextInput) {
      this.updateValue(this.input.nativeElement.value, text, 'insert');
      return;
    }

    const selectionStart = this.input.nativeElement.selectionStart;
    const selectionEnd = this.input.nativeElement.selectionEnd;
    const value = this.input.nativeElement.value;
    const inputValue = this.trim ? value.trim() : value;
    const decimalCharIndex = inputValue.search(this.parser._decimal);
    this.parser._decimal.lastIndex = 0;
    const minusCharIndex = inputValue.search(this.parser._minusSign);
    this.parser._minusSign.lastIndex = 0;
    let newValueStr;

    // MINUS SIGN
    if (sign.isMinusSign) {
      if (selectionStart === 0) {
        newValueStr = inputValue;
        if (minusCharIndex === -1 || selectionEnd !== 0) {
          newValueStr = this.insertText(inputValue, text, 0, selectionEnd);
        }

        this.updateValue(newValueStr, text, 'insert');
      }
    }
    // DECIMAL SIGN
    else if (sign.isDecimalSign) {
      if (this.decimalPlaces == 0) {
        // do nothing
      } else if (decimalCharIndex > 0 && selectionStart === decimalCharIndex) {
        this.updateValue(inputValue, text, 'insert');
      } else if (decimalCharIndex > selectionStart && decimalCharIndex < selectionEnd) {
        newValueStr = this.insertText(inputValue, text, selectionStart, selectionEnd);
        this.updateValue(newValueStr, text, 'insert');
      }
    }
    // NUMBER
    else {
      const maxFractionDigits = this.parser._numberFormat.resolvedOptions().maximumFractionDigits ?? 2;

      if (decimalCharIndex > 0 && selectionStart > decimalCharIndex) {
        if (selectionStart + text.length - (decimalCharIndex + 1) <= maxFractionDigits) {
          newValueStr = inputValue.slice(0, selectionStart) + text + inputValue.slice(selectionStart + text.length);
          this.updateValue(newValueStr, text, 'insert');
        }
      } else {
        newValueStr = this.insertText(inputValue, text, selectionStart, selectionEnd);
        const pre = newValueStr.slice(0, selectionStart);
        if (pre.length > 0 && +pre === 0 && selectionStart === selectionEnd) {
          this.input.nativeElement.setSelectionRange(selectionStart - 1, selectionStart - 1);
        }
        const operation = selectionStart !== selectionEnd ? 'range-insert' : 'insert';
        this.updateValue(newValueStr, text, operation);
      }
    }
  }

  private insertText(value: string, text: string, start: number, end: number): string {
    const textSplit = text.split('.');

    if (textSplit.length === 2) {
      const decimalCharIndex = value.slice(start, end).search(this.parser._decimal);
      this.parser._decimal.lastIndex = 0;
      return decimalCharIndex > 0 ? value.slice(0, start) + this.formatValue(text) + value.slice(end) : value;
    } else if (end - start === value.length) {
      return this.formatValue(text);
    } else if (start === 0) {
      return text + value.slice(end);
    } else if (end === value.length) {
      return value.slice(0, start) + text;
    } else {
      return value.slice(0, start) + text + value.slice(end);
    }
  }

  private formatValue(value: ParsedValue | string | undefined): string {
    if (value === '-' || this.isTextInput) {
      return value + '';
    }

    if (value === undefined) {
      value = null;
    }

    return this.parser.formatValue(value);
  }

  private updateModel(value: string | number | null): boolean {
    let isChanged = false;
    if (value === null) {
      return false;
    }
    const currentUnit = this.getCurrentUnitType();
    let newValue: string | number;
    if (this.isTextInput) {
      newValue = this.trim ? value.toString().trim() : value.toString();
    } else if (currentUnit === null) {
      newValue = value;
    } else {
      let siOffset = 0;
      if (this.offset !== undefined) {
        siOffset = this.getUnitConverter().toSi(this.offset, currentUnit);
      }
      newValue = this.getUnitConverter().toSi(+value, currentUnit) - siOffset;
    }

    if ((this.value != null && !areStringsTheSame(this.value, newValue, this.isStringComparisonStrict)) || this.triggerOnKeyPress) {
      this.value = newValue;
      this.onModelChange(newValue);
      isChanged = true;
    }

    this.onModelTouched();
    return isChanged;
  }

  private getNumericDisplayValue(unit: number): number {
    return this.value != null ? +this.getUnitConverter().fromSi(+this.value, unit).toFixed(this.parser.decimalPlaces) : 0;
  }

  private displayTooltip(): void {
    setTimeout(() => {
      this.input && this.input.nativeElement.dispatchEvent(new Event('mouseenter'));
      this.inputAsLabel && this.inputAsLabel.nativeElement.dispatchEvent(new Event('mouseenter'));
    }, 0);
  }

  public onInputKeyUp(event: KeyboardEvent): void {
    if (this.triggerOnKeyPress && event.key !== 'Enter') {
      this.onInputBlur(event).then();
    }
  }

  private autoFocusInput(): void {
    if (!this.input) {
      return;
    }
    this.input.nativeElement.focus();
    // by default, it would focus at the end of input

    if (this.autoFocus === AutoFocusPosition.START) {
      this.input.nativeElement.selectionStart = 0;
      this.input.nativeElement.selectionEnd = 0;
    }
  }

  public onKeyPressed(e: KeyboardEvent): void {
    if (e.key === 'F4') {
      this.displayUnitConversionToolTip();
    }

    if (e.key === 'F9') {
      this.displayMDTVDConversionToolTip();
    }

    if (e.key === 'F1') {
      e.preventDefault();
      window.parent.postMessage('SHOW-HIDE-HELP', window.origin);
    }

    if (e.key === 'Escape') {
      this.setOriginalValue();
      this.blur(true);
      e.stopPropagation();
    }

    // keyboard events that will be handled in grid ( just emitted up )
    if (e.key === 'Insert' || (e.code === 'KeyA' && e.ctrlKey)) {
      e.preventDefault();
      this.keyPressed.emit(e);
    }
  }

  public onKeyupEnter(): void {
    if (!this.isGridInput && this.input) {
      this.input.nativeElement.blur();
    }
  }

  public get isLabelCropped(): boolean {
    const element = this.inputAsLabel?.nativeElement;

    if (!element) {
      return false;
    }
    return element.scrollWidth > element.offsetWidth;
  }
}

type InputOperation =
  | 'delete-single'
  | 'delete-range'
  | 'range-insert'
  | 'insert'
  | 'delete-back-single'
  | 'minus-insert'
  | 'minus-remove'
  | 'spin';

export type StopEditingKey = 'ArrowUp' | 'ArrowDown' | 'Tab' | 'Enter' | 'Esc' | 'None'; // empty string means it's a click outside
