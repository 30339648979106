import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { IChartResultColumn } from '../../../../+store/reporting/reporting.selectors';
import { GridContainerComponent } from '../../../../shared/components/grid/grid-container.component';
import { ColumnType, IGridColumnConfig } from '../../../../shared/components/grid/grid.interfaces';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../modals/modal.service';
import { GridConfig } from '../../../../shared/components/grid/grid-config';
import { ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { updateChartResultColumnRow } from '../../../../+store/reporting/reporting.actions';
import { IScreenSize, ScreenService } from '../../../../shared/services';
import { GridResizeService } from '../../../../shared/services/grid-resize.service';
import { IDeleteRowsProps, IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';

@Component({
  selector: 'app-chart-configuration-grid',
  templateUrl: './chart-configuration-grid.component.html',
  styleUrls: ['./chart-configuration-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartConfigurationGridComponent extends GridContainerComponent<IChartResultColumn> implements AfterViewInit {
  @Input() public chartSeries: ITableState<IChartResultColumn> = { rows: [], isValid: true };

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    private modalService: ModalService,
    el: ElementRef,
    protected resizeService: ScreenService,
    gridResizeService: GridResizeService,
  ) {
    super(store, cdRef, el, new ChartConfigurationGridConfig(store, modalService), gridResizeService);
    this.margin = 310;
  }

  private _columns: IGridColumnConfig<IChartResultColumn>[] = [
    {
      disabled: false,
      visible: true,
      colId: ' ',
      type: ColumnType.selection,
    },
    {
      disabled: false,
      visible: false,
      colId: 'GroupName',
      type: ColumnType.select,
    },
    {
      disabled: false,
      visible: true,
      colId: 'SecondaryLeft',
      type: ColumnType.checkbox,
      headerText: 'Secondary Left Y-Axis',
      width: 135,
      isNotUiLockable: true,
    },
    {
      disabled: false,
      visible: true,
      colId: 'PrimaryLeft',
      type: ColumnType.checkbox,
      headerText: 'Primary Left Y-Axis',
      width: 135,
      isNotUiLockable: true,
    },
    {
      disabled: false,
      readonly: true,
      visible: true,
      colId: 'ColumnName',
      type: ColumnType.text,
      headerText: 'Column Name',
      align: 'center',
    },
    {
      disabled: false,
      visible: true,
      colId: 'PrimaryRight',
      type: ColumnType.checkbox,
      headerText: 'Primary Right Y-Axis',
      width: 135,
      isNotUiLockable: true,
    },
    {
      disabled: false,
      visible: true,
      colId: 'SecondaryRight',
      type: ColumnType.checkbox,
      headerText: 'Secondary Right Y-Axis',
      width: 145,
      isNotUiLockable: true,
    },
  ];

  public override resize(screenSize?: IScreenSize): void {
    setTimeout(() => {
      this.height = window.innerHeight - this.margin;
      this.cdRef.markForCheck();
    }, 10);
  }

  protected getColumns(): IGridColumnConfig<IChartResultColumn>[] {
    return this._columns;
  }
}

class ChartConfigurationGridConfig extends GridConfig<IChartResultColumn> {
  constructor(
    private store: Store,
    modalService: ModalService,
  ) {
    super(modalService);
  }

  public override async canRowsBeDeleted(props: IDeleteRowsProps): Promise<boolean> {
    return false;
  }

  public override updateRowsAction(props: IUpdateTableRowsProps<IChartResultColumn>): void {
    this.store.dispatch(updateChartResultColumnRow(props));
  }
}
