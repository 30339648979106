import { BaseReportGenerator } from '../common/base-report-generator';
import { ReportInfoDto, ReportInfoFields } from '@dunefront/common/dto/report-info.dto';
import { IAppTargetConfig } from '@dunefront/common/common/app-target-config';
import { Store } from '@ngrx/store';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import PptxGenJS from 'pptxgenjs';
import { ReportScenarioImages } from '../doc/sections/chart-report-generator.helper';
import { TitlePptGenerator } from './sections/title-ppt-generator';
import { ReportInformationPptGenerator } from './sections/report-information-ppt-generator';
import { DisclaimerPptGenerator } from './sections/disclaimer-ppt-generator';
import { GeneralDataPptGenerator } from './sections/general-data-ppt-generator';
import { ImagePptGenerator } from './image-ppt-generator';
import { CasingDataPptGenerator } from './sections/casing-data-ppt-generator';
import { ReservoirDataPptGenerator } from './sections/reservoir-data-ppt-generator';
import { LowerCompletionDataPptGenerator } from './sections/lower-completion-data-ppt-generator';
import { RunningStringDataPptGenerator } from './sections/running-string-data-ppt-generator';
import { ShuntTubeDataPptGenerator } from './sections/shunt-tube-data-ppt-generator';
import { VolumesDataPptGenerator } from './sections/volumes-data-ppt-generator';
import { FluidsDataPptGenerator } from './sections/fluids-data-ppt-generator';
import { GravelsDataPptGenerator } from './sections/gravels-data-ppt-generator';
import { ImportedDataInformationPptGenerator } from './sections/imported-data-information-ppt-generator';
import { CompletionValidation } from '@dunefront/common/modules/completion/model/completion.validation';
import { ValidatedCompletionModuleState } from '@dunefront/common/modules/completion/completion-module.state';
import { firstValueFrom } from 'rxjs';
import { getValidatedCompletionModuleState } from '../../../../../+store/completion/validated-completion.selectors';
import { PumpingInitialDataPptGenerator } from './sections/pumping-initial-data-ppt-generator';
import { PumpingWellFluidsDataPptGenerator } from './sections/pumping-well-fluids-data-ppt-generator';
import { PumpingScheduleDataPptGenerator } from './sections/pumping-schedule-data-ppt-generator';
import { SimulationSummaryDataPptGenerator } from './sections/simulation-summary-data-ppt-generator';
import { ReportingTabsGenerator } from './sections/reporting-tabs-generator';
import { PsdInputDataPptGenerator } from './sections/psd-input-data-ppt-generator';
import { PsdAnalysisDataPptGenerator } from './sections/psd-analysis-data-ppt-generator';
import { PsdSummaryDataPptGenerator } from './sections/psd-summary-data-ppt-generator';
import { TrendAnalysisDataPptGenerator } from './sections/trend-analysis-data-ppt-generator';
import { PsdScreenAndGravelSelectionDataPptGenerator } from './sections/psd-screen-and-gravel-selection-data-ppt-generator';
import { MediaPptGenerator } from './media-ppt-generator';
import { EmptyResultsPptGenerator } from './sections/empty-results-ppt-generator';
import { RangeNamePptGenerator } from './sections/range-name-ppt-generator';
import { CompletionGeneralDataPptGenerator } from './sections/completion-general-data-ppt-generator';

type PptxGenJSDF = PptxGenJS & { created: Date; modified: Date };

export class PptReportGenerator extends BaseReportGenerator {
  public readonly reportFileExtension = 'pptx';
  public readonly reportTypeName = 'PowerPoint Presentation';
  public pptx: PptxGenJSDF;

  constructor(
    reportInfo: ReportInfoDto,
    reportInfoFields: ReportInfoFields,
    reportName: string,
    appConfig: IAppTargetConfig,
    images: ReportScenarioImages,
    store: Store,
    convertUnitPipe: ConvertUnitPipe,
  ) {
    super(reportInfo, reportInfoFields, reportName, appConfig, images, store, convertUnitPipe);

    this.pptx = new PptxGenJS() as PptxGenJSDF;
    this.pptx.layout = 'LAYOUT_16x9';

    this.pptx.created = new Date(reportInfoFields.Date);
    this.pptx.modified = new Date(reportInfoFields.Date);
  }

  protected async createTitleAndReportInfo(): Promise<void> {
    await new TitlePptGenerator(this).generate();
    await new ReportInformationPptGenerator(this).generate();
  }

  protected async createDisclaimer(): Promise<void> {
    await new DisclaimerPptGenerator(this).generate();
  }

  protected async createTOC(): Promise<void> {
    // not available in ppt
  }

  protected override async createWellSection(isOpenHole: boolean): Promise<void> {
    // well general data
    await new GeneralDataPptGenerator(this).generate();

    // survey preview
    await new ImagePptGenerator({ ...this, image: this.images.surveyChart, title: 'Survey Preview' }).generate();

    // casing data
    await new CasingDataPptGenerator(this).generate();

    // reservoir zones data grid
    await new ReservoirDataPptGenerator(this).generate();

    // caliper preview
    if (isOpenHole) {
      await new ImagePptGenerator({ ...this, image: this.images.caliperChart, title: 'Caliper Preview' }).generate();
    }
  }

  protected override async createCompletionSection(): Promise<void> {
    // completion - general data
    await new CompletionGeneralDataPptGenerator(this).generate();

    // lower completion grid
    await new LowerCompletionDataPptGenerator(this).generate();

    // running string grid
    await new RunningStringDataPptGenerator(this).generate();

    // shunt tubes
    const completionState: ValidatedCompletionModuleState = await firstValueFrom(this.store.select(getValidatedCompletionModuleState));
    if (CompletionValidation.IsShuntTubeEnabled(completionState)) {
      await new ShuntTubeDataPptGenerator(this).generate();
    }

    // schematic
    await new ImagePptGenerator({ ...this, image: this.images.schematic, title: 'Schematic' }).generate();

    // volumes
    await new VolumesDataPptGenerator(this).generate();
  }

  protected override async createFluidsSection(): Promise<void> {
    // lower completion grid
    await new FluidsDataPptGenerator(this).generate();
  }

  protected override async createGravelsSection(): Promise<void> {
    // gravels
    await new GravelsDataPptGenerator(this).generate();
  }

  protected override async createImportedDataInformationSection(): Promise<void> {
    // imported data information
    await new ImportedDataInformationPptGenerator(this).generate();
  }

  protected override async createRangeHeader(rangeName: string, rangeNotes: string | null): Promise<void> {
    await new RangeNamePptGenerator(this).generate(rangeName, rangeNotes);
  }

  protected override async createPumpingSection(rangeName: string): Promise<void> {
    // pumping initial data
    await new PumpingInitialDataPptGenerator(this).generate(rangeName);

    // pumping well fluids
    await new PumpingWellFluidsDataPptGenerator(this).generate(rangeName);

    // pumping schedule
    await new PumpingScheduleDataPptGenerator(this).generate(rangeName);
  }

  protected override async createSettingsSection(): Promise<void> {
    // not available in ppt
  }

  protected override async createEvaluationAnimationSection(rangeId: number, rangeName: string): Promise<void> {
    const rangeImages = this.images.rangeImages ? this.images.rangeImages[rangeId] : {};
    const { evaluationAnimationPressureChart, evaluationAnimationFrictionChart } = rangeImages;
    const title = 'Evaluation Animation';
    const subTitle = rangeName;

    if (!evaluationAnimationPressureChart && !evaluationAnimationFrictionChart) {
      return await new EmptyResultsPptGenerator({
        ...this,
        title,
        subTitle,
      }).generate();
    }

    await new ImagePptGenerator({
      ...this,
      image: evaluationAnimationPressureChart,
      title: `${title} - Pressure`,
      subTitle,
    }).generate();

    await new ImagePptGenerator({
      ...this,
      image: evaluationAnimationFrictionChart,
      title: `${title} - Friction`,
      subTitle,
    }).generate();
  }

  protected override async createSimulationAnimationSection(rangeId: number, rangeName: string): Promise<void> {
    const rangeImages = this.images.rangeImages ? this.images.rangeImages[rangeId] : {};
    const { simulationAnimationPressureChart, simulationAnimationWell, simulationAnimationPerfPackingChart } = rangeImages;
    const title = 'Simulation Animation';
    const subTitle = rangeName;

    if (!simulationAnimationPressureChart && !simulationAnimationWell && !simulationAnimationPerfPackingChart) {
      return await new EmptyResultsPptGenerator({
        ...this,
        title,
        subTitle,
      }).generate();
    }

    await new ImagePptGenerator({
      ...this,
      image: simulationAnimationPressureChart,
      title: `${title} - Pressure`,
      subTitle,
    }).generate();

    await new MediaPptGenerator({
      ...this,
      media: simulationAnimationWell,
      title: `${title} - Well`,
      subTitle,
    }).generate();

    await new MediaPptGenerator({
      ...this,
      media: simulationAnimationPerfPackingChart,
      title: `${title} - Perf Packing`,
      subTitle,
    }).generate();
  }

  protected override async createWellVisualizationSection(rangeId: number, rangeName: string): Promise<void> {
    const rangeImages = this.images.rangeImages ? this.images.rangeImages[rangeId] : {};
    const { wellVisualization } = rangeImages;
    const title = 'Well Visualization';
    const subTitle = rangeName;

    if (!wellVisualization) {
      return await new EmptyResultsPptGenerator({
        ...this,
        title,
        subTitle,
      }).generate();
    }

    await new MediaPptGenerator({
      ...this,
      media: wellVisualization,
      title,
      subTitle,
    }).generate();
  }

  protected override async createSummarySection(isTestRun: boolean, rangeName: string): Promise<void> {
    await new SimulationSummaryDataPptGenerator(this).generate(rangeName, isTestRun);
  }

  protected override async createChartsSection(rangeId: number, rangeName: string): Promise<void> {
    const rangeImages = this.images.rangeImages ? this.images.rangeImages[rangeId] : null;
    const reportingTabsImages = rangeImages?.reportingTabs || [];

    await new ReportingTabsGenerator({ ...this, reportingTabsImages }).generate(rangeName);
  }

  protected override async createPsdInputSection(): Promise<void> {
    await new PsdInputDataPptGenerator(this).generate();
  }

  protected override async createPsdAnalysisSection(): Promise<void> {
    await new PsdAnalysisDataPptGenerator(this).generate();
  }

  protected override async createPsdSummarySection(): Promise<void> {
    await new PsdSummaryDataPptGenerator(this).generate();
  }

  protected override async createTrendAnalysisInputsSection(rangeId: number, rangeName: string): Promise<void> {
    await new TrendAnalysisDataPptGenerator(this).generate(rangeId, rangeName);
  }

  protected override async createPsdScreenAndGravelSelectionSection(): Promise<void> {
    await new PsdScreenAndGravelSelectionDataPptGenerator(this).generate();
  }

  protected override async getReportAsBase64(): Promise<string> {
    return (await this.pptx.write({ outputType: 'base64' })) as string;
  }

  protected override async getReportAsBlob(): Promise<Blob> {
    return (await this.pptx.write({ outputType: 'blob' })) as Blob;
  }
}
