<app-grid
  [dataSource]="inputData"
  [gridConfig]="gridConfig"
  [columns]="gridConfig.columns"
  [showCrudControls]="false"
  [height]="height - 60"
  [minRowCount]="1"
  [isFirstRowInsertingAllowed]="true"
  elementId="injection-test-grid"
  [isFirstRowDeleteAllowed]="true"
  (selectedRowsChanged)="selectedRowsChanged.emit($event)"
></app-grid>
<div class="buttons-panel" *ngIf="inputData">
  <div></div>
  <div>
    <app-button
      dataCy="injection-test-input-data-import"
      (buttonClick)="onImport()"
      text="Import"
    >
    </app-button>
    <app-button (buttonClick)="onDelete()" text="Delete"></app-button>
  </div>
</div>
