import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { IFile } from '@dunefront/common/dto/file.dto';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { getRecentFolders } from '../../../../+store/file-manager/file-manager.selectors';
import { FileUrlHelper } from '../../file-url.helper';
import { isElectron } from '@dunefront/common/common/electron/is-electron';

enum recentTabModes {
  FILES = 0,
  FOLDERS = 1,
}

@Component({
  selector: 'app-recent-files',
  templateUrl: 'recent-files.component.html',
  styleUrls: ['./recent-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecentFilesComponent {
  public recentFolders$ = this.store.select(getRecentFolders);

  public recentTabModes = recentTabModes;
  public selectedRecentTabMode = recentTabModes.FILES;

  constructor(
    private store: Store,
    private router: Router,
    private modalService: ModalService,
  ) {}

  public recentFolderClick(recentFolderDto: IFile): void {
    FileUrlHelper.fileNavigate(recentFolderDto, this.router, this.modalService);
  }

  public get isFilesMode(): boolean {
    return this.selectedRecentTabMode === recentTabModes.FILES;
  }

  public get isFoldersMode(): boolean {
    return this.selectedRecentTabMode === recentTabModes.FOLDERS;
  }

  protected readonly isElectron = isElectron;
}
