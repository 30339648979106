import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { isSimulateBased, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { IValidatedState, ValidationHelpers } from '@dunefront/common/modules/validation/validation-helpers';
import { FluidUsageSimulateEvaluateFinderDeps } from '@dunefront/common/modules/validation/fluid-gravel-usage-finder';
import { AdvancedValidationAction, ValidationModuleName } from '@dunefront/common/modules/validation/validation.actions';
import { ErrorHelper } from '@dunefront/common/common/common-state.interfaces';
import { IBaseAdvancedValidationActionPayload } from './calculation-engine/calculation-engine.actions';
import { ModalService } from '../common-modules/modals/modal.service';
import { getAdvancedValidationInput } from './calculation-engine/advanced-validation.selectors';
import { AdvancedValidationJobInputData } from '@dunefront/common/modules/validation/dto/validation.dto';
import { getValidatedState } from './app.selector';
import { getRedisJobConnectionStatus, IRedisJobConnectionStatus } from './calculation-engine/calculation-engine.selectors';
import { getFluidUsageSimulateEvaluateFinderDeps } from './pumping/selectors/fluid-and-gravel-delete.selectors';
import { BaseWsEffects } from './base-ws.effects';
import { selectValidatedOrganizationGlobalOptions, selectValidatedUserGlobalOptions } from './common-db/common-db.selectors';
import { ValidatedGlobalOptions } from '@dunefront/common/dto/global-options.dto';
import { concatLatestFrom } from '@ngrx/operators';

export function validateState<T extends IBaseAdvancedValidationActionPayload>(
  wsEffect: BaseWsEffects,
  modalService: ModalService,
  store: Store,
) {
  return function (source: Observable<T>): Observable<T> {
    return source.pipe(
      concatLatestFrom(() => [
        store.select(getValidatedState),
        store.select(selectValidatedOrganizationGlobalOptions),
        store.select(selectValidatedUserGlobalOptions),
        store.select(getRedisJobConnectionStatus),
        store.select(getFluidUsageSimulateEvaluateFinderDeps),
      ]),
      filter(([action, validatedState, orgGlobalOptions, userGlobalOptions, redisConnectionStatus, finderDeps]) =>
        basicValidate(modalService, action.moduleType, validatedState, orgGlobalOptions, userGlobalOptions, redisConnectionStatus, finderDeps),
      ),
      concatLatestFrom(() => store.select(getAdvancedValidationInput)),
      mergeMap(([prevState, advancedValidationInputData]) => {
        const [action] = prevState;
        return advancedValidate(wsEffect, modalService, advancedValidationInputData, action);
      }),
      filter((validationState) => validationState.isValid),
      map((state) => state.action),
    );
  };
}

function basicValidate(
  modalService: ModalService,
  moduleType: ModuleType,
  state: IValidatedState,
  orgGlobalOptions: ValidatedGlobalOptions,
  userGlobalOptions: ValidatedGlobalOptions,
  redisConnectionStatus: IRedisJobConnectionStatus,
  finderDeps: FluidUsageSimulateEvaluateFinderDeps,
): boolean {
  if (redisConnectionStatus.status !== 'ready') {
    modalService.showAlert("Can't start calculation. Redis is not connected.").then();
    return false;
  }

  let validationMessage =
    moduleType === ModuleType.Trend_Analysis
      ? ValidationHelpers.checkValidationForTrendAnalysis(state)
      : ValidationHelpers.checkValidationForSimulationAndEvaluation(moduleType, state, finderDeps, state.pumpingState.rangeId);

  validationMessage = ValidationHelpers.checkValidationForGlobalOptions(validationMessage, orgGlobalOptions, userGlobalOptions);

  if (validationMessage) {
    modalService.showAlert(validationMessage, 'Warning', 'lg').then();
    return false;
  }
  return true;
}

function advancedValidate<T extends IBaseAdvancedValidationActionPayload>(
  wsEffect: BaseWsEffects,
  modalService: ModalService,
  advancedValidationInputData: AdvancedValidationJobInputData,
  action: T,
): Observable<{ isValid: boolean; action: T }> {
  let validationModule: ModuleType;

  if (isSimulateBased(action.moduleType)) {
    validationModule = ModuleType.Simulate_Advanced_Validation;
  } else if (action.moduleType === ModuleType.Evaluate) {
    validationModule = ModuleType.Evaluate_Advanced_Validation;
  } else if (action.moduleType === ModuleType.Trend_Analysis) {
    validationModule = ModuleType.Trend_Analysis_Advanced_Validation;
  } else {
    throw new Error(`Advanced Validation is not supported for ModuleType: ${action.moduleType}`);
  }

  return wsEffect
    .emitToModule<
      string[]
    >(ValidationModuleName,
      true,
      new AdvancedValidationAction(action.scenariosAndRanges[0].scenarioId,
        advancedValidationInputData,
        validationModule),
      'Validating Data...')
    .pipe(
      map((advancedValidationMessages) => {
        const isValid = advancedValidationMessages.payload.length === 0;
        if (!isValid) {
          let advValidationMessage = ErrorHelper.ERROR_MULTIPLE_SCREENS_MESSAGE_HEADER;
          advancedValidationMessages.payload.forEach((errorMessage) => (advValidationMessage += `-> ${errorMessage}<br>`));
          modalService.showAlert(advValidationMessage, 'Warning', 'lg').then();
        }
        return { action, isValid };
      }),
    );
}
