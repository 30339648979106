import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { BaseCompletionComponent } from '../base-completion.component';
import { ITableRow } from '@dunefront/common/common/common-grid.interfaces';
import * as completionActions from '../../../../+store/completion/completion.actions';
import { updateRunningStringRow } from '../../../../+store/completion/completion.actions';
import { Store } from '@ngrx/store';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { CompletionModuleState } from '@dunefront/common/modules/completion/completion-module.state';
import { PipeType } from '@dunefront/common/dto/pipe.dto';
import { ScreenService } from '../../../../shared/services';
import * as uiActions from '../../../../+store/ui/ui.actions';
import { Pipe } from '@dunefront/common/modules/pipes/pipe';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { IUpdateTableRowsProps } from '@dunefront/common/common/common-store-crud.interfaces';
import { PanelHelpMode } from '../../../../shared/components/help-button/help-buton.component';

@Component({
  selector: 'app-running-string',
  templateUrl: './running-string.component.html',
  styleUrls: ['./running-string.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RunningStringComponent extends BaseCompletionComponent {
  public PipeType = PipeType;
  public PanelHelpMode = PanelHelpMode;

  constructor(store: Store, cdRef: ChangeDetectorRef, modalService: ModalService, resizeService: ScreenService) {
    super(store, cdRef, modalService, resizeService);
    super.onHelpChange('completion', 'workstring');
    store.dispatch(uiActions.setCompletionTabAction({ tab: 'running-string' }));
  }

  public getRows(): ITableRow<Pipe>[] {
    return this.completionState?.RunningString.rows ?? [];
  }

  public updateRow(props: IUpdateTableRowsProps<any>): void {
    this.store.dispatch(updateRunningStringRow(props));
  }

  public override onCompletionPropertyChanged(props: ObjectChangeProp<CompletionModuleState>): void {
    this.store.dispatch(completionActions.changeCompletionProperty(props));
  }

  public get isPipeDataDisabled(): boolean {
    return !this.selectedRow;
  }

  public get shouldShowOptionsPanel(): boolean {
    const allowedPipeTypes = [
      PipeType.Service_Tool,
      PipeType.Concentric_Gauge_Carrier,
      PipeType.Eccentric_Gauge_Carrier,
      PipeType.Pressure_Attenuator,
    ];

    if (this.isToolJointAnalyzed) {
      allowedPipeTypes.push(PipeType.Workstring);
    }

    return this.selectedRow != undefined && allowedPipeTypes.includes(this.selectedRow.rowData.PipeType as PipeType);
  }

  public override onInsert(): void {
    if (!this.gridComponent) {
      return;
    }
    const selectedRowIdx = this.gridComponent.grid.selectedRowsIndexes.length === 1 ? this.gridComponent.grid.selectedRowsIndexes[0] : -1;

    const overrideProps: ObjectChangeProp<Pipe>[] = [];
    if (selectedRowIdx >= 0 && this.gridComponent.grid.dataSource?.rows[selectedRowIdx].rowData.PipeType === PipeType.Service_Tool) {
      overrideProps.push({ key: 'PipeType', value: PipeType.Workstring, shouldResetResults: false });
    }

    this.gridComponent.grid.onInsertClicked(true, overrideProps, 'insert');
  }
}
