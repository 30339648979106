<div class="summary-panels-wrapper" [class.fluidpro]="isFluidPro">

  <div class="top-grid" [class.hrwp]="(wellTreatmentType$ | async) === treatmentType.High_Rate_Water_Pack"
       *ngrxLet="isOpenHole$ as isOpenHole">
    <app-page-panel header="Pack Efficiencies" *ngIf="!isFluidPro">
      <div class="grid-form-container pack-efficiencies-panel">
        <app-form-input
          label="Well"
          appUnwrap
          [readOnlyValue]="summary.FinalOverallPackPercentage"
          [decimalPlaces]="2"
          [unitType]="UnitType.None"
          unitLabel="%"
          [disabled]="true"
        >
        </app-form-input>
        <app-form-input
          label="Screen"
          appUnwrap
          [readOnlyValue]="summary.FinalScreenPackPercentage"
          [decimalPlaces]="2"
          [unitType]="UnitType.None"
          unitLabel="%"
          [disabled]="true"
        >
        </app-form-input>
        <ng-container  *ngIf="!isOpenHole">
          <label class="disabled">Perf</label>
          <div class="perf-wrapper">
            <app-form-input
              appUnwrap
              [readOnlyValue]="summary.FinalPerforationPackPercentage"
              [unitType]="UnitType.None"
              unitLabel="%"
              [disabled]="true"
            >
            </app-form-input>
            <app-form-input
              appUnwrap
              [readOnlyValue]="summary.PerfLinearGravelMass"
              [unitType]="UnitType.Linear_Mass"
              [disabled]="true"
            >
            </app-form-input>
          </div>
        </ng-container>
        <ng-container *ngIf="isOpenHole">
          <label class="disabled">Max Alpha Height</label>
          <div class="max-path-wrapper">
            <app-form-input
              appUnwrap
              [readOnlyValue]="summary.MaxPackHeightRatio"
              [unitType]="UnitType.None"
              unitLabel="%"
              [disabled]="true"
            >
            </app-form-input>
            <app-form-input
              appUnwrap
              [readOnlyValue]="summary.MaxPackHeight"
              [unitType]="UnitType.Short_Length"
              [disabled]="true"
            >
            </app-form-input>
          </div>
        </ng-container>
      </div>
    </app-page-panel>
    <app-page-panel header="Gravel Below Packer" *ngIf="!isFluidPro">
      <div class="grid-form-container">
        <app-form-input
          label="Blank"
          appUnwrap
          [readOnlyValue]="summary.BlankGravelMass"
          [decimalPlaces]="2"
          [unitType]="UnitType.Mass"
          [disabled]="true"
        >
        </app-form-input>
        <app-form-input
          label="Screen"
          appUnwrap
          [readOnlyValue]="summary.ScreenGravelMass"
          [decimalPlaces]="2"
          [unitType]="UnitType.Mass"
          [disabled]="true"
        >
        </app-form-input>
        <app-form-input
          label="Perf"
          appUnwrap
          [readOnlyValue]="summary.PerfGravelMass"
          [decimalPlaces]="2"
          [unitType]="UnitType.Mass"
          [disabled]="true"
        >
        </app-form-input>
      </div>
    </app-page-panel>
    <app-page-panel header="Max Total Pressures">
      <div class="grid-form-container">
        <app-form-input
          label="Surface"
          appUnwrap
          [readOnlyValue]="summary.MaxSurfacePressure"
          [decimalPlaces]="2"
          [unitType]="UnitType.Pressure"
          [disabled]="true"
        >
        </app-form-input>
        <app-form-input
          label="Heel"
          appUnwrap
          [readOnlyValue]="summary.MaxHeelPressure"
          [decimalPlaces]="2"
          [unitType]="UnitType.Pressure"
          [disabled]="true"
        >
        </app-form-input>
        <app-form-input
          label="Toe"
          appUnwrap
          [readOnlyValue]="summary.MaxToePressure"
          [decimalPlaces]="2"
          [unitType]="UnitType.Pressure"
          [disabled]="true"
        >
        </app-form-input>
        <app-form-input
          label="Packer (workstring)"
          appUnwrap
          [readOnlyValue]="summary.MaxPackerWorkstringPressure"
          [decimalPlaces]="2"
          [unitType]="UnitType.Pressure"
          [disabled]="true"
          *ngIf="isFluidPro"
        >
        </app-form-input>
        <app-form-input
          label="Packer (annulus)"
          appUnwrap
          [readOnlyValue]="summary.MaxPackerAnnulusPressure"
          [decimalPlaces]="2"
          [unitType]="UnitType.Pressure"
          [disabled]="true"
          *ngIf="isFluidPro"
        >
        </app-form-input>
      </div>
    </app-page-panel>

    <app-page-panel header="Max Total ECD's" *ngIf="isFluidPro">
      <div class="grid-form-container">
        <app-form-input
          label="Heel"
          appUnwrap
          [readOnlyValue]="summary.MaxHeelECD"
          [decimalPlaces]="2"
          [unitType]="UnitType.Liquid_Density"
          [disabled]="true"
        >
        </app-form-input>
        <app-form-input
          label="Toe"
          appUnwrap
          [readOnlyValue]="summary.MaxToeECD"
          [decimalPlaces]="2"
          [unitType]="UnitType.Liquid_Density"
          [disabled]="true"
        >
        </app-form-input>
        <app-form-input
          label="Packer (workstring)"
          appUnwrap
          [readOnlyValue]="summary.MaxPackerWorkstringECD"
          [decimalPlaces]="2"
          [unitType]="UnitType.Liquid_Density"
          [disabled]="true"
          *ngIf="isFluidPro"
        >
        </app-form-input>
        <app-form-input
          label="Packer (annulus)"
          appUnwrap
          [readOnlyValue]="summary.MaxPackerAnnulusECD"
          [decimalPlaces]="2"
          [unitType]="UnitType.Liquid_Density"
          [disabled]="true"
          *ngIf="isFluidPro"
        >
        </app-form-input>
      </div>
    </app-page-panel>

    <app-page-panel header="Final Frac Data"
                    *ngIf="!isFluidPro && (wellTreatmentType$ | async) === treatmentType.High_Rate_Water_Pack">
      <div class="grid-form-container">
        <app-form-input
          label="Net Pressure"
          appUnwrap
          [readOnlyValue]="summary.FracNetPressure"
          [decimalPlaces]="2"
          [unitType]="UnitType.Pressure"
          [disabled]="true"
        >
        </app-form-input>
        <app-form-input
          label="Fluid Efficiency"
          appUnwrap
          [readOnlyValue]="summary.FracFluidEfficiency"
          [decimalPlaces]="2"
          [unitType]="UnitType.None"
          [disabled]="true"
          [unitLabel]="'%'"
        >
        </app-form-input>
        <app-form-input
          label="Pack Efficiency"
          appUnwrap
          [readOnlyValue]="summary.FracPackEfficiency"
          [decimalPlaces]="2"
          [unitType]="UnitType.None"
          [disabled]="true"
          [unitLabel]="'%'"
        >
        </app-form-input>
      </div>
    </app-page-panel>
  </div>
  <app-page-panel header="Max Friction Pressures" *ngIf="isFluidPro">
    <div class="grid-form-container no-padding">
      <div class="grid-form-container">
        <app-form-input
          label="Workstring"
          appUnwrap
          [readOnlyValue]="summary.MaxWorkstringFriction"
          [decimalPlaces]="2"
          [unitType]="UnitType.Pressure"
          [disabled]="true"
        >
        </app-form-input>
        <app-form-input
          label="Washpipe"
          appUnwrap
          [readOnlyValue]="summary.MaxWashpipeFriction"
          [decimalPlaces]="2"
          [unitType]="UnitType.Pressure"
          [disabled]="true"
        >
        </app-form-input>
        <app-form-input
          label="Upper Annulus"
          appUnwrap
          [readOnlyValue]="summary.MaxUpperAnnulusFriction"
          [decimalPlaces]="2"
          [unitType]="UnitType.Pressure"
          [disabled]="true"
        >
        </app-form-input>
        <app-form-input
          label="Lower Annulus"
          appUnwrap
          [readOnlyValue]="summary.MaxLowerAnnulusFriction"
          [decimalPlaces]="2"
          [unitType]="UnitType.Pressure"
          [disabled]="true"
        >
        </app-form-input>
      </div>
    </div>
  </app-page-panel>
  <app-page-panel header="Max Friction Pressures" *ngIf="!isFluidPro">
    <div class="grid-form-container no-padding">
      <div class="grid-form-container">
        <app-form-input
          label="Treating Line"
          appUnwrap
          [readOnlyValue]="summary.MaxTreatingFriction"
          [decimalPlaces]="2"
          [unitType]="UnitType.Pressure"
          [disabled]="true"
        >
        </app-form-input>
        <app-form-input
          label="Workstring"
          appUnwrap
          [readOnlyValue]="summary.MaxWorkstringFriction"
          [decimalPlaces]="2"
          [unitType]="UnitType.Pressure"
          [disabled]="true"
        >
        </app-form-input>
        <app-form-input
          label="Service Tool Cross Over"
          appUnwrap
          [readOnlyValue]="summary.MaxServiceToolDownFlowFriction"
          [decimalPlaces]="2"
          [unitType]="UnitType.Pressure"
          [disabled]="true"
        >
        </app-form-input>
        <app-form-input
          label="Blank"
          appUnwrap
          [readOnlyValue]="summary.MaxBlankFriction"
          [decimalPlaces]="2"
          [unitType]="UnitType.Pressure"
          [disabled]="true"
        >
        </app-form-input>
        <app-form-input
          label="Washpipe"
          appUnwrap
          [readOnlyValue]="summary.MaxWashpipeFriction"
          [decimalPlaces]="2"
          [unitType]="UnitType.Pressure"
          [disabled]="true"
        >
        </app-form-input>
        <app-form-input
          label="Shunt"
          appUnwrap
          [readOnlyValue]="summary.MaxShuntFriction"
          [decimalPlaces]="2"
          [unitType]="UnitType.Pressure"
          [disabled]="true"
        >
        </app-form-input>
        <app-form-input
          label="Choke"
          appUnwrap
          [readOnlyValue]="summary.MaxChokeFriction"
          [decimalPlaces]="2"
          [unitType]="UnitType.Pressure"
          [disabled]="true"
        >
        </app-form-input>
      </div>
      <div class="grid-form-container">
        <app-form-input
          label="Annular Line"
          appUnwrap
          [readOnlyValue]="summary.MaxAnnularLineFriction"
          [decimalPlaces]="2"
          [unitType]="UnitType.Pressure"
          [disabled]="true"
        >
        </app-form-input>
        <app-form-input
          label="Upper Annulus"
          appUnwrap
          [readOnlyValue]="summary.MaxUpperAnnulusFriction"
          [decimalPlaces]="2"
          [unitType]="UnitType.Pressure"
          [disabled]="true"
        >
        </app-form-input>
        <app-form-input
          label="Service Tool Return"
          appUnwrap
          [readOnlyValue]="summary.MaxServiceToolUpFlowFriction"
          [decimalPlaces]="2"
          [unitType]="UnitType.Pressure"
          [disabled]="true"
        >
        </app-form-input>
        <app-form-input
          label="Screen"
          appUnwrap
          [readOnlyValue]="summary.MaxScreenFriction"
          [decimalPlaces]="2"
          [unitType]="UnitType.Pressure"
          [disabled]="true"
        >
        </app-form-input>
        <app-form-input
          label="Gauge Carriers"
          appUnwrap
          [readOnlyValue]="summary.MaxGaugeCarrierFriction"
          [decimalPlaces]="2"
          [unitType]="UnitType.Pressure"
          [disabled]="true"
        >
        </app-form-input>
        <app-form-input
          label="Gravel Pack"
          appUnwrap
          [readOnlyValue]="summary.GravelPack"
          [decimalPlaces]="2"
          [unitType]="UnitType.Pressure"
          [disabled]="true"
        >
        </app-form-input>
        <div class="dummy-app-input"></div>
      </div>
    </div>
  </app-page-panel>

</div>
