import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { getStartStopButton } from './left-nav.helpers';
import { LeftNavHelperService } from './left-nav-helper.service';
import { getCurrentAppModuleType } from '../../../+store/ui/ui.selectors';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { firstValueFrom, Subscription } from 'rxjs';
import { getAreCurrentResultsPresent } from '../../../+store/calculation-engine/calculation-engine-results.selectors';
import { ModalService } from '../../../common-modules/modals/modal.service';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { isEvaluate, isTrendAnalysis } from '../../../+store/menu-selectors/menu-selectors.helpers';
import { getCurrentRangeId } from '../../../+store/range/range.selectors';

const loaderRadius = 16;
const loaderStrokeWidth = 3;
const loaderCXY = loaderRadius + loaderStrokeWidth;
const loaderCircumference = 2 * Math.PI * loaderRadius;

@Component({
  selector: 'app-start-stop-button',
  template: ` <div
    class="start-stop-button-container left-nav-links-single"
    *ngIf="startStopButton$ | async as startStopButton"
    (click)="startStopClick()"
    [class.extended]="isSideNavExpanded"
    [class.disabled]="!startStopButton.enabled"
  >
    <svg width="40" height="40" viewBox="0 0 40 40" class="loader-circle">
      <circle
        *ngIf="startStopButton.progress"
        #circle
        fill="none"
        [attr.cx]="loaderParams.loaderCXY"
        [attr.cy]="loaderParams.loaderCXY"
        [attr.r]="loaderParams.loaderRadius"
        [attr.stroke]="'var(--app-color)'"
        [attr.stroke-width]="loaderParams.loaderStrokeWidth"
        [attr.stroke-dasharray]="loaderParams.loaderCircumference"
        [attr.stroke-dashoffset]="loaderParams.loaderCircumference - (startStopButton.progress / 100) * loaderParams.loaderCircumference"
      />
    </svg>
    <div class="start-stop-button play-button" [class.stop-button]="startStopButton.state === 'stop'" data-cy="simulate-link">
      <span [innerHTML]="startStopButton.icon"></span>
    </div>

    <p class="main-label" *ngIf="calculationType">Calculate</p>
  </div>`,
  styleUrls: ['./left-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartStopButtonComponent implements OnDestroy {
  @Input() public isSideNavExpanded = false;
  public calculationType: string | undefined;
  public startStopButton$ = this.store.select(getStartStopButton);

  private subscription = new Subscription();
  private currentModuleType?: ModuleType;

  public loaderParams = {
    loaderRadius,
    loaderCXY,
    loaderStrokeWidth,
    loaderCircumference,
  };

  constructor(
    protected store: Store,
    private runCalculationService: LeftNavHelperService,
    private modalService: ModalService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.subscription.add(
      this.store.select(getCurrentAppModuleType).subscribe((appModule) => {
        this.currentModuleType = appModule;
        this.getCalculationType();
        this.cdRef.markForCheck();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public getCalculationType(): void {
    switch (this.currentModuleType) {
      case ModuleType.Evaluate:
        this.calculationType = 'Evaluation';
        break;
      case ModuleType.Simulate:
      case ModuleType.Simulate_CH:
      case ModuleType.Simulate_Disp:
        this.calculationType = 'Simulation';
        break;
      case ModuleType.Trend_Analysis:
        this.calculationType = 'Trend Analysis';
        break;
      case ModuleType.PSD_Analysis_Basic:
      case ModuleType.PSD_Analysis_Full:
        this.calculationType = 'PSD Analysis';
        break;
      case ModuleType.Friction_Calc:
      case ModuleType.Injection_Test_Calc:
      case ModuleType.MASP_Calc:
      case ModuleType.Leakoff_Coefficient_Calc:
      case ModuleType.Resuspension_Calc:
      case ModuleType.Multiple_Section_Volume_Calc:
      case ModuleType.Single_Section_Volume_Calc:
      case ModuleType.Reference_Variable_Calc:
      case ModuleType.Settling_Calc:
      case ModuleType.Calculators_Generic:
        this.calculationType = 'Calculation';
    }
  }

  public async startStopClick(): Promise<void> {
    const resultsPresent = await firstValueFrom(this.store.select(getAreCurrentResultsPresent));
    const currentRangeId = await firstValueFrom(this.store.select(getCurrentRangeId));
    const isCalculationRunning = (await firstValueFrom(this.startStopButton$))?.state === 'stop';

    if (resultsPresent && !isCalculationRunning) {
      const confirm = await this.modalService.showConfirm(
        `There are no changes in the input data.<br> Would you still like to run the calculations?`,
        `Start calculation`,
        'sm',
      );

      if (!confirm) {
        return;
      }
    }

    const isEvaluateOrTrendAnalysis =
      this.currentModuleType != null && (isEvaluate(this.currentModuleType) || isTrendAnalysis(this.currentModuleType));
    if (isEvaluateOrTrendAnalysis && currentRangeId === RangeConstants.EntireRangeId) {
      await this.modalService.showAlert(`Cannot run ${this.calculationType} on Entire Range`);
      return;
    }

    this.runCalculationService.emitCalculation();
  }
}
