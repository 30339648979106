<div class="dialog-header">
  <h4>Reporting Chart Configuration</h4>
</div>
<div class="dialog-content">
  <app-chart-configuration-grid *ngIf="resultColumnsTableState.rows.length" #gridComponent [chartSeries]="resultColumnsTableState">
  </app-chart-configuration-grid>
</div>
<div class="dialog-footer">
  <app-button
    dataCy="reporting-save-as-template"
    [disabled]="!isSomethingSelected || isDataPro"
    [isUiLockable]="false"
    (buttonClick)="onSaveClicked()"
    text="Save as Template"
  >
  </app-button>
  <app-button [isUiLockable]="false" (buttonClick)="onClearClicked()" dataCy="reporting-clear-chart-config" text="Clear Data"></app-button>
  <app-button  [isUiLockable]="false" (buttonClick)="onClearCustomizationsClicked()" text="Clear Customizations"></app-button>
  <app-button
    dataCy="reporting-cancel-btn"
    [isUiLockable]="false"
    (buttonClick)="onCancelClicked()"
    text="Cancel"
  >
  </app-button>
  <app-button
    dataCy="reporting-generate-chart-btn"
    [isUiLockable]="false"
    (buttonClick)="onOkClicked()"
    [disabled]="!isSomethingSelected"
    text="Generate Chart"
  >
  </app-button>
</div>
