import { ReportInfoDto, ReportInfoFields } from '@dunefront/common/dto/report-info.dto';
import { IAppTargetConfig } from '@dunefront/common/common/app-target-config';
import { ReportScenarioImages } from '../doc/sections/chart-report-generator.helper';
import { Store } from '@ngrx/store';
import { ConvertUnitPipe } from '@dunefront/common/modules/units/convert-unit.pipe/convert-unit.pipe';
import dayjs from 'dayjs';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { firstValueFrom } from 'rxjs';
import { selectCurrentUnitSystem } from '../../../../../+store/units/units.selectors';
import { getIsOpenHole } from '../../../../../+store/well/well.selectors';
import { getCurrentRange } from '../../../../../+store/range/range.selectors';
import { RangeConstants } from '@dunefront/common/dto/range.dto';
import { changeCurrentRangeIdAction } from '../../../../../+store/range/range.actions';
import { isElectron } from '@dunefront/common/common/electron/is-electron';
import { ISaveReportPayload } from '@dunefront/common/common/electron/electron.actions';
import { electronSaveReportAsFileAction } from '../../../../../+store/electron-main/electron-main.actions';
import { saveAs } from 'file-saver';
import { getCurrentNotes } from '../../../../../+store/note/note.selectors';
import { isRunningInCypress } from '../../../../services/environment';
import { getCurrentAppModuleType } from '../../../../../+store/ui/ui.selectors';
import { isSimulateDisp } from '../../../../../+store/menu-selectors/menu-selectors.helpers';

export abstract class BaseReportGenerator {
  public readonly reportDate: string;

  protected constructor(
    public readonly reportInfo: ReportInfoDto,
    public readonly reportInfoFields: ReportInfoFields,
    public readonly reportName: string,
    public readonly appConfig: IAppTargetConfig,
    public readonly images: ReportScenarioImages,
    public readonly store: Store,
    public readonly convertUnitPipe: ConvertUnitPipe,
  ) {
    this.reportDate = dayjs(reportInfoFields.Date === 0 ? new Date().valueOf() : reportInfoFields.Date).format('DD-MMM-YYYY');
  }

  public async generateReport(): Promise<void> {
    await this.createTitleAndReportInfo();

    await this.createDisclaimer();

    await this.createTOC();

    await this.generateReportScenarioPart();

    const range = await firstValueFrom(this.store.select(getCurrentRange));

    const rangeIds = this.reportInfoFields.RangeIds ?? [];
    if (rangeIds.length) {
      for (const rangeId of rangeIds) {
        this.store.dispatch(changeCurrentRangeIdAction({ rangeId }));
        await this.generateReportRangesPart(rangeId);
      }
      this.store.dispatch(changeCurrentRangeIdAction({ rangeId: range?.Id ?? RangeConstants.EmptyRangeId }));
    }

    await this.generateReportPsdAnalysisPart();

    await this.saveReport();
  }

  public async generateReportScenarioPart(): Promise<void> {
    const { reportInfoFields } = this;
    const currentAppModuleType = await firstValueFrom(this.store.select(getCurrentAppModuleType));
    const isFluidPro = isSimulateDisp(currentAppModuleType);

    if (reportInfoFields.IsWell) {
      const isOpenHole = await firstValueFrom(this.store.select(getIsOpenHole));
      await this.createWellSection(isOpenHole);
    }

    if (reportInfoFields.IsCompletion) {
      await this.createCompletionSection();
    }

    if (reportInfoFields.IsFluids) {
      await this.createFluidsSection();
    }

    if (reportInfoFields.IsGravel && !isFluidPro) {
      await this.createGravelsSection();
    }

    if (reportInfoFields.IsImportedDataInformation) {
      await this.createImportedDataInformationSection();
    }

    if (reportInfoFields.IsSettings) {
      await this.createSettingsSection();
    }
  }
  private async generateRangeHeader(
    reportInfoFields: ReportInfoFields,
    rangeId: number,
    rangeName: string,
    rangeNotes: string | null,
  ): Promise<void> {
    const rangeDependentFields = [
      reportInfoFields.IsPumping,
      reportInfoFields.IsEvaluationAnimation,
      reportInfoFields.IsSimulationAnimation,
      reportInfoFields.IsWellVisualization,
      reportInfoFields.IsSummary,
      reportInfoFields.IsTrendAnalysisInputs,
      reportInfoFields.IsCharts,
    ];

    const selectedDependentFieldsCount = rangeDependentFields.filter((f) => f).length;

    if (selectedDependentFieldsCount > 0) {
      // there are range dependent fields selected

      let shouldAddHeader = true;
      const currentRangeReportingImagesCount = (this.images.rangeImages ?? {})[rangeId]?.reportingTabs?.length ?? 0;

      if (selectedDependentFieldsCount === 1 && reportInfoFields.IsCharts) {
        // only reporting charts are selected
        // we shouldn't create range header when there are no charts
        shouldAddHeader = currentRangeReportingImagesCount > 0;
      }

      if (rangeId === RangeConstants.EntireRangeId && currentRangeReportingImagesCount === 0) {
        // we shouldn't render Entire Range when there are no charts to render
        shouldAddHeader = false;
      }

      if (shouldAddHeader) {
        // Create Range Header
        await this.createRangeHeader(rangeName, rangeNotes);
      }
    }
  }

  public async generateReportRangesPart(rangeId: number): Promise<void> {
    const { reportInfoFields } = this;
    let rangeName: string | undefined = undefined;

    if (rangeId === RangeConstants.EmptyRangeId) {
      rangeName = 'Simulate';
    } else if (rangeId === RangeConstants.EntireRangeId) {
      rangeName = 'Entire Range';
    } else {
      rangeName = (await firstValueFrom(this.store.select(getCurrentRange)))?.Name ?? '';
    }

    const rangeNotes = await firstValueFrom(this.store.select(getCurrentNotes));

    await this.generateRangeHeader(reportInfoFields, rangeId, rangeName, rangeNotes.rangeNote?.Note ?? null);

    if (rangeId != RangeConstants.EntireRangeId) {
      // There are no Pumping or Results for Entire Range
      if (reportInfoFields.IsPumping) {
        await this.createPumpingSection(rangeName);
      }

      if (reportInfoFields.IsEvaluationAnimation) {
        await this.createEvaluationAnimationSection(rangeId, rangeName);
      }

      if (reportInfoFields.IsSimulationAnimation) {
        await this.createSimulationAnimationSection(rangeId, rangeName);
      }

      if (reportInfoFields.IsWellVisualization) {
        await this.createWellVisualizationSection(rangeId, rangeName);
      }

      if (reportInfoFields.IsSummary) {
        await this.createSummarySection(isRunningInCypress(), rangeName);
      }

      if (reportInfoFields.IsTrendAnalysisInputs) {
        await this.createTrendAnalysisInputsSection(rangeId, rangeName);
      }
    }

    if (reportInfoFields.IsCharts) {
      await this.createChartsSection(rangeId, rangeName);
    }
  }

  public async generateReportPsdAnalysisPart(): Promise<void> {
    const { reportInfoFields } = this;
    if (reportInfoFields.IsPsdInput) {
      await this.createPsdInputSection();
    }

    if (reportInfoFields.IsPsdAnalysis) {
      await this.createPsdAnalysisSection();
    }

    if (reportInfoFields.IsPsdSummary) {
      await this.createPsdSummarySection();
    }

    if (reportInfoFields.IsPsdScreenAndGravelSelection) {
      await this.createPsdScreenAndGravelSelectionSection();
    }
  }

  protected async getCurrentUnitSystem(): Promise<IUnitSystemDto> {
    return await firstValueFrom(this.store.select(selectCurrentUnitSystem));
  }

  protected abstract createTitleAndReportInfo(): Promise<void>;

  protected abstract createDisclaimer(): Promise<void>;

  protected abstract createTOC(): Promise<void>;

  protected abstract createWellSection(isOpenHole: boolean): Promise<void>;

  protected abstract createCompletionSection(): Promise<void>;

  protected abstract createFluidsSection(): Promise<void>;

  protected abstract createGravelsSection(): Promise<void>;

  protected abstract createImportedDataInformationSection(): Promise<void>;

  protected abstract createRangeHeader(rangeName: string, rangeNotes: string | null): Promise<void>;

  protected abstract createPumpingSection(rangeName: string): Promise<void>;

  protected abstract createSettingsSection(): Promise<void>;

  protected abstract createEvaluationAnimationSection(rangeId: number, rangeName: string): Promise<void>;

  protected abstract createSimulationAnimationSection(rangeId: number, rangeName: string): Promise<void>;

  protected abstract createWellVisualizationSection(rangeId: number, rangeName: string): Promise<void>;

  protected abstract createSummarySection(isTestRun: boolean, rangeName: string): Promise<void>;

  protected abstract createChartsSection(rangeId: number, rangeName: string): Promise<void>;

  protected abstract createPsdInputSection(): Promise<void>;

  protected abstract createPsdAnalysisSection(): Promise<void>;

  protected abstract createPsdSummarySection(): Promise<void>;

  protected abstract createTrendAnalysisInputsSection(rangeId: number, rangeName: string): Promise<void>;

  protected abstract createPsdScreenAndGravelSelectionSection(): Promise<void>;

  protected abstract get reportFileExtension(): string;

  protected abstract get reportTypeName(): string;

  protected abstract getReportAsBase64(): Promise<string>;

  protected abstract getReportAsBlob(): Promise<Blob>;

  protected async saveReport(): Promise<void> {
    const { store, reportName, reportFileExtension, reportTypeName } = this;

    // if electron is available, send base64 data to it and save file from there
    if (isElectron()) {
      const base64 = await this.getReportAsBase64();
      const fileFilters = [{ name: reportTypeName, extensions: [reportFileExtension] }];
      const payload: ISaveReportPayload = { base64, reportName, fileFilters };

      store.dispatch(electronSaveReportAsFileAction({ payload }));
    }
    // in browser use file-saver
    else {
      const blob = await this.getReportAsBlob();

      saveAs(blob, `${reportName}.${reportFileExtension}`);
    }
  }
}
