<!--Todo: This is work in progress. Several improvement are needed:-->
<!-- - Check and fix unit types-->
<!-- - Check and fix decimal places-->
<!-- - Add some indentation/headers if needed-->
<!-- - Sort inputs the same way as help descriptions(updates needed as well)-->

<app-page-panel header="General" *ngIf="developerSettings && defaultOptions" [allowFullScreen]="false">
  <div class="grid-form-container">
    <ng-container *ngIf="false; else tbd">
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Max Loop Iterations"
        [source]="developerSettings"
        [key]="'MaxLoopIterations'"
        [unitType]="UnitType.None"
        [decimalPlaces]="0"

        [warning]="developerSettings.MaxLoopIterations !== defaultOptions.MaxLoopIterations"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Global Tolerance"
        [source]="developerSettings"
        [key]="'GlobalTolerance'"
        [unitType]="UnitType.Rate"
        [decimalPlaces]="4"

        [warning]="developerSettings.GlobalTolerance !== defaultOptions.GlobalTolerance"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Rate Distribution Min Tolerance"
        [source]="developerSettings"
        [key]="'RateDistributionMinTolerance'"
        [unitType]="UnitType.Rate"
        [decimalPlaces]="4"

        [warning]="developerSettings.RateDistributionMinTolerance !== defaultOptions.RateDistributionMinTolerance"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Rate Distribution Max Tolerance"
        [source]="developerSettings"
        [key]="'RateDistributionMaxTolerance'"
        [unitType]="UnitType.Rate"
        [decimalPlaces]="4"

        [warning]="developerSettings.RateDistributionMaxTolerance !== defaultOptions.RateDistributionMaxTolerance"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Vertical Deviation Limit"
        [source]="developerSettings"
        [key]="'VerticalDeviationLimit'"
        [unitType]="UnitType.None"
        [decimalPlaces]="4"

        [warning]="developerSettings.VerticalDeviationLimit !== defaultOptions.VerticalDeviationLimit"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <app-check-box
        [source]="developerSettings"
        [key]="'IsPackingEccentric'"
        class="grid-two-one"
        label="Is Packing Eccentric"
        [warning]="developerSettings.IsPackingEccentric !== defaultOptions.IsPackingEccentric"
        (valueChanged)="onValueChange($event)"
      >
      </app-check-box>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Max Cell Length"
        [source]="developerSettings"
        [key]="'MaxCellLength'"
        [unitType]="UnitType.Long_Length"
        [decimalPlaces]="4"

        [warning]="developerSettings.MaxCellLength !== defaultOptions.MaxCellLength"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="MaxChart Points Per Series"
        [source]="developerSettings"
        [key]="'MaxChartPointsPerSeries'"
        [unitType]="UnitType.None"
        [decimalPlaces]="4"

        [warning]="developerSettings.MaxChartPointsPerSeries !== defaultOptions.MaxChartPointsPerSeries"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Minimum Shunt And Darcy Viscosity"
        [source]="developerSettings"
        [key]="'MinimumShuntAndDarcyViscosity'"
        [unitType]="UnitType.Viscosity"
        [decimalPlaces]="4"

        [warning]="developerSettings.MinimumShuntAndDarcyViscosity !== defaultOptions.MinimumShuntAndDarcyViscosity"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Max Darcy Volume Step"
        [source]="developerSettings"
        [key]="'MaxDarcyVolumeStep'"
        [unitType]="UnitType.None"
        [decimalPlaces]="4"

        [warning]="developerSettings.MaxDarcyVolumeStep !== defaultOptions.MaxDarcyVolumeStep"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Max Reservoir Boundary Radius"
        [source]="developerSettings"
        [key]="'MaxReservoirBoundaryRadius'"
        [unitType]="UnitType.Angle"
        [decimalPlaces]="4"

        [warning]="developerSettings.MaxReservoirBoundaryRadius !== defaultOptions.MaxReservoirBoundaryRadius"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Penetration Boundary Ratio"
        [source]="developerSettings"
        [key]="'PenetrationBoundaryRatio'"
        [unitType]="UnitType.None"
        [decimalPlaces]="4"

        [warning]="developerSettings.PenetrationBoundaryRatio !== defaultOptions.PenetrationBoundaryRatio"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Damage Boundary Ratio"
        [source]="developerSettings"
        [key]="'DamageBoundaryRatio'"
        [unitType]="UnitType.None"
        [decimalPlaces]="4"

        [warning]="developerSettings.DamageBoundaryRatio !== defaultOptions.DamageBoundaryRatio"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Leakoff Gravel Concentration Tolerance"
        [source]="developerSettings"
        [key]="'LeakoffGravelConcentrationTolerance'"
        [unitType]="UnitType.Solid_Concentration"
        [decimalPlaces]="4"

        [warning]="developerSettings.LeakoffGravelConcentrationTolerance !== defaultOptions.LeakoffGravelConcentrationTolerance"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Leakoff Min Rate Tolerance"
        [source]="developerSettings"
        [key]="'LeakoffMinRateTolerance'"
        [unitType]="UnitType.Rate"
        [decimalPlaces]="4"

        [warning]="developerSettings.LeakoffMinRateTolerance !== defaultOptions.LeakoffMinRateTolerance"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Leakoff Max Rate Tolerance"
        [source]="developerSettings"
        [key]="'LeakoffMaxRateTolerance'"
        [unitType]="UnitType.Rate"
        [decimalPlaces]="4"

        [warning]="developerSettings.LeakoffMaxRateTolerance !== defaultOptions.LeakoffMaxRateTolerance"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Min Cell Length"
        [source]="developerSettings"
        [key]="'MinCellLength'"
        [unitType]="UnitType.Long_Length"
        [decimalPlaces]="4"

        [warning]="developerSettings.MinCellLength !== defaultOptions.MinCellLength"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
      <app-form-input
        class="grid-two-one"
        appUnwrap
        label="Settling Gravel Concentration Tolerance"
        [source]="developerSettings"
        [key]="'SettlingGravelConcentrationTolerance'"
        [unitType]="UnitType.Solid_Concentration"
        [decimalPlaces]="4"

        [warning]="developerSettings.SettlingGravelConcentrationTolerance !== defaultOptions.SettlingGravelConcentrationTolerance"
        (valueChanged)="onValueChange($event)"
      >
      </app-form-input>
    </ng-container>
  </div>
</app-page-panel>

<ng-template #tbd>
  <div>To be delivered</div>
</ng-template>
