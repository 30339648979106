import { Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { isElectron } from '@dunefront/common/common/electron/is-electron';
import { ClientAuthService } from '../../common-modules/auth/client-auth.service';
import { getCurrentAppCodeBySelectedAppModule } from '../../+store/ui/ui.selectors';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Injectable()
export class AssetService implements OnDestroy {
  public $currentAppCode = this.store.select(getCurrentAppCodeBySelectedAppModule);
  private renderer: Renderer2;
  private currentAppCodeSubscription: Subscription | null = null;

  constructor(
    private clientAuthService: ClientAuthService,
    protected store: Store,
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.currentAppCodeSubscription = this.$currentAppCode.subscribe((appCode) => {
      this.setFavicon(appCode != null ? `favicon-${appCode}.ico` : 'favicon-dunefront.ico');
    });
  }

  public ngOnDestroy(): void {
    if (this.currentAppCodeSubscription) {
      this.currentAppCodeSubscription.unsubscribe();
    }
  }

  public async getAssetUrl(filePath: string, openInNewTab = false): Promise<string> {
    if (isElectron()) {
      return filePath;
    }

    if (openInNewTab) {
      return `help-files/doc.html?path=${filePath}`;
    }

    const token = await this.clientAuthService.getAccessToken();
    const sessionId = this.clientAuthService.sessionId;

    return filePath + `?ppSessionId=${sessionId}&jwt=${token}`;
  }

  public setFavicon(faviconPath: string): void {
    const link: HTMLLinkElement | null = document.querySelector("link[rel*='icon']");
    if (link) {
      this.renderer.setAttribute(link, 'href', faviconPath);
    }
  }
}
