import { BasePptSectionGenerator, PptContext } from '../base-ppt-section-generator';
import { firstValueFrom } from 'rxjs';
import { selectCurrentUnitSystem } from '../../../../../../+store/units/units.selectors';
import { CommonReportDataHelpers } from '../../common/common-report-data-helpers';
import { PptDocumentTableGeneratorHelper } from '../ppt-document-table-generator.helper';
import { ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { calculateVolumesSectionAction } from '../../../../../../+store/completion/completion.actions';
import { getGaugeSectionInformationRows } from '../../../../../../+store/completion/validated-completion.selectors';
import { SectionInformationRow } from '@dunefront/common/modules/completion/model/volumes/section-information-row';
import { IUnitSystemDto } from '@dunefront/common/dto/unit-system.dto';
import { filter } from 'rxjs/operators';
import { isDefined } from '@dunefront/common/common/state.helpers';
import { getCurrentAppModuleType } from '../../../../../../+store/ui/ui.selectors';
import { ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';

export class VolumesDataPptGenerator extends BasePptSectionGenerator {
  constructor(context: PptContext) {
    super(context);
  }

  public async generate(): Promise<void> {
    this.store.dispatch(calculateVolumesSectionAction({ calculationType: 'multiple' }));

    const currentUnitSystem: IUnitSystemDto = await firstValueFrom(this.store.select(selectCurrentUnitSystem));
    const isFluidPro = (await firstValueFrom(this.store.select(getCurrentAppModuleType))) === ModuleType.Simulate_Disp;
    const volumeGaugeSection: ITableState<SectionInformationRow> = await firstValueFrom(
      this.store.select(getGaugeSectionInformationRows).pipe(filter(isDefined)),
    );

    if (volumeGaugeSection.rows.length === 0) {
      return;
    }

    const { tableData, enumItemMap } = CommonReportDataHelpers.createVolumesGaugeSectionTableData(volumeGaugeSection, currentUnitSystem, {
      isFluidPro,
    });
    const tableRows = PptDocumentTableGeneratorHelper.createTableRows<SectionInformationRow>(tableData, enumItemMap, currentUnitSystem);

    const title = 'Volumes - Gauge Section Information';

    this.addTableSlide({
      title,
      tableRows,
      presetColumnsWidths: isFluidPro
        ? { 0: 6, 1: 40, 2: 12, 3: 14, 4: 14, 5: 14 }
        : {
            0: 6,
            1: 24,
            2: 10,
            3: 12,
            4: 12,
            5: 12,
            6: 12,
            7: 12,
          },
    });
  }
}
