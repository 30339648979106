import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { DbDependentComponent } from '../../../../common-modules/db-connection/db-dependent.component';
import { ModalService } from '../../../../common-modules/modals/modal.service';
import { changeSettingsProperty, updateSettingsRow } from '../../../../+store/settings/settings.actions';
import { SettingsDto, ValidatedSettings } from '@dunefront/common/modules/settings/dto/settingsDto';
import { getValidatedSettingsState } from '../../../../+store/settings/validated-settings.selectors';
import { ObjectChangeProp } from '@dunefront/common/common/common-state.interfaces';
import { getReferenceVariables } from '../../../../+store/reference-variables/reference-variables.selectors';
import { InputsHelperService } from '../../../../shared/services/inputs-helper.service';

@Component({
  selector: 'app-project-options',
  templateUrl: './project-options.component.html',
  styleUrls: ['./project-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectOptionsComponent extends DbDependentComponent {
  public settings!: ValidatedSettings;
  public defaultSettings!: SettingsDto;

  public referenceVariables$ = this.store.select(getReferenceVariables);

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    private modalService: ModalService,
    private inputsHelperService: InputsHelperService,
  ) {
    super(store, cdRef);
    this.subscription.add(
      this.store.select(getValidatedSettingsState).subscribe((settingsState) => {
        this.settings = settingsState.settings;
        this.defaultSettings = settingsState.defaults;
        this.cdRef.markForCheck();
      }),
    );
    super.onHelpChange('settings', 'general');
  }

  public submitOption(props: ObjectChangeProp<ValidatedSettings>): void {
    if (this.settings[props.key] !== props.value) {
      this.store.dispatch(changeSettingsProperty(props as unknown as ObjectChangeProp<SettingsDto>));
    }
  }

  public async resetDefault(): Promise<void> {
    const ifResetConfirmed = await this.modalService.showConfirm('Are you sure you want to reset Project settings?', 'Warning');

    if (ifResetConfirmed) {
      const resetSettings = { ...this.defaultSettings };
      resetSettings.ScenarioId = this.settings.ScenarioId;
      resetSettings.ShearRate = this.settings.ShearRate;
      resetSettings.SimulationAnimationWellLayout = this.settings.SimulationAnimationWellLayout;
      resetSettings.WellVisualizationWellLayout = this.settings.WellVisualizationWellLayout;
      resetSettings.WellVisualizationWellPart = this.settings.WellVisualizationWellPart;

      if (await this.inputsHelperService.checkResultsAndDeleteIfNeeded(true)) {
        this.store.dispatch(updateSettingsRow(resetSettings));
      }
    }
  }
}
