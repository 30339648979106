/* eslint-disable max-len */
import { ToolPosition } from '../../dto/pumping.dto';
import { FlowPathDescription } from '../../dto/well-fluid.dto';
import { getRowsForCalculations, ITableRow, ITableState } from '../../../../common/common-grid.interfaces';
import { CompletionCalculations } from '../../../completion/model/completion.calculations';
import { Pumping } from '../pumping/pumping';
import { WellFluid } from './well-fluid';
import { IPumpingValidationDependencies, PumpingStateByRange, ValidatedPumpingModuleState } from '../../pumping-module.state';
import { Pipe } from '../../../pipes/pipe';
import { ConvertUnitPipe } from '../../../units/convert-unit.pipe/convert-unit.pipe';
import { MAX_GRAVEL_CONCENTRATION_SI } from '../../../../common/constants';
import { Gravel } from '../../../gravel/model/gravel';
import { IError } from '../../../../common/common-state.interfaces';
import { Fluid } from '../../../fluid/model/fluid';
import { ValidateGravel, ValidateGravelDensity } from '../../../gravel/model/gravel.validation';
import { isFluidValid } from '../../../fluid/model/fluid.validation';
import { UnitSystem } from '../../../../dto/unit-system.dto';
import { IDictionaryWithArray, noErrors } from '../../../../common/state.helpers';
import { DeveloperSettingsDto } from '../../../settings/dto/settingsDto';
import { ReferenceVariableCalculatorResultsDto } from '../../../reference-variables/dto/reference-variable-calculator-results.dto';
import { RunningStringPipesCalculations } from '../../../pipes/running-string-pipes/running-string-pipes.calculations';
import { RangeConstants } from '../../../../dto/range.dto';
import { ModuleType } from '../../../scenario/scenario.dto';

export class WellFluidValidation {
  public static validateWellFluidsTab(
    pumpingState: ValidatedPumpingModuleState,
    deps: IPumpingValidationDependencies,
  ): ValidatedPumpingModuleState {
    const WorkstringFluidId = this.ValidateWorkStringFluids(pumpingState.pumping, deps.fluids, deps.rangeId);
    const UpperAnnulusFluidId = this.ValidateUpperAnnulusFluids(pumpingState.pumping, deps.fluids, deps.rangeId);
    const WashpipeFluidId = this.ValidateWashpipeFluids(pumpingState.pumping, deps.fluids, deps.rangeId);
    const LowerAnnulusFluidId = this.ValidateLowerAnnulusFluids(pumpingState.pumping, deps.fluids, deps.rangeId);

    const newPumping = {
      ...pumpingState.pumping,
      error: {
        ...pumpingState.pumping.error,
        WorkstringFluidId,
        UpperAnnulusFluidId,
        WashpipeFluidId,
        LowerAnnulusFluidId,
      },
    };

    const newWellFluids = this.ValidateWellFluidsGrid(pumpingState, deps);

    const isValid = !WorkstringFluidId && !UpperAnnulusFluidId && !WashpipeFluidId && !LowerAnnulusFluidId && newWellFluids.isValid;

    return {
      ...pumpingState,
      pumping: newPumping,
      wellFluids: newWellFluids,
      isWellFluidsScreenValid: isValid,
    };
  }

  private static ValidateWorkStringFluids(pumping: Pumping, fluids: IDictionaryWithArray<Fluid>, rangeId: number): string {
    if (pumping.IsWellFluidSelectionSimple && rangeId !== RangeConstants.EntireRangeId) {
      if (pumping.WorkstringFluidId === null) {
        return 'Workstring fluid must be selected';
      }

      return isFluidValid(fluids, pumping.WorkstringFluidId);
    }

    return '';
  }

  private static ValidateUpperAnnulusFluids(pumping: Pumping, fluids: IDictionaryWithArray<Fluid>, rangeId: number): string {
    if (pumping.IsWellFluidSelectionSimple && rangeId !== RangeConstants.EntireRangeId) {
      if (pumping.UpperAnnulusFluidId === null) {
        return 'Upper annulus fluid must be selected';
      }
      return isFluidValid(fluids, pumping.UpperAnnulusFluidId);
    }
    return '';
  }

  private static ValidateWashpipeFluids(pumping: Pumping, fluids: IDictionaryWithArray<Fluid>, rangeId: number): string {
    if (pumping.IsWellFluidSelectionSimple && rangeId !== RangeConstants.EntireRangeId) {
      if (pumping.WashpipeFluidId === null) {
        return 'Washpipe fluid must be selected';
      }
      return isFluidValid(fluids, pumping.WashpipeFluidId);
    }
    return '';
  }

  private static ValidateLowerAnnulusFluids(pumping: Pumping, fluids: IDictionaryWithArray<Fluid>, rangeId: number): string {
    if (pumping.IsWellFluidSelectionSimple && rangeId !== RangeConstants.EntireRangeId) {
      if (pumping.LowerAnnulusFluidId === null) {
        return 'Lower annulus fluid must be selected';
      }
      return isFluidValid(fluids, pumping.LowerAnnulusFluidId);
    }
    return '';
  }

  private static ValidateWellFluidVolume(wf: ITableRow<WellFluid>, pumping: Pumping, flowPathVolumeSIUnit: number): string {
    if (!pumping.IsWellFluidSelectionSimple && pumping.IsWellFluidDepthByVolume) {
      if (wf.rowData.Volume <= 0) {
        return 'Volume must be greater than zero';
      }

      if (wf.rowData.CumulativeVolume > flowPathVolumeSIUnit) {
        return 'Cumulative volume must be less than or equal to flow path volume';
      }
    }
    return '';
  }

  private static ValidateWellFluidBottomMD(
    wf: ITableRow<WellFluid>,
    pumping: Pumping,
    developerSettings: DeveloperSettingsDto,
    lowerCompletionRows: Pipe[],
    runningStringRows: Pipe[],
  ): string {
    if (!pumping.IsWellFluidSelectionSimple && !pumping.IsWellFluidDepthByVolume) {
      const maxRunningStringMD = CompletionCalculations.MaxRunningStringMD(runningStringRows);
      const maxLowerCompletionMD = CompletionCalculations.MaxLowerCompletionMD(lowerCompletionRows);

      if (wf.rowData.BottomMD <= 0) {
        return 'Bottom MD must be greater than zero';
      } else if (wf.rowData.BottomMD <= wf.rowData.TopMD) {
        return 'Bottom MD must be greater than top MD';
      } else if (
        (wf.rowData.FlowPathDescription === FlowPathDescription.Workstring ||
          wf.rowData.FlowPathDescription === FlowPathDescription.Upper_Annulus) &&
        wf.rowData.BottomMD > lowerCompletionRows[0].TopMD
      ) {
        return 'Bottom MD must be less than packer top MD';
      } else if (wf.rowData.FlowPathDescription === FlowPathDescription.Washpipe && wf.rowData.BottomMD > maxRunningStringMD) {
        return 'Bottom MD must be less than max running string bottom MD';
      } else if (wf.rowData.FlowPathDescription === FlowPathDescription.Lower_Annulus && wf.rowData.BottomMD > maxLowerCompletionMD) {
        return 'Bottom MD must be less than max lower completion bottom MD';
      }
    }
    return '';
  }

  private static ValidateWellFluidGravelIndex(
    wf: ITableRow<WellFluid>,
    pumping: Pumping,
    runningStringRows: Pipe[],
    lowerAnnulusNoGravelVolumeSIUnit: number,
    gravels: IDictionaryWithArray<Gravel>,
    fluids: IDictionaryWithArray<Fluid>,
  ): string {
    if (!pumping.IsWellFluidSelectionSimple && wf.rowData.GravelId > 0) {
      if (pumping.ToolPosition === ToolPosition.Washdown) {
        return "Gravel can't be pumped when tool position is washdown";
      }
      if (wf.rowData.FlowPathDescription === FlowPathDescription.Lower_Annulus) {
        if (!pumping.IsWellFluidDepthByVolume && wf.rowData.BottomMD > RunningStringPipesCalculations.WashpipeBottomMD(runningStringRows)) {
          return "Gravel can't be defined below the end of the running string";
        }
        if (pumping.IsWellFluidDepthByVolume && wf.rowData.CumulativeVolume > lowerAnnulusNoGravelVolumeSIUnit) {
          return "Gravel can't be defined below the end of the running string";
        }
      }
      const gravelError =
        ValidateGravel(gravels, wf.rowData.GravelId) || ValidateGravelDensity(gravels, wf.rowData.GravelId, fluids, wf.rowData.FluidId);
      if (gravelError) {
        return gravelError;
      }
    }
    return '';
  }

  private static ValidateWellFluidGravelConcentration(wf: ITableRow<WellFluid>, pumping: Pumping): string {
    if (!pumping.IsWellFluidSelectionSimple && wf.rowData.GravelId && wf.rowData.GravelId > 0 && !wf.rowData.IsPacked) {
      const gravelCon = wf.rowData.GravelConcentration;
      if (gravelCon <= 0 || gravelCon > MAX_GRAVEL_CONCENTRATION_SI) {
        const encodeValue = ConvertUnitPipe.encode(UnitSystem.Solid_Concentration, MAX_GRAVEL_CONCENTRATION_SI);
        return `Gravel concentration must be between 0 and ${encodeValue}`;
      }
    }
    return '';
  }

  private static ValidateWellFluidIndex(wf: ITableRow<WellFluid>, pumping: Pumping, fluids: IDictionaryWithArray<Fluid>): string {
    if (!pumping.IsWellFluidSelectionSimple) {
      if (wf.rowData.FluidId === null) {
        return 'Fluid must be selected';
      }
      return isFluidValid(fluids, wf.rowData.FluidId);
    }

    return '';
  }

  private static ValidateWellFluidsRow(
    pumpingState: PumpingStateByRange,
    deps: IPumpingValidationDependencies,
    lowerCompletionRows: Pipe[],
    runningStringRows: Pipe[],
    rowIndex: number,
  ): ITableRow<WellFluid> {
    const { pumping } = pumpingState;
    const { referenceVariables, gravels, fluids, developerSettings } = deps;
    const row = pumpingState.wellFluids.rows[rowIndex];
    if (pumpingState.pumping.IsWellFluidSelectionSimple || !referenceVariables) {
      return { ...row, isValid: true, error: {} };
    }

    const isFluidPro = deps.appModuleType === ModuleType.Simulate_Disp;
    const flowPathVolumeSIUnit = this.getFlowPathVolume(row.rowData.FlowPathDescription, referenceVariables);
    const lowerAnnulusNoGravelVolumeSIUnit = referenceVariables.LowerAnnulusVolumeForGravel;
    const error: IError<WellFluid> = {
      Volume: this.ValidateWellFluidVolume(row, pumping, flowPathVolumeSIUnit),
      BottomMD: this.ValidateWellFluidBottomMD(row, pumping, developerSettings, lowerCompletionRows, runningStringRows),
      GravelId: isFluidPro
        ? ''
        : this.ValidateWellFluidGravelIndex(row, pumping, runningStringRows, lowerAnnulusNoGravelVolumeSIUnit, gravels, fluids),
      GravelConcentration: isFluidPro ? '' : this.ValidateWellFluidGravelConcentration(row, pumping),
      FluidId: this.ValidateWellFluidIndex(row, pumping, fluids),
    };
    const isValid = noErrors(error);
    return { ...row, isValid, error: error };
  }

  private static getFlowPathVolume(flowPathDescription: FlowPathDescription, wellFluidsVolumes: ReferenceVariableCalculatorResultsDto): number {
    switch (flowPathDescription) {
      case FlowPathDescription.Workstring:
        return wellFluidsVolumes.WorkstringVolume;
      case FlowPathDescription.Upper_Annulus:
        return wellFluidsVolumes.CasingAnnulusVolume;
      case FlowPathDescription.Washpipe:
        return wellFluidsVolumes.WashpipeVolume;
      case FlowPathDescription.Lower_Annulus:
        return wellFluidsVolumes.LowerAnnulusVolume;
    }
  }

  private static ValidateWellFluidsGrid(pumpingState: PumpingStateByRange, deps: IPumpingValidationDependencies): ITableState<WellFluid> {
    const { wellFluids } = pumpingState;
    const { completion } = deps;

    const lowerCompletionRows = getRowsForCalculations(completion.LowerCompletion.rows);
    const runningStringRows = getRowsForCalculations(completion.RunningString.rows);
    let isValid = true;
    const newWellFluidRows = wellFluids.rows.map((row, rowIndex) => {
      if (row.rowType === 'insert-row') {
        return row;
      }
      const newRow = this.ValidateWellFluidsRow(pumpingState, deps, lowerCompletionRows, runningStringRows, rowIndex);
      isValid = isValid && newRow.isValid;
      return newRow;
    });

    return { ...wellFluids, rows: newWellFluidRows, isValid };
  }

  public static ValidateLineVolume(
    wellFluids: ITableState<WellFluid>,
    referenceVariables: ReferenceVariableCalculatorResultsDto,
  ): string | undefined {
    const isLessThanOrEqualToZero = wellFluids.rows.find((row) => row.rowData.Volume <= 0);
    const treatingLineVolume = referenceVariables.TreatingLineVolume;
    const returnLineVolume = referenceVariables.ReturnLineVolume;

    //Find the first row index of each flow path
    const wellFluidsRows = getRowsForCalculations(wellFluids.rows);
    const firstWorkstringFluid = wellFluidsRows[0];
    let firstUpperAnnulusFluid: WellFluid | null = null;

    for (let rowIndex = 1; rowIndex < wellFluidsRows.length; rowIndex++) {
      const wf = wellFluidsRows[rowIndex];
      const prevWf = wellFluidsRows[rowIndex - 1];
      if (wf.FlowPathDescription === FlowPathDescription.Upper_Annulus && wf.FlowPathDescription !== prevWf.FlowPathDescription) {
        //This is the first row in the flow path
        firstUpperAnnulusFluid = wf;
        break;
      }
    }

    //Validate line volume
    if (isLessThanOrEqualToZero) {
      return 'Volume must be greater than zero';
    } else if (treatingLineVolume > 0 && firstWorkstringFluid.Volume <= treatingLineVolume) {
      return 'Volume of first workstring fluid must be greater than treating line volume';
    } else if (firstUpperAnnulusFluid && returnLineVolume > 0 && firstUpperAnnulusFluid.Volume <= returnLineVolume) {
      return 'Volume of first upper annulus fluid must be greater than return line volume';
    }
    return;
  }
}
