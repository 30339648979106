import { LoadFluidsActionResponse } from '@dunefront/common/modules/fluid/fluid-module.actions';
import { IFluidUpdate } from './fluid.actions';
import { deleteObjectsFromArray, DictionaryWithArray, IDictionaryWithArray, updateObjectsInArray } from '@dunefront/common/common/state.helpers';
import { FluidHelper, FluidType } from '@dunefront/common/modules/fluid/dto/fluid.dto';
import { CommonDbCopyDirection, CrudResponse } from '@dunefront/common/modules/common.actions';
import { ITableState } from '@dunefront/common/common/common-grid.interfaces';
import { RheologyDto } from '@dunefront/common/modules/fluid/dto/rheology/rheology.dto';
import { RheometerReadingDto } from '@dunefront/common/modules/fluid/dto/rheometer.dto';
import { ArrayHelpers } from '@dunefront/common/common/array-helpers';
import { FluidModuleState, fluidsModuleInitialState } from '@dunefront/common/modules/fluid/fluid-module.state';
import { RheologyFactory } from '@dunefront/common/modules/fluid/model/rheology/rheology.factory';
import { RheometerFactory } from '@dunefront/common/modules/fluid/model/rheometer/rheometer.factory';
import { RheometerReadingFactory } from '@dunefront/common/modules/fluid/model/rheometer-reading/rheometer-reading.factory';
import { Rheology } from '@dunefront/common/modules/fluid/model/rheology/rheology';
import { Rheometer } from '@dunefront/common/modules/fluid/model/rheometer/rheometer';
import { RheometerReading } from '@dunefront/common/modules/fluid/model/rheometer-reading/rheometer-reading';
import { FluidFactory } from '@dunefront/common/modules/fluid/model/fluid.factory';
import { changeProp } from '@dunefront/common/common/common-state.interfaces';
import { ActionResponse } from '@dunefront/common/response-ws.action';

export class FluidModuleReducerHelper {
  // region load data

  public static onLoadScenarioDataSuccessAction(
    state: FluidModuleState,
    response: ActionResponse<LoadFluidsActionResponse>,
    shearRate: number,
  ): FluidModuleState {
    if (response.status !== 'ok' || !response.payload) {
      return fluidsModuleInitialState;
    }
    return FluidFactory.create(response.payload, shearRate);
  }

  // endregion

  // region fluid

  public static selectFluid(state: FluidModuleState, fluidId: number): FluidModuleState {
    return {
      ...state,
      SelectedFluidId: fluidId,
      SelectedTemperatureId: state.Rheometers.dict[fluidId]?.rows[0]?.rowData.Id ?? -1,
    };
  }

  public static changeFluidProperty(state: FluidModuleState, fluidChange: IFluidUpdate): FluidModuleState {
    let modifiedFluid = DictionaryWithArray.getCopy(state.Fluids, fluidChange.fluidId);
    if (!modifiedFluid) {
      return state;
    }

    if (fluidChange.changedProp.key === 'Type') {
      if (fluidChange.changedProp.value === FluidType.Newtonian) {
        modifiedFluid.ThermalConductivity = 0.6;
        modifiedFluid.SpecificHeat = 3500;
      }
      if (fluidChange.changedProp.value === FluidType.Polymer) {
        modifiedFluid.ThermalConductivity = 2;
        modifiedFluid.SpecificHeat = 1500;
      }
      if (fluidChange.changedProp.value === FluidType.Surfactant_Gel) {
        modifiedFluid.ThermalConductivity = 3;
        modifiedFluid.SpecificHeat = 1000;
      }
    }

    modifiedFluid = changeProp(modifiedFluid, fluidChange.changedProp);

    const newFluids = DictionaryWithArray.upsert(state.Fluids, modifiedFluid, 'Id');
    return { ...state, Fluids: newFluids };
  }

  public static updateFluidRowSuccess(state: FluidModuleState, action: CrudResponse): FluidModuleState {
    if (!action.affectedRows.fluid) {
      return state;
    }
    const newFluid = FluidFactory.createFluid(action.affectedRows.fluid.rows[0]);
    return { ...state, Fluids: DictionaryWithArray.upsert(state.Fluids, newFluid, 'Id') };
  }

  public static insertFluidRowSuccess(state: FluidModuleState, action: CrudResponse): FluidModuleState {
    const affectedFluid = action.affectedRows.fluid;
    if (!affectedFluid || action.commonDbCopyDirection === CommonDbCopyDirection.toCommonDb) {
      return state;
    }

    let Fluids = state.Fluids;
    let Rheologies = state.Rheologies;
    let Rheometers = state.Rheometers;
    let RheometerReadings = state.RheometerReadings;
    let SelectedFluidId = state.SelectedFluidId;

    const deletedFluidIds = affectedFluid.deletedIds;
    if (deletedFluidIds.length) {
      const deleteRheometersResult = this.deleteRheometers(state, deletedFluidIds);
      Fluids = DictionaryWithArray.deleteItems(state.Fluids, deletedFluidIds);
      Rheometers = deleteRheometersResult.Rheometers;
      RheometerReadings = deleteRheometersResult.RheometerReadings;
      Rheologies = DictionaryWithArray.deleteItems(state.Rheologies, deletedFluidIds);
    }

    if (affectedFluid.replace === 'all') {
      // this is after undo
      Fluids = FluidFactory.createDictionaryWithArray(affectedFluid.rows);
      SelectedFluidId = affectedFluid.rows.find((row) => row.Name === FluidHelper.DEFAULT_NAME)?.Id ?? +Fluids.ids[Fluids.ids.length - 1];
    } else {
      const newFluid = FluidFactory.createFluid(affectedFluid.rows[0]);
      Fluids = DictionaryWithArray.upsert(Fluids, newFluid, 'Id');
      SelectedFluidId = newFluid.Id;
    }

    return {
      ...state,
      Fluids,
      SelectedFluidId,
      SelectedTemperatureId: -1,
      Rheometers,
      RheometerReadings,
      Rheologies,
    };
  }

  public static deleteFluidRowsSuccess(state: FluidModuleState, action: CrudResponse): FluidModuleState {
    if (!action.affectedRows.fluid) {
      return state;
    }
    const fluidIdToDelete = action.affectedRows.fluid?.deletedIds[0];
    const Fluids = DictionaryWithArray.deleteItem(state.Fluids, fluidIdToDelete);

    const deleteRheometersResult = this.deleteRheometers(state, [fluidIdToDelete]);
    const Rheometers = deleteRheometersResult.Rheometers;
    const RheometerReadings = deleteRheometersResult.RheometerReadings;
    const Rheologies = DictionaryWithArray.deleteItems(state.Rheologies, [fluidIdToDelete]);

    return {
      ...state,
      Fluids,
      Rheometers,
      Rheologies,
      RheometerReadings,
      SelectedFluidId: DictionaryWithArray.getMaxId(Fluids),
      SelectedTemperatureId: -1,
    };
  }

  // endregion

  // region rheology

  public static updateRheologyRowSuccess(state: FluidModuleState, action: CrudResponse): FluidModuleState {
    if (!action.affectedRows.rheology || !action.affectedRows.rheology.rows.length) {
      return state;
    }

    const updatedRows = action.affectedRows.rheology.rows;
    const fluidId = updatedRows[0].FluidId;
    let rheologyTableState = DictionaryWithArray.get(state.Rheologies, fluidId);

    if (!rheologyTableState) {
      console.error('updateRheologyRowSuccess - rheology not found');
      return state;
    }

    if (action.affectedRows.rheology.deletedIds.length) {
      rheologyTableState = {
        ...rheologyTableState,
        rows: deleteObjectsFromArray(rheologyTableState.rows, action.affectedRows.rheology.deletedIds),
      };
    }

    const updatedRheologiesTableState =
      action.affectedRows.rheology.replace === 'byParentId'
        ? RheologyFactory.createTableStateForFluidId(updatedRows, action.scenarioId, fluidId)
        : this.updateRheologyRows(rheologyTableState, action.affectedRows.rheology.rows);
    const newRheologies = DictionaryWithArray.upsertById(state.Rheologies, updatedRheologiesTableState, fluidId);
    const newState = {
      ...state,
      Rheologies: newRheologies,
    };
    return newState;
  }

  public static insertRheologyRowsSuccess(state: FluidModuleState, action: CrudResponse): FluidModuleState {
    if (!action.affectedRows.rheology || action.commonDbCopyDirection === CommonDbCopyDirection.toCommonDb) {
      return state;
    }

    const fluidId = action.affectedRows.rheology.parentId as number;
    const newRows = RheologyFactory.createTableStateForFluidId(action.affectedRows.rheology.rows, action.scenarioId, fluidId);
    return { ...state, Rheologies: DictionaryWithArray.upsertById(state.Rheologies, newRows, fluidId) };
  }

  public static deleteRheologyRowsSuccess(state: FluidModuleState, action: CrudResponse): FluidModuleState {
    if (!action.affectedRows.rheology || action.affectedRows.rheology.parentId == null) {
      return state;
    }
    const fluidId = action.affectedRows.rheology.parentId;
    const rheologyTableState = DictionaryWithArray.get(state.Rheologies, fluidId);
    if (!rheologyTableState) {
      console.error('deleteRheologyRowsSuccess - rheology not found');
      return state;
    }

    const rows: ITableState<Rheology> = {
      ...rheologyTableState,
      rows: deleteObjectsFromArray(rheologyTableState.rows, action.affectedRows.rheology.deletedIds),
    };

    const updatedRows = this.updateRheologyRows(rows, action.affectedRows.rheology.rows);

    return { ...state, Rheologies: DictionaryWithArray.upsertById(state.Rheologies, updatedRows, fluidId) };
  }

  private static updateRheologyRows(rheologiesTableState: ITableState<Rheology>, affectedRows: RheologyDto[]): ITableState<Rheology> {
    const rows = updateObjectsInArray(
      rheologiesTableState.rows,
      affectedRows.map((rowDto) => RheologyFactory.createRheology(rowDto)),
    );

    // if it's a 'initial' load of rheologies, f.e. after switching fluid type to Newtonian, n'k' -
    // just override placeholder rows from the state, with newly created rheology
    const dataRows = rows.filter((r) => r.rowType === 'data');
    if (dataRows.length === 1 && dataRows[0].rowData.Id === -1 && affectedRows.length > 0) {
      const { ScenarioId, FluidId } = affectedRows[0];
      return {
        ...rheologiesTableState,
        rows: RheologyFactory.createTableStateForFluidId(affectedRows, ScenarioId, FluidId).rows,
      };
    }

    return { ...rheologiesTableState, rows };
  }

  // endregion

  // region rheometer

  public static selectTemperature(state: FluidModuleState, temperatureId: number): FluidModuleState {
    return { ...state, SelectedTemperatureId: temperatureId };
  }

  public static updateRheometerRowSuccess(state: FluidModuleState, action: CrudResponse): FluidModuleState {
    if (!action.affectedRows.rheometer) {
      return state;
    }
    const updatedRows = action.affectedRows.rheometer.rows;
    const fluidId = updatedRows[0].FluidId;
    const fluidRheometers = DictionaryWithArray.get(state.Rheometers, fluidId);
    if (!fluidRheometers) {
      return state;
    }

    const newRheometersRows = updateObjectsInArray(
      fluidRheometers.rows,
      updatedRows.map((dto) => RheometerFactory.createRheometer(dto)),
    );
    const newRheometerTableState: ITableState<Rheometer> = {
      ...fluidRheometers,
      rows: newRheometersRows.sort((a, b) => a.rowData.Temperature - b.rowData.Temperature),
    };

    return {
      ...state,
      Rheometers: DictionaryWithArray.upsertById(state.Rheometers, newRheometerTableState, fluidId),
    };
  }

  public static insertRheometerRowsSuccess(state: FluidModuleState, action: CrudResponse): FluidModuleState {
    if (!action.affectedRows.rheometer || action.commonDbCopyDirection === CommonDbCopyDirection.toCommonDb) {
      return state;
    }

    const newFluidId = action.affectedRows.rheometer.rows[0].FluidId;
    const newRheometerRows = RheometerFactory.createTableStateForFluidId(action.affectedRows.rheometer.rows);
    const selectedTemp = ArrayHelpers.last(newRheometerRows.rows)?.rowData.Id ?? state.SelectedTemperatureId;

    return {
      ...state,
      Rheometers: DictionaryWithArray.upsertById(state.Rheometers, newRheometerRows, newFluidId),
      SelectedTemperatureId: selectedTemp,
    };
  }

  public static deleteRheometerRowsSuccess(state: FluidModuleState, action: CrudResponse): FluidModuleState {
    if (!action.affectedRows.rheometer || action.affectedRows.rheometer.parentId == null) {
      return state;
    }
    const fluidId = action.affectedRows.rheometer.parentId;

    const rheometerTableState = DictionaryWithArray.get(state.Rheometers, fluidId);
    if (!rheometerTableState) {
      console.error('deleteRheometerRowsSuccess - rheometerTable not found', fluidId);
      return state;
    }
    const rheometerTable: ITableState<Rheometer> = {
      ...rheometerTableState,
      rows: deleteObjectsFromArray(rheometerTableState.rows, action.affectedRows.rheometer.deletedIds),
    };

    const selectedTemp = ArrayHelpers.last(rheometerTable.rows)?.rowData.Id ?? state.SelectedTemperatureId;
    return {
      ...state,
      Rheometers: DictionaryWithArray.upsertById(state.Rheometers, rheometerTable, fluidId),
      SelectedTemperatureId: selectedTemp,
    };
  }

  // endregion

  // region rheometer readings

  public static updateRheometerReadingRowSuccess(state: FluidModuleState, action: CrudResponse): FluidModuleState {
    if (!action.affectedRows.rheometerReading) {
      return state;
    }
    let newRheometerReadings = { ...state.RheometerReadings };

    if (action.affectedRows.rheometerReading.replace === 'byParentId') {
      let deleteParentIds: number[] = [];
      if (action.affectedRows.rheometerReading.parentId != null) {
        deleteParentIds = [action.affectedRows.rheometerReading.parentId];
      } else if (action.affectedRows.rheometerReading.replaceByParentIds) {
        deleteParentIds = action.affectedRows.rheometerReading.replaceByParentIds;
      }
      deleteParentIds.forEach((parentId) => {
        newRheometerReadings = DictionaryWithArray.deleteItem(newRheometerReadings, parentId);
      });
    }

    newRheometerReadings = DictionaryWithArray.groupAndUpsert(
      newRheometerReadings,
      action.affectedRows.rheometerReading.rows,
      (data) => data.RheometerId,
      (rrRows) => RheometerReadingFactory.createRheometerReadingTableState(rrRows),
    );

    return { ...state, RheometerReadings: newRheometerReadings };
  }

  public static insertRheometerReadingRowsSuccess(state: FluidModuleState, action: CrudResponse): FluidModuleState {
    if (!action.affectedRows.rheometerReading || action.commonDbCopyDirection === CommonDbCopyDirection.toCommonDb) {
      return state;
    }
    const newRheometerReadings = DictionaryWithArray.groupAndUpsert(
      state.RheometerReadings,
      action.affectedRows.rheometerReading.rows,
      (data) => data.RheometerId,
      (rrRows) => RheometerReadingFactory.createRheometerReadingTableState(rrRows),
      true,
    );

    return { ...state, RheometerReadings: newRheometerReadings };
  }

  public static deleteRheometerReadingRowsSuccess(state: FluidModuleState, action: CrudResponse): FluidModuleState {
    if (!action.affectedRows.rheometerReading || action.affectedRows.rheometerReading.parentId == null) {
      return state;
    }
    const rheometerId = action.affectedRows.rheometerReading.parentId;
    const rheometerReadingTableState = DictionaryWithArray.get(state.RheometerReadings, rheometerId);

    if (!rheometerReadingTableState) {
      return state;
    }

    const rows: ITableState<RheometerReading> = {
      ...rheometerReadingTableState,
      rows: deleteObjectsFromArray(rheometerReadingTableState.rows, action.affectedRows.rheometerReading.deletedIds),
    };
    const updatedRows = this.updateRheometerReadingRows(rows, action.affectedRows.rheometerReading.rows);
    return {
      ...state,
      RheometerReadings: DictionaryWithArray.upsertById(state.RheometerReadings, updatedRows, rheometerId),
    };
  }

  private static updateRheometerReadingRows(
    rheologiesTableState: ITableState<RheometerReading>,
    affectedRows: RheometerReadingDto[],
  ): ITableState<RheometerReading> {
    const rows = updateObjectsInArray(
      rheologiesTableState.rows,
      affectedRows.map((rowDto) => RheometerReadingFactory.createRheometerReading(rowDto)),
    );
    return { ...rheologiesTableState, rows };
  }

  // endregion

  private static deleteRheometers(
    state: FluidModuleState,
    fluidIdsToDelete: (string | number)[],
  ): {
    Rheometers: IDictionaryWithArray<ITableState<Rheometer>>;
    RheometerReadings: IDictionaryWithArray<ITableState<RheometerReading>>;
  } {
    let RheometerReadings = state.RheometerReadings;
    fluidIdsToDelete.forEach((fluidIdToDelete) => {
      const fluidRheometers = DictionaryWithArray.get(state.Rheometers, fluidIdToDelete);
      if (!fluidRheometers) {
        return;
      }
      const rheometerIds = fluidRheometers.rows.map((rh) => rh.rowData.Id);
      RheometerReadings = DictionaryWithArray.deleteItems(RheometerReadings, rheometerIds);
    });
    const Rheometers = DictionaryWithArray.deleteItems(state.Rheometers, fluidIdsToDelete);
    return { Rheometers, RheometerReadings };
  }
}
