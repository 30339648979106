import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { getAreCurrentResultsPresentAndCompleted } from '../../../+store/calculation-engine/calculation-engine-results.selectors';
import { ISelectItem } from '@dunefront/common/common/select.helpers';
import { ExportDataStartJobActionPayload } from '@dunefront/common/modules/reporting/reporting-module.actions';
import { isSimulateBased, ModuleType } from '@dunefront/common/modules/scenario/scenario.dto';
import { validateAndStartExportDataJobAction } from '../../../+store/reporting/reporting.actions';
import { DataFileType } from '@dunefront/common/dto/data-storage';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DbDependentComponent } from '../../db-connection/db-dependent.component';
import { defaultModalCancelButton, modalButton, ModalButtonConfig } from '../generic-modal/generic-modal.component';
import { getExportDataUseTimestamps } from '../../../+store/ui/ui.selectors';
import { PrimitiveChangeValue } from '@dunefront/common/common/common-state.interfaces';
import { setExportDataUseTimestampsAction } from '../../../+store/ui/ui.actions';
import { electronGetTargetDirAndExportData } from '../../../+store/electron-main/electron-main.actions';
import { isElectron } from '@dunefront/common/common/electron/is-electron';
import { ModalService } from '../modal.service';

@Component({
  selector: 'app-export-data',
  template: ` <app-generic-modal [title]="modalTitle" [modalButtonsConfigs]="modalButtonsConfigs">
    <div class="grid-form-container cols-1">
      <div class="grid-one-one">
        <app-radio-group
          label="File Type"
          [items]="fileTypes"
          layout="vertical"
          [value]="fileType"
          (primitiveValueChanged)="fileTypeChanged($event.value)"
          [isUiLockable]="false"
          id="export-data-radio"
        >
        </app-radio-group>
      </div>
    </div>
    <div class="grid-form-container cols-1">
      <label>X-Axis format</label>
      <app-radio-group
        layout="horizontal"
        [isUiLockable]="false"
        [items]="[
          { value: false, text: 'Delta time' },
          { value: true, text: 'Timestamp' },
        ]"
        [value]="exportDataUseTimestamps"
        (primitiveValueChanged)="onExportDataUseTimestampsChanged($event)"
        id="export-data-should-use-timestamps"
        dataCy="export-data-x-axis-format"
      >
      </app-radio-group>
    </div>
  </app-generic-modal>`,
  styleUrls: ['./export-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportDataComponent extends DbDependentComponent implements OnInit {
  public modalTitle = 'Export Results';
  public fileType: DataFileType = DataFileType.ImportedData;
  public fileTypes: ISelectItem<DataFileType>[] = [];
  public exportDataUseTimestamps = false;
  private resultsPresent!: boolean;
  private readonly dataTypesWithImportedData = [
    DataFileType.ImportedData,
    DataFileType.MergedData,
    DataFileType.TrendAnalysisData,
    DataFileType.DataWithXYAxisShifts,
  ];

  public modalButtonsConfigs: ModalButtonConfig[] = [
    defaultModalCancelButton((): void => this.closeDialog()),
    modalButton('Export Results', (): Promise<void> => this.exportDataClicked(), 'export-data-export-btn'),
  ];

  constructor(
    store: Store,
    cdRef: ChangeDetectorRef,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private modal: ModalService,
  ) {
    super(store, cdRef);

    this.subscription.add(
      this.store.select(getAreCurrentResultsPresentAndCompleted).subscribe((resultsPresent) => {
        this.resultsPresent = resultsPresent;
        this.cdRef.markForCheck();
      }),
    );

    this.subscription.add(
      this.store.select(getExportDataUseTimestamps).subscribe((useTimestamps) => {
        this.exportDataUseTimestamps = useTimestamps;
        this.cdRef.markForCheck();
      }),
    );
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    if (this.currentAppModuleType === ModuleType.Evaluate && this.currentRangeId > 0 && this.resultsPresent) {
      this.fileTypes.push({ value: DataFileType.EvaluateResult, text: 'Evaluate results' });
    }
    if (this.currentAppModuleType === ModuleType.Trend_Analysis && this.currentRangeId > 0 && this.resultsPresent) {
      this.fileTypes.push({ value: DataFileType.EvaluateResult, text: 'Trend analysis results' });
    }
    if (
      isSimulateBased(this.currentAppModuleType) ||
      (this.currentAppModuleType === ModuleType.Evaluate && this.currentRangeId > 0 && this.resultsPresent)
    ) {
      let text;
      switch (this.currentAppModuleType) {
        case ModuleType.Simulate_CH:
          text = 'Simulate CH results';
          break;
        case ModuleType.Simulate_Disp:
          text = 'FluidPro Results';
          break;
        default:
          text = 'Simulate results';
      }

      this.fileTypes.push({ value: DataFileType.SimulateResult, text });
    }
    if (this.currentAppModuleType === ModuleType.Trend_Analysis || this.currentAppModuleType === ModuleType.Evaluate) {
      this.fileTypes.push({ value: DataFileType.MergedData, text: 'Imported data with X axis shift' });
    }
    if (this.currentAppModuleType === ModuleType.Trend_Analysis && this.currentRangeId > 0) {
      this.fileTypes.push({ value: DataFileType.TrendAnalysisData, text: 'Imported data with X and Y axis shifts' });
    }
    if (this.currentAppModuleType === ModuleType.Data_Analysis) {
      this.fileTypes.push({ value: DataFileType.DataWithXYAxisShifts, text: 'Imported data with X and Y axis shifts' });
    }

    this.fileType = this.fileTypes?.[0].value as DataFileType;
  }

  public onExportDataUseTimestampsChanged(valueChanged: PrimitiveChangeValue<boolean>): void {
    this.store.dispatch(setExportDataUseTimestampsAction({ useTimestamps: valueChanged.value }));
  }

  public closeDialog(): void {
    this.ref.close();
  }

  public async exportDataClicked(): Promise<void> {
    if (this.fileType == null || this.currentAppModuleType == null) {
      return;
    }

    const selectedScenarios = this.config.data?.scenariosForExport ? this.config.data.scenariosForExport : [this.currentScenarioId];

    const dataExportPayloads: ExportDataStartJobActionPayload[] = selectedScenarios.map((scenarioId: number) => ({
      scenarioId: scenarioId,
      rangeId: this.currentRangeId,
      fileType: this.fileType,
      moduleType: this.currentAppModuleType,
      useTimestamps: this.exportDataUseTimestamps,
    }));

    if (selectedScenarios.length > 1 && isElectron()) {
      this.store.dispatch(electronGetTargetDirAndExportData({ payloads: dataExportPayloads }));
    } else {
      this.store.dispatch(
        validateAndStartExportDataJobAction({
          payload: { payloads: dataExportPayloads, totalPayloads: dataExportPayloads.length },
        }),
      );
    }

    this.closeDialog();
  }

  public fileTypeChanged(fileType: number): void {
    this.fileType = fileType;
  }
}
