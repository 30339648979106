import { createEmptyReportInfoDto, createEmptyReportInfoFields, ReportInfoDto, ReportInfoFields } from '@dunefront/common/dto/report-info.dto';
import { createReducer, on } from '@ngrx/store';
import {
  loadReportInfoModuleSuccessAction,
  reportInfoSelectAllClearAllAction,
  updateReportInfoFieldsAction,
  updateReportInfoRangesConfigAction,
} from './report-info.actions';
import { ReportInfoReducerHelper } from './report-info.reducer.helper';
import { resetScenarioState, updateRowSuccess } from '../app.actions';
import { setAppModuleAction } from '../ui/ui.actions';
import { TreeListConfig } from '../../shared/components/filterable-tree/filterable-tree.component';

export const ReportInfoModuleFeatureName = 'reportInfo';

export interface ReportInfoModuleState {
  reportInfo: ReportInfoDto;
  reportInfoFields: ReportInfoFields;
  rangesConfig: TreeListConfig;
}

const initialState: ReportInfoModuleState = {
  reportInfo: createEmptyReportInfoDto(),
  reportInfoFields: createEmptyReportInfoFields(),
  rangesConfig: {
    itemsOrder: [],
  },
};

export const reportInfoModuleReducer = createReducer<ReportInfoModuleState>(
  initialState,
  on(resetScenarioState, setAppModuleAction, (state, action) => ReportInfoReducerHelper.ResetRangeSelection(state)),
  on(loadReportInfoModuleSuccessAction, (state, action) => ReportInfoReducerHelper.ReportInfoLoadSuccess(state, action)),
  on(updateReportInfoFieldsAction, (state, action) => ReportInfoReducerHelper.UpdateReportInfoFields(state, action)),
  on(updateReportInfoRangesConfigAction, (state, action) => ReportInfoReducerHelper.UpdateReportInfoRangesConfig(state, action)),
  on(updateRowSuccess, (state, action): ReportInfoModuleState => {
    if (action.affectedRows.reportInfo) {
      return { ...state, reportInfo: action.affectedRows.reportInfo };
    }
    return state;
  }),
  on(reportInfoSelectAllClearAllAction, (state, action): ReportInfoModuleState => ReportInfoReducerHelper.SelectAllClearAll(state, action)),
);
