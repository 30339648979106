import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { IFile, Repository } from '@dunefront/common/dto/file.dto';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { getCurrentFolderStateFiltered, getRecentFiles, getSearchPhrase } from '../../../+store/file-manager/file-manager.selectors';
import { loadRecentFiles } from '../../../+store/file-manager/file-manager.actions';
import { Router } from '@angular/router';
import { FileUrlHelper } from '../file-url.helper';
import { ContextMenuRequired } from './file-context-menu-component/file-context-menu.component';
import { ArrayHelpers } from '@dunefront/common/common/array-helpers';
import { ModalService } from '../../../common-modules/modals/modal.service';
import {
  RouteModuleAdmin,
  RouteModuleAdminFileManagement,
  RouteModuleAdminUser,
} from '@dunefront/common/common/routes-names/admin-routes-names';
import { RouterHelperService } from '../../../shared/services/router-helper.service';
import { RouteModuleHome } from '../home-page-routes-names';
import { getBackendToClientConfig } from '@dunefront/common/backend-to-client-config';
import { IUserProfile } from '@dunefront/common/modules/auth/auth.interfaces';
import { getFilesJobsTypes, IFileJobTypes } from '../../../+store/calculation-engine/files-jobs.selectors';
import { FileJobTypesHelper } from '../../../+store/calculation-engine/file-job-types-helper';
import { ProjectFileHelpers } from '@dunefront/common/common/project-file-helpers';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileListComponent implements OnInit, OnDestroy {
  @Input() public repository: Repository = 'personal';
  @Input() public isRecentMode = false;
  @Input() public customFileClickHandler?: (file: IFile) => void;
  @Input() public showRowContextMenu = true;
  @Input() public user: IUserProfile | null = null;


  @HostBinding('class')
  public get pipePropsTypeCssName(): string {
    return this.repository
  }

  public searchPhrase$ = this.store.select(getSearchPhrase);

  protected subscription = new Subscription();
  public folder: IFile | undefined;
  public uploadUrl: string;
  public progress = 0;
  public files: IFile[] = [];
  public removeSpaceRegex = /\s/g;
  public isAdmin = false;
  public contextMenuRequired?: ContextMenuRequired | null;

  public allFilesJobTypes: IFileJobTypes[] = [];

  constructor(
    private store: Store,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private modalService: ModalService,
    private routerHelperService: RouterHelperService,
  ) {
    this.uploadUrl = getBackendToClientConfig().ServerBaseUrl + '/file-manager/upload';
    this.isAdmin = FileUrlHelper.isAdmin(router.url);
  }

  public ngOnInit(): void {
    if (this.isRecentMode) {
      this.fetchRecentFiles();
    } else {
      this.fetchRepositoryItems();
    }

    this.subscription.add(
      this.store.select(getFilesJobsTypes).subscribe((filesJobTypes) => {
        this.allFilesJobTypes = filesJobTypes;
        this.cdRef.markForCheck();
      }),
    );
  }

  private fetchRecentFiles(): void {
    this.subscription.add(
      this.store.select(getRecentFiles).subscribe((files) => {
        this.files = files;
        this.cdRef.markForCheck();
      }),
    );
    this.store.dispatch(loadRecentFiles());
  }

  private fetchRepositoryItems(): void {
    this.subscription.add(
      this.store.select(getCurrentFolderStateFiltered).subscribe((currentFolder: IFile | undefined) => {
        if (currentFolder == null) {
          this.files = [];
          this.folder = undefined;
        } else {
          const folder = { ...currentFolder };
          let children: IFile[] = [];
          if (folder.Children && folder.Children.length) {
            // if in trash folder, flatten children from sub folders
            if (folder.Repository === 'trash') {
              const flatChildren: IFile[] = [];
              this.flattenChildren(folder, flatChildren);
              folder.Children = flatChildren;
            }

            children = [...folder.Children].sort((file1, file2) => {
              if (file1.FileType !== file2.FileType) {
                return Number(file2.FileType === 'folder') * 2 - 1;
              }

              const name1 = ProjectFileHelpers.isValidProjectFile(file1.Name) ? file1.Name.slice(0, -4) : file1.Name;
              const name2 = ProjectFileHelpers.isValidProjectFile(file2.Name) ? file2.Name.slice(0, -4) : file2.Name;
              return ArrayHelpers.stringCompare(name1, name2);
            });
          }

          this.folder = { ...folder, Children: children };
          this.files = this.folder.Children || [];
        }
        this.cdRef.markForCheck();
      }),
    );
  }

  private flattenChildren(currentFolder: IFile, flatArray: IFile[]): void {
    if (!currentFolder.Children) {
      return;
    }

    currentFolder.Children.forEach((file) => {
      if (file.FileType === 'folder') {
        this.flattenChildren(file, flatArray);
      } else {
        flatArray.push(file);
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public openFile(file: IFile): void {
    if (file.FileType !== 'folder' && this.isAdmin) {
      // dont open files on admin mode
      return;
    }

    if (!FileJobTypesHelper.canOpenFile(file, this.allFilesJobTypes)) {
      this.modalService.showAlert("Migration in progress, file can't be opened.").then();
      return;
    }

    if (this.customFileClickHandler != null) {
      this.customFileClickHandler(file);
    } else {
      FileUrlHelper.fileNavigate(file, this.router, this.modalService, undefined, this.user != null);
    }
  }

  public onContextMenu(file: IFile, event: MouseEvent): boolean {
    this.contextMenuRequired = { file, x: event.x, y: event.y, event };
    return false;
  }

  public folderClick(folderLevel?: number): void {
    if (!this.folder) {
      return;
    }
    if (folderLevel === -1) {
      const url = this.user
        ? `/${RouteModuleAdmin}/${RouteModuleAdminFileManagement}/${RouteModuleAdminUser}`
        : `/${RouteModuleHome}/` + this.repository;
      this.routerHelperService.navigate([url]).then();

      return;
    }
    if (folderLevel !== undefined) {
      const targetFolder: IFile = {
        ...this.folder,
        Folder: this.folder.Folder.slice(0, folderLevel),
        Name: this.folder.Folder[folderLevel],
      };
      FileUrlHelper.fileNavigate(targetFolder, this.router, this.modalService, undefined, this.user != undefined);
      return;
    }
  }

  public resetContextMenu(): void {
    this.contextMenuRequired = null;
  }

  public trackByFn(index: number, item: IFile): string {
    return item.Name;
  }
}
