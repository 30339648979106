<div class="grid-form-container main" *ngIf="completion">
  <div class="grid-form-container no-padding inline left-col">
    <app-form-input
      appUnwrap
      label="Start MD"
      dataCy="calculation-volume-start-md"
      [source]="completion.Volumes"
      [key]="'SectionVolCalcStartMD'"
      [unitType]="UnitType.Long_Length"
      [isUiLockable]="false"
      [name]="'MD'"
      (valueChanged)="onVolumesPropertyChanged($event)"
    ></app-form-input>

    <label>Start Location</label>
    <app-select
      dataCy="calculation-volume-start-location"
      [source]="completion.Volumes"
      [key]="'SectionVolCalcStartMeasurementType'"
      [items]="GaugeLocationItems"
      [isUiLockable]="false"
      (valueChanged)="onVolumesPropertyChanged($event)"
    >
    </app-select>

    <app-form-input
      [source]="completion.Volumes"
      [key]="'SectionVolCalcEndMD'"
      appUnwrap
      label="End MD"
      dataCy="calculation-volume-end-md"
      [isUiLockable]="false"
      [unitType]="UnitType.Long_Length"
      [name]="'MD'"
      (valueChanged)="onVolumesPropertyChanged($event)"
    ></app-form-input>

    <label>End Location</label>
    <app-select
      dataCy="calculation-volume-end-location"
      [source]="completion.Volumes"
      [key]="'SectionVolCalcEndMeasurementType'"
      [items]="GaugeLocationItems"
      [isUiLockable]="false"
      (valueChanged)="onVolumesPropertyChanged($event)"
    >
    </app-select>

    <app-gravel-selector
      *hideOnFluidPro
      appUnwrap
      label="Gravel for Packing"
      dataCy="calculation-volume-gravel-for-packing"
      [source]="completion.Volumes"
      [key]="'SectionVolCalcGravelId'"
      [isUiLockable]="false"
      (valueChanged)="onVolumesPropertyChanged($event)"
      [errorMessage]="completion.Volumes.error.SectionVolCalcGravelId"
      [showSymbol]="true"
    >
    </app-gravel-selector>

    <app-form-input
      *hideOnFluidPro
      appUnwrap
      label="Blank Gravel Conc."
      dataCy="calculation-volume-blank-gravel-conc"
      [source]="completion.Volumes"
      [disabled]="completion.Volumes.SectionVolCalcGravelId <= 0"
      [key]="'SectionVolCalcGravelConc'"
      [isUiLockable]="false"
      [unitType]="UnitType.Solid_Concentration"
      (valueChanged)="onVolumesPropertyChanged($event)"
    ></app-form-input>
  </div>

  <div class="grid-form-container no-padding inline" *ngIf="sectionInfo$ | async as si">
    <app-form-input
      appUnwrap
      label="Inner Volume"
      dataCy="calculation-volume-inner-volume"
      [source]="si"
      [key]="'InnerVolume'"
      [isUiLockable]="false"
      [unitType]="UnitType.Liquid_Volume"
      [disabled]="true"
    ></app-form-input>

    <app-form-input
      appUnwrap
      label="Outer Volume"
      dataCy="calculation-volume-outer-volume"
      [source]="si"
      [key]="'OuterVolume'"
      [isUiLockable]="false"
      [unitType]="UnitType.Liquid_Volume"
      [disabled]="true"
    ></app-form-input>

    <app-form-input
      appUnwrap
      label="Total Volume"
      dataCy="calculation-volume-total-volume"
      [source]="si"
      [readOnlyValue]="si.OuterVolume + si.InnerVolume"
      [unitType]="UnitType.Liquid_Volume"
      [isUiLockable]="false"
      [disabled]="true"
    ></app-form-input>

    <app-form-input
      *hideOnFluidPro
      appUnwrap
      label="Packed Gravel"
      dataCy="calculation-volume-packed-gravel"
      [source]="si"
      [key]="'PackedGravelMass'"
      [isUiLockable]="false"
      [unitType]="UnitType.Mass"
      [disabled]="true"
    ></app-form-input>

    <app-form-input
      *hideOnFluidPro
      appUnwrap
      label="Suspended Gravel"
      dataCy="calculation-volume-suspended-gravel"
      [source]="si"
      [key]="'SuspendedGravelMass'"
      [isUiLockable]="false"
      [unitType]="UnitType.Mass"
      [disabled]="true"
    ></app-form-input>

    <div></div>

    <app-button
      [isUiLockable]="false"
      dataCy="calculation-volume-calculate-btn"
      (buttonClick)="onCalculate()"
      text="Calculate"
    >
    </app-button>
  </div>
</div>
