import { ValidatedWellModuleState } from '../well/well-module.state';
import { ValidatedCompletionModuleState } from '../completion/completion-module.state';
import { ValidatedFluidModuleState } from '../fluid/fluid-module.state';
import { ValidatedDeveloperSettings, ValidatedSettings } from '../settings/dto/settingsDto';
import { ValidatedGravelModuleState } from '../gravel/gravel-module.state';
import { ValidatedAnalysisDataComponentState, ValidatedPumpingModuleState } from '../pumping/pumping-module.state';
import { FluidGravelUsageFinder, FluidUsageSimulateEvaluateFinderDeps } from './fluid-gravel-usage-finder';
import { ErrorHelper } from '../../common/common-state.interfaces';
import { ModuleType } from '../scenario/scenario.dto';
import { ValidatedGlobalOptions } from '../../dto/global-options.dto';
import { IValidatedScenarioRangeProperties } from '../range/model/range';

export interface IValidatedSimulateEvaluateState {
  wellState: ValidatedWellModuleState;
  completionState: ValidatedCompletionModuleState;
  fluidsState: ValidatedFluidModuleState;
  settings: ValidatedSettings;
  developerSettings: ValidatedDeveloperSettings;
  gravelState: ValidatedGravelModuleState;
  pumpingState: ValidatedPumpingModuleState;
  gaugeDataPageValidation: IGaugeDataPageValidation;
}

export interface IValidateTrendAnalysisState {
  validatedScenarioRangeProperties: IValidatedScenarioRangeProperties;
  validatedPumpRateAndPressure: ValidatedAnalysisDataComponentState;
}

export interface IValidatedState extends IValidatedSimulateEvaluateState, IValidateTrendAnalysisState {}

export class ValidationHelpers {
  public static checkValidationForScheduleGenerator(state: IValidatedState): string {
    let errorMessage = ErrorHelper.ERROR_MULTIPLE_SCREENS_MESSAGE_HEADER;
    const startLength = errorMessage.length;

    const autoScheduleCarrierFluidId = state.pumpingState.pumping.AutoScheduleCarrierFluidId as number;
    const autoScheduleFlushFluidId = state.pumpingState.pumping.AutoScheduleFlushFluidId as number;
    const autoScheduleGravelId = state.pumpingState.pumping.AutoScheduleGravelId as number;

    errorMessage = this.validateWell(errorMessage, state);
    errorMessage = this.validateCompletion(errorMessage, state);

    if (state.fluidsState.Fluids.dict[autoScheduleCarrierFluidId]?.isValid === false) {
      errorMessage += '-> Carrier Fluid <br>';
    }

    if (state.fluidsState.Fluids.dict[autoScheduleFlushFluidId]?.isValid === false) {
      errorMessage += '-> Flush Fluid <br>';
    }

    if (state.gravelState.Gravels.dict[autoScheduleGravelId]?.isValid === false) {
      errorMessage += '-> Gravel <br>';
    }

    errorMessage = this.validatePumping(errorMessage, state, false, true);
    errorMessage = this.validateSettings(errorMessage, state);

    if (startLength !== errorMessage.length) {
      return errorMessage;
    }

    return '';
  }

  public static checkValidationForSimulationAndEvaluation(
    moduleType: ModuleType,
    state: IValidatedSimulateEvaluateState,
    deps: FluidUsageSimulateEvaluateFinderDeps,
    rangeId: number,
  ): string {
    let errorMessage = ErrorHelper.ERROR_MULTIPLE_SCREENS_MESSAGE_HEADER;
    const startLength = errorMessage.length;

    errorMessage = this.validateWell(errorMessage, state);
    errorMessage = this.validateCompletion(errorMessage, state);
    errorMessage = this.validateUsedFluids(errorMessage, state, deps, rangeId);
    if (moduleType !== ModuleType.Simulate_Disp) {
      errorMessage = this.validateUsedGravels(errorMessage, state, deps, rangeId);
    }
    errorMessage = this.validatePumping(errorMessage, state, true, false);
    errorMessage = this.validateSettings(errorMessage, state);
    if (moduleType === ModuleType.Evaluate) {
      errorMessage = this.validateGaugeData(errorMessage, state);
    }

    if (startLength !== errorMessage.length) {
      return errorMessage;
    }

    return '';
  }

  public static checkValidationForTrendAnalysis(state: IValidatedState): string {
    let errorMessage = ErrorHelper.ERROR_MULTIPLE_SCREENS_MESSAGE_HEADER;
    const startLength = errorMessage.length;
    errorMessage = this.validateGaugeData(errorMessage, state);
    errorMessage = this.validateTrendAnalysisTab(errorMessage, state);
    if (startLength !== errorMessage.length) {
      return errorMessage;
    }
    return '';
  }

  private static validateGaugeData(errorMessage: string, state: IValidatedSimulateEvaluateState): string {
    if (!state.gaugeDataPageValidation.isValid) {
      errorMessage += '-> Data Grid <br>';
    }
    return errorMessage;
  }

  private static validateWell(errorMessage: string, state: IValidatedSimulateEvaluateState): string {
    if (!state.wellState.isGeneralDataScreenValid) {
      errorMessage += '-> Well - General Data <br>';
    }

    if (!state.wellState.CasingData.isValid) {
      errorMessage += '-> Well - Casing Data <br>';
    }

    if (!state.wellState.ZoneData.isValid) {
      errorMessage += '-> Well - Reservoir Data <br>';
    }

    if (!state.wellState.CaliperData.isValid) {
      errorMessage += '-> Well - Caliper Data <br>';
    }
    return errorMessage;
  }

  private static validateCompletion(errorMessage: string, state: IValidatedSimulateEvaluateState): string {
    if (!state.completionState.LowerCompletion.isValid) {
      errorMessage += '-> Completion - Lower Completion <br>';
    }

    if (!state.completionState.RunningString.isValid) {
      errorMessage += '-> Completion - Running String <br>';
    }

    if (!state.completionState.ShuntTube.isValid) {
      errorMessage += '-> Completion - Shunt Tubes <br>';
    }
    return errorMessage;
  }

  private static validatePumping(
    errorMessage: string,
    state: IValidatedSimulateEvaluateState,
    validateScheduleScreen: boolean,
    validateScheduleGenerator: boolean,
  ): string {
    if (!state.pumpingState.isInitialScreenValid) {
      errorMessage += '-> Pumping - Initial Data <br>';
    }

    if (!state.pumpingState.isWellFluidsScreenValid) {
      errorMessage += '-> Pumping - Well Fluids <br>';
    }

    if (validateScheduleScreen && !state.pumpingState.isScheduleScreenValid) {
      errorMessage += '-> Pumping - Schedule <br>';
    }

    if (
      !state.pumpingState.isAutomaticSchedulingPanelValid ||
      (validateScheduleGenerator ? !state.pumpingState.isScheduleGeneratorPanelValid : false)
    ) {
      errorMessage += '-> Pumping - Schedule Options <br>';
    }

    return errorMessage;
  }

  private static validateTrendAnalysisTab(errorMessage: string, deps: IValidatedState): string {
    if (!deps.validatedScenarioRangeProperties.isValid || !deps.validatedPumpRateAndPressure.isValid) {
      errorMessage += '-> Trend Analysis <br>';
    }
    return errorMessage;
  }

  public static validateUsedFluids(
    errorMessage: string,
    state: IValidatedSimulateEvaluateState,
    deps: FluidUsageSimulateEvaluateFinderDeps,
    rangeId: number,
  ): string {
    let isValid = true;
    for (const fluidId of state.fluidsState.Fluids.ids) {
      const isValidationRequired = FluidGravelUsageFinder.FindFluidInPumping(+fluidId, deps, rangeId);
      if (isValidationRequired.length && !state.fluidsState.validFluidIds.includes(+fluidId)) {
        isValid = false;
      }
    }
    if (!isValid) {
      errorMessage += '-> Fluids <br>';
    }
    return errorMessage;
  }

  public static validateUsedGravels(
    errorMessage: string,
    state: IValidatedSimulateEvaluateState,
    deps: FluidUsageSimulateEvaluateFinderDeps,
    rangeId: number,
  ): string {
    let isValid = true;
    for (const gravelId of state.gravelState.Gravels.ids) {
      const isValidationRequired = FluidGravelUsageFinder.FindGravelInPumping(+gravelId, deps, rangeId);
      if (isValidationRequired.length && !state.gravelState.validGravelIds.includes(+gravelId)) {
        isValid = false;
      }
    }
    if (!isValid) {
      errorMessage += '-> Gravel <br>';
    }
    return errorMessage;
  }

  private static validateSettings(errorMessage: string, state: IValidatedSimulateEvaluateState): string {
    if (!state.settings.isValid) {
      errorMessage += '-> Settings<br>';
    }
    return errorMessage;
  }

  public static checkValidationForGlobalOptions(
    validationMessage = '',
    orgGlobalOptions: ValidatedGlobalOptions,
    userGlobalOptions: ValidatedGlobalOptions,
  ): string {
    if ((!orgGlobalOptions.isValid || !userGlobalOptions.isValid) && !validationMessage.length) {
      validationMessage += ErrorHelper.ERROR_MULTIPLE_SCREENS_MESSAGE_HEADER;
    }

    if (!orgGlobalOptions.isValid) {
      validationMessage += '-> Settings - Organization <br>';
    }

    if (!userGlobalOptions.isValid) {
      validationMessage += '-> Settings - User <br>';
    }

    return validationMessage;
  }
}

export interface IGaugeDataPageValidation {
  isValid: boolean;
  invalidFileNames: string[];
}
